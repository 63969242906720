<template>
  <figure class="w-full mx-auto">
    <figcaption class="w-full relative bg-white">
      <img
        :src="product.image || require('@/assets/images/product.webp')"
        alt="product image"
        class="object-contain max-h-[17rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
    </figcaption>
    <blockquote>
      <div class="w-full relative pt-4">
        <div class="flex items-center gap-4">
          <div class="flex-1 space-y-2">
            <div class="grid">
              <p class="text-sm font-medium text-gray-800 truncate">
                {{ product.title }}
              </p>
            </div>
            <h1 class="font-medium rtl:font-semibold text-gray-600">
              <span class="text-sm font-bold"> {{ product.price }} ر.س</span>
            </h1>
          </div>
          <div>
            <button
              class="w-12 h-12 flex items-center justify-center rounded-full text-sm border border-gray-100 text-gray-600 hover:text-content hover:border-0 hover:bg-color"
            >
              <i class="fa-solid fa-shopping-cart align-middle"></i>
            </button>
          </div>
        </div>
      </div>
    </blockquote>
  </figure>
</template>
<script>
export default {
  name: "foodProduct",
  props: ["product"],
};
</script>
