<template>
  <section class="w-full">
    <main class="w-full space-y-6">
      <div class="w-full xl:container mx-auto bg-white rounded-xl grid">
        <header
          class="p-5 border-b border-gray-200 flex items-center gap-2 flex-wrap w-full relative flex-col sm:flex-row"
        >
          <div class="sm:flex-1 w-full">
            <div class="w-full sm:max-w-sm relative">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="بحث في الصفحات.."
                class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
                v-model="search_key"
                @keyup.enter.stop="searchAll"
              />
              <!-- @blur="handleSearch('close')" -->
              <span
                class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="search_disabled"
                ></i>
                <i v-else class="fa-solid fa-search"></i>
              </span>
            </div>
          </div>
          <div>
            <router-link
              to="/pages/create"
              class="bg-yellow-500 font-medium rtl:font-semibold text-sm rounded-lg text-center py-2 px-4"
            >
              صفحة جديدة
            </router-link>
          </div>
        </header>
        <div class="w-full pb-5 overflow-x-auto">
          <table class="styled-table">
            <thead>
              <tr>
                <th>#</th>
                <th>اسم الصفحة</th>
                <th>رابط الصفحة</th>
                <th>العرض في المتجر</th>
                <th>الاجراءات</th>
              </tr>
            </thead>
            <tbody v-if="pages.length > 0 && load">
              <tr v-for="(page, index) in pages" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ page.title }}</td>
                <td>
                  <a
                    :href="`${$store.state.store.store_info.store_url}/${page.slug}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-blue-500 no-underline hover:underline font-semibold"
                  >
                    {{ page.slug }}
                  </a>
                </td>
                <td>
                  <div class="flex items-center gap-3">
                    <input
                      v-model="page.status"
                      type="checkbox"
                      class="toggle-input"
                      :true-value="'on'"
                      :false-value="'off'"
                      @change="changeStatus(page.id, index)"
                    />
                  </div>
                </td>

                <td>
                  <div class="actions-group">
                    <div>
                      <router-link
                        :to="`/pages/${page.id}`"
                        class="py-1.5 px-3 rounded-lg bg-green-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      >
                        تعديل
                      </router-link>
                    </div>
                    <div>
                      <button
                        class="py-1.5 px-3 rounded-lg bg-transparent text-red-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                        data-title="حذف"
                        @click.prevent="deletePage(page.id, index)"
                        :disabled="delete_disabled[index]"
                      >
                        <i
                          class="fa-solid fa-spinner fa-spin"
                          v-if="delete_disabled[index]"
                        ></i>
                        <i class="fa-solid fa-trash" v-else></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!load">
              <TableLoad v-for="i in 4" :key="i" :rows="5" />
            </tbody>
            <tbody v-if="load && pages.length === 0">
              <tr>
                <td colspan="5" class="!text-center">لا توجد بيانات</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </section>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
export default {
  name: "IndexPages",
  components: { TableLoad },
  data() {
    return {
      pages: [],
      search_disabled: false,
      search_key: "",

      load: false,
      status_disabled: [],
      delete_disabled: [],
    };
  },
  created() {
    this.getPages();
  },

  methods: {
    getPages() {
      return this.axios
        .get("store_pages", {
          params: {
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.pages = data.result.store_pages;
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    deletePage(id, i) {
      this.$set(this.delete_disabled, i, true);
      return this.axios
        .post("store_pages/delete/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getPages();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.delete_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    changeStatus(id, i) {
      this.$set(this.status_disabled, i, true);
      return this.axios
        .post("store_pages/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getPages();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.status_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getOrders().then(() => {
        this.search_disabled = false;
      });
    },
  },
};
</script>
