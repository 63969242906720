<template>
  <section class="w-full p-6 space-y-7">
    <FileSystemHeader>
      <template #title> الاعدادات </template>
    </FileSystemHeader>
    <div
      class="w-full xl:container mx-auto flex lg:flex-row flex-col-reverse gap-6"
    >
      <aside class="w-full lg:w-auto lg:flex-1 lg:max-w-sm">
        <div class="lg:sticky lg:top-24">
          <figure class="w-full rounded-xl bg-white">
            <header class="w-full p-4 border-b border-gray-100">
              <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
                الاعدادات
              </h1>
            </header>
            <div
              class="w-full p-4 grid grid-cols-1 sm:grid-cols-2 lg:block lg:space-y-4 lg:max-h-[calc(100vh-12rem)] items-start justify-start gap-4 overflow-y-auto"
            >
              <Button
                title="اعدادات الحساب"
                icon="fa-regular fa-user"
                content="استعراض وتعديل معلومات الحساب"
                :component="'account'"
                :isComponent="isComponent === 'account'"
                @change="isComponent = $event"
              />

              <Button
                title="بيانات المتجر"
                icon="fa-solid fa-store"
                content="تعديل معلومات النشاط التجاري والتواصل الاجتماعي واوقات العمل"
                :component="'info'"
                :isComponent="isComponent === 'info'"
                @change="isComponent = $event"
              />
              <Button
                title="اللغات"
                icon="fa-solid fa-language"
                content="تغيير لغات المتجر و اختيار اللغة الاساسية لمتجرك"
                :isComponent="isComponent === 'languages'"
                :component="'languages'"
                @change="isComponent = $event"
              />
              <Button
                title="رابط المتجر الالكتروني"
                icon="fa-solid fa-globe"
                content="تغيير عنوان رابط متجرك الـ Domain"
                :isComponent="isComponent === 'domains'"
                :component="'domains'"
                @change="isComponent = $event"
              />
              <Button
                title="خيارات تصمم المتجر"
                icon="fa-solid fa-compass-drafting"
                content="التحكم في مظهر متجرك من حيث الالوان والقالب"
                :isComponent="isComponent === 'themes'"
                :component="'themes'"
                @change="isComponent = $event"
              />
              <Button
                title="اشعارات البريد الالكتروني"
                icon="fa-solid fa-envelope"
                content="التحكم في قوالب البريد الالكتروني المرسلة للعملاء"
                :isComponent="isComponent === 'email-templates'"
                :component="'email-templates'"
                @change="isComponent = $event"
              />
              <Button
                title="الصفحات التعريفية"
                icon="fa-solid fa-file"
                content="اضافة وتعديل الصفحات الفرعية لمتجرك"
                :isComponent="isComponent === 'pages'"
                :component="'pages'"
                @change="isComponent = $event"
              />
              <Button
                title="طرق الدفع"
                icon="fa-regular fa-credit-card"
                content="إدارة الحسابات البنكية وتمكين خيارات الدفع الاضافية"
                :isComponent="isComponent === 'payment-methods'"
                :component="'payment-methods'"
                @change="isComponent = $event"
              />

              <Button
                title="الربط مع الشركات"
                icon="fa-solid fa-truck-fast"
                content="الربط مع شركات الشحن والتوصيل والشركات الاخرى لاستقبال طلباتك من خلالهم لمتجرك هنا."
                :isComponent="isComponent === 'shipping-methods'"
                :component="'shipping-methods'"
                @change="isComponent = $event"
              />
              <Button
                title="الربط مع الواتساب ورسائل SMS"
                icon="fa-solid fa-message"
                content="تواصل مع عملاءك بكل سهولة من خلال الربط مع الواتساب"
                data-badge="قريبا"
                disabled
              />
            </div>
          </figure>
        </div>
      </aside>
      <main class="w-full lg:w-auto lg:flex-1 space-y-6">
        <component :is="isComponent"></component>
        <div class="w-full"></div>
      </main>
    </div>
  </section>
</template>
<script>
import Button from "@/components/settings/Button.vue";
import PersonalAccount from "@/components/settings/PersonalAccount.vue";
import PersonalPassword from "@/components/settings/PersonalPassword.vue";
import StoreInfo from "@/components/settings/StoreInfo.vue";
import Domains from "@/components/settings/Domains.vue";
import StoreLangs from "@/components/settings/StoreLangs.vue";
import customizingStore from "@/components/settings/customizingStore.vue";
import PaymentsAndBankAccounts from "@/components/settings/PaymentsAndBankAccounts.vue";
import ShippingMethods from "@/components/settings/ShippingMethods.vue";
import EmailTemplates from "@/components/settings/EmailTemplates.vue";
import Pages from "@/components/settings/Pages.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "SettingsPage",
  data() {
    return {
      isComponent: "account",
    };
  },

  components: {
    Button,
    PersonalPassword,
    account: PersonalAccount,
    info: StoreInfo,
    languages: StoreLangs,
    domains: Domains,
    themes: customizingStore,
    "payment-methods": PaymentsAndBankAccounts,
    "shipping-methods": ShippingMethods,
    "email-templates": EmailTemplates,
    FileSystemHeader,
    Pages,
  },
};
</script>
