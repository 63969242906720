<template>
  <form class="w-full max-w-xl mx-auto space-y-6 mt-10">
    <div class="w-full space-y-3">
      <p class="text-red-500 font-semibold text-center" v-if="error">
        {{ error }}
      </p>
      <label
        for="email"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        البريد الالكتروني
      </label>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="example@example.com"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        autocomplete="off"
        v-model="add_data.email_mobile"
      />
      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.email_mobile || []).join(" ") }}
      </span>
    </div>
    <div class="w-full space-y-3">
      <label
        for="password"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        كلمة المرور
      </label>
      <div class="relative">
        <input
          type="password"
          name="password"
          id="password"
          placeholder="••••••••"
          class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded-md"
          autocomplete="off"
          v-model="add_data.password"
        />
        <button
          class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
          type="button"
          @click.prevent="textPasswordType"
        >
          <i class="fa-solid fa-eye"></i>
        </button>
      </div>
      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.password || []).join(" ") }}
      </span>
    </div>
    <div class="w-full space-y-4">
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500 text-gray-800 text-center font-medium rtl:font-semibold"
        :disabled="disabled"
        @click.prevent="login"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
        تسجيل دخول
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500/10 text-yellow-500 text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'register')"
      >
        ليس لدي حساب في متجري
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md text-yellow-500 bg-transparent text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'password')"
        v-if="false"
      >
        نسيت كلمة المرور؟
      </button>
    </div>
  </form>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      disabled: false,
      add_data: {
        email_mobile: "",
      },
      add_errors: {},
      error: "",
    };
  },
  methods: {
    login() {
      this.disabled = true;
      let data = {
        ...this.add_data,
        email_mobile: this.add_data.email_mobile
          .replace(/\s/g, "")
          .replace("+", ""),
      };
      return this.axios
        .post("login", data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.$store.commit("SET_USER_DATA", {
              user: data.result.account,
              token: data.result.access_token,
            });
            const search = new URLSearchParams(window.location.search);
            this.$cookies.set("token", data.result.access_token);
            if (search.has("redirect")) {
              if (!["auth"].includes(search.get("redirect"))) {
                this.$router.push({
                  path: search.get("redirect"),
                });
              } else {
                this.$router.push("/");
              }
            } else {
              this.$router.push("/");
            }
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
