<template>
  <figure class="w-full">
    <figcaption class="w-full relative">
      <img
        :src="product.image || require('@/assets/images/product.png')"
        alt="product image"
        class="object-contain max-h-[12.5rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
      <button
        class="w-12 h-12 rounded-full absolute bg-color text-content top-2 left-2 rtl:left-auto text-sm flex items-center justify-center"
      >
        <i e class="fa-solid fa-shopping-cart"></i>
      </button>
    </figcaption>
    <blockquote>
      <div class="w-full relative p-4">
        <p class="text-sm font-medium rtl:font-semibold text-gray-600 mb-1">
          flag
        </p>
        <h1 class="text-base font-bold text-gray-800 mb-3 line-clamp-2">
          {{ product.title }}
        </h1>
        <p class="text-sm font-medium rtl:font-semibold text-gray-600">
          السعر:
          <span class="text-lg font-bold text-color mb-4">
            {{ product.price }} ر.س</span
          >
        </p>
      </div>
    </blockquote>
  </figure>
</template>
<script>
export default {
  name: "basicProduct",
  props: ["product"],
};
</script>
