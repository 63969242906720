<template>
  <form class="w-full space-y-6">
    <figure class="w-full bg-white rounded-xl">
      <header class="w-full p-4 border-b border-gray-100">
        <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
          تغيير كلمة المرور
        </h1>
        <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
          بإمكانك تغيير كلمة المرور عبر هذه الصفحة.
        </p>
      </header>
      <blockquote class="w-full py-10 px-6 space-y-6">
        <div class="w-full space-y-3">
          <label
            for="current_password"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            كلمة المرور الحالية
          </label>
          <input
            type="password"
            name="current_password"
            id="current_password"
            placeholder="••••••••"
            class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
          />
        </div>

        <div class="flex w-full flex-col sm:flex-row gap-4">
          <div class="w-full space-y-3">
            <label
              for="new_password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              كلمة المرور الجديدة
            </label>
            <input
              type="password"
              name="new_password"
              id="new_password"
              placeholder="••••••••"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            />
          </div>
          <div class="w-full space-y-3">
            <label
              for="confirmation_password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              إعادة تأكيد كلمة المرور
            </label>
            <input
              type="password"
              name="confirmation_password"
              id="confirmation_password"
              placeholder="••••••••"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            />
          </div>
        </div>
      </blockquote>
    </figure>
    <button
      class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
    >
      حفظ التغييرات
    </button>
  </form>
</template>
<script>
export default {
  name: "PersonalPassword",
};
</script>
