<template>
  <figure class="w-full mx-auto relative overflow-hidden product-item-hovered">
    <figcaption class="w-full relative bg-white">
      <img
        :src="product.image || require('@/assets/images/product.png')"
        alt="product image"
        class="object-contain max-h-[17rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
    </figcaption>
    <blockquote>
      <div class="w-full relative pt-4 text-center space-y-2">
        <h1 class="font-medium rtl:font-semibold text-sm">
          {{ product.title }}
        </h1>
        <h5 class="text-color text-sm font-medium">
          {{ product.price.toFixed(2) }}
          <span class="line-through text-gray-500">{{
            product.price.toFixed(2)
          }}</span>
        </h5>
      </div>
    </blockquote>
    <!-- box -->
    <div
      class="absolute inset-0 bg-white/70 p-4 flex items-center justify-end flex-col w-full h-full gap-4 hovered-box opacity-0 pointer-events-none invisible translate-y-4"
    >
      <div class="space-y-1 text-center">
        <div class="grid">
          <h1 class="font-medium rtl:font-semibold text-sm truncate">
            {{ product.title }}
          </h1>
        </div>
        <h5 class="text-color text-sm font-medium">
          {{ product.price.toFixed(2) }}
          <span class="line-through text-gray-500">{{
            product.price.toFixed(2)
          }}</span>
        </h5>
      </div>
      <div class="space-y-2">
        <router-link
          to="#"
          class="py-1.5 px-6 rounded-full border-color border text-center text-xs text-color font-medium table mx-auto truncate"
        >
          View product
        </router-link>
        <button
          class="py-1.5 px-6 rounded-full text-center text-xs bg-color text-content font-medium table mx-auto truncate"
        >
          Add to cart
        </button>
      </div>
    </div>
  </figure>
</template>
<script>
export default {
  name: "hoveredProduct",
  props: ["product"],
};
</script>
<style lang="scss">
.product-item-hovered {
  &:hover {
    .hovered-box {
      @apply opacity-100 pointer-events-auto translate-y-0 visible;
    }
  }
}
</style>
