<template>
  <div class="w-full space-y-6">
    <figure class="w-full bg-white rounded-xl">
      <header class="w-full p-4 border-b border-gray-100">
        <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
          الشركات
        </h1>
      </header>
      <blockquote class="w-full p-2 space-y-2">
        <div class="space-y-4" v-if="!load">
          <div class="space-y-3 px-4 py-3" v-for="l in 3" :key="l">
            <div class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"></div>
            <div class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
          </div>
        </div>
        <div class="space-y-2" v-if="load">
          <div
            class="w-full flex items-center justify-center gap-4 p-4 border-b border-gray-100 last:border-0"
            v-for="(item, index) in shipping_methods"
            :key="index"
          >
            <div class="flex items-center justify-start gap-4 flex-1">
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-full h-full object-contain"
                  />
                </div>
              </div>
              <div class="space-y-1 flex-1">
                <h1
                  class="text-base font-semibold rtl:font-bold text-yellow-600"
                >
                  {{ item.name }}
                </h1>

                <div class="grid">
                  <a
                    :href="item.website"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-600 font-medium text-sm hover:underline inline-block truncate"
                    v-if="item.website"
                  >
                    {{ item.website }}
                  </a>
                </div>
                <a
                  href="#"
                  class="text-blue-500 font-medium text-sm hover:underline block !my-2"
                  v-if="Number(item.has_request) === 1"
                  @click.prevent="openRequests(index)"
                >
                  عرض الطلب
                </a>
                <transition name="slideUpDown">
                  <div
                    class="w-full mt-2 p-4 bg-gray-100 rounded-md"
                    v-if="
                      request_active[index] && Number(item.has_request) === 1
                    "
                  >
                    <ul
                      class="space-y-3 list-disc marker:text-yellow-600 pl-6 rtl:pr-6 rtl:pl-0"
                    >
                      <li
                        class="space-y-2"
                        v-for="(doc, idx) in (
                          requests.find(
                            (reqItem) => reqItem.method.id === payment.id
                          ) || {}
                        ).request_documents"
                        :key="idx"
                      >
                        <h1 class="text-base font-semibold rtl:font-bold">
                          {{ doc.name }}
                          <span
                            class="text-xs inline-block py-1 px-2 rounded-md bg-gray-200"
                            >{{ $t(`status.${doc.status}`) }}</span
                          >
                        </h1>
                        <p
                          class="text-sm font-semibold rtl:font-bold text-gray-600"
                        >
                          {{ doc.desc }}
                          <a
                            :href="doc.document_file"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-xs hover:underline rounded-md inline-block text-yellow-600"
                            v-if="doc.document_file"
                            :download="doc.document_file"
                          >
                            عرض الملف
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
            <div
              class="flex items-center justify-center flex-col sm:flex-row gap-4"
            >
              <div v-if="Number(item.need_review) === 0">
                <div class="flex flex-col items-center justify-center gap-3">
                  <!-- <div class="block w-full h-px bg-gray-200"></div> -->
                  <input
                    v-model="item.status"
                    type="checkbox"
                    class="toggle-input"
                    :true-value="'on'"
                    :false-value="'off'"
                    @change="changeStatus(item.id)"
                    :disabled="Number(item.need_review) !== 0"
                  />
                </div>
              </div>
              <div v-if="Number(item.need_review) !== 0">
                <button
                  class="py-2 px-4 rounded-sm bg-yellow-600 text-white text-xs font-medium"
                  @click.prevent="openDocuments(item.id)"
                >
                  تفعيل
                </button>
              </div>
              <div v-if="item.config_fields">
                <div>
                  <button
                    class="border border-gray-200 text-gray-600 text-xs py-2 px-4 hover:bg-gray-100"
                    @click.prevent="openConfig(item.id)"
                  >
                    الاعداد
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </blockquote>
    </figure>

    <!-- apply -->
    <SweetModal
      :close="false"
      class="!mt-0"
      v-if="apply_active"
      @close="apply_active = $event"
    >
      <template #default>
        <div class="w-full p-6" v-if="documents_load && documents.length !== 0">
          <div
            v-for="(item, index) in documents"
            :key="index"
            class="border-b border-gray-100 last:!border-0 px-4 py-6 first:pt-0 last:pb-0"
          >
            <figure class="xs:grid w-full space-y-2">
              <div class="xs:!py-2">
                {{ item.name }}
              </div>
              <p
                class="font-medium rtl:font-semibold text-gray-600 table text-sm mt-2"
                v-if="item.desc"
              >
                {{ item.desc }}
                <a
                  :href="item.standard_form"
                  class="font-medium rtl:font-semibold text-yellow-600 inline-block text-sm"
                  :download="item.standard_form"
                  v-if="item.is_standard_form === 1"
                >
                  تحميل النوذج
                </a>
              </p>
              <div class="xs:!py-2 space-y-2 !mt-4">
                <label
                  :for="`uploader_image_${item.id}`"
                  class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-md inline-block"
                  accept="image/*,application/pdf"
                >
                  ارفاق ملف
                </label>
                <input
                  type="file"
                  :name="`uploader_image_${item.id}`"
                  :id="`uploader_image_${item.id}`"
                  hidden
                  accept="image/*"
                  class="hidden"
                  @change.prevent="
                    apply_data['document_' + item.id] = $event.target.files[0]
                  "
                />
                <div
                  v-if="apply_data['document_' + item.id]"
                  class="inline-block"
                >
                  <a
                    :href="convertImage(apply_data['document_' + item.id])"
                    class="text-xs text-gray-600 px-4 font-medium"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ apply_data["document_" + item.id].name }}
                  </a>
                </div>
                <div v-if="apply_errors['document_' + item.id]">
                  <p class="text-xs text-red-500 px-4">
                    {{ apply_errors["document_" + item.id].join(" ") }}
                  </p>
                </div>
              </div>
            </figure>
          </div>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="apply_disabled"
                @click.prevent="applyDocuments"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="apply_disabled"
                ></i>
                حفظ التغييرات
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                type="reset"
                @click.prevent="closeApply"
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
        <div class="w-full p-6" v-if="documents_load && documents.length === 0">
          <p class="text-center text-gray-600 font-medium rtl:font-semibold">
            ليس هناك مستندات
          </p>
        </div>
        <div class="space-y-4" v-if="!documents_load">
          <div class="space-y-3 px-4 py-3" v-for="l in 3" :key="l">
            <div class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"></div>
            <div class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </template>
    </SweetModal>
    <!-- apply -->
    <!-- add shipping config -->
    <SweetModal
      class="!mt-0"
      v-if="config_active"
      @close="config_active = $event"
      :title="'تعديل حساب الشركة'"
    >
      <template #default>
        <div class="w-full p-6">
          <div class="w-full space-y-4" v-if="config_content.image">
            <div>
              <img
                :src="
                  config_content.image ||
                  require('@/assets/images/placeholder.png')
                "
                alt=""
                class="max-w-[150px]"
              />
            </div>
            <div>
              <p class="text-gray-600 font-medium rtl:font-semibold">
                {{ config_content.desc }}
              </p>
              <a
                :href="config_content.website"
                class="text-blue-600 inline-block font-medium rtl:font-semibold"
                >عرض المزيد</a
              >
            </div>
          </div>
          <form @submit.prevent class="w-full space-y-4 mt-4">
            <div
              v-for="(item, index) in config_content.config_fields"
              :key="index"
            >
              <div
                class="w-full space-y-3"
                v-if="
                  ['text', 'date', 'url', 'number'].includes(item.input_type) &&
                  !['access_token', 'payment_method'].includes(item.key)
                "
              >
                <label
                  :for="item.key"
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  {{ item.name }}
                </label>
                <input
                  :type="item.input_type"
                  :name="item.key"
                  :id="item.key"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="config_data[item.key]"
                />
                <p class="text-red-500 font-medium rtl:font-semibold">
                  {{ (config_errors[item.key] || []).join(" ") }}
                </p>
              </div>
              <div
                class="space-y-3"
                v-if="['boolean'].includes(item.input_type)"
              >
                <div class="checkbox">
                  <div>
                    <input
                      type="checkbox"
                      :name="item.key"
                      :id="item.key"
                      :true-value="1"
                      :false-value="0"
                      v-model="config_data[item.key]"
                    />
                  </div>
                  <label :for="item.key">
                    {{ item.name }}
                  </label>
                </div>
                <p class="text-red-500 font-medium rtl:font-semibold">
                  {{ (config_errors[item.key] || []).join(" ") }}
                </p>
              </div>
            </div>

            <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                  :disabled="config_disabled"
                  @click.prevent="updateConfig"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="config_disabled"
                  ></i>
                  حفظ
                </button>
              </div>
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                  type="reset"
                  @click.prevent="closeHyper"
                >
                  الغاء
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </SweetModal>
    <!-- add shipping config -->
  </div>
</template>
<script>
import TableLoad from "../loading/TableLoad.vue";
import SweetModal from "../modals/sweetModal.vue";
export default {
  name: "ShippingMethods",
  data() {
    return {
      load: false,
      shipping_methods: [],
      request_load: false,
      requests: [],
      apply_disabled: false,
      apply_data: {
        item_method_id: "",
      },
      documents: [],
      documents_load: false,
      apply_active: false,
      apply_errors: {},
      config_disabled: false,
      config_active: false,
      config_data: {
        shipping_method_id: null,
      },
      config_content: {},
      config_errors: {},
      requests: [],
      request_active: [],
    };
  },
  created() {
    this.getItems();
    this.getRequests();
  },
  methods: {
    getRequests() {
      return this.axios
        .get("settings/shipping_methods/requests")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.requests = data.result.shipping_requests;
          }
        })
        .finally(() => (this.request_load = true));
    },
    getItems() {
      return this.axios
        .get("settings/shipping_methods")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.shipping_methods = data.result.shipping_methods;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => (this.load = true));
    },
    closeApply() {
      this.apply_data = {};
      this.apply_disabled = false;
    },
    changeStatus(id) {
      return this.axios
        .post("settings/shipping_methods/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getItems();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          const item = this.shipping_methods.find((item) => item.id === id);
          if (error_flag === 1) {
            this.createdAlert("error", message);
            this.$set(item, "status", "off");
          }
          if (error_flag === 1 && id === 3) {
            this.hyper_active = true;
            this.$set(item, "status", "off");
          }
        });
    },
    updateConfig() {
      this.config_disabled = true;
      this.config_errors = {};
      return this.axios
        .post("settings/shipping_methods/update", this.config_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getItems();
            this.createdAlert("success", data.message);
            this.config_data = {};
            this.config_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.config_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.config_disabled = false;
          // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },

    closeHyper() {
      Object.keys(this.config_data).forEach((key) => {
        this.config_data[key] = "";
      });
      this.config_active = false;
    },
    openConfig(id) {
      let item = this.shipping_methods.find((item) => item.id == id);
      this.config_content = item;
      item.config_fields?.forEach((field) => {
        if (field) {
          let fields = { [field.key]: null };

          switch (field.key) {
            case "access_token":
              fields.access_token =
                this.$store.state.token || this.$cookies.get("token");
              break;
            default:
              break;
          }
          this.config_data = {
            ...this.config_data,
            shipping_method_id: item.id,
            ...fields,
          };
        }
        if (item?.config) {
          Object.keys(item.config).forEach((key) => {
            this.config_data[key] = item.config[key];
          });
        }
      });
      this.config_active = true;
    },
    openDocuments(id) {
      this.apply_data.item_method_id = id;
      return this.axios
        .get("settings/shipping_methods/documents/" + id)
        .then(({ data }) => {
          this.documents = data.result.documents;
          this.apply_active = true;
          data.result.documents.forEach((doc) => {
            this.apply_data = {
              ...this.apply_data,
              ["document_" + doc.id]: null,
            };
          });
        })
        .finally(() => {
          this.documents_load = true;
        });
    },
    applyDocuments() {
      this.apply_disabled = true;
      this.apply_errors = {};
      let fd = new FormData();
      Object.keys(this.apply_data).forEach((key) => {
        if (/document_*/.test(key)) {
          fd.append(key, this.apply_data[key], this.apply_data[key].name);
        } else {
          fd.append(key, this.apply_data[key]);
        }
      });
      return this.axios
        .post("settings/shipping_methods/apply", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.apply_data = {};
            this.apply_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.apply_errors = message;
            } else {
              this.error = message;
            }
          }
        })
        .finally(() => {
          this.apply_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    openRequests(i) {
      this.$set(this.request_active, i, !this.request_active[i]);
    },
  },
  components: { TableLoad, SweetModal },
};
</script>
