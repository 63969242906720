<template>
  <div class="w-full">
    <section class="w-full" v-if="load">
      <header
        class="w-full py-8 px-6 xl:container flex stepper-container overflow-x-auto"
      >
        <div
          class="w-full flex items-center justify-start gap-4 flex-col p-4 step-item whitespace-nowrap min-w-[8rem] active-step"
          v-for="(track, i) in order.tracking"
          :key="i"
        >
          <div>
            <div
              class="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 border-2 border-gray-100 step-icon"
            >
              <i class="fa-solid fa-check"></i>
            </div>
          </div>
          <div class="space-y-1 text-center">
            <h1 class="text-sm font-semibold text-gray-600">
              {{ $t(`status.${track.status}`) }}
            </h1>
            <p class="text-xs text-gray-500 font-medium">
              {{ track.created_at | moment("from") }}
            </p>
          </div>
        </div>
      </header>
      <div
        class="xl:container mx-auto w-full flex lg:flex-row flex-col flex-wrap gap-6 px-6 pb-10"
      >
        <div class="w-full lg:flex-1">
          <div class="bg-white rounded-xl w-full">
            <header
              class="w-full p-5 flex items-center flex-wrap border-b border-gray-200"
            >
              <div class="flex-1">
                <p
                  class="text-yellow-600 text-sm font-medium rtl:font-semibold"
                >
                  {{ $t("table.order_no") }}
                  <span class="text-gray-600">{{ order.order_no }}</span>
                </p>
              </div>
              <div>
                <div class="flex gap-4 items-center">
                  <div>
                    <i
                      class="fa-solid fa-spinner fa-spin text-gray-600"
                      v-if="order_disabled"
                    ></i>
                  </div>
                  <div>
                    <select
                      id="order_status"
                      name="status"
                      class="custom-select text-sm"
                      v-model="order_status"
                      @change="updateOrderStatus"
                    >
                      <option value="" selected disabled>-- اختار --</option>
                      <option
                        v-for="(status, idx) in statuses"
                        :key="idx"
                        :disabled="status === 'new'"
                        :value="status"
                      >
                        {{ $t(`status.${status}`) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </header>
            <div class="w-full p-5">
              <h1
                class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
              >
                {{ $t("table.products") }}
                <span>({{ order.products.length }})</span>
              </h1>
              <div
                class="w-full border border-gray-200 rounded-lg overflow-x-auto mb-8"
              >
                <table class="styled-table">
                  <thead>
                    <tr>
                      <th>{{ $t("table.product") }}</th>
                      <th>{{ $t("table.sku") }}</th>
                      <th>{{ $t("table.qty") }}</th>
                      <th>{{ $t("table.price") }} ({{ $t("sar") }})</th>
                      <th>{{ $t("table.total") }} ({{ $t("sar") }})</th>
                    </tr>
                  </thead>
                  <tbody v-if="order.products.length > 0">
                    <tr v-for="(product, idx) in order.products" :key="idx">
                      <td>
                        <div class="flex items-center gap-4">
                          <div>
                            <div class="w-10 h-10">
                              <img
                                :src="product.image"
                                alt="product image"
                                class="w-full h-full rounded-full object-cover border border-gray-200 bg-gray-200"
                              />
                            </div>
                          </div>
                          <div class="flex-1">
                            <span
                              class="text-sm font-medium rtl:font-semibold max-w-[15rem] block line-clamp-2"
                            >
                              {{ product.name }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{{ product.sku }}</td>
                      <td>{{ product.qty }}</td>
                      <td>{{ product.price }}</td>
                      <td>
                        {{
                          Number(
                            Number(product.qty) * Number(product.price)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="!text-center">
                        {{ $t("no_data.products") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex gap-4 flex-col md:flex-row mb-8">
                <div class="flex-1">
                  <h1
                    class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
                  >
                    {{ $t("invoice") }}
                  </h1>
                  <div
                    class="w-full overflow-y-hidden overflow-x-auto border border-gray-200 rounded-xl"
                  >
                    <table class="styled-table">
                      <tbody>
                        <tr>
                          <td>{{ $t("table.subtotal") }}</td>
                          <td>{{ order.sub_total || 0 }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t("table.delivery_fees") }}</td>
                          <td>{{ order.delivery_fees || 0 }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t("table.discount") }}</td>
                          <td>{{ order.coupon_discount || 0 }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t("table.vat") }}</td>
                          <td>{{ order.vat || 0 }}</td>
                        </tr>

                        <tr>
                          <td>{{ $t("table.total") }}</td>
                          <td>{{ order.total || 0 }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex-1 w-full md:max-w-sm">
                  <h1
                    class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
                  >
                    {{ $t("client_notes") }}
                  </h1>
                  <div class="w-full bg-yellow-600/10 p-5 rounded-lg mb-6">
                    <p
                      class="text-sm font-medium rtl:font-semibold text-gray-800"
                    >
                      {{ order.notes || "-" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flex-1 border border-gray-100 rounded-xl"></div> -->
        <div
          class="flex-1 w-full lg:max-w-md space-y-6 sm:space-y-0 lg:space-y-6 grid grid-cols-1 md:grid-cols-2 gap-4 lg:block"
        >
          <div>
            <div class="w-full bg-white rounded-xl">
              <header class="w-full p-5 border-b border-gray-200">
                <p class="text-sm font-semibold rtl:font-bold text-yellow-600">
                  {{ $t("table.client_info") }}
                </p>
              </header>
              <div class="w-full p-5">
                <ul class="list-none space-y-4">
                  <li>
                    <a
                      href="#"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span>
                        <i class="fa-regular fa-user text-gray-600"></i>
                      </span>
                      <span class="flex-1"> {{ order.customer_name }} </span>
                    </a>
                  </li>
                  <li>
                    <a
                      :href="`tel:${order.customer_mobile}`"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span>
                        <i class="fa-solid fa-phone text-gray-600"></i>
                      </span>
                      <span class="flex-1">
                        {{ order.customer_mobile }}
                      </span>
                      <span>
                        <i class="fa-solid fa-circle-check text-green-600"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      :href="`mailto:${order.customer_email}`"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span> <i class="fa-solid fa-envelope"></i> </span>
                      <span class="flex-1"> {{ order.customer_email }} </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span>
                        <i class="fa-solid fa-map-marker-alt"></i>
                      </span>
                      <span class="flex-1">
                        {{ order.shipping_address }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span>
                        <i class="fa-solid fa-map-location-dot"></i>
                      </span>
                      <span class="flex-1">
                        {{ (order.shipping_region || {}).name || "-" }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                    >
                      <span>
                        <i class="fa-solid fa-map-location-dot"></i>
                      </span>
                      <span class="flex-1">
                        {{ (order.shipping_city || {}).name || "-" }}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="w-full bg-white rounded-xl">
              <header
                class="w-full p-5 border-b border-gray-200 flex items-center gap-4"
              >
                <div class="flex-1">
                  <p
                    class="text-sm font-semibold rtl:font-bold text-yellow-600"
                  >
                    {{ $t("shipping_way") }}
                  </p>
                </div>
                <div>
                  <button
                    class="py-1.5 px-3 rounded-md bg-yellow-600 text-sm font-medium text-white"
                    @click.prevent="openShippingData($route.params.order_id)"
                  >
                    خيارات الشحن
                  </button>
                </div>
              </header>
              <div class="w-full">
                <table class="styled-table">
                  <tbody>
                    <tr>
                      <td>طريقة التوصيل</td>
                      <td>مندوب</td>
                    </tr>

                    <tr>
                      <td>{{ $t("table.payment_opt_meth") }}</td>
                      <td>{{ (order.payment_method || {}).name || "-" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="space-y-6">
            <div class="w-full bg-white rounded-xl" v-if="order.payment_status">
              <header class="w-full p-5 border-b border-gray-200">
                <p class="text-sm font-semibold rtl:font-bold text-yellow-600">
                  {{ $t("table.payment_details") }}
                </p>
              </header>
              <div class="w-full p-5 space-y-4">
                <form class="w-full space-y-4">
                  <select
                    id="payment_status"
                    name="status"
                    class="custom-select"
                    v-model="payment_status"
                  >
                    <option value="" selected disabled>-- اختار --</option>
                    <option
                      v-for="(status, idx) in payment_statuses"
                      :key="idx"
                      :disabled="status === 'new'"
                      :value="status"
                    >
                      {{ $t(`status.${status}`) }}
                    </option>
                  </select>
                  <div class="w-full">
                    <button
                      class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm"
                      :disabled="payment_disabled"
                      @click.prevent="updatePayment"
                    >
                      <i
                        class="fa-solid fa-spinner fa-spin"
                        v-if="payment_disabled"
                      ></i>
                      {{ $t("btn.change_status") }}
                    </button>
                  </div>
                </form>
                <a
                  :href="order.payment_details"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="py-1.5 px-4 rounded-md bg-gray-600 text-gray-200 text-xs inline-block"
                  v-if="order.payment_details"
                >
                  {{ getFileName(order.payment_details) }}
                </a>
              </div>
            </div>
            <div>
              <div
                class="grid gap-4 w-full"
                v-if="
                  ['ready', 'delivering', 'completed'].includes(order.status) ||
                  order.generate_waybill === 1
                "
              >
                <div
                  class="w-full"
                  v-if="
                    order.generate_waybill === 0 ||
                    !order.download_waybill ||
                    order.download_waybill === 0
                  "
                >
                  <button
                    class="w-full py-3 px-4 rounded-md bg-green-600 text-white font-medium rtl:font-semibold text-sm"
                    @click.prevent="generateWayBill"
                    :disabled="generate_disabled"
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="generate_disabled"
                    ></i>
                    اصدار بوليصة
                  </button>
                </div>
                <div
                  class="w-full"
                  v-if="
                    order.generate_waybill === 1 &&
                    (order.download_waybill || order.download_waybill === 1)
                  "
                >
                  <button
                    class="w-full py-3 px-4 rounded-md bg-blue-500 text-white font-medium rtl:font-semibold text-sm"
                    @click.prevent="downloadWayBill"
                    :disabled="download_disabled"
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="download_disabled"
                    ></i>
                    تحميل البوليصة
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="py-10 table mx-auto text-center" v-if="!load">
      <i class="fa-solid fa-spinner fa-spin text-3xl text-yellow-500"></i>
      <p class="text-gray-600 text-sm font-medium rtl:font-semibold mt-2">
        جاري تجهيز البيانات
      </p>
    </div>

    <!-- shipping modal -->
    <sweet-modal
      :title="'خيارات التوصيل'"
      v-if="add_active"
      @close="add_active = $event"
    >
      <template>
        <form class="p-6 space-y-2">
          <div class="w-full space-y-3">
            <label
              for="shipping"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              شركة الشحن
            </label>
            <select
              name="shipping"
              id="shipping"
              class="custom-select"
              v-model="add_data.shipping_method_id"
            >
              <option value="" disabled select>-- اختار --</option>
              <option v-for="item in shipping" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors["shipping_method_id"] || []).join(" ") }}
            </p>
          </div>
          <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="w-full space-y-3">
              <label
                for="weight"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الوزن
              </label>
              <input
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.pack.weight"
                type="number"
                min="1"
                id="weight"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["pack.weight"] || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="length"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الكمية
              </label>
              <input
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.pack.length"
                type="number"
                min="1"
                id="length"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["pack.length"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="w-full space-y-3">
              <label
                for="width"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                العرض
              </label>
              <input
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.pack.width"
                type="number"
                min="1"
                id="width"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["pack.width"] || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="height"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الطول
              </label>
              <input
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.pack.height"
                type="number"
                min="1"
                id="height"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["pack.height"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex items-center gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-6 rounded-md bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                @click.prevent="addShippingDetails"
                :disabled="add_disabled"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                ارسال
              </button>
            </div>
            <div>
              <button
                class="py-2 px-6 rounded-md bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="closeShipping"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </sweet-modal>
    <!-- ./shipping modal -->
  </div>
</template>
<script>
import sweetModal from "@/components/modals/sweetModal.vue";
export default {
  components: { sweetModal },
  name: "SingleOrder",

  data() {
    return {
      load: false,
      order: {},
      statuses: [
        "new",
        "preparing",
        "ready",
        "delivering",
        "completed",
        "cancelled",
      ],
      status_index: [],
      order_status: "",
      order_disabled: false,
      payment_disabled: false,
      payment_status: "",
      payment_statuses: ["success", "pending", "rejected"],
      add_data: {
        id: null,
        shipping_method_id: "",
        pack: {},
      },
      add_errors: {},
      add_disabled: false,
      shipping: [],
      add_active: false,
      generate_disabled: false,
      download_disabled: false,
    };
  },
  created() {
    this.getOrders();
    this.getShippingMethods();
  },
  methods: {
    getShippingMethods() {
      return this.axios
        .get("settings/shipping_methods", {
          params: {
            status: "on",
          },
        })
        .then(({ data }) => {
          this.shipping = data.result.shipping_methods;
        });
    },
    getOrders() {
      return this.axios
        .get("orders/" + this.$route.params.order_id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            let order = data.result.order;
            this.order = order;
            this.order_status = order.status;
            this.payment_status = order.payment_status;
            let index = this.statuses.findIndex((s) => s === order.status);
            if (index > 0) {
              for (let i = 0; i <= index; i++) {
                this.status_index.push(i);
              }
            }
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.orders = {};
          }
          this.$router.go(-1);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateOrderStatus() {
      this.order_disabled = true;
      return this.axios
        .post("orders/update_status", {
          status: this.order_status,
          id: this.$route.params.order_id,
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getOrders();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.order_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updatePayment() {
      this.payment_disabled = true;
      return this.axios
        .post("orders/update_payment_status", {
          status: this.payment_status,
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getOrders();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.payment_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    addShippingDetails() {
      this.add_disabled = true;
      this.add_errors = {};
      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (key === "pack") {
          Object.keys(this.add_data[key]).forEach((packKey) => {
            fd.append(`pack[${packKey}]`, this.add_data[key][packKey]);
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });

      return this.axios
        .post("orders/update_shipping_method", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = {
              id: null,
              shipping_method_id: "",
              pack: {},
            };
            this.getOrders().then(() => {
              this.createdAlert("success", data.message);
              this.add_active = false;
            });
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    openShippingData(id) {
      let totalQuantity = 0;
      let item = this.order;
      item?.products.forEach((product) => {
        totalQuantity += Number(product.qty);
      });
      if (item?.pack_details) {
        this.add_data = {
          ...this.add_data,
          id,
          pack: {
            ...item.pack_details,
          },
        };
      } else {
        this.add_data = {
          ...this.add_data,
          id,
          pack: {
            ...this.add_data.pack,
            length: totalQuantity,
          },
        };
      }
      this.add_active = true;
    },
    closeShipping() {
      this.add_data = {
        id: null,
        shipping_method_id: "",
        pack: {},
      };
      this.add_active = false;
    },
    generateWayBill() {
      let id = this.$route.params.order_id;
      this.generate_disabled = true;

      return this.axios
        .post(`orders/generate_waybill/${id}`)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getOrders();
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.generate_disabled = false;
        });
    },
    downloadWayBill() {
      this.download_disabled = true;
      let id = this.$route.params.order_id;
      this.axios
        .get(`orders/download_waybill/${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `waybill-${this.$route.params.order_id}.pdf`;
          link.click();
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.download_disabled = false;
        });
    },
  },
};
</script>
<style lang="scss">
.stepper-container {
  .step-item {
    @apply relative;
    &:not(:last-child) {
      &::after {
        content: "";
        @apply absolute top-10 w-1/2 h-0.5 bg-gray-300 right-0 rtl:right-auto rtl:left-0 z-[-1];
      }
    }
    &:not(:first-child) {
      &::before {
        content: "";
        @apply absolute top-10 w-1/2 h-0.5 bg-gray-300 left-0 rtl:left-auto rtl:right-0 z-[-1];
      }
    }
  }
  .active-step {
    &:not(:last-child) {
      &::after {
        @apply bg-yellow-600;
      }
    }
    &:not(:first-child) {
      &::before {
        @apply bg-yellow-600;
      }
    }
    .step-icon {
      @apply bg-yellow-600 text-white;
    }
  }
}
</style>
