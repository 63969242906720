<template>
  <section class="w-full py-10 px-6">
    <div class="w-full xl:container space-y-6">
      <header
        class="p-5 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row rounded-xl bg-white"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="بحث في الكوبونات.."
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
        <div>
          <div class="flex gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-4 rounded-lg bg-yellow-600/10 text-yellow-600 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                @click="openCloseMoreBox"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
            </div>
          </div>
          <div
            class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
            ref="moreBox"
          >
            <button
              @click.prevent="active = true"
              class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm block w-full"
            >
              <i class="fa-solid fa-plus"></i>
              اضافة كوبون جديد
            </button>
            <button
              class="py-2 px-6 rounded-lg bg-green-600 text-white text-center text-sm block w-full"
              v-if="false"
            >
              <i class="fa-solid fa-arrow-up-from-bracket"></i> استيراد العملاء
            </button>
            <button
              class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block w-full"
              v-if="false"
            >
              <i class="fa-solid fa-file-excel"></i>
              تصدير جميع العملاء
            </button>
          </div>
        </div>
      </header>
      <main
        class="w-full p-5 bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        v-if="load && coupons.length > 0"
      >
        <figure
          class="w-full space-y-4"
          v-for="(item, index) in coupons"
          :key="index"
        >
          <div
            class="flex items-center justify-center flex-wrap border border-gray-100 gap-3 px-6 py-4 bg-white rounded-xl"
          >
            <div class="flex-1">
              <p class="text-sm font-medium rtl:font-semibold">الحالة:</p>
            </div>
            <input
              v-model="item.status"
              type="checkbox"
              class="toggle-input"
              :true-value="'on'"
              :false-value="'off'"
              @change="changeStatus(item.id)"
            />
          </div>
          <div class="w-full rounded-xl bg-yellow-600 bg-image relative">
            <div class="py-10 px-6 text-center space-y-2 z-[2]">
              <p class="text-sm text-yellow-800 font-bold uppercase">
                {{ item.discount_type }}
              </p>
              <p class="text-3xl text-white font-bold">{{ item.code }}</p>
              <p class="text-base text-gray-100 font-medium rtl:font-semibold">
                {{ item.name }}
                <span class="block text-xs" dir="ltr">
                  {{ item.created_at | moment(" h:mm a . MMMM Do YYYY") }}
                </span>
              </p>
            </div>
            <div
              class="p-4 w-full border-dashed border-t-2 border-yellow-700 bottom-style relative z-[2]"
            >
              <button
                class="py-2 px-4 rounded-lg bg-white text-gray-600 font-medium rtl:font-semibold mx-auto text-sm table"
                @click.prevent="copyElement(item.code)"
              >
                نسخ الكود
              </button>
            </div>
          </div>
          <button
            class="py-2 px-4 rounded-lg bg-gray-100 text-gray-500 font-medium rtl:font-semibold mx-auto text-sm table w-full"
            @click.prevent="showCouponModal(item.id)"
          >
            تعديل الكوبون
          </button>
          <button
            class="py-2 px-4 rounded-lg bg-transparent text-red-500 font-medium rtl:font-semibold mx-auto text-sm table"
            @click.prevent="deleteItem(item.id)"
          >
            حذف الكوبون
          </button>
        </figure>
      </main>
      <main
        class="w-full p-5 rounded-xl bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        v-if="!load"
      >
        <figure class="p-6 flex-1" v-for="idx in 4" :key="idx">
          <div
            class="table mx-auto w-full h-32 rounded-xl mb-4 bg-gray-200 animate-pulse"
          ></div>
          <div
            class="w-2/5 h-4 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
          ></div>
          <div
            class="w-4/5 h-3 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
          ></div>
        </figure>
      </main>
      <main
        class="w-full p-5 rounded-xl bg-white"
        v-if="load && coupons.length === 0"
      >
        <p class="font-medium rtl:font-semibold text-center p-4">
          لا توجد كوبونات
        </p>
        <button
          @click.prevent="active = true"
          class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm table mx-auto mt-2"
        >
          <i class="fa-solid fa-plus"></i>
          اضافة كوبون جديد
        </button>
      </main>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
    <SweetModal v-if="active" @close="active = $event" :close="false">
      <template #default>
        <form class="w-full space-y-3 p-5">
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="name"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الاسم
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="example"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.name"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="code"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الكود
              </label>
              <input
                type="text"
                name="code"
                id="code"
                placeholder="example123"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md text-left"
                autocomplete="off"
                v-model="add_data.code"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.code || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="discount_type"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                نوع الخصم
              </label>

              <select
                name="discount_type"
                id="discount_type"
                v-model="add_data.discount_type"
                class="custom-select"
              >
                <option value="" selected disabled>-- اختار --</option>
                <option value="percentage">نسبة</option>
                <option value="fixed">قيمة</option>
              </select>
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.discount_type || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="discount_value"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                قيمة الخصم
              </label>
              <input
                type="number"
                min="0"
                name="discount_value"
                id="discount_value"
                placeholder="0 - 100"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.discount_value"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.discount_value || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div
              class="w-full space-y-3"
              v-if="add_data.discount_type === 'percentage'"
            >
              <label
                for="max_discount"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                اقصى خصم
              </label>
              <input
                type="number"
                min="0"
                name="max_discount"
                id="max_discount"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.max_discount"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.max_discount || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="min_order_amount"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الحد الادنى للطلب
              </label>
              <input
                type="number"
                min="1"
                name="min_order_amount"
                id="min_order_amount"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.min_order_amount"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.min_order_amount || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="coupon_limit"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                مرات استخدام الكوبون
              </label>
              <input
                type="number"
                min="1"
                name="coupon_limit"
                id="coupon_limit"
                placeholder="1"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.coupon_limit"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.coupon_limit || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="customer_limit"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                مرات استخدام الكوبون لكل عميل
              </label>
              <input
                type="number"
                min="1"
                name="customer_limit"
                id="customer_limit"
                placeholder="1"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.customer_limit"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.customer_limit || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="categories"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              التصنيفات
            </label>
            <MultiListSelect
              :list="categories"
              :selectedItems="add_data.categories"
              v-model="add_data.categories"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="handleSearchSelect($event, 'add_data', 'categories')"
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.categories || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="excluded_categories"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              تصنيفات غير مشمولة
            </label>
            <MultiListSelect
              :list="categories"
              :selectedItems="add_data.excluded_categories"
              v-model="add_data.excluded_categories"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="
                handleSearchSelect($event, 'add_data', 'excluded_categories')
              "
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.excluded_categories || []).join(" ") }}
            </p>
          </div>

          <div class="w-full space-y-3">
            <label
              for="products"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              المنتجات
            </label>
            <MultiListSelect
              :list="products"
              :selectedItems="add_data.products"
              v-model="add_data.products"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="handleSearchSelect($event, 'add_data', 'products')"
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="excluded_products"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              منتجات غير مشمولة
            </label>
            <MultiListSelect
              :list="products"
              :selectedItems="add_data.excluded_products"
              v-model="add_data.excluded_products"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="
                handleSearchSelect($event, 'add_data', 'excluded_products')
              "
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.excluded_products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <div class="checkbox">
              <div>
                <input
                  type="checkbox"
                  name="free_shipping"
                  id="free_shipping"
                  v-model="add_data.free_shipping"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label
                for="free_shipping"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                شحن مجاني
              </label>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.free_shipping || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <div class="checkbox">
              <div>
                <input
                  type="checkbox"
                  name="except_sale_products"
                  id="except_sale_products"
                  v-model="add_data.except_sale_products"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label
                for="except_sale_products"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                باستثناء المنتجات المخفضة
              </label>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.except_sale_products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="start_date"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                تاريخ البدء
              </label>
              <input
                type="date"
                name="start_date"
                id="start_date"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.start_date"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.start_date || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="end_date"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                تاريخ الانتهاء
              </label>
              <input
                type="date"
                name="end_date"
                id="end_date"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="add_data.end_date"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (add_errors.end_date || []).join(" ") }}
              </p>
            </div>
          </div>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="add_disabled"
                @click.prevent="addCoupon"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                اضافة
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
    <SweetModal v-if="edit_active" @close="edit_active = $event" :close="false">
      <template #default>
        <form class="w-full space-y-3 p-5">
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="name"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الاسم
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="example"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.name"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="code"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الكود
              </label>
              <input
                type="text"
                name="code"
                id="code"
                placeholder="example123"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md text-left"
                autocomplete="off"
                v-model="edit_data.code"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.code || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="discount_type"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                نوع الخصم
              </label>

              <select
                name="discount_type"
                id="discount_type"
                v-model="edit_data.discount_type"
                class="custom-select"
              >
                <option value="" selected disabled>-- اختار --</option>
                <option value="percentage">نسبة</option>
                <option value="fixed">قيمة</option>
              </select>
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.discount_type || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="discount_value"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                قيمة الخصم
              </label>
              <input
                type="number"
                min="0"
                name="discount_value"
                id="discount_value"
                placeholder="0 - 100"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.discount_value"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.discount_value || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div
              class="w-full space-y-3"
              v-if="edit_data.discount_type === 'percentage'"
            >
              <label
                for="max_discount"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                اقصى خصم
              </label>
              <input
                type="number"
                min="0"
                name="max_discount"
                id="max_discount"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.max_discount"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.max_discount || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="min_order_amount"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                الحد الادنى للطلب
              </label>
              <input
                type="number"
                min="1"
                name="min_order_amount"
                id="min_order_amount"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.min_order_amount"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.min_order_amount || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="coupon_limit"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                مرات استخدام الكوبون
              </label>
              <input
                type="number"
                min="1"
                name="coupon_limit"
                id="coupon_limit"
                placeholder="1"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.coupon_limit"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.coupon_limit || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="customer_limit"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                مرات استخدام الكوبون لكل عميل
              </label>
              <input
                type="number"
                min="1"
                name="customer_limit"
                id="customer_limit"
                placeholder="1"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.customer_limit"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.customer_limit || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="categories"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              التصنيفات
            </label>
            <MultiListSelect
              :list="categories"
              :selectedItems="edit_data.categories"
              v-model="edit_data.categories"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="handleSearchSelect($event, 'edit_data', 'categories')"
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.categories || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="excluded_categories"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              تصنيفات غير مشمولة
            </label>
            <MultiListSelect
              :list="categories"
              :selectedItems="edit_data.excluded_categories"
              v-model="edit_data.excluded_categories"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="
                handleSearchSelect($event, 'edit_data', 'excluded_categories')
              "
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.excluded_categories || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="products"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              المنتجات
            </label>
            <MultiListSelect
              :list="products"
              :selectedItems="edit_data.products"
              v-model="edit_data.products"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="handleSearchSelect($event, 'edit_data', 'products')"
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="excluded_products"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              منتجات غير مشمولة
            </label>
            <MultiListSelect
              :list="products"
              :selectedItems="edit_data.excluded_products"
              v-model="edit_data.excluded_products"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="
                handleSearchSelect($event, 'edit_data', 'excluded_products')
              "
              class="custom-multi-select"
            >
            </MultiListSelect>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.excluded_products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <div class="checkbox">
              <div>
                <input
                  type="checkbox"
                  name="free_shipping"
                  id="free_shipping"
                  v-model="edit_data.free_shipping"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label
                for="free_shipping"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                شحن مجاني
              </label>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.free_shipping || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <div class="checkbox">
              <div>
                <input
                  type="checkbox"
                  name="except_sale_products"
                  id="except_sale_products"
                  v-model="edit_data.except_sale_products"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label
                for="except_sale_products"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                باستثناء المنتجات المخفضة
              </label>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (edit_errors.except_sale_products || []).join(" ") }}
            </p>
          </div>
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="w-full space-y-3">
              <label
                for="start_date"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                تاريخ البدء
              </label>
              <input
                type="date"
                name="start_date"
                id="start_date"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.start_date"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.start_date || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="end_date"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                تاريخ الانتهاء
              </label>
              <input
                type="date"
                name="end_date"
                id="end_date"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
                autocomplete="off"
                v-model="edit_data.end_date"
              />
              <p class="text-red-500 font-medium rtl:font-semibold text-sm">
                {{ (edit_errors.end_date || []).join(" ") }}
              </p>
            </div>
          </div>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="edit_disabled"
                @click.prevent="updateCoupon"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="edit_disabled"></i>
                حفظ التغييرات
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="edit_active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import SweetModal from "@/components/modals/sweetModal.vue";
import { MultiListSelect } from "vue-search-select";

export default {
  name: "CouponsIndex",
  data() {
    return {
      search_key: "",
      search_disabled: false,
      coupons: [],
      products: [],
      categories: [],
      currentPage: 1,
      perPage: 0,
      rows: 0,
      totalPages: 0,
      load: false,
      active: false,
      add_disabled: false,
      add_data: {
        discount_type: "",
        categories: [],
        products: [],
        excluded_categories: [],
        excluded_products: [],
      },
      add_errors: {},
      edit_active: false,
      edit_disabled: false,
      edit_data: {
        discount_type: "",
        categories: [],
        products: [],
        excluded_categories: [],
      },
      edit_errors: {},
      error: "",
      success: "",
    };
  },
  created() {
    this.getCoupons();
    this.getProducts();
    this.getCategories();
  },
  watch: {
    currentPage() {
      this.getCoupons();
    },
    active() {
      if (!this.active) {
        this.add_data = {
          discount_type: "",
          categories: [],
          products: [],
          excluded_categories: [],
        };
        this.add_errors = {};
      }
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {
          discount_type: "",
          categories: [],
          products: [],
          excluded_categories: [],
        };
        this.edit_errors = {};
      }
    },
  },
  methods: {
    getProducts() {
      return this.axios.get("products").then(({ data }) => {
        if (data.error_flag === 0) {
          this.products = data.result.products;
          this.add_data.products = data.result.products;
        }
      });
    },
    getCategories() {
      return this.axios.get("store_categories").then(({ data }) => {
        if (data.error_flag === 0) {
          this.categories = data.result.store_categories;
          this.add_data.categories = data.result.store_categories;
        }
      });
    },
    getCoupons() {
      return this.axios
        .get("coupons", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.coupons = data.result.coupons.data;
            this.perPage = data.result.coupons.pagination.per_page;
            this.rows = data.result.coupons.pagination.total;
            this.totalPages = data.result.coupons.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.coupons = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getCoupons().then(() => {
        this.search_disabled = false;
      });
    },
    changeStatus(id) {
      return this.axios
        .post("coupons/update_status/" + id)
        .then((response) => {
          const res = response.data;
          if (res.error_flag === 0) {
            this.getCoupons();
            this.createdAlert("success", res.message);
          }
        })
        .catch((error) => {
          const err = error.response.data;
          if (err.error_flag === 1) {
            this.createdAlert("error", err.message);
          }
        });
    },
    deleteItem(id) {
      return this.axios
        .post("coupons/delete/" + id)
        .then((response) => {
          const res = response.data;
          if (res.error_flag === 0) {
            this.getCoupons();
            this.createdAlert("success", res.message);
          }
        })
        .catch((error) => {
          const err = error.response.data;
          if (err.error_flag === 1) {
            this.createdAlert("error", err.message);
          }
        });
    },
    addCoupon() {
      this.add_disabled = true;
      let fd = new FormData();
      Object.keys(this.add_data).forEach((key) => {
        if (["categories", "products", "excluded_categories"].includes(key)) {
          this.add_data[key].forEach((item, idx) => {
            fd.append(`${key}[${idx}]`, item.id);
          });
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      this.add_errors = {};
      return this.axios
        .post("coupons/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCoupons();
            this.createdAlert("success", data.message);
            this.active = false;
            this.add_data = {
              discount_type: "",
              categories: [],
              products: [],
              excluded_categories: [],
            };
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateCoupon() {
      this.edit_disabled = true;
      this.edit_errors = {};
      let fd = new FormData();
      Object.keys(this.edit_data).forEach((key) => {
        if (
          [
            "categories",
            "products",
            "excluded_categories",
            "excluded_products",
          ].includes(key)
        ) {
          this.edit_data[key].forEach((item, idx) => {
            fd.append(`${key}[${idx}]`, item.id);
          });
        } else {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.add_errors = {};
      return this.axios
        .post("coupons/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCoupons();
            this.createdAlert("success", data.message);
            this.edit_active = false;
            this.edit_data = {
              discount_type: "",
              categories: [],
              products: [],
              excluded_categories: [],
            };
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.edit_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.edit_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    uploadMainImage(e, type) {
      e.preventDefault();
      this.add_data[type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    showCouponModal(id) {
      this.edit_data = this.coupons.find((c) => c.id == id);
      this.edit_active = true;
    },
  },
  components: { SweetModal, MultiListSelect },
};
</script>
