<template>
  <section class="w-full relative auth-container grid">
    <div
      class="flex items-end sm:items-center justify-center pt-24 sm:py-20 z-10 relative"
    >
      <div
        class="flex-1 max-w-xl relative mx-auto bg-white rounded-t-3xl sm:rounded-3xl py-14"
      >
        <main
          class="w-full px-6 sm:px-10 h-full relative z-[1]"
          v-if="type !== 'customizing'"
        >
          <img
            src="@/assets/images/logo-vertical.png"
            alt="matjary logo"
            width="150"
            class="mx-auto table mb-6"
          />
          <h1
            class="text-gray-800 text-center font-semibold rtl:font-bold mb-4 text-3xl"
          >
            {{ titles[type] }}
          </h1>
          <transition name="move">
            <Login @action="type = $event" v-if="type === 'login'" />
          </transition>
          <transition name="move">
            <Register @action="type = $event" v-if="type === 'register'" />
          </transition>
          <transition name="move">
            <ForgotPassword
              @action="type = $event"
              v-if="type === 'password'"
            />
          </transition>
          <transition name="move">
            <StoreInfo v-if="type === 'store'" @action="type = $event" />
          </transition>
          <transition name="move">
            <attachments
              v-if="type === 'attachments'"
              @action="type = $event"
            />
          </transition>
        </main>
        <main
          class="w-full py-20 px-8 h-full relative z-[1]"
          v-if="type === 'customizing'"
        >
          <transition name="move">
            <div>
              <div
                class="w-14 h-14 rounded-full bg-yellow-600/20 text-yellow-600 flex items-center justify-center text-lg font-bold mb-6 mx-auto relative z-[1]"
              >
                {{ timer }}
                <span
                  class="w-full h-full rounded-full animate-ping bg-yellow-600/20 absolute z-[-1]"
                ></span>
              </div>
              <h1
                class="text-yellow-600 text-center font-semibold rtl:font-bold mb-4 text-2xl text-center"
              >
                جاري تخصيص المتجر..
              </h1>
              <div class="max-w-screen-md mx-auto">
                <p
                  class="text-gray-600 font-medium rtl:font-semibold text-center text-lg"
                >
                  مرحبا بك عزيزي\تي , من فضلك انتظر ربما تأخد تلك العملية بضع
                  ثواني ونأمل ان ذلك لا يسبب لكم الازعاج ونحن حارصين جداً على
                  ذلك , شكرا لوقتك الثمين.
                </p>
              </div>
            </div>
          </transition>
        </main>
      </div>
    </div>

    <!-- <auth-animate /> -->
  </section>
</template>
<script>
import Login from "@/components/auth/login.vue";
import Register from "@/components/auth/register.vue";
import ForgotPassword from "@/components/auth/forgotPassword.vue";
import StoreInfo from "@/components/auth/storeInfo.vue";
import attachments from "@/components/auth/attachments.vue";
import AuthAnimate from "@/components/animations/auth.animate.vue";
export default {
  name: "Auth",
  data() {
    return {
      type: this.$store.state.auth_type || "login",
      timer: 5,

      titles: {
        login: "تسجيل دخول",
        register: "إنشاء حساب جديد",
        password: "اعادة تعيين كلمة المرور",
        store: "اضافة بيانات المتجر",
        attachments: "ارفاق المستندات المطلوبة لانشاء متجرك",
      },
    };
  },
  created() {
    document.querySelector("body").classList.remove("bg-gray-100");
  },
  watch: {
    type() {
      if (this.type === "customizing") {
        this.timerStartStop();
      }
    },
  },
  methods: {
    timerStartStop() {
      const timer = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(timer);
          this.$router.push("/");
        }
      }, 1000);
    },
    componentsActions(ev) {
      this.type = ev;
      this.$store.commit("SET_AUTH_TYPE", ev);
    },
  },
  components: {
    Login,
    Register,
    ForgotPassword,
    StoreInfo,
    attachments,
    AuthAnimate,
  },
};
</script>
<style lang="scss">
.login-aside {
  @apply after:w-full 
  after:h-40
  after:absolute
  after:bottom-0 
  after:right-0 
  after:bg-gradient-to-b 
  after:from-white
  after:to-white/90;
}
.login-aside::before {
  content: "";
  background-image: url("../assets/images/bg-store.jpg");
  @apply absolute bottom-0 right-0 w-full h-40;
}
.auth-container {
  &::before {
    content: "";
    background-image: url("../assets/images/store.jpg");
    @apply w-full h-full fixed inset-0 bg-cover bg-no-repeat;
  }
  &::after {
    content: "";
    @apply bg-gradient-to-tl from-yellow-500 to-yellow-600 w-full h-full fixed inset-0 opacity-90;
  }
}
</style>
