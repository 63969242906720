<template>
  <div class="w-full space-y-6">
    <div v-if="!payment_load" class="space-y-4">
      <figure
        class="w-full bg-white rounded-xl py-4 px-3"
        v-for="l in 3"
        :key="l"
      >
        <div class="space-y-4">
          <div class="space-y-3 px-4 py-3">
            <div class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"></div>
            <div class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </figure>
    </div>

    <div v-if="payment_load" class="space-y-4">
      <figure
        class="w-full bg-white rounded-lg p-3"
        v-for="(payment, index) in payment_methods"
        :key="index"
      >
        <blockquote class="w-full p-2 space-y-2">
          <div class="w-full flex items-start justify-center gap-4">
            <div class="flex items-center gap-4 flex-1">
              <div class="self-start">
                <div class="w-10 h-10">
                  <img
                    :src="
                      payment.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-full h-full object-contain"
                  />
                </div>
              </div>
              <div class="space-y-1 flex-1">
                <h1
                  class="text-base font-semibold rtl:font-bold text-yellow-600"
                >
                  {{ payment.name }}
                </h1>
                <div class="grid">
                  <a
                    :href="payment.website"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-600 font-medium text-sm hover:underline inline-block truncate"
                    v-if="payment.website"
                  >
                    {{ payment.website }}
                  </a>
                </div>
                <a
                  href="#"
                  class="text-blue-500 font-medium text-sm hover:underline block !my-2"
                  v-if="Number(payment.has_request) === 1"
                  @click.prevent="openRequests(index)"
                >
                  عرض الطلب
                </a>
                <transition name="slideUpDown">
                  <div
                    class="w-full mt-2 p-4 bg-gray-100 rounded-md"
                    v-if="
                      request_active[index] && Number(payment.has_request) === 1
                    "
                  >
                    <ul
                      class="space-y-3 list-disc marker:text-yellow-600 pl-6 rtl:pr-6 rtl:pl-0"
                    >
                      <li
                        class="space-y-2"
                        v-for="(doc, idx) in (
                          requests.find(
                            (reqItem) => reqItem.method.id === payment.id
                          ) || {}
                        ).request_documents"
                        :key="idx"
                      >
                        <h1 class="text-base font-semibold rtl:font-bold">
                          {{ doc.name }}
                          <span
                            class="text-xs inline-block py-1 px-2 rounded-md bg-gray-200"
                            >{{ $t(`status.${doc.status}`) }}</span
                          >
                        </h1>
                        <p
                          class="text-sm font-semibold rtl:font-bold text-gray-600"
                        >
                          {{ doc.desc }}
                          <a
                            :href="doc.document_file"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-xs hover:underline rounded-md inline-block text-yellow-600"
                            v-if="doc.document_file"
                            :download="doc.document_file"
                          >
                            عرض الملف
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
            <div
              class="flex items-center justify-center flex-col sm:flex-row gap-4 sm:gap-3"
            >
              <div v-if="Number(payment.need_review) !== 0">
                <button
                  class="py-2 px-4 rounded-sm bg-yellow-600 text-white text-xs font-medium"
                  @click.prevent="openDocuments(payment.id)"
                >
                  اشتراك
                </button>
              </div>
              <div v-if="payment.config_fields">
                <button
                  class="border border-gray-200 text-gray-600 text-xs py-2 px-4 hover:bg-gray-100"
                  @click.prevent="openConfig(payment.id)"
                >
                  الاعداد
                </button>
              </div>
              <div v-if="payment.id === 2">
                <button
                  class="py-2 px-4 rounded-sm bg-yellow-600 text-white text-sm table"
                  @click.prevent="acc_add_active = true"
                >
                  اضافة حساب جديد
                </button>
              </div>
              <div v-if="Number(payment.need_review) === 0">
                <input
                  v-model="payment.status"
                  type="checkbox"
                  class="toggle-input"
                  :true-value="'on'"
                  :false-value="'off'"
                  @change="changeStatus(payment.id)"
                  :disabled="Number(payment.need_review) !== 0"
                />
              </div>
            </div>
          </div>
        </blockquote>
        <div v-if="payment.id === 2" class="border-t border-gray-100 mt-4">
          <div class="w-full py-4 px-2 space-y-6">
            <div class="w-full" v-if="acc_load && accounts.length !== 0">
              <div
                class="flex gap-4 border-b border-gray-100 last:border-0 py-4"
                v-for="(bank, idx) in accounts"
                :key="idx"
              >
                <div>
                  <div
                    class="w-10 h-10 rounded-full text-sm text-gray-600 bg-gray-100 flex items-center justify-center"
                  >
                    <i class="fa-solid fa-bank"></i>
                  </div>
                </div>
                <div class="flex-1 space-y-4">
                  <div class="space-y-2">
                    <h1
                      class="text-base font-medium rtl:font-semibold text-gray-800"
                    >
                      {{ bank.beneficiary_name }} - {{ bank.bank_name }}
                    </h1>

                    <p
                      class="text-sm font-medium rtl:font-semibold text-gray-600"
                    >
                      {{ bank.account_no }}
                    </p>
                    <p
                      class="text-sm font-medium rtl:font-semibold text-gray-600"
                    >
                      {{ bank.iban }}
                    </p>

                    <!-- <p class="text-sm font-medium rtl:font-semibold text-gray-600"></p> -->
                  </div>
                  <div class="flex flex-wrap gap-3">
                    <div>
                      <button
                        class="py-2 px-4 rounded-md bg-green-500 text-white text-sm table"
                        @click.prevent="openUpdate(bank.id)"
                      >
                        تعديل
                      </button>
                    </div>
                    <div>
                      <button
                        class="py-2 px-4 rounded-md bg-transparent text-red-500 text-sm table"
                        data-title="حذف"
                        @click.prevent="deleteCurrentAcc(bank.id, idx)"
                        :disabled="bank_delete_disabled[idx]"
                      >
                        <i
                          class="fa-solid fa-spinner fa-spin"
                          v-if="bank_delete_disabled[idx]"
                        ></i>
                        <i v-else class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      v-model="bank.status"
                      type="checkbox"
                      class="toggle-input"
                      :true-value="'on'"
                      :false-value="'off'"
                      @change="changeBankStatus(bank.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full text-center p-4 text-gray-600 font-medium rtl:font-semibold"
              v-if="acc_load && accounts.length === 0"
            >
              ليس هناك حسابك بعد
            </div>
            <div class="space-y-4" v-if="!acc_load">
              <div class="space-y-3 px-4 py-3" v-for="l in 3" :key="l">
                <div
                  class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"
                ></div>
                <div
                  class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </figure>
    </div>
    <!-- add new account -->
    <SweetModal
      class="!mt-0"
      v-if="acc_add_active"
      @close="acc_add_active = $event"
      :title="'اضافة حساب جديد'"
    >
      <template #default>
        <div class="w-full p-6">
          <form @submit.prevent class="w-full space-y-4">
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                صورة الحساب
              </h1>
              <label
                for="uploader_image"
                class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                type="file"
                name="uploader_image"
                id="uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="bank_data.image = $event.target.files[0]"
              />
              <div v-if="bank_data.image" class="table">
                <div
                  class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                >
                  <img
                    :src="convertImage(bank_data.image)"
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                  @click.prevent="bank_data.image = null"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="bank_name"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم البنك
              </label>
              <select
                name="bank_name"
                id="bank_name"
                v-model="bank_data.bank_name"
                class="custom-select"
              >
                <option disabled selected value="">-- اختار --</option>
                <option
                  v-for="(item, index) in bank_names"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (bank_errors.bank_name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="beneficiary_name"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم المستفيد
              </label>
              <input
                type="text"
                name="beneficiary_name"
                id="beneficiary_name"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="bank_data.beneficiary_name"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (bank_errors.beneficiary_name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="account_no"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم الحساب المحلي
              </label>
              <input
                type="text"
                name="account_no"
                id="account_no"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="bank_data.account_no"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (bank_errors.account_no || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="iban"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم الحساب الدولي ( IBAN )
              </label>
              <input
                type="text"
                name="iban"
                id="iban"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="bank_data.iban"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (bank_errors.iban || []).join(" ") }}
              </p>
            </div>
            <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                  :disabled="acc_disabled"
                  @click.prevent="addNewAccount"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="acc_disabled"
                  ></i>
                  اضافة
                </button>
              </div>
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                  type="reset"
                  @click.prevent="closeAdd"
                >
                  الغاء
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </SweetModal>
    <!-- ./add new account -->
    <!-- edit new account -->
    <SweetModal
      class="!mt-0"
      v-if="edit_acc_active"
      @close="edit_acc_active = $event"
      :title="'تعديل الحساب'"
    >
      <template #default>
        <div class="w-full p-6">
          <form @submit.prevent class="w-full space-y-4">
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                صورة الحساب
              </h1>
              <label
                for="uploader_image"
                class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                type="file"
                name="uploader_image"
                id="uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="edit_bank_data.image = $event.target.files[0]"
              />
              <div v-if="edit_bank_data.image" class="table">
                <div
                  class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                >
                  <img
                    :src="
                      typeof edit_bank_data.image === 'object'
                        ? convertImage(edit_bank_data.image)
                        : edit_bank_data.image
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                  @click.prevent="edit_bank_data.image = null"
                  v-if="typeof edit_bank_data.image === 'object'"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="bank_name"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم البنك
              </label>
              <select
                name="bank_name"
                id="bank_name"
                v-model="edit_bank_data.bank_name"
                class="custom-select"
              >
                <option disabled selected value="">-- اختار --</option>
                <option
                  v-for="(item, index) in bank_names"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (edit_bank_errors.bank_name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="beneficiary_name"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم المستفيد
              </label>
              <input
                type="text"
                name="beneficiary_name"
                id="beneficiary_name"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="edit_bank_data.beneficiary_name"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (edit_bank_errors.beneficiary_name || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="account_no"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم الحساب المحلي
              </label>
              <input
                type="text"
                name="account_no"
                id="account_no"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="edit_bank_data.account_no"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (edit_bank_errors.account_no || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="iban"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم الحساب الدولي ( IBAN )
              </label>
              <input
                type="text"
                name="iban"
                id="iban"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="edit_bank_data.iban"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (edit_bank_errors.iban || []).join(" ") }}
              </p>
            </div>
            <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                  :disabled="edit_acc_disabled"
                  @click.prevent="updateAccount"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="edit_acc_disabled"
                  ></i>
                  حفظ التغييرات
                </button>
              </div>
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                  type="reset"
                  @click.prevent="closeEdit"
                >
                  الغاء
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </SweetModal>
    <!-- ./edit new account -->
    <!-- add new account -->
    <SweetModal
      class="!mt-0"
      v-if="config_active"
      @close="config_active = $event"
      :title="'تعديل بيانات البوابة'"
    >
      <template #default>
        <div class="w-full p-6">
          <div class="w-full space-y-4" v-if="config_content.image">
            <div>
              <img
                :src="
                  config_content.image ||
                  require('@/assets/images/placeholder.png')
                "
                alt=""
                class="max-w-[150px]"
              />
            </div>
            <div>
              <p class="text-gray-600 font-medium rtl:font-semibold">
                {{ config_content.desc }}
              </p>
              <a
                :href="config_content.website"
                class="text-blue-600 inline-block font-medium rtl:font-semibold"
                >عرض المزيد</a
              >
            </div>
          </div>
          <form @submit.prevent class="w-full space-y-4 mt-4">
            <div
              v-for="(item, index) in config_content.config_fields"
              :key="index"
            >
              <div
                class="w-full space-y-3"
                v-if="
                  ['text', 'date', 'url', 'number'].includes(item.input_type) &&
                  !['access_token', 'payment_method'].includes(item.key)
                "
              >
                <label
                  :for="item.key"
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  {{ item.name }}
                </label>
                <input
                  :type="item.input_type"
                  :name="item.key"
                  :id="item.key"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="config_data[item.key]"
                />
                <p class="text-red-500 font-medium rtl:font-semibold">
                  {{ (config_errors[item.key] || []).join(" ") }}
                </p>
              </div>
              <div
                class="space-y-3"
                v-if="['boolean'].includes(item.input_type)"
              >
                <div class="checkbox">
                  <div>
                    <input
                      type="checkbox"
                      :name="item.key"
                      :id="item.key"
                      :true-value="1"
                      :false-value="0"
                      v-model="config_data[item.key]"
                    />
                  </div>
                  <label :for="item.key">
                    {{ item.name }}
                  </label>
                </div>
                <p class="text-red-500 font-medium rtl:font-semibold">
                  {{ (config_errors[item.key] || []).join(" ") }}
                </p>
              </div>
            </div>

            <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                  :disabled="config_disabled"
                  @click.prevent="updateConfig"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="config_disabled"
                  ></i>
                  حفظ
                </button>
              </div>
              <div>
                <button
                  class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                  type="reset"
                  @click.prevent="closeHyper"
                >
                  الغاء
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </SweetModal>
    <!-- ./add new account -->
    <!-- apply -->
    <SweetModal
      class="!mt-0"
      v-if="apply_active"
      @close="apply_active = $event"
      :title="'ارفاق المستندات'"
    >
      <template #default>
        <div class="w-full p-5 space-y-4" v-if="doc_content.image">
          <div>
            <img
              :src="
                doc_content.image || require('@/assets/images/placeholder.png')
              "
              alt=""
              class="max-w-[150px]"
            />
          </div>
          <div>
            <p class="text-gray-600 font-medium rtl:font-semibold">
              {{ doc_content.desc }}
            </p>
            <a
              :href="doc_content.website"
              class="text-blue-600 inline-block font-medium rtl:font-semibold"
              >عرض المزيد</a
            >
          </div>
        </div>
        <div class="w-full p-5" v-if="documents_load && documents.length !== 0">
          <div
            v-for="(item, index) in documents"
            :key="index"
            class="border-b border-gray-100 last:!border-0 px-4 py-6 first:pt-0 last:pb-0"
          >
            <figure class="xs:grid w-full space-y-2">
              <div class="xs:!py-2">
                {{ item.name }}
              </div>
              <p
                class="font-medium rtl:font-semibold text-gray-600 table text-sm mt-2"
                v-if="item.desc"
              >
                {{ item.desc }}
                <a
                  :href="item.standard_form"
                  class="font-medium rtl:font-semibold text-yellow-600 inline-block text-sm"
                  :download="item.standard_form"
                  v-if="item.is_standard_form === 1"
                >
                  تحميل النموذج
                </a>
              </p>
              <div class="xs:!py-2 space-y-2 !mt-4">
                <label
                  :for="`uploader_image_${item.id}`"
                  class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-md inline-block"
                  accept="image/*,application/pdf"
                >
                  ارفاق ملف
                </label>
                <input
                  type="file"
                  :name="`uploader_image_${item.id}`"
                  :id="`uploader_image_${item.id}`"
                  hidden
                  accept="image/*"
                  class="hidden"
                  @change.prevent="
                    apply_data['document_' + item.id] = $event.target.files[0]
                  "
                />
                <div
                  v-if="apply_data['document_' + item.id]"
                  class="inline-block"
                >
                  <a
                    :href="convertImage(apply_data['document_' + item.id])"
                    class="text-xs text-gray-600 px-4 font-medium"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ apply_data["document_" + item.id].name }}
                  </a>
                </div>
                <div v-if="apply_errors['document_' + item.id]">
                  <p class="text-xs text-red-500 px-4">
                    {{ apply_errors["document_" + item.id].join(" ") }}
                  </p>
                </div>
              </div>
            </figure>
          </div>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="apply_disabled"
                @click.prevent="applyDocuments"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="apply_disabled"
                ></i>
                حفظ التغييرات
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                type="reset"
                @click.prevent="closeApply"
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
        <div class="w-full p-5" v-if="documents_load && documents.length === 0">
          <p class="text-center text-gray-600 font-medium rtl:font-semibold">
            ليس هناك مستندات
          </p>
        </div>
        <div class="space-y-4" v-if="!documents_load">
          <div class="space-y-3 px-4 py-3" v-for="l in 3" :key="l">
            <div class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"></div>
            <div class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </template>
    </SweetModal>
    <!-- apply -->
  </div>
</template>
<script>
import TableLoad from "../loading/TableLoad.vue";
import SweetModal from "../modals/sweetModal.vue";
export default {
  name: "PaymentsAndBankAccounts",
  data() {
    return {
      acc_load: false,
      accounts: [],
      bank_data: {
        image: null,
      },
      bank_errors: {},
      acc_disabled: false,
      acc_add_active: false,
      payment_load: false,
      payment_methods: [],
      edit_bank_data: {},
      edit_bank_errors: {},
      edit_acc_disabled: false,
      edit_acc_active: false,
      bank_delete_disabled: [],
      config_disabled: false,
      config_active: false,
      config_data: {
        access_token: this.$store.state.token || this.$cookies.get("token"),
        payment_method_id: null,
      },
      config_content: {},
      config_errors: {},
      bank_names: [
        {
          name: "Saudi National Bank",
        },
        {
          name: "The Saudi British Bank (SABB)",
        },
        {
          name: "Saudi Investment Bank",
        },
        {
          name: "Alinma bank",
        },
        {
          name: "Banque Saudi Fransi",
        },
        {
          name: "Riyad Bank",
        },
        {
          name: "Al Rajhi Bank",
        },
        {
          name: "Arab National Bank",
        },
      ],
      apply_disabled: false,
      apply_data: {
        payment_method_id: "",
      },
      doc_content: {},
      documents: [],
      documents_load: false,
      apply_active: false,
      apply_errors: {},
      request_load: false,
      requests: [],
      request_active: [],
    };
  },
  created() {
    this.getPayments();
    this.getBankAccounts();
    this.getRequests();
  },
  methods: {
    getRequests() {
      return this.axios
        .get("settings/payment_methods/requests")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.requests = data.result.payment_method_requests;
          }
        })
        .finally(() => (this.request_load = true));
    },
    getPayments() {
      return this.axios
        .get("settings/payment_methods")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.payment_methods = data.result.payment_methods;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => (this.payment_load = true));
    },
    getBankAccounts() {
      return this.axios
        .get("settings/bank_accounts")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.accounts = data.result.bank_accounts;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => (this.acc_load = true));
    },
    addNewAccount() {
      this.acc_disabled = true;
      this.bank_errors = {};
      let fd = new FormData();
      Object.keys(this.bank_data).forEach((key) => {
        if (key === "image") {
          this.bank_data[key] &&
            fd.append(key, this.bank_data[key], this.bank_data[key].name);
        } else {
          fd.append(key, this.bank_data[key]);
        }
      });
      return this.axios
        .post("settings/bank_accounts/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBankAccounts();
            this.createdAlert("success", data.message);
            this.bank_data = {};
            this.acc_add_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.bank_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.acc_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateAccount() {
      this.edit_acc_disabled = true;
      this.edit_bank_errors = {};
      let fd = new FormData();
      Object.keys(this.edit_bank_data).forEach((key) => {
        if (key === "image") {
          this.edit_bank_data[key] &&
            typeof this.edit_bank_data[key] === "object" &&
            fd.append(
              key,
              this.edit_bank_data[key],
              this.edit_bank_data[key].name
            );
        } else {
          fd.append(key, this.edit_bank_data[key]);
        }
      });
      return this.axios
        .post("settings/bank_accounts/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBankAccounts();
            this.createdAlert("success", data.message);
            this.edit_bank_data = {};
            this.edit_acc_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.edit_bank_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.edit_acc_disabled = false;
          // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    closeAdd() {
      Object.keys(this.bank_data).forEach((key) => {
        this.bank_data[key] = "";
      });
      this.acc_add_active = false;
    },
    closeEdit() {
      Object.keys(this.edit_bank_data).forEach((key) => {
        this.edit_bank_data[key] = "";
      });
      this.edit_acc_active = false;
    },
    closeApply() {
      this.apply_data = {};
      this.apply_active = false;
    },
    openUpdate(id) {
      this.edit_bank_data = this.accounts.find((acc) => acc.id === id);
      this.edit_acc_active = true;
    },
    changeBankStatus(id) {
      return this.axios
        .post("settings/bank_accounts/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBankAccounts();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        });
    },
    deleteCurrentAcc(id, i) {
      this.$set(this.bank_delete_disabled, i, true);
      return this.axios
        .post("settings/bank_accounts/delete/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBankAccounts();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.bank_delete_disabled.splice(i, 1);
        });
    },
    changeStatus(id) {
      return this.axios
        .post("settings/payment_methods/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getPayments();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          const item = this.payment_methods.find(
            (payment) => payment.id === id
          );
          if (error_flag === 1) {
            this.createdAlert("error", message);
            this.$set(item, "status", "off");
          }
          if (error_flag === 1 && id === 3) {
            this.config_active = true;
            this.$set(item, "status", "off");
          }
        });
    },
    updateConfig() {
      this.config_disabled = true;
      this.config_errors = {};
      return this.axios
        .post("settings/payment_methods/update", this.config_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getPayments();
            this.createdAlert("success", data.message);
            this.config_data = {};
            this.config_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.config_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.config_disabled = false;
          // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },

    closeHyper() {
      Object.keys(this.config_data).forEach((key) => {
        this.config_data[key] = "";
      });
      this.config_active = false;
    },
    openConfig(id) {
      let item = this.payment_methods.find((item) => item.id == id);
      this.config_content = item;
      item.config_fields?.forEach((field) => {
        if (field) {
          let fields = { [field.key]: null };

          switch (field.key) {
            case "access_token":
              fields.access_token =
                this.$store.state.token || this.$cookies.get("token");
              break;
            default:
              break;
          }
          this.config_data = {
            ...this.config_data,
            payment_method_id: item.id,
            ...fields,
          };
        }
        if (item?.config) {
          Object.keys(item.config).forEach((key) => {
            this.config_data[key] = item.config[key];
          });
        }
      });
      this.config_active = true;
    },
    openDocuments(id) {
      this.doc_content = this.payment_methods.find((pay) => pay.id === id);
      this.apply_data.payment_method_id = id;
      return this.axios
        .get("settings/payment_methods/documents/" + id)
        .then(({ data }) => {
          this.documents = data.result.documents;
          this.apply_active = true;
          data.result.documents.forEach((doc) => {
            this.apply_data = {
              ...this.apply_data,
              ["document_" + doc.id]: null,
            };
          });
        })
        .finally(() => {
          this.documents_load = true;
        });
    },
    applyDocuments() {
      this.apply_disabled = true;
      this.apply_errors = {};
      let fd = new FormData();
      Object.keys(this.apply_data).forEach((key) => {
        if (/document_*/.test(key)) {
          fd.append(key, this.apply_data[key], this.apply_data[key].name);
        } else {
          fd.append(key, this.apply_data[key]);
        }
      });
      return this.axios
        .post("settings/payment_methods/apply", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.apply_data = {};
            this.apply_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.apply_errors = message;
            } else {
              this.error = message;
            }
          }
        })
        .finally(() => {
          this.apply_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    openRequests(i) {
      this.$set(this.request_active, i, !this.request_active[i]);
    },
  },
  components: { TableLoad, SweetModal },
};
</script>
