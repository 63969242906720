<template>
  <div class="py-10 px-6 space-y-6">
    <section class="w-full">
      <main class="w-full xl:container grid grid-cols-1 lg:grid-cols-2 gap-6">
        <figure
          class="w-full bg-white rounded-xl overflow-hidden lg:col-span-2"
        >
          <header class="w-full p-4 border-b border-gray-100">
            <p class="text-yellow-600 font-medium rtl:font-semibold">الطلبات</p>
          </header>
          <blockquote class="p-4 space-y-2">
            <div>
              <apexchart
                height="350"
                class="w-full"
                width="100%"
                :options="{
                  ...chartOptions,
                  xaxis: orders_xaxis,
                  colors: [
                    '#22c55e', // new
                    '#f97316', // preparing
                    '#2563eb', // ready
                    '#eab308', // delivering
                    '#f59e0b', // completed
                    '#ef4444', // cancelled
                  ],

                  dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return val;
                    },
                    offsetY: -30,
                    style: {
                      fontSize: '12px',
                      colors: ['#304758'],
                    },
                  },
                }"
                :series="order_series"
                type="bar"
              ></apexchart>
            </div>
          </blockquote>
        </figure>
        <figure class="w-full bg-white rounded-xl overflow-hidden">
          <header class="w-full p-4 border-b border-gray-100">
            <p class="text-yellow-600 font-medium rtl:font-semibold">
              اجمالي طرق الدفع
            </p>
          </header>
          <blockquote class="p-4 space-y-2">
            <div>
              <apexchart
                height="350"
                class="w-full"
                width="100%"
                :options="{
                  ...chartOptions,
                  dataLabels: {
                    enabled: false,
                  },
                  colors: [
                    '#0d9488', // cash
                    '#0369a1', // card
                    '#8b5cf6', // visa
                    '#0f172a', // apple pay
                    '#f59e0b', // bank account
                  ],
                  labels: [
                    'CASH',
                    'CARD',
                    'VISA',
                    'APPLE PAY',
                    'BANK TRANSFER',
                  ],
                  legend: {
                    show: true,

                    formatter: function (seriesName, opts) {
                      return (
                        ' ' +
                        seriesName +
                        ' :  ' +
                        opts.w.globals.series[opts.seriesIndex]
                      );
                    },
                    itemMargin: {
                      vertical: 3,
                    },
                  },
                }"
                :series="[30, 55, 20, 100, 55]"
                type="radialBar"
              ></apexchart>
            </div>
          </blockquote>
        </figure>
        <figure class="w-full bg-white rounded-xl overflow-hidden">
          <header class="w-full p-4 border-b border-gray-100">
            <p class="text-yellow-600 font-medium rtl:font-semibold">
              المبيعات
            </p>
          </header>
          <blockquote class="p-4 space-y-2">
            <div>
              <apexchart
                height="350"
                class="w-full"
                width="100%"
                :options="{
                  ...chartOptions,
                  dataLabels: {
                    enabled: false,
                  },
                  colors: [
                    '#0d9488', // cash
                    '#0369a1', // card
                    '#8b5cf6', // visa
                    '#0f172a', // apple pay
                  ],
                  labels: [
                    'اجمالي المبيعات',
                    'اجمالي رسوم الشحن',
                    'اجمالي الضريبة المضافة',
                    'اجمالي قسائم الخصم',
                  ],
                  legend: {
                    show: true,

                    formatter: function (seriesName, opts) {
                      return (
                        ' ' +
                        seriesName +
                        ' :  ' +
                        opts.w.globals.series[opts.seriesIndex]
                      );
                    },
                    itemMargin: {
                      vertical: 3,
                    },
                  },
                }"
                :series="[30, 55, 20, 100]"
                type="radialBar"
              ></apexchart>
            </div>
          </blockquote>
        </figure>
        <header class="w-full p-4 border-b border-gray-100 lg:col-span-2">
          <p class="text-yellow-600 font-medium rtl:font-semibold">
            المناطق الاكثر طلبا
          </p>
        </header>
        <figure class="w-full bg-white rounded-xl overflow-hidden">
          <blockquote class="space-y-2">
            <header class="p-5 border-b border-gray-200 space-y-4">
              <div
                class="flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
              >
                <div class="w-full sm:flex-1 sm:max-w-sm relative">
                  <ModelListSelect
                    :list="regions"
                    :selectedItems="product_filter.region_id"
                    v-model="product_filter.region_id"
                    option-value="id"
                    option-text="name"
                    placeholder="المنطقة"
                    class="custom-multi-select"
                  >
                  </ModelListSelect>
                </div>
              </div>
            </header>
            <div class="w-full pb-5 overflow-x-auto">
              <table class="styled-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("table.product") }}</th>
                    <th>{{ $t("table.name") }}</th>
                    <th>{{ $t("table.sku") }}</th>
                    <th>{{ $t("table.qty") }}</th>
                    <th>{{ $t("table.price") }} ({{ $t("sar") }})</th>
                    <th>{{ $t("table.status") }}</th>
                    <th>{{ $t("table.created_at") }}</th>
                  </tr>
                </thead>
                <tbody v-if="products.length > 0 && load">
                  <tr v-for="(product, index) in products" :key="index">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      <div class="w-10 h-10">
                        <img
                          :src="product.image"
                          @error="$event.target.src = '/images/placeholder.png'"
                          alt="product image"
                          class="w-full h-full rounded-lg border object-cover border-gray-100"
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        class="inline-block line-clamp-2 whitespace-normal max-w-[200px]"
                      >
                        {{ product.name }}
                      </span>
                    </td>
                    <td>{{ product.sku }}</td>
                    <td>{{ product.qty }}</td>
                    <td>{{ product.price }}</td>

                    <td>
                      <span
                        class="inline-block py-1.5 px-3 rounded-full text-sm text-white"
                        :class="
                          product.status === 'on' ? 'bg-blue-500' : 'bg-red-500'
                        "
                      >
                        {{ product.status }}
                      </span>
                    </td>
                    <td>
                      <div>
                        {{ product.created_at | moment("from") }}
                      </div>
                      <div>
                        <span
                          dir="ltr"
                          class="text-gray-600 text-xs font-medium"
                          >{{
                            product.created_at
                              | moment(" h:mm a . MMMM Do YYYY")
                          }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="!load">
                  <TableLoad :rows="8" v-for="i in 4" :key="i" />
                </tbody>
                <tbody v-if="load && products.length === 0">
                  <tr>
                    <td colspan="8" class="!text-center">لا توجد بيانات</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
              <paginate
                v-model="currentPage"
                :page-count="totalPages"
                :page-range="5"
                :margin-pages="2"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </blockquote>
        </figure>
        <figure
          class="w-full bg-white rounded-xl overflow-hidden hidden"
          v-if="region_series.length > 0"
        >
          <blockquote class="p-4 space-y-2 w-full">
            <div>
              <apexchart
                height="400"
                class="w-full"
                width="100%"
                :options="{
                  ...chartOptions,
                  dataLabels: {
                    enabled: false,
                  },
                  colors: ['#0d9488', '#0369a1', '#8b5cf6', '#0f172a'],
                  legend: {
                    show: true,

                    formatter: function (seriesName, opts) {
                      return ` ${seriesName} `;
                    },
                    itemMargin: {
                      vertical: 3,
                    },
                  },
                }"
                :series="region_series"
                type="area"
              ></apexchart>
            </div>
          </blockquote>
        </figure>
      </main>
    </section>
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import TableLoad from "@/components/loading/TableLoad.vue";

export default {
  name: "AnalyticsPage",
  components: { ModelListSelect, TableLoad },
  data() {
    return {
      series: [
        {
          name: "series-1",
          data: [30, 55, 20, 100, 55, 60],
        },
      ],
      order_series: [],
      chartOptions: {
        zoom: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: [],
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: "20",
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#ca8a04"],
        chart: {
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            fontFamily: "Poppins, Cairo",

            class: "chart-item",
          },
        },
        tooltip: {
          enabled: true,
        },
        xaxis: {
          labels: {
            style: {
              colors: "#000",
              fontFamily: "Cairo",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "#e2e8f0",
            },
            axisTicks: {
              show: false,
            },
            labels: {
              style: {
                colors: "#000",
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        grid: {
          show: true,
          borderColor: "#e2e8f0",
          strokeDashArray: 1,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      orders_xaxis: {
        categories: [
          "new",
          "preparing",
          "ready",
          "delivering",
          "completed",
          "cancelled",
        ],
        labels: {
          formatter: (w) => {
            return this.$t("status." + w);
          },
        },
      },
      products: [],
      load: false,
      order_modal: false,
      order: {},
      currentPage: 1,
      search_disabled: false,
      search_key: "",
      perPage: 0,
      rows: 0,
      totalPages: 0,
      regions: [],
      product_filter: {
        region_id: "",
      },
      region_series: [],
    };
  },
  created() {
    this.getLocations();
    this.getProducts();
  },
  watch: {
    "product_filter.region_id"() {
      this.getProducts();
    },
  },
  methods: {
    getLocations() {
      return this.axios
        .get("locations")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.regions = data.result.regions;
            data.result.regions.forEach((region) => {
              let data = [0];
              data.push(Math.floor(Math.random() * 100));
              let series = {
                name: region.name,
                data,
              };
              this.region_series.push(series);
            });
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.regions = {};
          }
        });
    },
    getProducts() {
      return this.axios
        .get("products", {
          params: {
            page: this.currentPage,
            region_id: this.product_filter.region_id,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            // this.products = data.result.products.data;
            // this.perPage = data.result.products.pagination.per_page;
            // this.rows = data.result.products.pagination.total;
            // this.totalPages = data.result.products.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.products = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
  },
  mounted() {
    let status = [
      "new",
      "preparing",
      "ready",
      "delivering",
      "completed",
      "cancelled",
    ];
    status.forEach((st) => {
      let data = [];
      data.push(Math.floor(Math.random() * 100));
      let series = {
        name: this.$t(`status.${st}`),
        data,
      };
      this.order_series.push(series);
    });
  },
};
</script>
