<template>
  <div>
    <section class="w-full">
      <header class="w-full py-20 px-6 bg-image relative bg-yellow-600">
        <div class="space-y-2">
          <h1 class="text-4xl font-bold text-white text-center">
            اختر أفضل خطة لك
          </h1>
          <h4
            class="text-base font-medium rtl:font-semibold text-center text-gray-100"
          >
            تنمو بشكل أسرع مع Matgary
          </h4>
        </div>
      </header>
      <main
        class="w-full max-w-screen-lg mx-auto p-6 py-10 space-y-10"
        v-if="load"
      >
        <div>
          <div
            class="flex flex-row items-center justify-center gap-3 flex-wrap"
          >
            <span
              class="py-1 px-3 rounded-lg text-sm font-medium text-gray-600"
            >
              شهري
            </span>

            <input
              type="checkbox"
              class="toggle-input"
              :true-value="'yearly_price'"
              :false-value="'monthly_price'"
              v-model="plan_type"
            />
            <span
              class="py-1 px-3 rounded-lg text-sm font-medium text-gray-600"
            >
              سنوي
            </span>
          </div>
        </div>
        <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div
            class="w-full py-10 px-6 rounded-xl text-center flex flex-col border"
            data-aos="fade-up"
            data-aos-delay="100"
            v-for="(item, index) in plans"
            :key="index"
            :class="{
              'bg-yellow-600 border-yellow-500':
                current_plan.name === item.name,
              'bg-white border-gray-200': current_plan.name !== item.name,
            }"
          >
            <h1
              class="text-lg font-semibold rtl:font-bold text-center"
              :class="{
                'text-white': current_plan.name === item.name,
                'text-yellow-600': current_plan.name !== item.name,
              }"
            >
              {{ item.name }}
            </h1>
            <h2
              class="text-2xl font-medium rtl:font-semibold text-center my-4"
              :class="{
                'text-white': current_plan.name === item.name,
                'text-gray-600': current_plan.name !== item.name,
              }"
            >
              {{
                item[plan_type] == 0
                  ? `مجانا`
                  : item[plan_type] +
                    " ريال " +
                    $t(plan_type.split("_price").join(""))
              }}
            </h2>
            <p
              class="text-base font-medium rtl:font-semibold text-center"
              :class="{
                'text-white': current_plan.name === item.name,
                'text-gray-500': current_plan.name !== item.name,
              }"
            >
              {{ item.desc }}
            </p>
            <ul class="list-none space-y-4 mt-6 flex-1">
              <li
                v-for="(feature, idx) in item.features.slice(0, 5)"
                :key="idx"
              >
                <p
                  class="text-base font-medium rtl:font-semibold text-center"
                  :class="{
                    'text-white': current_plan.name === item.name,
                    'text-gray-500': current_plan.name !== item.name,
                  }"
                >
                  {{
                    (features.find((e) => e.code === feature.code) || {}).name
                  }}
                </p>
              </li>
            </ul>
            <button
              class="py-3 px-4 rounded-full font-medium text-center mt-10 block w-full max-w-[60%] mx-auto text-sm"
              :data-type="item.name"
              @click.prevent="chooseCurrentPlan(item.id)"
              :disabled="current_plan.name === item.name"
              :class="{
                'text-yellow-600 bg-white': current_plan.name === item.name,
                'bg-gradient-to-tr from-yellow-500 to-yellow-600 text-white':
                  current_plan.name !== item.name,
              }"
            >
              {{
                current_plan.name === item.name
                  ? "الباقة مُختارة بالفعل"
                  : "اختار الباقة"
              }}
            </button>
          </div>
        </div>
      </main>
      <footer class="xl:container p-6 py-10" v-if="load">
        <div class="w-full overflow-x-auto bg-white">
          <table class="styled-table pricing-table border border-gray-200">
            <thead>
              <tr>
                <th></th>
                <th v-for="(item, index) in plans" :key="index">
                  <h1
                    class="text-lg font-medium text-yellow-500 text text-center"
                  >
                    {{ item.name }}
                  </h1>
                  <h2
                    class="text-2xl font-medium text-gray-600 text text-center my-4"
                  >
                    {{
                      item[plan_type] == 0
                        ? `مجانا`
                        : item[plan_type] +
                          " ريال " +
                          $t(plan_type.split("_price").join(""))
                    }}
                  </h2>
                  <p
                    class="text-base font-medium text-gray-500 text text-center"
                  >
                    {{ item.desc }}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(feature, index) in features" :key="index">
                <td class="min-w-[20rem] space-y-1 !whitespace-normal">
                  <h2 class="text-base font-medium text-gray-800">
                    {{ feature.name }}
                  </h2>
                  <p
                    class="text-sm font-medium text-gray-600"
                    v-if="feature.desc"
                  >
                    {{ feature.desc }}
                  </p>
                </td>
                <td v-for="(item, idx) in plans" :key="idx">
                  <div v-if="getFeature(item, feature)">
                    <i
                      class="fa-solid fa-check-circle text-green-600 text-xl"
                      v-if="getFeature(item, feature).value === undefined"
                    ></i>
                    <p v-else-if="getFeature(item, feature).value">
                      {{ getFeature(item, feature).value }}
                    </p>
                    <p v-else-if="getFeature(item, feature).value === null">
                      غير محدود
                    </p>
                  </div>
                  <i
                    v-else
                    class="fa-solid fa-circle-xmark text-red-600 text-xl"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </footer>
      <div class="table mx-auto mt-4 px-6" v-if="!load">
        <div
          class="w-12 h-12 rounded-full flex items-center justify-center text-gray-600 bg-white shadow-2xl"
        >
          <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
      </div>
    </section>
    <SweetModal
      @close="closeCurrentModel"
      class="!mt-0"
      v-if="update_active"
      :title="'تحديث الباقة'"
    >
      <template>
        <div class="p-4 space-y-4" v-if="!checkout_active">
          <figure class="w-full bg-yellow-600/10 flex">
            <figcaption class="p-4">
              <div v-if="+plan[plan_type] !== 0">
                <h1 class="text-xl font-bold text-center text-yellow-600">
                  {{ plan[plan_type] }}
                  <span class="text-xs font-medium rtl:font-semibold"
                    >ريال</span
                  >
                </h1>
                <p class="text-xs font-medium rtl:font-semibold">
                  {{ $t(plan_type.split("_price").join("")) }}
                </p>
              </div>
              <div v-else>
                <h1 class="text-xl font-bold text-center text-yellow-600">
                  مجانا
                </h1>
              </div>
            </figcaption>
            <blockquote class="flex-1 p-4 space-y-1">
              <div class="grid">
                <h1 class="font-medium rtl:font-semibold truncate">
                  {{ plan.name }}
                </h1>
              </div>
              <p class="text-font-medium rtl:font-semibold text-sm">
                تاريخ الاشتراك:
                <span class="text-gray-800 font-bold text-left" dir="ltr">{{
                  new Date() | moment("YYYY/MM/DD")
                }}</span>
                تاريخ التجديد:
                <span class="text-gray-800 font-bold text-left" dir="ltr">{{
                  afterPeriod(plan_type)
                }}</span>
              </p>
            </blockquote>
          </figure>
          <div class="space-y-3">
            <label
              for="payment_option"
              class="font-medium rtl:font-semibold table text-sm text-gray-600"
            >
              طريقة الدفع
            </label>
            <div class="grid">
              <div class="flex flex-wrap gap-4">
                <div
                  v-for="pay in payments"
                  :key="pay"
                  class="p-2 rounded-md ring-2 ring-gray-100"
                  :class="{
                    'bg-yellow-600/10 ring-yellow-600 ring-offset-2 ring-offset-white':
                      add_data.payment_option === pay,
                  }"
                >
                  <input
                    type="radio"
                    name="paymentOptionGroup"
                    :id="pay"
                    class="hidden"
                    hidden
                    aria-hidden="true"
                    @change="changeCurrentOption(pay)"
                  />
                  <label :for="pay" class="block cursor-pointer">
                    <img
                      :src="require(`@/assets/images/${pay}.svg`)"
                      alt="payment option logo"
                      class="w-full h-full max-w-[4rem] max-h-[2rem] table mx-auto"
                    />
                  </label>
                </div>
              </div>
            </div>
            <p
              class="text-red-500 font-medium rtl:font-semibold text-sm"
              v-if="add_errors.payment_option"
            >
              {{ (add_errors.payment_option || []).join("") }}
            </p>
          </div>
          <div class="pt-4 border-t border-gray-100">
            <button
              class="py-2 px-4 rounded-md bg-blue-600 text-white text-sm font-medium rtl:font-semibold table ml-auto rtl:ml-0 rtl:mr-auto"
              :disabled="add_disabled"
              @click.prevent="updatePlan"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
              تحديث
            </button>
          </div>
        </div>
        <div v-else class="w-full p-4">
          <form
            :action="`/settings/plans/done?payment=${add_data.payment_option}&plan=${plan.name}&price=${plan[plan_type]}`"
            class="paymentWidgets"
            :data-brands="
              add_data.payment_option === 'visa'
                ? 'VISA MASTER'
                : add_data.payment_option === 'mada'
                ? 'MADA'
                : ''
            "
          ></form>
        </div>
      </template>
    </SweetModal>
  </div>
</template>
<script>
import SweetModal from "@/components/modals/sweetModal.vue";
export default {
  name: "PlansPage",
  components: { SweetModal },
  data() {
    return {
      plans: [],
      features: [],
      load: false,
      add_disabled: false,
      update_active: false,
      checkout_active: false,
      add_disables: [],
      add_data: {},
      add_errors: {},
      plan: {},
      plan_type: "monthly_price",
      payments: ["visa", "mada"],
      current_plan: {},
    };
  },
  created() {
    this.getPlans();
    this.current_plan = this.$store.state.plan;
  },
  methods: {
    getPlans() {
      this.axios
        .get("https://apis-mystore.appli.one/website/plans")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.plans = data.result.plans;
            this.features = data.result.all_features;
            const { plan } = this.$route.query;
            const { name } = this.$store.state.plan;
            const { id } = this.plans.find((e) => e.name === name);

            if (+plan === +id) {
              this.createdAlert("success", "مشترك بالفعل في تلك الباقة");
            } else {
              this.chooseCurrentPlan(+plan);
            }
          }
        })
        .catch(({ response }) => {
          if (response.data.error_flag == 1) {
            console.log(response.data);
            this.$router.go(-1);
          }
        })
        .finally(() => (this.load = true));
    },
    getFeature(item, feature) {
      const final = item.features.find((e) => e.code === feature.code);
      if (final) {
        return final;
      }
    },
    chooseCurrentPlan(id) {
      // this.add_data = {};
      // this.plan = {};
      this.plan = this.plans.find((e) => e.id === id);
      this.add_data = {
        ...this.add_data,
        plan_id: id,
        plan_period:
          this.plan_type.split("_price").join("") === "monthly"
            ? "month"
            : "year",
        payment_option: "",
      };
      this.update_active = true;
    },
    afterPeriod(type) {
      return this.$moment()
        .locale("ar")
        .subtract(-1, type === "monthly_price" ? "months" : "years")
        .format("YYYY/MM/DD");
    },
    updatePlan() {
      this.add_disabled = true;
      this.add_errors = {};
      return this.axios
        .post("settings/update_plan", this.add_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            // this.add_data = {};
            const externalScript = document.createElement("script");
            const scriptStyle = document.createElement("script");
            externalScript.setAttribute("src", `${data.result.checkout_url}`);
            scriptStyle.innerHTML = `var wpwlOptions = {
                          style: "plain",
                          locale: "${this.$i18n.locale}",
                          validation: true
                      }
                        `;
            document.head.appendChild(scriptStyle);
            document.head.appendChild(externalScript);
            this.checkout_active = true;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.add_errors = message;
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    closeCurrentModel(e) {
      this.update_active = e;
      this.add_data = {};
      this.plan = {};
      this.checkout_active = false;
    },
    changeCurrentOption(e) {
      this.$set(this.add_data, "payment_option", e);
    },
  },
};
</script>
<style lang="scss">
.pricing-table {
  th {
    @apply bg-gray-200;
  }
  tr {
    td:not(:first-child) {
      @apply text-center;
    }
  }
}
</style>
