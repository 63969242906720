import axios from "axios";
export default function can({ next, store, to }) {
  const { id, payment } = to.query;
  axios
    .post("settings/check_checkout_status", {
      checkout_id: id,
      payment_option: payment,
    })
    .then(({ data }) => {
      if (
        data.error_flag === 0 &&
        ["000.000.000", "000.100.110"].includes(data.result.code)
      ) {
        store.commit("SET_DONE_LOAD", true);
        return next();
      } else {
        return next({
          name: "PageNotFound",
        });
      }
    })
    .catch(({ response }) => {
      if (response.data.error_flag === 1) {
        return next({
          name: "PageNotFound",
        });
      }
    });
}
