<template>
  <section class="w-full p-8">
    <main class="w-full max-w-screen-lg mx-auto text-center">
      <img
        src="@/assets/images/access.webp"
        alt="permission image"
        class="w-full max-w-lg mx-auto mb-6"
      />
      <h1 class="text-2xl font-bold text-center text-yellow-600 mb-4">
        غير مسموح بالدخول
      </h1>
      <p class="font-medium rtl:font-semibold text-gray-600 text-lg mb-10">
        ليس لديك صلاحية للوصول لتلك الخاصية من فضلك التزم بالصلاحيات المحددة لك
        واذا كنت تريد الاستمتاع بتلك الخاصية بامكانك التواصل مع المسؤول للسماح
        لك بذلك.
      </p>
      <router-link
        to="/"
        class="py-2 px-6 rounded-full bg-blue-500 text-white text-sm mb-3 inline-block mx-1 font-medium rtl:font-semibold"
      >
        العودة للرئيسية
      </router-link>
      <router-link
        to="/products"
        class="py-2 px-6 rounded-full text-gray-600 text-sm inline-block mx-1 f.ont-medium rtl:font-semibold"
      >
        تصفح المنتجات
      </router-link>
    </main>
  </section>
</template>
<script>
export default {
  name: "PermissionsAllowed",
};
</script>
