<template>
  <div class="w-full">
    <main class="w-full mx-auto bg-white rounded-xl grid">
      <header
        class="p-5 border-b border-gray-200 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="بحث في الايميلات.."
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
      </header>
      <div class="w-full pb-5 overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>العنوان</th>
              <th>نوع الايميل</th>
              <th>الحالة</th>
              <th>الاجراءات</th>
            </tr>
          </thead>
          <tbody v-if="emails.length > 0 && load">
            <tr v-for="(email, index) in emails" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ email.subject }}
              </td>
              <td>
                {{ email.email_type }}
              </td>

              <td>
                <span
                  class="inline-block py-1.5 px-3 rounded-full text-sm text-white"
                  :class="email.status === 'on' ? 'bg-blue-500' : 'bg-red-500'"
                >
                  {{ email.status }}
                </span>
              </td>

              <td>
                <div class="actions-group">
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-green-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      @click.prevent="showModal(email.id)"
                    >
                      تعديل
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-blue-500/20 text-blue-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تغيير الحالة"
                      @click.prevent="changeStatus(email.id, index)"
                      :disabled="status_disabled[index]"
                    >
                      <i
                        class="fa-solid fa-spinner fa-spin"
                        v-if="status_disabled[index]"
                      ></i>
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <TableLoad v-for="i in 4" :key="i" :rows="5" />
          </tbody>
          <tbody v-if="load && emails.length === 0">
            <tr>
              <td colspan="5" class="!text-center">لا توجد بيانات</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
    <sweet-modal :close="false" v-if="active" @close="active = $event">
      <template>
        <div class="p-6">
          <form @submit.prevent class="w-full space-y-4">
            <div class="w-full space-y-3">
              <label
                for="subject"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                العنوان | عربي
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.ar.subject"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["ar.subject"] || []).join(" ") }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="subject"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                العنوان | انجليزي
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.en.subject"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["en.subject"] || []).join(" ") }}
              </p>
            </div>
            <div
              class="w-full space-y-3"
              v-if="add_data.ar.body || add_data.id"
            >
              <label
                for="subject"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المحتوى | عربي
              </label>
              <tinymce
                :other_options="options"
                id="bodyAr"
                style="height: auto !important"
                :plugins="plugins"
                :toolbar1="toolbar"
                v-model="add_data.ar.body"
                @editorInit="(e) => e.setContent(add_data.ar.body || '')"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["ar.body"] || []).join(" ") }}
              </p>
            </div>
            <div
              class="w-full space-y-3"
              v-if="add_data.en.body || add_data.id"
            >
              <label
                for="subject"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المحتوى | انجليزي
              </label>
              <tinymce
                :other_options="options"
                id="bodyEn"
                style="height: auto !important"
                :plugins="plugins"
                :toolbar1="toolbar"
                v-model="add_data.en.body"
                @editorInit="(e) => e.setContent(add_data.en.body || '')"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors["en.body"] || []).join(" ") }}
              </p>
            </div>
            <div>
              <button
                class="py-3 px-6 rounded-lg text-sm font-medium rtl:font-semibold text-white w-full max-w-[12rem] bg-blue-500"
                @click.prevent="updateEmail"
                :disabled="add_disabled"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                حفظ التغييرات
              </button>
            </div>
          </form>
        </div>
      </template>
    </sweet-modal>
  </div>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
export default {
  name: "EmailTemplate",
  components: { TableLoad, SweetModal },
  data() {
    return {
      emails: [],
      load: false,
      email: {},
      currentPage: 1,
      search_disabled: false,
      search_key: "",
      perPage: 0,
      rows: 0,
      totalPages: 0,
      add_data: {
        ar: {},
        en: {},
      },
      add_errors: {},
      add_disabled: false,
      active: false,
      status_disabled: [],
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste imagetools wordcount",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      options: {
        height: 250,
        menubar: false,
        statusbar: false,
        directionality: "rtl",
        language: this.$i18n.locale,
        language_url: "/" + this.$i18n.locale + ".js",
        skin: "naked",
        icons: "small",
        toolbar_location: "bottom",
        automatic_uploads: true,
        images_reuse_filename: true,
        content_style:
          "body { font-family:Cairo,Quicksand,Helvetica,Arial,sans-serif; font-size:14px }",
      },
    };
  },
  created() {
    this.getEmails();
  },
  watch: {
    currentPage() {
      this.getEmails();
    },
    active() {
      if (!this.active) {
        this.add_data = {
          ar: {},
          en: {},
        };
      }
    },
  },
  methods: {
    getEmails() {
      return this.axios
        .get("store_email_templates", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.emails = data.result.store_email_templates.data;
            this.perPage =
              data.result.store_email_templates.pagination.per_page;
            this.rows = data.result.store_email_templates.pagination.total;
            this.totalPages =
              data.result.store_email_templates.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.emails = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    showModal(id) {
      let item = { ...this.emails.find((email) => email.id === id) };
      if (item.en) {
        this.add_data = item;
      } else {
        this.add_data = {
          ...item,
          en: {},
        };
      }
      this.active = true;
    },
    changeStatus(id, i) {
      this.$set(this.status_disabled, i, true);
      return this.axios
        .post("store_email_templates/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getEmails();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.status_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateEmail() {
      this.add_disabled = true;
      this.add_errors = {};
      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((keyTr) => {
            fd.append(`${key}[${keyTr}]`, this.add_data[key][keyTr]);
          });
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("store_email_templates/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = {
              ar: {},
              en: {},
            };
            this.getEmails();
            this.active = false;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getEmails().then(() => {
        this.search_disabled = false;
      });
    },
  },
};
</script>
