<template>
  <section class="w-full py-10 px-6 space-y-7">
    <FileSystemHeader>
      <template #title> العملاء</template>
    </FileSystemHeader>
    <div class="w-full xl:container space-y-6">
      <header
        class="p-5 flex items-start sm:items-center gap-2 flex-wrap w-full relative flex-col sm:flex-row rounded-xl bg-white"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="بحث في العملاء.."
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
        <div>
          <div class="flex gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-4 rounded-md bg-gray-100 text-gray-600 text-center text-sm flex gap-3 items-center ml-auto rtl:mr-auto rtl:ml-0"
                @click="openCloseMoreBox"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
                <span class="inline sm:hidden"> عرض المزيد </span>
              </button>
            </div>
          </div>
          <div
            class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
            ref="moreBox"
          >
            <div>
              <button
                class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block w-full"
                @click.prevent="exportAll"
                :disabled="export_disabled"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="export_disabled"
                ></i>
                <i class="fa-solid fa-file-excel" v-else></i>
                تصدير الكل
              </button>
            </div>
            <div>
              <button
                class="py-2 px-6 rounded-lg bg-gray-300 text-gray-600 text-center text-sm block w-full"
                @click.prevent="email_active = true"
              >
                <i class="fa-solid fa-file-excel"></i>
                ارسال عبر البريد الالكتروني
              </button>
            </div>
          </div>
        </div>
      </header>
      <main
        class="w-full p-5 rounded-xl bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        v-if="load && customers.length > 0"
      >
        <figure
          class="w-full border border-gray-100 rounded-xl pb-4"
          v-for="(customer, index) in customers"
          :key="index"
        >
          <div
            class="flex items-center justify-center flex-wrap gap-3 px-6 py-4"
          >
            <div class="flex-1">
              <p class="text-sm font-medium rtl:font-semibold">الحالة:</p>
            </div>
            <input
              v-model="customer.status"
              type="checkbox"
              class="toggle-input"
              :true-value="'on'"
              :false-value="'off'"
              @change="changeStatus(customer.id)"
            />
          </div>

          <router-link
            :to="`/customers/${customer.id}`"
            class="w-full block bg-gray-100"
          >
            <div class="w-full p-6">
              <img
                :src="
                  customer.photo || require('@/assets/images/placeholder.png')
                "
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
                alt="client image"
                class="w-20 h-20 rounded-full mb-4 mx-auto object-cover"
              />
              <h1
                class="text-base text-gray-800 mb-0.5 font-semibold text-center"
              >
                {{ customer.name }}
              </h1>
              <a
                :href="`mailto:${customer.email}`"
                class="text-sm text-yellow-600 font-medium table mx-auto text-center"
              >
                {{ customer.email }}
              </a>
            </div>
          </router-link>
          <button
            class="py-2 px-4 rounded-lg bg-transparent text-red-500 font-medium rtl:font-semibold mx-auto text-sm table mt-4"
            @click.prevent="deleteItem(customer.id)"
          >
            حذف العميل
          </button>
        </figure>
      </main>
      <main
        class="w-full p-5 rounded-xl bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        v-if="!load"
      >
        <figure class="p-6 flex-1" v-for="idx in 4" :key="idx">
          <div
            class="table mx-auto w-20 h-20 rounded-full mb-4 bg-gray-200 animate-pulse"
          ></div>
          <div
            class="w-2/5 h-4 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
          ></div>
          <div
            class="w-4/5 h-3 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
          ></div>
        </figure>
      </main>
      <main
        class="w-full p-5 rounded-xl bg-white"
        v-if="load && customers.length === 0"
      >
        <p class="font-medium rtl:font-semibold text-center p-4">
          لا يوجد عملاء
        </p>
        <button
          @click.prevent="active = true"
          class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm table mx-auto mt-2"
        >
          <i class="fa-solid fa-plus"></i>
          اضافة عميل جديد
        </button>
      </main>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
    <SweetModal v-if="active" @close="active = $event" :close="false">
      <template #default>
        <form class="w-full p-6">
          <div class="w-full space-y-3">
            <h1
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              صورة شخصية
            </h1>
            <label
              for="id_uploader_image"
              class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
            >
              تحميل ( .png , .jpg )
            </label>
            <input
              type="file"
              name="id_uploader_image"
              id="id_uploader_image"
              hidden
              accept="image/*"
              class="hidden"
              @change.prevent="uploadMainImage($event, 'photo')"
            />
            <div v-if="add_data.photo" class="table">
              <div
                class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
              >
                <img
                  :src="convertImage(add_data.photo)"
                  alt="upload"
                  class="w-full rounded-lg h-full object-cover"
                />
              </div>
              <button
                class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm relative"
                @click.prevent="add_data.photo = null"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.photo || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="name"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              الاسم بالكامل
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="example example"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
              autocomplete="off"
              v-model="add_data.name"
            />
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.name || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              البريد الالكتروني
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
              autocomplete="off"
              v-model="add_data.email"
            />
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.email || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              رقم الجوال
            </label>
            <vue-tel-input
              id="mobile"
              ref="registerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('registerMobile')"
            ></vue-tel-input>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.mobile || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="password"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              كلمة المرور
            </label>
            <div class="relative">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                v-model="add_data.password"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded-md"
                autocomplete="off"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.password || []).join(" ") }}
            </p>
          </div>
          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="add_disabled"
                @click.prevent="addCustomer"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                اضافة
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
    <SweetModal
      class="!m-0"
      v-if="email_active"
      @close="email_active = $event"
      :title="'ارسال العملاء عبر البريد الالكتروني'"
    >
      <template>
        <div class="p-5">
          <form class="w-full space-y-4">
            <input-tag
              id="keywords"
              v-model="email_data.emails"
              placeholder="اضف جديد"
              validate="email"
            ></input-tag>

            <button
              class="py-3 px-4 rounded-lg bg-blue-500 text-white font-medium rtl:font-semibold text-sm w-full max-w-[7rem]"
              :disabled="email_disabled"
              @click.prevent="sendViaEmails"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="email_disabled"></i>
              ارسال
            </button>
          </form>
        </div>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import SweetModal from "@/components/modals/sweetModal.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "CustomerIndex",
  data() {
    return {
      search_key: null,
      search_disabled: false,
      customers: [],
      currentPage: 1,
      perPage: 0,
      rows: 0,
      totalPages: 0,
      load: false,
      active: false,
      add_disabled: false,
      add_data: {
        photo: null,
      },
      add_errors: {},
      error: "",
      success: "",
      email_disabled: false,
      email_data: {
        emails: [],
      },
      email_active: false,
      export_disabled: false,
    };
  },
  created() {
    this.getCustomers();
    this.email_data?.emails.push(this.$store.state.user?.email);
  },
  watch: {
    currentPage() {
      this.getCustomers();
    },
  },
  methods: {
    getCustomers() {
      return this.axios
        .get("customers", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.customers = data.result.customers.data;
            this.perPage = data.result.customers.pagination.per_page;
            this.rows = data.result.customers.pagination.total;
            this.totalPages = data.result.customers.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.customers = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getCustomers().then(() => {
        this.search_disabled = false;
      });
    },
    changeStatus(id) {
      return this.axios
        .post("customers/update_status/" + id)
        .then((response) => {
          const res = response.data;
          if (res.error_flag === 0) {
            this.getCustomers();
            this.createdAlert("success", res.message);
          }
        })
        .catch((error) => {
          const err = error.response.data;
          if (err.error_flag === 1) {
            this.createdAlert("error", err.message);
          }
        });
    },
    deleteItem(id) {
      return this.axios
        .post("customers/delete/" + id)
        .then((response) => {
          const res = response.data;
          if (res.error_flag === 0) {
            this.getCustomers();
            this.createdAlert("success", res.message);
          }
        })
        .catch((error) => {
          const err = error.response.data;
          if (err.error_flag === 1) {
            this.createdAlert("error", err.message);
          }
        });
    },
    addCustomer() {
      this.add_disabled = true;
      let fd = new FormData();
      Object.keys(this.add_data).forEach((key) => {
        if (key === "photo") {
          if (this.add_data[key]) {
            fd.append(key, this.add_data[key], this.add_data[key].name);
          }
        } else if (key === "mobile") {
          fd.append(
            key,
            this.add_data[key].replace(/\s/g, "").replace("+", "")
          );
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      this.add_errors = {};
      return this.axios
        .post("customers/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCustomers();
            this.createdAlert("success", data.message);
            thi.active = false;
            this.add_data = {
              photo: null,
            };
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    uploadMainImage(e, type) {
      e.preventDefault();
      this.add_data[type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    exportAll() {
      this.export_disabled = true;
      this.$refs.moreBox.classList.remove("hidden");
      return this.axios
        .get("customers/export_excel", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `customers-${new Date().getTime()}.xlsx`
          );
          link.click();
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.export_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    sendViaEmails() {
      this.email_disabled = true;
      let fd = new FormData();

      Object.keys(this.email_data).forEach((key) => {
        if (key === "emails") {
          this.email_data[key].forEach((email, index) => {
            fd.append(`${key}[${index}]`, email);
          });
        }
      });
      return this.axios
        .post("customers/send_excel", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.email_data.emails = [];
            this.email_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.email_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
  components: { SweetModal, FileSystemHeader },
};
</script>
