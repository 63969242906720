<template>
  <form class="w-full max-w-lg mx-auto space-y-6 mt-10">
    <div class="w-full space-y-3">
      <label
        for="email"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        البريد الالكتروني
      </label>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="example@example.com"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        outocomplete="off"
      />
    </div>

    <div class="w-full space-y-4">
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500 text-gray-800 text-center font-medium rtl:font-semibold"
        :disabled="disabled"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
        ارسال
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500/10 text-yellow-500 text-center font-medium rtl:font-semibold"
      >
        لم احصل على رمز, اعادة الارسال ؟
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md text-yellow-500 bg-transparent text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'login')"
      >
        تسجيل الدخول بدلا من ذلك
      </button>
    </div>
  </form>
</template>
<script>
export default {
  name: "forgotPassword",
  data() {
    return {
      disabled: false,
    };
  },
};
</script>
