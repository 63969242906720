<template>
  <div class="w-full h-full absolute bottom-0 right-0 overflow-hidden">
    <!-- xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs" -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="1440"
      height="560"
      preserveAspectRatio="xMinYMin slice"
      viewBox="0 0 1440 560"
      class="w-full h-full"
    >
      <g mask='url("#SvgjsMask1040")' fill="none">
        <path
          d="M89.947,376.691C124.17,377.938,155.468,357.545,172.376,327.764C189.073,298.354,189.328,261.825,171.466,233.108C154.505,205.839,122.059,195.419,89.947,195.238C57.449,195.055,22.968,203.977,6.82,232.18C-9.253,260.252,1.176,294.073,17.305,322.113C33.491,350.251,57.508,375.509,89.947,376.691"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M993.963,212.66C1031.401,210.461,1057.821,180.998,1077.467,149.054C1098.463,114.915,1120.779,73.904,1100.593,39.28C1080.484,4.787,1033.87,4.552,993.963,5.824C957.014,7.002,918.948,14.931,898.491,45.723C875.862,79.784,871.248,124.474,891.549,159.972C911.976,195.69,952.887,215.072,993.963,212.66"
          fill="#d9770650"
          class="triangle-float2"
        ></path>
        <path
          d="M1108.255,589.595C1166.379,590.794,1223.425,559.82,1248.744,507.487C1271.717,460.003,1244.435,408.66,1217.827,363.113C1191.56,318.15,1160.327,266.656,1108.255,266.999C1056.502,267.34,1026.332,319.323,1001.154,364.54C976.929,408.045,956.064,456.926,977.461,501.889C1001.637,552.692,1052.005,588.434,1108.255,589.595"
          fill="#d9770650"
          class="triangle-float1"
        ></path>
        <path
          d="M257.178,351.601C310.118,349.927,348.188,306.519,373.927,260.227C398.769,215.548,412.305,162.286,387.2,117.754C361.726,72.567,309.042,52.153,257.178,53.133C207.113,54.079,161.352,79.69,135.394,122.51C108.396,167.046,102.457,222.051,126.903,268.037C152.858,316.862,201.911,353.348,257.178,351.601"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M176.289,629.831C220.131,629.298,260.947,603.285,279.612,563.611C296.165,528.424,277.621,490.348,257.588,457.019C238.395,425.088,213.463,394.17,176.289,391.715C135.187,389.001,95.637,409.849,73.989,444.894C51.195,481.795,47.527,528.222,68.522,566.176C90.166,605.305,131.576,630.374,176.289,629.831"
          fill="#d9770650"
          class="triangle-float1"
        ></path>
        <path
          d="M1396.683,539.609C1440.597,542.072,1481.801,514.082,1501.25,474.633C1518.902,438.828,1502.198,399.425,1483.071,364.385C1462.763,327.182,1438.939,286.374,1396.683,283.07C1349.989,279.42,1303.356,306.468,1283.235,348.762C1265.063,386.958,1289.02,427.583,1310.633,463.942C1331.551,499.132,1355.809,537.317,1396.683,539.609"
          fill="#d9770650"
          class="triangle-float1"
        ></path>
        <path
          d="M1199.012,222.537C1221.876,222.936,1243.336,211.202,1255.012,191.541C1266.94,171.456,1269.276,145.554,1256.243,126.167C1244.289,108.385,1220.405,107.034,1199.012,108.239C1180.202,109.299,1162.197,116.229,1151.957,132.043C1140.717,149.4,1136.756,171.298,1146.296,189.645C1156.537,209.341,1176.816,222.15,1199.012,222.537"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M454.518,560.529C483.165,558.624,509.596,544.984,523.996,520.146C538.442,495.229,538.905,464.569,524.668,439.532C510.268,414.209,483.614,399.385,454.518,397.967C422.471,396.406,389.116,405.291,371.437,432.066C352.022,461.471,347.816,501.003,366.951,530.59C384.906,558.353,421.528,562.723,454.518,560.529"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M1281.478,358.057C1298.184,358.475,1314.035,349.919,1322.439,335.475C1330.891,320.949,1330.126,303.142,1321.988,288.438C1313.568,273.225,1298.845,260.606,1281.478,261.463C1265.108,262.271,1254.927,277.588,1247.35,292.122C1240.491,305.279,1237.096,320.335,1243.917,333.512C1251.286,347.748,1265.453,357.656,1281.478,358.057"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M1429.055,490.557C1476.132,487.203,1517.669,465.333,1544.649,426.609C1577.119,380.005,1607.334,322.45,1581.381,271.926C1553.991,218.605,1488.923,198.558,1429.055,201.579C1374.38,204.338,1329.644,239.279,1301.218,286.065C1271.421,335.108,1250.495,396.741,1280.914,445.4C1310.244,492.317,1373.865,494.489,1429.055,490.557"
          fill="#d9770650"
          class="triangle-float2"
        ></path>
        <path
          d="M490.287,509.036C530.98,508.596,573.595,501.44,596.458,467.774C622.094,430.025,626.733,380.375,604.858,340.329C582.134,298.728,537.69,273.396,490.287,273.407C442.903,273.418,398.971,299.045,375.808,340.382C353.14,380.837,354.022,431.743,380.066,470.112C403.52,504.665,448.528,509.487,490.287,509.036"
          fill="#d9770650"
          class="triangle-float1"
        ></path>
        <path
          d="M665.835,574.322C685.832,574.094,706.605,566.82,715.78,549.051C724.434,532.29,715.214,513.486,705.636,497.236C696.266,481.339,684.287,464.097,665.835,464.315C647.602,464.53,637.068,482.556,627.631,498.158C617.676,514.616,605.255,533.019,613.879,550.212C623.054,568.503,645.373,574.555,665.835,574.322"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M821.583,334.866C857.27,334.432,885.667,309.197,904.191,278.691C923.62,246.695,936.309,207.959,918.704,174.924C900.253,140.301,860.792,125.034,821.583,123.677C779.432,122.218,732.361,130.796,712.076,167.773C692.254,203.906,712.83,246.274,735.42,280.744C755.346,311.148,785.234,335.308,821.583,334.866"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M1141.24,687.819C1197.266,686.886,1237.48,637.557,1261.213,586.797C1281.29,543.857,1274.53,495.305,1250.296,454.566C1226.681,414.867,1187.388,387.139,1141.24,385.114C1091.245,382.92,1040.54,401.631,1014.244,444.209C986.704,488.801,989.913,544.435,1014.141,590.91C1040.537,641.544,1084.147,688.769,1141.24,687.819"
          fill="#d9770650"
          class="triangle-float2"
        ></path>
        <path
          d="M696.159,724.969C750.127,725.506,796.704,692.172,825.119,646.287C855.357,597.458,873.3,535.869,843.763,486.613C814.797,438.309,752.371,427.692,696.159,431.233C646.672,434.35,602.673,460.603,577.596,503.38C552.208,546.688,547.884,599.503,571.568,643.766C596.634,690.614,643.03,724.441,696.159,724.969"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M297.588,475.53C357.032,475.8,421.41,463.959,451.703,412.812C482.421,360.947,464.216,297.234,434.118,245.007C403.968,192.691,357.97,145.631,297.588,145.388C236.882,145.144,189.211,191.166,159.139,243.9C129.398,296.053,115.078,359.889,145.88,411.422C176.069,461.929,238.748,475.263,297.588,475.53"
          fill="#d9770650"
          class="triangle-float2"
        ></path>
        <path
          d="M1315.571,607.412C1354.669,609.052,1396.316,600.914,1417.86,568.246C1441.359,532.614,1442.732,485.032,1420.176,448.796C1398.686,414.272,1356.227,403.035,1315.571,403.917C1276.704,404.76,1237.68,419.331,1218.539,453.168C1199.643,486.572,1206.702,527.761,1227.138,560.246C1246.205,590.555,1279.795,605.911,1315.571,607.412"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M1057.861,501.753C1088.892,503.111,1121.686,495.453,1138.545,469.366C1156.693,441.284,1157.108,404.062,1139.273,375.78C1122.433,349.075,1089.418,342.366,1057.861,341.416C1023.947,340.395,984.313,341.143,967.489,370.607C950.735,399.947,969.376,434.981,988.547,462.801C1004.665,486.191,1029.482,500.511,1057.861,501.753"
          fill="#d9770650"
          class="triangle-float2"
        ></path>
        <path
          d="M117.502,691.268C166.36,690.911,214.151,668.867,237.716,626.066C260.571,584.555,250.358,534.668,226.147,493.933C202.543,454.218,163.67,425.53,117.502,423.799C68.183,421.949,19.285,442.977,-6.24,485.218C-32.593,528.83,-32.031,584.162,-5.797,627.846C19.706,670.313,67.967,691.63,117.502,691.268"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
        <path
          d="M1281.124,382.25C1295.182,381.451,1306.397,371.582,1313.091,359.194C1319.423,347.475,1319.482,333.927,1313.726,321.915C1306.945,307.763,1296.817,292.437,1281.124,292.479C1265.469,292.521,1256.373,308.374,1248.823,322.088C1241.643,335.129,1235.134,350.108,1242.176,363.224C1249.502,376.868,1265.663,383.129,1281.124,382.25"
          fill="#d9770650"
          class="triangle-float3"
        ></path>
      </g>
      <defs>
        <mask id="SvgjsMask1040">
          <rect width="1440" height="560" fill="#ffffff"></rect>
        </mask>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
@keyframes float1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-10px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.triangle-float1 {
  animation: float1 5s infinite;
}

@keyframes float2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.triangle-float2 {
  animation: float2 4s infinite;
}

@keyframes float3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.triangle-float3 {
  animation: float3 6s infinite;
}
</style>
