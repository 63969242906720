<template>
  <section class="w-full py-10 px-6">
    <main class="w-full xl:container bg-white rounded-xl">
      <header class="w-full p-4 border-b border-gray-100">
        <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
          بيانات العميل
        </h1>
        <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
          بإمكانك تغيير معلومات العميل [ الاسم، البريد الإلكتروني، الجوال ] عبر
          هذه الصفحة.
        </p>
      </header>
      <form class="w-full space-y-6 p-8">
        <div class="w-full space-y-6">
          <p
            class="text-center font-semibold"
            v-if="error || success"
            :class="error ? 'text-red-500' : 'text-green-600'"
          >
            {{ error || success }}
          </p>
          <div class="w-full space-y-3">
            <h1
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              الصورة الشخصية
            </h1>
            <label
              for="id_uploader_image"
              class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
            >
              تحميل ( .png , .jpg )
            </label>
            <input
              type="file"
              name="id_uploader_image"
              id="id_uploader_image"
              hidden
              accept="image/*"
              class="hidden"
              @change.prevent="uploadMainImage($event, 'photo')"
            />
            <div v-if="add_data.photo" class="table">
              <div
                class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
              >
                <img
                  :src="
                    typeof add_data.photo === 'object'
                      ? convertImage(add_data.photo)
                      : add_data.photo
                  "
                  alt="upload"
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  class="w-full rounded-lg h-full object-cover"
                />
              </div>
              <button
                class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                v-if="typeof add_data.photo === 'object'"
                @click.prevent="add_data.photo = null"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.photo || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="name"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              الاسم بالكامل
            </label>
            <input
              type="text"
              name="name"
              id="name"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.name"
            />
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors || {}).name }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              البريد الالكتروني
            </label>
            <input
              type="text"
              name="email"
              id="email"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.email"
            />
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors || {}).email }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="tel"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              رقم الجوال
            </label>
            <vue-tel-input
              id="mobile"
              ref="registerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('registerMobile')"
            ></vue-tel-input>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors || {}).mobile }}
            </p>
          </div>

          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="new_password"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                كلمة المرور الجديدة
              </label>
              <input
                type="password"
                name="new_password"
                id="new_password"
                placeholder="••••••••"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.password"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors || {}).password }}
              </p>
            </div>
            <div class="w-full space-y-3">
              <label
                for="confirmation_password"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                إعادة تأكيد كلمة المرور
              </label>
              <input
                type="password"
                name="confirmation_password"
                id="confirmation_password"
                placeholder="••••••••"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.c_password"
              />
              <p class="text-red-500 font-medium rtl:font-semibold">
                {{ (add_errors || {}).c_password }}
              </p>
            </div>
          </div>
          <div class="w-full space-y-4">
            <p
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              النوع
            </p>
            <ul class="list-none space-y-4">
              <li>
                <div class="checkbox">
                  <div>
                    <input
                      id="male"
                      v-model="add_data.gender"
                      value="male"
                      type="radio"
                      name="gender"
                    />
                  </div>
                  <label for="male"> ذكر </label>
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <div>
                    <input
                      id="female"
                      v-model="add_data.gender"
                      value="female"
                      type="radio"
                      name="gender"
                    />
                  </div>
                  <label for="female"> انثى </label>
                </div>
              </li>
            </ul>
            <p class="text-red-500 font-medium rtl:font-semibold text-sm">
              {{ (add_errors.gender || []).join(" ") }}
            </p>
          </div>
        </div>

        <button
          class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
          :disabled="disabled"
          @click.prevent="updateCustomer"
        >
          <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
          حفظ التغييرات
        </button>
      </form>
    </main>
  </section>
</template>
<script>
export default {
  name: "customer",
  data() {
    return {
      id: null,
      load: false,
      add_data: {},
      add_errors: {},
      disabled: false,
      error: "",
      success: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      return this.axios
        .get("customers/" + this.id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = data.result.customer;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            console.log(message);
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateCustomer() {
      this.disabled = true;
      let fd = new FormData();
      Object.keys(this.add_data).forEach((key) => {
        if (key === "photo") {
          if (this.add_data[key] && typeof this.add_data[key] === "object") {
            fd.append(key, this.add_data[key], this.add_data[key].name);
          } else {
            fd.delete(key);
          }
        } else if (key === "mobile") {
          fd.append(
            key,
            this.add_data[key].replace(/\s/g, "").replace("+", "")
          );
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      this.add_errors = {};
      return this.axios
        .post("customers/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCustomers();
            this.createdAlert("success", data.message);
            thi.active = false;
            this.add_data = {
              photo: null,
            };
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    uploadMainImage(e, type) {
      e.preventDefault();
      this.add_data[type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
  },
};
</script>
