<template>
  <!-- footer -->
  <footer class="w-full bg-white relative">
    <div class="w-full p-6 text-center font-medium rtl:font-semibold text-sm">
      جميع الحقوق محفوظة © 2022 لدى
      <a
        href="https://ms.ia6g.com"
        class="text-yellow-600 underline font-semibold"
      >
        {{ $t("app_name") }}
      </a>
    </div>
  </footer>
  <!-- ./footer -->
</template>
<script>
export default {};
</script>
