<template>
  <div>
    <form class="w-full space-y-6 mt-10">
      <header class="w-full max-w-lg table mx-auto relative !my-10">
        <span
          class="w-[calc(100%-7rem)] bg-gray-200 h-px absolute top-7 left-1/2 -translate-x-1/2 z-[-1]"
        ></span>
        <div class="flex items-start justify-between gap-4 relative">
          <div>
            <div
              class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
            >
              <i class="fa-solid fa-user"></i>
            </div>
            <span
              class="text-center font-medium text-xs text-gray-800 table mx-auto mt-2"
            >
              بيانات صاحب المتجر
            </span>
          </div>
          <div>
            <div
              class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
            >
              <i class="fa-solid fa-store"></i>
            </div>
            <span
              class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
            >
              بيانات المتجر
            </span>
          </div>
          <div>
            <div
              class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
            >
              <i class="fa-solid fa-file"></i>
            </div>
            <span
              class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
            >
              المستندات
            </span>
          </div>
        </div>
      </header>
      <div class="w-full space-y-4 max-w-xl mx-auto">
        <p class="text-red-500 font-medium rtl:font-semibold text-center">
          {{ error }}
        </p>
        <div class="w-full space-y-3">
          <h1 class="font-medium rtl:font-semibold text-gray-600 table text-sm">
            شعار المتجر
          </h1>
          <label
            for="store_logo"
            class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
          >
            تحميل ( .png , .jpg )
          </label>
          <input
            type="file"
            name="store_logo"
            id="store_logo"
            hidden
            accept="image/*"
            class="hidden"
            @change.prevent="add_data.store_logo = $event.target.files[0]"
          />
          <div v-if="add_data.store_logo" class="table">
            <div class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50">
              <img
                :src="convertImage(add_data.store_logo)"
                alt="upload"
                class="w-full rounded-lg h-full object-cover"
              />
            </div>
            <button
              class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm relative"
              @click.prevent="add_data.store_logo = null"
              type="button"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
          <span
            class="table font-medium rtl:font-semibold text-red-500 text-sm"
          >
            {{ (add_errors.store_logo || []).join(" ") }}
          </span>
        </div>
        <div class="w-full space-y-3">
          <h1 class="font-medium rtl:font-semibold text-gray-600 table text-sm">
            صورة الهوية
          </h1>
          <label
            for="national_id_image"
            class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
          >
            تحميل ( .png , .jpg )
          </label>
          <input
            type="file"
            name="national_id_image"
            id="national_id_image"
            hidden
            accept="image/*"
            class="hidden"
            @change.prevent="
              add_data.national_id_image = $event.target.files[0]
            "
          />
          <div v-if="add_data.national_id_image" class="table">
            <div
              class="w-auto h-20 p-1 rounded-xl border-2 border-yellow-600/50"
            >
              <img
                :src="convertImage(add_data.national_id_image)"
                alt="upload"
                class="w-full rounded-lg h-full object-cover"
              />
            </div>
            <button
              class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm relative"
              @click.prevent="add_data.national_id_image = null"
              type="button"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
          <span
            class="table font-medium rtl:font-semibold text-red-500 text-sm"
          >
            {{ (add_errors.national_id_image || []).join(" ") }}
          </span>
        </div>
        <div class="w-full space-y-3">
          <h1 class="font-medium rtl:font-semibold text-gray-600 table text-sm">
            صورة السجل التجاري
          </h1>
          <label
            for="commercial_register_image"
            class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
          >
            تحميل ( .png , .jpg )
          </label>
          <input
            type="file"
            name="commercial_register_image"
            id="commercial_register_image"
            hidden
            accept="image/*"
            class="hidden"
            @change.prevent="
              add_data.commercial_register_image = $event.target.files[0]
            "
          />
          <div v-if="add_data.commercial_register_image" class="table">
            <div
              class="w-auto h-20 p-1 rounded-xl border-2 border-yellow-600/50"
            >
              <img
                :src="convertImage(add_data.commercial_register_image)"
                alt="upload"
                class="w-full rounded-lg h-full object-cover"
              />
            </div>
            <button
              class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm relative"
              @click.prevent="add_data.commercial_register_image = null"
              type="button"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
          <span
            class="table font-medium rtl:font-semibold text-red-500 text-sm"
          >
            {{ (add_errors.commercial_register_image || []).join(" ") }}
          </span>
        </div>

        <div class="w-full space-y-4">
          <button
            class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500 text-gray-800 text-center font-medium rtl:font-semibold"
            @click.prevent="AddNewVendor"
            :disabled="add_disabled"
          >
            <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
            ارسال
          </button>
          <button
            class="w-full h-auto py-3 px-4 rounded-md bg-transparent text-yellow-500 text-center font-medium rtl:font-semibold"
            @click.prevent="$emit('action', 'store')"
          >
            السابقة
          </button>
        </div>
      </div>
    </form>
    <SweetModal v-if="active" @close="active = $event">
      <template #default>
        <div class="p-4 space-y-">
          <h1 class="text-gray-600 text-center text-lg font-bold">
            تم الانتهاء من التسجيل بنجاح
          </h1>
          <p class="text-gray-600 text-center text-sm">
            {{ success }}
          </p>
        </div>
      </template>
    </SweetModal>
  </div>
</template>
<script>
import SweetModal from "@/components/modals/sweetModal.vue";
export default {
  name: "attachments",
  data() {
    return {
      add_disabled: false,
      add_data: {
        store_logo: null,
        national_id_image: null,
        commercial_register_image: null,
      },
      add_errors: {},
      error: "",
      success: "",
      active: false,
    };
  },
  created() {
    Object.assign(this.add_data, {
      ...this.$store.state.vendor_data,
      form_step: 3,
    });
  },
  methods: {
    AddNewVendor() {
      this.add_disabled = true;
      this.error = "";
      this.success = "";
      this.add_errors = {};
      let fd = new FormData();
      Object.keys(this.add_data).forEach((key) => {
        if (
          [
            "national_id_image",
            "commercial_register_image",
            "store_logo",
          ].includes(key)
        ) {
          if (this.add_data[key]) {
            fd.append(key, this.add_data[key], this.add_data[key].name);
          }
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("register", fd)
        .then(({ data }) => {
          if (data.error_flag == 0) {
            this.createdAlert("success", data.message);
            this.$store.commit("SET_USER_DATA", {
              user: data.result.account,
              token: data.result.access_token,
            });
            this.$cookies.set("token", data.result.access_token);
            this.$emit("action", "customizing");
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }
      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, (response) => {
        if (response.status === "OK") {
          const address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.Place.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(this.add_data, "address", this.$refs.Place.$refs.input.value);
      }
    },
  },
  components: { SweetModal },
};
</script>
