import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store";
Vue.use(VueAxios, axios);

axios.defaults.baseURL = store.state.BASE_URL;

axios.interceptors.request.use(async (config) => {
  if (store.state.token) {
    config.headers["Authorization"] =
      "Bearer " + store.state.token || Vue.$cookies.get("token");
  }
  config.headers["lang"] = Vue.$cookies.get("locale") || "ar";
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { url } = error.config;
    const { plan } = store.state;
    if (error.response.status === 426) {
      store.commit("SET_SHOULD", {
        response: true,
      });
    }
    if (["settings/account"].includes(url)) {
      store.commit("SET_USER_DATA", {
        user: {},
        token: null,
        image_url: null,
      });
    }
    return Promise.reject(error);
  }
);
