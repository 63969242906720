import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "@/views/Auth.vue";
import Home from "@/views/Home.vue";
import PageNotFound from "@/views/access/PageNotFound.vue";
import PermissionsAllowed from "@/views/access/PermissionsAllowed.vue";
// shopping pages
import Seo from "@/views/Seo.vue";
import SmsPricing from "@/views/shopping/sms/Pricing.vue";
import AbandonedCarts from "@/views/shopping/AbandonedCarts.vue";
// products pages
import ProductsIndex from "@/views/products/index.vue";
import StockIndex from "@/views/products/Stock.vue";
import CreateProduct from "@/views/products/Create.vue";
import UpdateProduct from "@/views/products/Update.vue";
import Categories from "@/views/products/Categories.vue";
import Brands from "@/views/Brands.vue";
// branches pages
import BranchesPage from "@/views/branches/index.vue";
import CreateBranch from "@/views/branches/Create.vue";
// settings page
import SettingsPage from "@/views/Settings.vue";
import Orders from "@/views/Orders.vue";
import SingleOrder from "@/views/SingleOrder.vue";

// customers
import CustomerIndex from "@/views/customers/index.vue";
import customer from "@/views/customers/customer.vue";
// customers
import CouponsIndex from "@/views/coupons/index.vue";
// import coupon from "@/views/coupons/customer.vue";
import IncomesIndex from "@/views/incomes/index.vue";
import OrdersIncomes from "@/views/incomes/Orders.vue";
import Products from "@/views/incomes/Products.vue";
// packages
import PlansPage from "@/views/Plans.vue";
import DonePage from "@/views/Done.vue";
// pages
import createPage from "@/views/pages/create.vue";
import updatePage from "@/views/pages/update.vue";

import AnalyticsPage from "@/views/Analytics.vue";
import CustomizingPage from "@/views/Customizing.vue";

Vue.use(VueRouter);
// middleware & store
import store from "@/store";
import auth from "@/middleware/auth";
import can from "@/middleware/can";
import checkStatus from "@/middleware/checkStatus";
import templates from "@/middleware/templates";

const routes = [
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    // meta: {
    //   middleware: [auth,can],
    // },
  },
  {
    path: "",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
      title: "home",
      permission: "home",
    },
  },
  {
    path: "/seo",
    name: "Seo",
    component: Seo,
    meta: {
      middleware: [auth],
      title: "seo",
      permission: "seo",
      can: "seo",
    },
  },
  {
    path: "/sms/pricing",
    name: "SmsPricing",
    component: SmsPricing,
    meta: {
      middleware: [auth],
      title: "sms_pricing",
      permission: "sms",
    },
  },
  {
    path: "/abandoned-carts",
    name: "AbandonedCarts",
    component: AbandonedCarts,
    meta: {
      middleware: [auth],
      title: "abandoned_carts",
      permission: "abandoned_carts",
      can: "abandoned_carts",
    },
  },
  {
    path: "/products",
    name: "ProductsIndex",
    component: ProductsIndex,
    meta: {
      middleware: [auth],
      title: "products",
      permission: "products",
    },
  },
  {
    path: "/stock",
    name: "StockIndex",
    component: StockIndex,
    meta: {
      middleware: [auth],
      title: "stock",
      permission: "stock",
    },
  },
  {
    path: "/branches",
    name: "BranchesPage",
    component: BranchesPage,
    meta: {
      middleware: [auth],
      title: "branches",
      permission: "branches",
    },
  },
  {
    path: "/branches/create",
    name: "CreateBranch",
    component: CreateBranch,
    meta: {
      middleware: [auth],
      title: "create_branch",
      permission: "branches",
    },
  },

  {
    path: "/products/create",
    name: "CreateProduct",
    component: CreateProduct,
    meta: {
      middleware: [auth],
      title: "create_product",
      permission: "products",
    },
  },
  {
    path: "/products/categories",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth],
      title: "products_categories",
      permission: "categories",
    },
  },
  {
    path: "/products/brands",
    name: "Brands",
    component: Brands,
    meta: {
      middleware: [auth],
      title: "brands",
      permission: "brands",
    },
  },
  {
    path: "/products/:id",
    name: "UpdateProduct",
    component: UpdateProduct,
    meta: {
      middleware: [auth],
      title: "update_product",
      permission: "products",
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      middleware: [auth],
      title: "orders",
      permission: "orders",
    },
  },
  {
    path: "/orders/:order_id",
    name: "SingleOrder",
    component: SingleOrder,
    meta: {
      middleware: [auth],
      title: "orders",
      permission: "orders",
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      middleware: [auth],
      title: "settings",
      permission: "settings",
    },
  },
  {
    path: "/customers",
    name: "CustomerIndex",
    component: CustomerIndex,
    meta: {
      middleware: [auth],
      title: "customers",
      permission: "customers",
    },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: customer,
    meta: {
      middleware: [auth],
      title: "update_customer",
      permission: "customers",
    },
  },
  {
    path: "/coupons",
    name: "CouponsIndex",
    component: CouponsIndex,
    meta: {
      middleware: [auth],
      title: "coupons",
      permission: "coupons",
      can: "coupons",
    },
  },

  {
    path: "/pages/create",
    name: "createPage",
    component: createPage,
    meta: {
      middleware: [auth],
      title: "create_page",
      permission: "pages",
      can: "pages",
    },
  },
  {
    path: "/pages/:id",
    name: "updatePage",
    component: updatePage,
    meta: {
      middleware: [auth],
      title: "update_page",
      permission: "pages",
      can: "pages",
    },
  },

  {
    path: "/not-allowed",
    name: "PermissionsAllowed",
    component: PermissionsAllowed,
    meta: {
      title: "page_not_found",
    },
  },
  {
    path: "/incomes/:date/orders",
    name: "OrdersIncomes",
    component: OrdersIncomes,
    meta: {
      middleware: [auth],
      title: "orders",
      permission: "orders",
    },
  },
  {
    path: "/incomes/:date/sell-products",
    name: "Products",
    component: Products,
    meta: {
      middleware: [auth],
      title: "products",
      permission: "products",
    },
  },

  {
    path: "/incomes",
    name: "IncomesIndex",
    component: IncomesIndex,
    meta: {
      middleware: [auth],
      title: "incomes",
      permission: "incomes",
    },
  },
  {
    path: "/settings/plans",
    name: "PlansPage",
    component: PlansPage,
    meta: {
      middleware: [auth],
      title: "packages",
      permission: "packages",
    },
  },
  {
    path: "/settings/plans/done",
    name: "DonePage",
    component: DonePage,
    meta: {
      middleware: [auth, checkStatus],
      title: "packages",
      permission: "packages",
    },
  },
  {
    path: "/analytics",
    name: "AnalyticsPage",
    component: AnalyticsPage,
    meta: {
      middleware: [auth],
      title: "analytics",
      permission: "analytics",
    },
  },
  {
    path: "/settings/:template/customization",
    name: "CustomizingPage",
    component: CustomizingPage,
    meta: {
      middleware: [templates, auth],
      title: "customizing",
      permission: "customize",
      can: "template_css",
    },
  },

  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      title: "page_not_found",
      middleware: [auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store,
      $cookies,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (store.state.token || Vue.$cookies.get("token")) {
    if (to.name === "Auth") {
      return next("");
    }
  }
  return next();
});
export default router;
