import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import "@/assets/styles/main.css";
import "@/assets/styles/custom.scss";
import i18n from "@/plugins/i18n.js";
import axios from "@/plugins/axios.js";
import VueEcho from "vue-echo";
Vue.config.productionTip = false;

/*------------ import plugins and libs --------------*/

// import tinymce editor
import tinymce from "vue-tinymce-editor";
Vue.component("tinymce", tinymce);

// import input tag component
import InputTag from "vue-input-tag";
Vue.component("input-tag", InputTag);

// vueTelInput
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput, {
  onlyCountries: ["sa", "bh", "ae", "jo", "kw", "qa", "eg"],
  mode: "international",
  defaultCountry: "SA",
  enabledFlags: true,
  // customValidate: true,
  dropdownOptions: {
    showDialCodeInSelection: false,
    showFlags: true,
  },
  styleClasses: "custom-tel-input",
  inputOptions: {
    placeholder: "رقم الجوال",
    required: true,
  },
});
// vue cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// set default config
Vue.$cookies.config("7d");

// import vue search input css
import "vue-search-select/dist/VueSearchSelect.css";

// import vuejs-paginate
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

// import vuejs-google-map
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBL9zlkm2YRyU_hN1yMGrVJ6_JlEHCzzig",
    libraries: "places",
  },
});

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: "ar", // e.g. 'en'
  defaultMode: "lat-lng", // or 'lat-lng'
  googleMapsApiKey: "AIzaSyBL9zlkm2YRyU_hN1yMGrVJ6_JlEHCzzig",
});

// import  vue-quill-editor
import VueQuillEditor from "vue-quill-editor";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor /* { default global options } */);
const moment = require("moment");
Vue.use(require("vue-moment"), { moment });
Vue.use(require("vue-pusher"), {
  api_key: "eed09efd52277fadc262",
  options: {
    cluster: "eu",
    encrypted: true,
    // authTransport: "jsonp",
    authEndpoint: "https://apis-mystore.appli.one/broadcasting/auth",
    forceTLS: false,
    // disableStats: true
    auth: {
      headers: {
        Authorization: "Bearer " + Vue.$cookies.get("token"),
      },
    },
  },
});
Vue.use(VueEcho, {
  broadcaster: "pusher",
  key: "eed09efd52277fadc262",
  authEndpoint: "https://apis-mystore.appli.one/broadcasting/auth",
  forceTLS: false,
  encrypted: true,

  cluster: "eu",
  // disableStats: true
  auth: {
    headers: {
      Authorization: "Bearer " + Vue.$cookies.get("token"),
    },
  },
});
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import Multiselect from "vue-multiselect";

// register globally
Vue.component("multiselect", Multiselect);

import VueCodemirror from "vue-codemirror";

// import base style
import "codemirror/lib/codemirror.css";
// codemirror language
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/theme/material-palenight.css";
import "codemirror/theme/mdn-like.css";

// codemirror active-line.js
import "codemirror/addon/selection/active-line.js";
Vue.use(VueCodemirror, {
  autofocus: true,
  disabled: false,
  indentWithTab: true,
  tabSize: 2,
  placeholder: "Code gose here...",
});
/*-------------------- vue mixin ----------------------*/
// vue mixin
Vue.mixin({
  data() {
    return {
      width: "500px",
    };
  },
  created() {
    // let locale = this.$cookies.get("locale");
    let locale = "ar";
    const html = document.documentElement;
    this.$store.commit(
      "SET_TOKEN",
      this.$cookies.get("token") ? this.$cookies.get("token") : null
    );
    // if (locale || locale !== "") {
    i18n.locale = "ar";
    moment.locale(i18n.locale);
    // } else {
    //   this.$cookies.set("locale", "ar");
    // }

    html.setAttribute("dir", locale === "en" ? "ltr" : "rtl");
    html.setAttribute("lang", locale || "ar");
    window.addEventListener("mouseup", this.closeDropDowns);

    this.handlePageTitle();
  },
  mounted() {
    this.handleFieldsValue();
    [
      "drop",
      "dragstart",
      "dragend",
      "dragenter",
      "dragleave",
      "dragover",
    ].forEach((event) => {
      window.addEventListener(event, (e) => e.preventDefault());
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropDowns);
  },
  methods: {
    handlePageTitle() {
      let title = this.$route.meta.title;
      this.$store.commit("SET_TITLE", title ? title : null);
      document.querySelector("title").innerHTML = title
        ? this.$t(`pages.${title}`)
        : "Matgary";
    },
    // change input type
    textPasswordType(e) {
      e.preventDefault();
      let input = e.currentTarget.parentElement.querySelector("input");
      if (input.type === "password") {
        input.setAttribute("type", "text");
        e.currentTarget
          .querySelector("i")
          .classList.replace("fa-eye", "fa-eye-slash");
      } else {
        input.setAttribute("type", "password");
        e.currentTarget
          .querySelector("i")
          .classList.replace("fa-eye-slash", "fa-eye");
      }
    },
    // change vue tel input dropdown list width when @open
    handleOpen(ref) {
      setTimeout(() => {
        this.$refs[ref].$el.querySelector(
          ".vti__dropdown-list"
        ).style.width = `${this.$refs[ref].$el.offsetWidth}px`;
      }, 5);
    },
    // change language after reload page
    changeLang(lang) {
      this.$cookies.set("locale", lang);
      window.location.reload();
    },
    // check all fields value
    handleFieldsValue() {
      const fields = ["input", "textarea"];
      fields.forEach((field) => {
        let element = document.querySelector(field);
        let validate = (val) => {
          return val.replace(/\s\s+/g, " ");
        };
        if (element) {
          if (
            ["text", "email", "password", "tel", "url"].includes(element.type)
          ) {
            element.addEventListener("input", (e) => {
              e.target.value = validate(e.target.value);
            });
          }

          if (element.tagName === "TEXTAREA") {
            element.addEventListener("input", (e) => {
              e.target.value = validate(e.target.value);
            });
          }
        }
      });
    },
    // close dorpDowns
    closeDropDowns(e) {
      const dropDowns = document.querySelectorAll(".v-dropdown");
      dropDowns.forEach((element) => {
        const btn = document.querySelector(
          `button[data-btn="${element.dataset.btn}"]`
        );
        if (e.target !== element) {
          if (!element.classList.contains("hidden")) {
            element.classList.add("hidden");
            if (btn) {
              btn
                .querySelector("i")
                .classList.replace(
                  element.classList.contains("hidden")
                    ? "fa-angle-up"
                    : "fa-angle-down",
                  element.classList.contains("hidden")
                    ? "fa-angle-down"
                    : "fa-angle-up"
                );
            }
          }
        }
      });
    },

    // update items
    handleSearchSelect(items, type, item) {
      this[type][item] = items;
    },
    convertFile(i, type, item) {
      return URL.createObjectURL(this[type][item][i]);
    },
    // backtop
    backTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    createdAlert(type, message) {
      const element = document.createElement("DIV");
      element.innerHTML += "lorem is goes here..";
      element.className =
        "fixed bottom-5 left-1/2 -translate-x-1/2 w-auto max-w-md py-3 px-5 rounded-xl text-center animate-scroll-bottom z-20";
      element.className += `
          ${
            type == "success"
              ? " bg-green-500 text-white"
              : " bg-red-500 text-white"
          }
      `;
      element.innerHTML = message;

      document.querySelector("#app").append(element);
      setTimeout(() => {
        element.remove();
      }, 3000);
    },
    copyElement(item) {
      if (window.navigator.clipboard) {
        window.navigator.clipboard
          .writeText(item)
          .then(() => {
            this.createdAlert("success", "Copied successfully.");
          })
          .catch(() => {
            this.createdAlert("error", "oops!!, maybe something went wrong.");
          });
      }
    },
    convertImage(img) {
      return URL.createObjectURL(img);
    },
    getFileName(file) {
      let item = file?.split("/");
      return item ? item[item.length - 1] : null;
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    // log out
    logUserOut() {
      this.$cookies.remove("token");
      store.commit("SET_USER_DATA", {
        user: {},
        token: null,
        image_url: null,
      });
      this.$store.commit("SET_AUTH_TYPE", "login");
      if (this.$router.currentRoute.path != "/auth") {
        return this.$router.push("/auth");
      }
    },
    // permission function
    $can(action) {
      return this.$store.state.permissions.indexOf(action) !== -1
        ? true
        : false;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  axios,
  render: (h) => h(App),
}).$mount("#app");
