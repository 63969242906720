<template>
  <div>
    <header
      class="w-full sticky top-0 border-b border-gray-100 py-4 lg:py-0 px-6 header-skew"
    >
      <div class="w-full xl:container flex items-center justify-between gap-6">
        <div>
          <div class="w-full">
            <img
              src="@/assets/images/logo-ipsum.png"
              alt="store logo"
              class="max-w-[10rem] max-h-9 object-contain align-middle"
            />
          </div>
        </div>
        <nav class="hidden lg:block flex-1">
          <ul class="list-none inline-flex gap-6">
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
              >
                <span>Home</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
              >
                <span>Products</span>
              </router-link>
            </li>
            <li>
              <button
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
                @click="openCategoriesList"
              >
                <span>Categories</span>
                <i class="fa-solid fa-angle-down"></i>
              </button>
            </li>
          </ul>
        </nav>

        <div class="block lg:hidden">
          <button
            class="w-10 h-10 flex items-center justify-center rounded-md text-white font-medium rtl:font-semibold text-sm text-center"
          >
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
        <div class="hidden lg:block flex-1 py-4 h-full skew-box">
          <button
            class="py-2 px-6 rounded-full text-gray-600 font-medium rtl:font-semibold text-sm text-center ml-auto rtl:ml-0 rtl:mr-auto table bg-white"
          >
            Login
          </button>
        </div>
      </div>
      <div
        class="w-full xl:container overflow-hidden opacity-0 pointer-events-none invisible h-0 max-h-screen hidden lg:block"
        ref="category"
      >
        <nav class="w-full">
          <ul class="list-none w-full inline-flex flex-wrap gap-4">
            <li class="p-3 flex items-center gap-3" v-for="i in 20" :key="i">
              <div class="relative text-gray-600">
                <span
                  class="absolute bottom-0 -left-2 rtl:left-auto rtl:-right-2 w-6 h-6 rounded-full bg-gray-200"
                ></span>
                <span class="z-[1] inline-block relative"
                  >{{ i > 9 ? i : "0" + i }}.</span
                >
              </div>
              <div class="flex-1">
                <router-link
                  to="#"
                  class="text-gray-600 font-medium rtl:font-semibold text-sm"
                >
                  Category item name
                </router-link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "skewHeader",
  methods: {
    openCategoriesList() {
      let list = this.$refs.category;
      if (list.classList.contains("h-0")) {
        list.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.add(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      } else {
        list.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.remove(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      }
    },
  },
};
</script>
<style lang="scss">
.header-skew {
  &::after {
    content: "";
    position: absolute;
    width: 50%;
    height: clamp(69px, 73px, 100%);
    @apply bg-color top-0 right-0 rtl:right-auto rtl:left-0;
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: clamp(69px, 73px, 100%);

    z-index: -1;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
    @apply bg-color top-0 right-0 rtl:right-auto rtl:left-0;
  }
}
</style>
