<template>
  <div>
    <header class="w-full sticky top-0 px-6">
      <div
        class="w-full xl:container border-b border-gray-100 items-center justify-between gap-3 py-4 hidden lg:flex"
      >
        <div class="flex-1">
          <div class="w-full">
            <img
              src="@/assets/images/logo-ipsum.png"
              alt="store logo"
              class="max-w-[10rem] max-h-9 object-contain align-middle"
            />
          </div>
        </div>

        <div>
          <button
            class="p-2 text-gray-500 text-sm flex items-center gap-2 font-medium rtl:font-semibold"
          >
            <i class="fa-solid fa-map-marker-alt text-color"></i>
            <span>SA, Riyadh</span>
          </button>
        </div>
        <div>
          <button class="p-2 text-gray-60 text-sm">Login</button>
        </div>
        <div>
          <button class="p-2 text-gray-600 text-sm relative">
            <span
              class="px-1.5 absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 bg-color rounded-full text-[10px] text-content"
              >+99</span
            >
            <i class="fa-solid fa-shopping-cart"></i>
          </button>
        </div>
      </div>
      <div
        class="w-full xl:container border-b border-gray-100 flex items-center justify-between gap-3 py-4 lg:py-0"
      >
        <div class="block lg:hidden">
          <div class="w-full">
            <img
              src="@/assets/images/logo-ipsum.png"
              alt="store logo"
              class="max-w-[10rem] max-h-9 object-contain align-middle"
            />
          </div>
        </div>
        <div class="hidden lg:block">
          <button
            class="py-2 px-6 rounded-full bg-color text-content font-medium rtl:font-semibold text-sm text-center"
          >
            Register
          </button>
        </div>
        <nav class="hidden lg:block flex-1">
          <ul class="list-none flex items-center justify-end gap-6">
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm py-6"
                active-class="text-color border-b-2 border-color"
              >
                <span>Home</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm py-6"
                active-class="text-color border-b-2 border-color"
              >
                <span>Products</span>
              </router-link>
            </li>
            <li>
              <button
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm py-6"
                active-class="text-color border-b-2 border-color"
                @click="openCategoriesList"
              >
                <span>Categories</span>
                <i class="fa-solid fa-angle-down"></i>
              </button>
            </li>
          </ul>
        </nav>
        <div class="block lg:hidden">
          <button
            class="w-10 h-10 flex items-center justify-center rounded-md text-gray-600 font-medium rtl:font-semibold text-sm text-center"
          >
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
      </div>
      <div
        class="w-full xl:container overflow-hidden opacity-0 pointer-events-none invisible h-0 max-h-screen hidden lg:block"
        ref="category"
      >
        <nav class="w-full">
          <ul class="list-none w-full inline-flex flex-wrap gap-4">
            <li class="p-3 flex items-center gap-3" v-for="i in 20" :key="i">
              <div class="relative text-gray-600">
                <span
                  class="absolute bottom-0 -left-2 rtl:left-auto rtl:-right-2 w-6 h-6 rounded-full bg-gray-200"
                ></span>
                <span class="z-[1] inline-block relative"
                  >{{ i > 9 ? i : "0" + i }}.</span
                >
              </div>
              <div class="flex-1">
                <router-link
                  to="#"
                  class="text-gray-600 font-medium rtl:font-semibold text-sm"
                >
                  Category item name
                </router-link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "withLocationHeader",
  methods: {
    openCategoriesList() {
      let list = this.$refs.category;
      if (list.classList.contains("h-0")) {
        list.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.add(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      } else {
        list.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.remove(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      }
    },
  },
};
</script>
<style lang="scss"></style>
