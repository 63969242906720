<template>
  <div>
    <div class="w-full h-full" v-if="load">
      <!-- banner -->
      <div class="p-6">
        <section
          class="py-12 px-8 rounded-2xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-600 to-yellow-500 flex items-start flex-col sm:flex-row gap-6 xl:container bg-image relative overflow-hidden"
        >
          <main class="flex-1 space-y-2 w-full">
            <h1 class="text-base font-medium rtl:font-semibold text-gray-200">
              {{ new Date().getHours() > 12 ? "مساء الخير" : "صباح الخير" }}
            </h1>
            <h2 class="text-lg font-semibold rtl:font-bold text-white !mt-0">
              مرحبا بك , {{ user.name }}
            </h2>
            <h1 class="text-lg font-medium rtl:font-semibold text-gray-100">
              هناك العديد من الطلبات في انتظارك , اسرع في قبولهم لزيادة المبيعات
              في متجرك.
            </h1>
          </main>
          <aside class="space-y-2 sm:flex-1 sm:max-w-xs w-full" v-if="false">
            <div
              class="mx-auto w-10 h-10 flex items-center justify-center text-gray-800 text-lg animate-bounce"
            >
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <router-link
              to="/orders"
              class="mx-auto py-3 px-6 rounded-full font-medium rtl:font-semibold text-center text-gray-white bg-white text-sm sm:table sm:w-full block"
            >
              دخول للطلبات
            </router-link>
          </aside>
        </section>
      </div>
      <!-- ./banner -->
      <!-- steps -->
      <section class="w-full p-6">
        <main class="xl:container flex gap-6 flex-col lg:flex-row">
          <div class="w-full flex-1 lg:max-w-sm">
            <div class="w-full bg-white p-5 rounded-xl border border-gray-200">
              <h1
                class="text-gray-800 font-semibold rtl:font-bold text-center p-4"
              >
                ابدء الان مع متجري
              </h1>
              <header class="w-full flex items-center justify-center p-4">
                <div class="w-[8.125rem] h-[8.125rem] relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="130"
                    height="130"
                    viewBox="0 0 130 130"
                  >
                    <circle
                      cx="65"
                      cy="65"
                      r="55"
                      stroke="#e2e8f0"
                      stroke-width="5"
                      fill="none"
                    />
                    <circle
                      cx="65"
                      cy="65"
                      r="55"
                      stroke="#0369a1"
                      stroke-width="6"
                      fill="none"
                      class="circle-progress"
                      style="--i: 50"
                    />
                  </svg>
                  <span
                    class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-lg text-[#0369a1] font-bold text-center block"
                  >
                    50%
                  </span>
                </div>
              </header>
              <div class="w-full p-4 space-y-2">
                <h1 class="text-gray-800 font-semibold rtl:font-bold">
                  تم استكمال 50% من الخطوات
                </h1>
                <p class="text-sm text-gray-600 font-medim rtl:font-semibold">
                  لانك عميل جديد قمنا باختصار لك مميزات الموقع في اربع خطوات قم
                  بمتابعة الخطوات التالية ليتم تجهيز متجرك واستقبال العملاء
                  والطلبات الجديدة, انت الان اصبحت مننا ونحن دائما معك منذ تلك
                  اللحظة اذا كنت تواجه اي مشكلة اثناء اللتصفح او تريد الاستفسار
                  عن اي شئ من خلال صندوق الدعم الفني في الاسفل.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-1 w-full space-y-4">
            <div
              class="w-full flex gap-4"
              v-for="(item, index) in steps"
              :key="index"
            >
              <div>
                <div
                  class="w-12 h-12 rounded-full bg-gray-50 text-gray-600 flex items-center justify-center"
                >
                  <i :class="item.icon"></i>
                </div>
              </div>
              <div
                class="flex-1 space-y-2 bg-white rounded-xl p-5 border border-gray-200"
              >
                <h1 class="text-gray-800 font-semibold rtl:font-bold">
                  {{ item.title }}
                </h1>
                <p class="text-sm text-gray-600 font-medim rtl:font-semibold">
                  {{ item.content }}
                </p>
                <router-link
                  :to="item.redirect"
                  class="text-sm table py-2 px-4 rounded-md bg-gray-600 text-white !mt-4"
                >
                  {{ item.btn }}
                </router-link>
              </div>
            </div>
          </div>
        </main>
      </section>
      <!-- steps -->
      <!-- reports -->
      <section class="w-full">
        <header class="w-full p-6 bg-white">
          <div
            class="xl:container flex justify-between flex-col sm:flex-row items-start gap-4"
          >
            <div class="flex-1 space-y-2">
              <div class="flex items-start gap-4">
                <span>
                  <i class="fa-solid fa-chart-pie text-lg text-green-600"></i>
                </span>
                <div class="flex-1 space-y-2">
                  <h1
                    class="flex font-semibold rtl:font-bold items-center gap-4 text-lg text-green-600"
                  >
                    <span> التقارير </span>
                  </h1>
                  <h2
                    class="text-base font-medium rtl:font-semibold text-gray-500"
                  >
                    ملخص لما حدث في متجرك
                  </h2>
                  <div class="table w-auto" v-if="dashboard.orders_count > 0">
                    <div
                      class="flex items-center gap-4 p-2 px-6 rounded-xl bg-red-500/10 text-yellow-600 font-medium rtl:font-semibold text-sm w-auto"
                    >
                      <span>
                        <i class="fa-solid fa-bell text-yellow-600"></i>
                      </span>
                      <span class="flex-1">
                        لديك {{ dashboard.orders_count }} طلبات جديدة
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="self-end">
              <label
                for="reports_filter"
                class="font-medium rtl:font-semibold text-gray-500 mb-2 text-sm block"
              >
                عرض بواسطة:
              </label>
              <select
                name="status"
                id="reports_filter"
                class="custom-select bg-yellow-600 text-sm text-white"
                v-model="date_filter"
              >
                <option value="" selected disabled>-- اختار --</option>
                <option value="yesterday">الامس</option>
                <option value="today">اليوم</option>
                <option value="last_week">هذا الاسبوع</option>
                <option value="last_month">هذا الشهر</option>
              </select>
            </div>
          </div>
        </header>
        <div class="xl:container">
          <div
            class="w-full p-6 mt-4 gap-6 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1"
          >
            <figure
              class="p-6 py-10 rounded-xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 cursor-pointer bg-image relative overflow-hidden"
            >
              <h2
                class="text-sm font-medium rtl:font-semibold text-white text-center mb-2"
              >
                المنتجات
              </h2>
              <h1
                class="text-2xl font-semibold rtl:font-bold text-white text-center"
              >
                {{ dashboard.products_count || 0 }}
              </h1>
            </figure>
            <figure
              class="p-6 py-10 rounded-xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 cursor-pointer bg-image relative overflow-hidden"
              v-if="false"
            >
              <h2
                class="text-sm font-medium rtl:font-semibold text-white text-center mb-2"
              >
                الرصيد
              </h2>
              <h1
                class="text-2xl font-semibold rtl:font-bold text-white text-center"
              >
                100 ر.س
              </h1>
            </figure>
            <figure
              class="p-6 py-10 rounded-xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 cursor-pointer bg-image relative overflow-hidden"
            >
              <h2
                class="text-sm font-medium rtl:font-semibold text-white text-center mb-2"
              >
                الطلبات
              </h2>
              <h1
                class="text-2xl font-semibold rtl:font-bold text-white text-center"
              >
                {{ dashboard.orders_count || 0 }}
              </h1>
            </figure>
            <figure
              class="p-6 py-10 rounded-xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 cursor-pointer bg-image relative overflow-hidden"
            >
              <h2
                class="text-sm font-medium rtl:font-semibold text-white text-center mb-2"
              >
                التصنيفات
              </h2>
              <h1
                class="text-2xl font-semibold rtl:font-bold text-white text-center"
              >
                {{ dashboard.categories_count || 0 }}
              </h1>
            </figure>
            <figure
              class="p-6 py-10 rounded-xl bg-gradient-to-r rtl:bg-gradient-to-l from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-500 cursor-pointer bg-image relative overflow-hidden"
            >
              <h2
                class="text-sm font-medium rtl:font-semibold text-white text-center mb-2"
              >
                العملاء
              </h2>
              <h1
                class="text-2xl font-semibold rtl:font-bold text-white text-center"
              >
                {{ dashboard.customers_count || 0 }}
              </h1>
            </figure>
          </div>
        </div>
      </section>
      <!-- ./reports -->
      <!-- orders -->
      <div class="w-full p-6">
        <div class="xl:container">
          <header class="w-full flex justify-between flex-wrap gap-4">
            <div class="flex-1 flex items-center gap-4 text-lg text-gray-800">
              <span>
                <i class="fa-solid fa-basket-shopping text-gray-500"></i>
              </span>
              <span> احدث طلبات اليوم </span>
            </div>
            <div>
              <router-link
                to="/orders"
                class="font-medium rtl:font-semibold text-yellow-500"
              >
                عرض المزيد
              </router-link>
            </div>
          </header>
          <div class="mt-6 w-full py-5 rounded-xl bg-white overflow-x-auto">
            <table class="styled-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>رقم الطلب</th>
                  <th>المنتجات</th>
                  <th>العنوان</th>
                  <th>الحالة</th>
                  <th>السعر</th>
                  <th>اخر تحديث</th>
                  <th>الاجراءات</th>
                </tr>
              </thead>
              <tbody v-if="orders ? orders.length > 0 : false">
                <tr v-for="(order, index) in orders" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ order.order_no }}
                  </td>
                  <td>
                    {{ order.products.length }}
                  </td>
                  <td>
                    {{ order.shipping_address }}
                  </td>
                  <td>
                    {{ Number(order.sub_total).toFixed(2) }}
                  </td>
                  <td>
                    <span class="inline-block py-1 5 px-3 rounded-lg bg-color">
                      {{ order.status }}
                    </span>
                  </td>

                  <td>{{ order.updated_at | moment("from") }}</td>

                  <td>
                    <div class="actions-group">
                      <div>
                        <router-link
                          :to="`/orders/${order.id}`"
                          class="py-1.5 px-3 rounded-lg bg-green-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                          :data-title="$t('btn.view')"
                        >
                          <i class="fa-solid fa-eye"></i>
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="orders ? orders.length === 0 : true">
                <tr>
                  <td colspan="9" class="!text-center">لا توجد بيانات</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- ./orders -->
      <!-- products -->
      <div class="w-full p-6">
        <div class="xl:container">
          <header class="w-full flex justify-between flex-wrap gap-4">
            <div class="flex-1 flex items-center gap-4 text-lg text-gray-800">
              <span>
                <i class="fa-solid fa-basket-shopping text-gray-500"></i>
              </span>
              <span> {{ $t("wanted_products") }} </span>
            </div>
          </header>
          <div class="mt-6 w-full py-5 rounded-xl bg-white overflow-x-auto">
            <table class="styled-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("table.product") }}</th>
                  <th>{{ $t("table.brand") }}</th>
                  <th>{{ $t("table.total") }}</th>
                  <th>{{ $t("table.sku") }}</th>
                  <th>{{ $t("table.total_qty") }}</th>
                  <th>{{ $t("table.orders_count") }}</th>
                </tr>
              </thead>
              <tbody v-if="products.length > 0 && product_load">
                <tr v-for="(product, index) in products" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>

                  <td>
                    <div class="flex items-center gap-3">
                      <div class="w-10 h-10">
                        <img
                          :src="
                            product.image ||
                            require('@/assets/images/placeholder.png')
                          "
                          @error="
                            $event.target.src = require('@/assets/images/placeholder.png')
                          "
                          alt="product image"
                          class="w-full h-full rounded-lg border object-cover border-gray-100"
                        />
                      </div>
                      <span
                        class="inline-block !line-clamp-2 !whitespace-normal max-w-sm"
                      >
                        {{ product["name_" + $i18n.locale] }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <p>
                      {{ (product.brand || {}).name || "-" }}
                    </p>
                  </td>

                  <td>{{ product.price || 0 }}</td>
                  <td>{{ product.sku || "-" }}</td>
                  <td>{{ product.total_qty }}</td>
                  <td>{{ product.orders_count }}</td>
                </tr>
              </tbody>
              <tbody v-if="!product_load">
                <TableLoad :rows="7" v-for="i in 4" :key="i" />
              </tbody>
              <tbody v-if="product_load && products.length === 0">
                <tr>
                  <td colspan="7" class="!text-center">
                    {{ $t("no_data.products") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- ./products -->

      <!-- clients & articles -->
      <section class="w-full p-6">
        <div class="xl:container flex lg:flex-row flex-col items-start gap-6">
          <main class="lg:flex-1 w-full">
            <div>
              <div class="w-full bg-white rounded-xl">
                <header class="w-full p-5 border-b border-gray-200">
                  <h1
                    class="font-semibold rtl:font-bold text-yellow-600 text-sm"
                  >
                    العملاء
                  </h1>
                </header>
                <div
                  class="p-5 w-full flex items-center gap-4 flex-wrap"
                  v-if="customers ? customers.length > 0 : false"
                >
                  <router-link
                    :to="`/customers/${customer.id}`"
                    class="flex-1"
                    v-for="(customer, index) in customers"
                    :key="index"
                  >
                    <figure class="p-6 rounded-xl bg-gray-100 w-full">
                      <img
                        :src="
                          customer.photo ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="client image"
                        class="w-20 h-20 rounded-full mb-4 mx-auto object-cover"
                      />
                      <h1
                        class="text-base text-gray-800 mb-0.5 font-semibold text-center"
                      >
                        {{ customer.name }}
                      </h1>
                      <a
                        :href="`mailto:${customer.email}`"
                        class="text-sm text-yellow-600 font-medium table mx-auto text-center min-w-[7rem]"
                      >
                        {{ customer.email }}
                      </a>
                    </figure>
                  </router-link>
                </div>
                <div
                  class="p-5 w-full flex items-center gap-4 flex-wrap"
                  v-if="customers ? customers.length === 0 : true"
                >
                  <div class="flex-1 p-4 text-center text-gray-600">
                    لا يوجد عملاء
                  </div>
                </div>
              </div>
            </div>
          </main>
          <aside class="lg:flex-1 w-full">
            <div>
              <div class="w-full bg-white rounded-xl">
                <header class="w-full p-5 border-b border-gray-200">
                  <h1
                    class="font-semibold rtl:font-bold text-yellow-600 text-sm"
                  >
                    احدث المقالات
                  </h1>
                </header>
                <div
                  class="w-full"
                  v-if="
                    dashboard.recent_blog_posts
                      ? dashboard.recent_blog_posts.length > 0
                      : false
                  "
                >
                  <article
                    class="w-full h-auto p-5 flex items-center gap-4 border-b border-gray-100 :last-border-0"
                    v-for="(article, index) in dashboard.recent_blog_posts"
                    :key="index"
                  >
                    <div>
                      <div class="w-20 h-20">
                        <img
                          :src="article.image"
                          @error="
                            $event.target.src = require('@/assets/images/placeholder.png')
                          "
                          alt="article image"
                          class="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <div>
                        <p class="text-gray-600 truncate font-medium mb-1">
                          {{ (article.blogCategory || {}).name }}
                        </p>
                        <div class="grid">
                          <h1
                            class="text-base text-gray-800 mb-0.5 font-semibold"
                          >
                            {{ article.title }}
                          </h1>
                        </div>
                        <p class="text-xs font-medium text-gray-500">
                          {{ article.created_at | moment("from") }}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
                <div
                  class="w-full p-4 text-center text-gray-600"
                  v-if="
                    dashboard.recent_blog_posts
                      ? dashboard.recent_blog_posts.length === 0
                      : true
                  "
                >
                  ليس هناك مقالات بعد
                </div>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <!-- ./clients & articles -->
    </div>
    <div class="w-full h-full" v-else>
      <div class="py-10 px-6">
        <div
          class="w-full xl:container rounded-3xl bg-gray-200 animate-pulse h-40"
        ></div>
      </div>
      <div class="w-full bg-white">
        <main
          class="w-full p-5 xl:container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        >
          <figure class="p-6 flex-1" v-for="idx in 4" :key="idx">
            <div
              class="table mx-auto w-20 h-20 rounded-full mb-4 bg-gray-200 animate-pulse"
            ></div>
            <div
              class="w-2/5 h-4 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
            ></div>
            <div
              class="w-4/5 h-3 mb-3 bg-gray-200 animate-pulse rounded-lg table mx-auto"
            ></div>
          </figure>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
export default {
  name: "Home",
  components: { TableLoad },
  computed: {
    user() {
      return this.$store.getters.getUserInfo;
    },
  },
  data() {
    return {
      order_modal: false,
      load: false,
      dashboard: {},
      order: {},
      order_status: {},
      order_disabled: false,
      products: [],
      product_load: false,
      order_load: false,
      date_filter: "today",
      customers: [],
      orders: [],
      steps: [
        {
          title: "تعديل الملف الشخصي",
          content:
            "ليكن لدينا طريقة افضل للتواصل معك بامكانك تعديل البريد الالكتروني واضافة رقم جوالك ليسهل علينا التواصل معك في حالة حدوث اي عطل او مشكلة تواجهك.",
          redirect: "/settings",
          icon: "fa-solid fa-pen",
          btn: "اكمال البيانات",
        },
        {
          title: "اضافة بيانات المتجر",
          content:
            "عملاءك في انتظار بيانات ونشاط متجرك اسرع الان واضف باقي البيانات ولن يستغرق الامر الا بضع دقائق وسيتم الانتهاء من تلك الخطوة",
          redirect: "/settings",
          icon: "fa-solid fa-store",
          btn: "اضافة البيانات",
        },
        {
          title: "تخصص واجهة المتجر",
          content:
            "لديك العديد من القوالب التي تخص نشاطك التجاري بامكانك تخصيص واحد من افضل القوالب لدينا وتغيير الالوان الاساسية لمتجرك",
          redirect: "/settings",
          icon: "fa-solid fa-compass-drafting",
          btn: "تخصيص الان",
        },
        {
          title: "اضف منتجاتك",
          content:
            "ليكن متجرك بحالة جيدة وجلب عملاء اكثر قم باضافة المنتجات لديك والتحكم في عرضها في المتجر واضافة صلاحيتها ان وجدت",
          redirect: "/products",
          icon: "fa-solid fa-sitemap",
          btn: "اضف الان",
        },
        // {
        //   title: "",
        //   content: "",
        //   redirect: "",
        //   icon: "",
        // },
      ],
    };
  },
  created() {
    this.getDashboard();
    this.getProducts();
    this.getOrders();
    this.getRecentOrders();
    this.getRecentCustomers();
  },
  watch: {
    date_filter() {
      this.getDashboard();
    },
  },
  methods: {
    getDashboard() {
      let filter = {
        yesterday: this.$moment()
          .locale("en")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        last_week: this.$moment()
          .locale("en")
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        last_month: this.$moment()
          .locale("en")
          .subtract(1, "months")
          .format("YYYY-MM-DD"),
        today: this.$moment(new Date())
          .locale("en")
          .format("YYYY-MM-DD")
          .replace(/[/]/g, "-"),
      };

      return this.axios
        .get("dashboard", {
          params: {
            from: filter[this.date_filter],
            to: ["last_month", "last_week"].includes(this.date_filter)
              ? filter.today
              : filter[this.date_filter],
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.dashboard = data.result;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    showModal(id) {
      this.order = {
        ...this.orders.find((order) => order.id === id),
      };
      this.order_status = {
        status: this.order.status,
        id: this.order.id,
      };
      this.order_modal = true;
    },
    getRecentOrders() {
      return this.axios.get("orders/recent").then(({ data }) => {
        this.orders = data.result.recent_orders;
      });
    },
    getRecentCustomers() {
      return this.axios.get("customers/recent").then(({ data }) => {
        this.customers = data.result.recent_customers;
      });
    },
    getProducts() {
      return this.axios
        .get("products/best_selling")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.products = data.result.best_selling_products;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.products = [];
          }
        })
        .finally(() => {
          this.product_load = true;
        });
    },
    getOrders() {
      let d = new Date(),
        date = `${d.getFullYear()}-${
          d.getMonth() > 9 ? d.getMonth() : "0" + d.getMonth()
        }-${d.getDate() > 9 ? d.getDate() : "0" + d.getDate()}`;
      return this.axios
        .get("orders", {
          params: {
            from: date,
            to: date,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.orders = data.result.orders;
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.orders = [];
          }
        })
        .finally(() => {
          this.order_load = true;
        });
    },
    updateOrderStatus() {
      this.order_disabled = true;
      return this.axios
        .post("orders/update_status", this.order_status)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getOrders();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.order_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
<style lang="scss">
.circle-progress {
  @apply transition-all ease-in-out;
  stroke-dasharray: 345;
  stroke-dashoffset: calc(345 - (345 * var(--i)) / 100);
  stroke-linecap: round;
}
</style>
