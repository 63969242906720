<template>
  <section class="w-full p-6 space-y-6">
    <FileSystemHeader>
      <template #title> الفروع والمستودعات</template>
    </FileSystemHeader>

    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header class="p-5 border-b border-gray-200 space-y-4">
        <div
          class="flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
        >
          <div class="w-full relative sm:flex-1">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="بحث في الفروع والمستودعات.."
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm sm:max-w-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
          <div class="flex gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-4 rounded-lg bg-yellow-600/10 text-yellow-600 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                @click="openCloseMoreBox"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
            </div>
          </div>
          <div
            class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
            ref="moreBox"
          >
            <router-link
              to="/branches/create"
              class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm block w-full"
            >
              <i class="fa-solid fa-plus"></i>
              اضافة جديد
            </router-link>
          </div>
        </div>

        <div class="flex gap-2 whitespace-nowrap flex-wrap">
          <div>
            <button
              class="py-2 px-4 text-xs rounded-lg font-medium rtl:font-semibold"
              :class="{
                'bg-yellow-600 text-white': is_warehouse === '',
                'bg-gray-100 text-gray-600': is_warehouse !== '',
              }"
              @click.prevent="filterItem('')"
            >
              الكل
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': is_warehouse !== '',
                  'bg-yellow-700': is_warehouse === '',
                }"
                >{{ branches.length || 0 }}</span
              >
            </button>
          </div>
          <div>
            <button
              class="py-2 px-4 text-xs rounded-lg font-medium rtl:font-semibold"
              @click.prevent="filterItem(0)"
              :disabled="type_disabled[0]"
              :class="{
                'bg-yellow-600 text-white': is_warehouse === 0,
                'bg-gray-100 text-gray-600': is_warehouse !== 0,
              }"
            >
              <i
                class="fa-solid fa-spinner fa-spin"
                v-if="type_disabled[0]"
              ></i>
              فرع
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': is_warehouse !== 0,
                  'bg-yellow-700': is_warehouse === 0,
                }"
                >{{
                  (branches.filter((o) => o.is_warehouse === (0 || "0")) || [])
                    .length || 0
                }}</span
              >
            </button>
          </div>
          <div>
            <button
              class="py-2 px-4 text-xs rounded-lg font-medium rtl:font-semibold"
              @click.prevent="filterItem(1)"
              :disabled="type_disabled[1]"
              :class="{
                'bg-yellow-600 text-white': is_warehouse === 1,
                'bg-gray-100 text-gray-600': is_warehouse !== 1,
              }"
            >
              <i
                class="fa-solid fa-spinner fa-spin"
                v-if="type_disabled[1]"
              ></i>
              مستودع
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': is_warehouse !== 1,
                  'bg-yellow-700': is_warehouse === 1,
                }"
                >{{
                  (branches.filter((o) => o.is_warehouse !== (0 || "0")) || [])
                    .length || 0
                }}</span
              >
            </button>
          </div>
        </div>
      </header>
      <div class="w-full pb-5 overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>الاسم</th>
              <th>العنوان</th>
              <th>النوع</th>
              <th>تاريخ الانشاء</th>
              <th>الحالة</th>
              <th>الاجراءات</th>
            </tr>
          </thead>
          <tbody v-if="branches.length > 0 && load">
            <tr v-for="(item, index) in branches" :key="index">
              <td>
                {{ index + 1 }}
              </td>

              <td>
                <span
                  class="inline-block whitespace-normal line-clamp-2 max-w-[15rem]"
                >
                  {{ item.name }}
                </span>
              </td>
              <td>
                <span
                  class="inline-block whitespace-normal line-clamp-2 max-w-[15rem]"
                >
                  {{ item.address }}
                </span>
              </td>
              <td>
                <span v-if="item.is_warehouse === ('1' || 1)"> مستودع </span>
                <span v-if="item.is_warehouse === ('0' || 0)"> فرع </span>
              </td>
              <td>
                <div>
                  {{ item.created_at | moment("from") }}
                </div>
                <div>
                  <span dir="ltr" class="text-gray-600 text-xs font-medium">{{
                    item.created_at | moment(" h:mm a . MMMM Do YYYY")
                  }}</span>
                </div>
              </td>
              <td>
                <span
                  class="inline-block py-1.5 px-3 rounded-full text-sm text-white"
                  :class="item.status === 'on' ? 'bg-blue-500' : 'bg-red-500'"
                >
                  {{ item.status }}
                </span>
              </td>
              <td>
                <div class="actions-group">
                  <div>
                    <button
                      @click.prevent="showModal(item.id)"
                      class="py-2 px-3 rounded-lg bg-green-600 text-white text-center text-xs block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تعديل"
                    >
                      تعديل
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-blue-500/20 text-blue-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تغيير الحالة"
                      @click.prevent="changeStatus(item.id, index)"
                      :disabled="status_disabled[index]"
                    >
                      <i
                        class="fa-solid fa-spinner fa-spin"
                        v-if="status_disabled[index]"
                      ></i>
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-red-500/20 text-red-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="حذف"
                      @click.prevent="showModalDelete(item.id)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <TableLoad :rows="7" v-for="i in 4" :key="i" />
          </tbody>
          <tbody v-if="load && branches.length === 0">
            <tr>
              <td colspan="7" class="!text-center">لا توجد بيانات</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
    <SweetModal
      :close="false"
      @close="active = $event"
      class="!mt-0"
      v-if="active"
    >
      <template>
        <div class="p-5">
          <h1 class="font-bold text-lg text-gray-800 text-center mb-2">
            هل انت متاكد؟
          </h1>
          <p
            class="font-medium rtl:font-semibold text-lg text-gray-600 text-center"
          >
            اذا كنت تريد حذف تلك المنتج هذا يعني ان لم يتم عرض اي محتوى لتلك
            المنتج في متجرك ولن يظهر لعملاء وبالتالي سيتم حذف الطلب من سلات
            التسوق للعملاء.
          </p>
          <p
            class="font-medium rtl:font-semibold text-sm text-red-500 text-center mt-4"
            v-if="error"
          >
            {{ error }}
          </p>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="delete_disabled"
                @click.prevent="deleteItem"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="delete_disabled"
                ></i>
                حذف
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="
                  active = false;
                  product_id = null;
                "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      </template>
    </SweetModal>
    <SweetModal
      @close="edit_active = $event"
      class="!mt-0"
      v-if="edit_active"
      :title="'تعديل الفرع'"
    >
      <template>
        <form class="w-full space-y-4" @submit.prevent novalidate>
          <figure class="w-full bg-white rounded-xl">
            <blockquote class="w-full p-6 space-y-6">
              <div class="flex w-full flex-col sm:flex-row gap-4">
                <div class="w-full space-y-3">
                  <label
                    for="name_ar"
                    class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                  >
                    الاسم | عربي
                  </label>
                  <input
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                    v-model="add_data.ar.name"
                  />
                  <span
                    class="table font-medium rtl:font-semibold text-red-500 text-sm"
                  >
                    {{ (add_errors["ar.name"] || []).join(" ") }}
                  </span>
                </div>
                <div class="w-full space-y-3">
                  <label
                    for="name_en"
                    class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                  >
                    الاسم | انجليزي
                  </label>
                  <input
                    type="text"
                    name="name_en"
                    id="name_en"
                    class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                    v-model="add_data.en.name"
                  />
                  <span
                    class="table font-medium rtl:font-semibold text-red-500 text-sm"
                  >
                    {{ (add_errors["en.name"] || []).join(" ") }}
                  </span>
                </div>
              </div>

              <div class="w-full space-y-3">
                <label
                  for="tel"
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  رقم الجوال
                </label>
                <vue-tel-input
                  id="mobile"
                  ref="registerMobile"
                  v-model="add_data.mobile"
                  @open="handleOpen('registerMobile')"
                ></vue-tel-input>
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors.mobile || []).join(" ") }}
                </span>
              </div>
              <div class="w-full space-y-3">
                <div class="checkbox">
                  <div>
                    <input
                      type="checkbox"
                      name="stock"
                      id="stock"
                      :true-value="1"
                      :false-value="0"
                      v-model="add_data.is_warehouse"
                    />
                  </div>
                  <label for="stock"> مستودع </label>
                </div>
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["is_warehouse"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex w-full flex-col sm:flex-row gap-4">
                <div class="w-full space-y-2">
                  <label
                    for="region"
                    class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                  >
                    المنطقة
                  </label>
                  <select
                    name="region"
                    id="region"
                    class="custom-select"
                    v-model="add_data.region_id"
                    :disabled="
                      locations.regions ? locations.regions.length === 0 : false
                    "
                  >
                    <option value="" selected disabled>-- اختار --</option>
                    <option
                      v-for="region in locations.regions"
                      :value="region.id"
                      :key="region.id"
                    >
                      {{ region["name"] }}
                    </option>
                  </select>
                  <span
                    class="table font-medium rtl:font-semibold text-red-500 text-sm"
                  >
                    {{ (add_errors.region_id || []).join(" ") }}
                  </span>
                </div>
                <div class="w-full space-y-2">
                  <label
                    for="city"
                    class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                  >
                    المدينة
                  </label>
                  <select
                    name="city"
                    id="city"
                    class="custom-select"
                    v-model="add_data.city_id"
                    :disabled="
                      locations.cities.filter(
                        (city) =>
                          Number(city.region_id) === Number(add_data.region_id)
                      ).length === 0
                    "
                  >
                    <option value="" selected disabled>-- اختار --</option>
                    <option
                      v-for="city in locations.cities.filter(
                        (city) =>
                          Number(city.region_id) === Number(add_data.region_id)
                      )"
                      :value="city.id"
                      :key="city.id"
                    >
                      {{ city["name"] }}
                    </option>
                  </select>
                  <span
                    class="table font-medium rtl:font-semibold text-red-500 text-sm"
                  >
                    {{ (add_errors.city_id || []).join(" ") }}
                  </span>
                </div>
              </div>
              <div class="w-full space-y-3">
                <label
                  for="district"
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  الحي
                </label>
                <select
                  name="district"
                  id="district"
                  class="custom-select"
                  v-model="add_data.district_id"
                  :disabled="
                    locations.districts.filter(
                      (district) =>
                        Number(district.city_id) === Number(add_data.city_id)
                    ).length === 0
                  "
                >
                  <option value="" selected disabled>-- اختار --</option>
                  <option
                    v-for="district in locations.districts.filter(
                      (district) =>
                        Number(district.city_id) === Number(add_data.city_id)
                    )"
                    :value="district.id"
                    :key="district.id"
                  >
                    {{ district["name"] }}
                  </option>
                </select>
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors.district_id || []).join(" ") }}
                </span>
              </div>
              <div class="w-full space-y-3">
                <label
                  for="full_location"
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  العنوان بالتفصيل
                </label>

                <gmap-autocomplete
                  ref="Place"
                  :value="add_data.address"
                  :options="{
                    componentRestrictions: { country: ['SA'] },
                    fields: [],
                    strictBounds: false,
                    types: ['geocode'],
                  }"
                  class="w-full h-full relative py-3 px-4 rounded-lg bg-gray-100"
                  @place_changed="setPlace"
                  placeholder="..."
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors.address || []).join(" ") }}
                </span>

                <div class="w-full">
                  <GmapMap
                    :center="{
                      lat: Number(add_data.lat),
                      lng: Number(add_data.lng),
                    }"
                    :cluster="{ options: { styles: map.clusterStyle } }"
                    :zoom="8"
                    map-type-id="terrain"
                    :options="{
                      fullscreenControl: false,
                      styles: map.mapStyle,
                      mapTypeControl: false,
                    }"
                    style="height: 400px"
                    class="rounded-lg overflow-hidden"
                  >
                    <GmapMarker
                      :position="{
                        lat: Number(add_data.lat),
                        lng: Number(add_data.lng),
                      }"
                      :draggable="true"
                      @dragend="updatePosition"
                    />
                  </GmapMap>
                </div>
              </div>
            </blockquote>
          </figure>
          <div class="p-6 pt-0">
            <button
              class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
              @click.prevent="updateBranch"
              :disabled="add_disabled"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
              حفظ التغييرات
            </button>
          </div>
        </form>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "BranchesPage",
  data() {
    return {
      load: false,
      branches: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      edit_active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      locations: {},
      add_disabled: false,
      add_errors: {},
      add_data: {},
      map: {
        clusterStyle: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            width: 56,
            height: 56,
            textColor: "#fff",
          },
        ],
        mapStyle: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#3e606f",
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: "#313536",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#44a688",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#f5e4e4",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: "14",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#067372",
              },
              {
                lightness: "-20",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#357374",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#004757",
              },
            ],
          },
        ],
      },
      type_disabled: [],
      is_warehouse: "",
    };
  },
  created() {
    this.getLocations();
    this.getBranches();
  },
  watch: {
    currentPage() {
      this.getBranches();
    },
  },
  methods: {
    getLocations() {
      return this.axios
        .get("locations")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.locations = data.result;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.locations = {};
          }
        });
    },
    getBranches() {
      return this.axios
        .get("branches", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            is_warehouse: this.is_warehouse,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.branches = data.result.branches.data;
            this.perPage = data.result.branches.pagination.per_page;
            this.rows = data.result.branches.pagination.total;
            this.totalPages = data.result.branches.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.branches = [];
          }
        })
        .finally(() => {
          this.load = true;
          this.search_disabled = false;
        });
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    showModalDelete(id) {
      this.product_id = id;
      this.active = true;
    },
    changeStatus(id, i) {
      this.$set(this.status_disabled, i, true);
      return this.axios
        .post("branches/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBranches();
            this.branches.find((product) => product.id === id).status =
              data.result.status;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.status_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    deleteItem() {
      this.delete_disabled = true;
      return this.axios
        .post("branches/delete/" + this.product_id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBranches();
            this.branches.splice(
              this.branches.findIndex(
                (product) => product.id === this.product_id
              ),
              1
            );
            this.active = false;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.delete_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    showModal(id) {
      const item = this.branches.find((i) => i.id == id);

      this.add_data = {
        ar: item.ar || {},
        en: item.en || {},
        region_id: (item.region || {}).id || "",
        city_id: (item.city || {}).id || "",
        district_id: (item.district || {}).id || "",
        ...item,
      };

      this.edit_active = true;
    },
    updateBranch() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.add_data[key][trKey]);
          });
        } else if (key === "mobile") {
          fd.append(
            key,
            this.add_data[key].replace(/\s/g, "").replace("+", "")
          );
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("branches/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getBranches();
            this.edit_active = false;
            this.add_data = {
              ar: {},
              en: {},
              city_id: "",
              region_id: "",
              district_id: "",
              lat: 24.774265,
              lng: 46.738586,
            };
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, (response) => {
        if (response.status === "OK") {
          const address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.Place.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(this.add_data, "address", this.$refs.Place.$refs.input.value);
      }
    },
    searchAll() {
      this.search_disabled = true;
      this.getBranches().then(() => {
        this.search_disabled = false;
      });
    },
    filterItem(i) {
      this.is_warehouse = i;
      this.$set(this.type_disabled, i, true);
      this.getBranches().then(() => {
        this.type_disabled.splice(i, 1);
      });
    },
  },
  components: { TableLoad, SweetModal, FileSystemHeader },
};
</script>
