<template>
  <div>
    <span
      v-if="!dateActive"
      class="date-line"
      :data-title="message.created_at | moment('MMMM Do YYYY')"
    >
      <span class="bg-white relative table mx-auto px-2 z-[1]">{{
        message.created_at | moment("MMMM Do YYYY")
      }}</span>
    </span>
    <div
      class="table max-w-[80%] message-item"
      :class="{
        'mr-auto rtl:mr-0 rtl:ml-auto': message.sender === 'store_admin',
        'ml-auto rtl:ml-0 rtl:mr-auto': message.sender === 'admin',
      }"
      :data-message="message.id"
    >
      <div
        class="flex items-center gap-2 mb-2"
        :class="{
          'flex-row': message.sender === 'store_admin',
          'flex-row-reverse': message.sender === 'admin',
        }"
        v-if="!isSame && message.sender === 'admin'"
      >
        <div>
          <div class="w-8 h-8">
            <img
              :src="message.sender === 'store_admin' ? avatar : admin_avatar"
              class="w-full h-full rounded-full object-cover border border-gray-100"
              v-if="message.sender === 'admin'"
            />
          </div>
        </div>
        <div v-if="message.sender === 'admin'">
          <h1 class="font-bold text-sm">
            {{
              message.sender === "store_admin"
                ? message.store_admin.name
                : message.admin.name
            }}
          </h1>
        </div>
      </div>
      <div
        :class="{
          'mr-auto rtl:mr-0 rtl:ml-auto': message.sender === 'store_admin',
          'ml-auto rtl:ml-0 rtl:mr-auto pr-8 rtl:pr-0 rtl:pl-8':
            message.sender === 'admin',
        }"
      >
        <div
          class="table py-2 px-4 bg-gray-200 text-sm font-medium text-gray-600 rounded-md"
          :class="{
            'bg-yellow-500 !text-white': message.sender === 'store_admin',
          }"
          @dblclick.prevent="openDetails(message.id)"
        >
          {{ message.body }}
        </div>
        <p
          class="text-xs text-gray-500 font-medium mt-1 px-2 hidden"
          :class="{
            'text-left rtl:text-right': message.sender === 'store_admin',
            'text-right rtl:text-left': message.sender === 'admin',
          }"
        >
          {{ message.created_at | moment("hh:mma") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleMessage",
  props: {
    message: {
      type: Object,
      default: {},
    },
    prev: {
      type: Array,
      default: Array,
      required: false,
    },
  },
  data() {
    return {
      isSame: false,
      avatar: require("@/assets/images/placeholder.png"),
      admin_avatar: require("@/assets/images/support-chat.png"),
      dateActive: false,
    };
  },
  created() {
    this.isSame = this.isSamePerson(this.message, this.prev);
    this.dateActive = this.isNotSameDate(this.message, this.prev);

    if (this.message.sender === "store_admin") {
      if (this.message.store?.logo) {
        this.avatar = this.message.store?.logo;
      } else {
        this.avatar = require("@/assets/images/placeholder.png");
      }
    } else {
      if (this.message.admin?.photo) {
        this.admin_avatar = this.message.admin?.photo;
      } else {
        this.admin_avatar = require("@/assets/images/support-chat.png");
      }
    }
  },
  methods: {
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false;
      } else if (prev[0]?.sender === msg?.sender) {
        return true;
      } else {
        return false;
      }
    },
    isNotSameDate(msg, prev) {
      if (prev === null) {
        return false;
      } else if (
        new Date(prev[0]?.created_at).getDate() ===
        new Date(msg?.created_at).getDate()
      ) {
        return true;
      } else {
        return false;
      }
    },
    openDetails(id) {
      const item = document.querySelector(
        `.message-item[data-message="${id}"]`
      );
      item.querySelector("p").classList.toggle("hidden");
    },
  },
};
</script>
<style lang="scss">
.date-line {
  @apply relative block w-full text-center text-gray-500 text-xs mb-3;
  &::after {
    content: "";
    @apply w-4/5 absolute h-px bg-gradient-to-tr from-gray-50 from-gray-200 to-gray-50 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
</style>
