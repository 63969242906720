<template>
  <section class="py-10 px-6">
    <main
      class="w-full max-w-xl table mx-auto rounded-lg bg-white border border-gray-200 p-6"
      v-if="$store.state.done_load"
    >
      <img
        src="@/assets/images/done.png"
        alt="done image"
        class="mx-auto max-w-[17rem]"
      />
      <div class="space-y-2 text-center mt-4 max-w-lg mx-auto">
        <h1 class="font-semibold rtl:font-bold text-yellow-600 text-lg">
          تم الاشتراك بنجاح
        </h1>
        <p class="font-medium rtl:font-semibold text-gray-600 text-sm">
          تم الاشتراك في باقة <span>{{ $route.query.plan }}</span> بمقابل
          <span>{{ $route.query.price }} ريال </span>
          انتقل الان الى لوحة تحكم متجرك للاستمتاع بالمميزات التي تمت اضافتها في
          متجرك من خلال شراءك الباقة الحالية.
        </p>
        <router-link
          to="/"
          class="font-medium rtl:font-semibold text-white text-sm rounded-full bg-green-600 table mx-auto py-2 px-4 !mt-4"
        >
          <i class="fa-solid fa-angle-right"></i>
          الانتقال للرئيسية
        </router-link>
      </div>
    </main>
    <div class="table mx-auto px-6" v-else>
      <div
        class="w-12 h-12 rounded-full flex items-center justify-center text-gray-600 bg-white shadow-2xl"
      >
        <i class="fa-solid fa-spinner fa-spin"></i>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "DonePage",
};
</script>
