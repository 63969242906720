import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth_type: "login",
    token: null,
    user: {},
    vendor_data: {},
    store: {},
    image_url: null,
    plan: {},
    BASE_URL:
      process.env.NODE_ENV != "production"
        ? "https://apis-matjari.brmaja.host/portal"
        : "https://apis-matjari.brmaja.host/portal",
    title: null,
    isLoading: false,
    permissions: [],
    done_load: false,
    should_upgrade: {},
  },
  mutations: {
    SET_AUTH_TYPE(state, data) {
      state.auth_type = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_TITLE(state, data) {
      state.title = data;
    },
    SET_STORE(state, data) {
      state.store = data;
    },
    SET_REGISTER_DATA(state, data) {
      state.vendor_data = Object.assign(data, {
        mobile: data.mobile.replace(/\s/g, "").replace("+", ""),
      });
    },
    SET_USER_DATA(state, data) {
      state.user = data.user;
      state.token = data.token;
      state.image_url = data.image_url;
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },
    SET_DONE_LOAD(state, data) {
      state.done_load = data;
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
    SET_PLAN(state, data) {
      state.plan = data;
    },
    SET_SHOULD(state, data) {
      state.should_upgrade = data;
    },
  },

  modules: {},
  getters: {
    getUserInfo(state) {
      return { ...state.user, token: state.token, image_url: state.image_url };
    },
  },
  actions: {
    getStoreInfo({ commit }) {
      return axios
        .get("settings/store")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            commit("SET_STORE", data.result);
            document.querySelector("title").innerHTML =
              data.result.store_info.name || "My store";
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        });
    },
  },
});
