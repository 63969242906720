<template>
  <div class="w-full py-10 px-6 space-y-7">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative xl:container"
    >
      <div class="w-full">
        <ModelListSelect
          :list="regions"
          :selectedItems="product_filter.shipping_region_id"
          v-model="product_filter.shipping_region_id"
          option-value="id"
          option-text="name"
          placeholder="المنطقة"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>
      <div class="w-full">
        <ModelListSelect
          :list="
            cities.filter(
              (city) =>
                Number(city.region_id) ===
                Number(product_filter.shipping_region_id)
            )
          "
          :isDisabled="
            cities.filter(
              (city) =>
                Number(city.region_id) ===
                Number(product_filter.shipping_region_id)
            ).length === 0
          "
          :selectedItems="product_filter.shipping_city_id"
          v-model="product_filter.shipping_city_id"
          option-value="id"
          option-text="name"
          placeholder="المدينة"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>
      <div class="w-full">
        <ModelListSelect
          :list="
            districts.filter(
              (district) =>
                Number(district.city_id) ===
                Number(product_filter.shipping_city_id)
            )
          "
          :isDisabled="
            districts.filter(
              (district) =>
                Number(district.city_id) ===
                Number(product_filter.shipping_city_id)
            ).length === 0
          "
          :selectedItems="product_filter.shipping_district_id"
          v-model="product_filter.shipping_district_id"
          option-value="id"
          option-text="name"
          placeholder="الحي"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>
      <div class="w-full">
        <ModelListSelect
          :list="products"
          :isDisabled="products.length === 0"
          :selectedItems="product_id"
          v-model="product_id"
          option-value="id"
          option-text="name"
          placeholder="المنتجات"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>

      <div class="w-full flex flex-wrap gap-2 items-stretch">
        <div>
          <button
            class="py-3 px-6 text-xs rounded-lg font-medium rtl:font-semibold bg-blue-500 text-white"
            @click.prevent="applyFilter(true)"
            :disabled="apply_disabled"
          >
            <i class="fa-solid fa-spinner fa-spin" v-if="apply_disabled"></i>

            تطبيق
          </button>
        </div>
        <div>
          <button
            class="py-3 px-6 text-xs rounded-lg font-medium rtl:font-semibold bg-gray-200 text-gray-600"
            @click.prevent="applyFilter(false)"
            :disabled="clear_disabled"
          >
            <i class="fa-solid fa-spinner fa-spin" v-if="clear_disabled"></i>
            الغاء الكل
          </button>
        </div>
      </div>
    </div>
    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header class="p-5 border-b border-gray-200 space-y-4">
        <div
          class="w-full flex items-start sm:items-center flex-col sm:flex-row gap-4 relative"
        >
          <div class="flex-1">
            <div class="w-full relative max-w-sm">
              <input
                type="text"
                name="search"
                id="search"
                :placeholder="$t('search')"
                class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
                v-model="search_key"
                @keyup.enter.stop="searchAll"
              />
              <!-- @blur="handleSearch('close')" -->
              <span
                class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="search_disabled"
                ></i>
                <i v-else class="fa-solid fa-search"></i>
              </span>
            </div>
          </div>
          <div class="flex gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-4 rounded-md bg-gray-100 text-gray-600 text-center text-sm flex gap-3 items-center ml-auto rtl:mr-auto rtl:ml-0"
                @click="openCloseMoreBox"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
                <span class="inline sm:hidden"> {{ $t("view_more") }} </span>
              </button>
            </div>
          </div>
          <div
            class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
            ref="moreBox"
          >
            <div>
              <button
                class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block w-full"
                @click.prevent="exportAll"
                :disabled="export_disabled"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="export_disabled"
                ></i>
                <i class="fa-solid fa-file-excel" v-else></i>
                {{ $t("drop.export") }}
              </button>
            </div>
            <div>
              <button
                class="py-2 px-6 rounded-lg bg-gray-300 text-gray-600 text-center text-sm block w-full"
                @click.prevent="email_active = true"
              >
                <i class="fa-solid fa-file-excel"></i>
                {{ $t("drop.send") }}
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2 whitespace-nowrap flex-wrap">
          <div>
            <button
              class="py-2 px-4 text-xs rounded-lg font-medium rtl:font-semibold"
              :class="{
                'bg-yellow-600 text-white': status_filter === '',
                'bg-gray-100 text-gray-600': status_filter !== '',
              }"
              @click.prevent="statusFilter('')"
            >
              {{ $t("all") }}
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-200': status_filter !== '',
                  'bg-yellow-700': status_filter === '',
                }"
                >{{ orders.length || 0 }}</span
              >
            </button>
          </div>
          <div v-for="(status, index) in statuses" :key="index">
            <button
              class="py-2 px-4 text-xs rounded-lg font-medium rtl:font-semibold"
              @click.prevent="statusFilter(status, index)"
              :disabled="status_filter_disabled[index]"
              :class="{
                'bg-yellow-600 text-white': status_filter === status,
                'bg-gray-100 text-gray-600': status_filter !== status,
              }"
            >
              <i
                class="fa-solid fa-spinner fa-spin"
                v-if="status_filter_disabled[index]"
              ></i>
              {{ $t(`status.${status}`) }}
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': status_filter !== status,
                  'bg-yellow-700': status_filter === status,
                }"
                >{{
                  (orders.filter((o) => o.status.includes(status)) || [])
                    .length || 0
                }}</span
              >
            </button>
          </div>
        </div>
      </header>
      <div v-if="active_order">
        <div class="w-full pb-5 overflow-x-auto">
          <table class="styled-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("table.order_no") }}</th>
                <th>{{ $t("table.products") }}</th>
                <th>{{ $t("table.address") }}</th>
                <th>{{ $t("table.price") }} ({{ $t("sar") }})</th>
                <th>{{ $t("table.notes") }}</th>
                <th>{{ $t("table.payment_opt_meth") }}</th>
                <th>{{ $t("table.status") }}</th>
                <th>{{ $t("table.latest_update") }}</th>
                <th>{{ $t("table.actions") }}</th>
              </tr>
            </thead>
            <tbody v-if="orders.length > 0 && load">
              <tr v-for="(order, index) in orders" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ order.order_no }}
                </td>
                <td>
                  {{ order.products.length }}
                </td>
                <td>
                  {{ order.shipping_address }}
                </td>
                <td>
                  {{ Number(order.sub_total).toFixed(2) }}
                </td>
                <td>
                  <button
                    class="w-8 h-8 flex items-center justify-center bg-gray-100 text-gray-600 text-sm rounded-md"
                    @click.prevent="openNotes(order.id)"
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                </td>
                <td>
                  {{ order.payment_option || "-" }}
                </td>
                <td>
                  <span
                    class="inline-block py-1.5 px-3 rounded-lg text-sm"
                    :class="status[order.status]"
                  >
                    {{ $t(`status.${order.status.toLowerCase()}`) }}
                  </span>
                </td>

                <td>
                  <div>
                    {{ order.updated_at | moment("from") }}
                  </div>
                  <div>
                    <span dir="ltr" class="text-gray-600 text-xs font-medium">{{
                      order.updated_at | moment(" h:mm a . MMMM Do YYYY")
                    }}</span>
                  </div>
                </td>

                <td>
                  <div class="actions-group">
                    <div>
                      <router-link
                        :to="`/orders/${order.id}`"
                        class="py-1.5 px-3 rounded-lg bg-green-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                        :data-title="$t('btn.view')"
                      >
                        <i class="fa-solid fa-eye"></i>
                      </router-link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!load">
              <TableLoad v-for="i in 4" :key="i" :rows="10" />
            </tbody>
            <tbody v-if="load && orders.length === 0">
              <tr>
                <td colspan="10" class="!text-center">لا توجد بيانات</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
          <paginate
            v-model="currentPage"
            :page-count="totalPages"
            :page-range="5"
            :margin-pages="2"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
      <div v-else>
        <div class="w-full pb-5 overflow-x-auto">
          <table class="styled-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("table.order") }}</th>
                <th>{{ $t("table.product") }}</th>
                <th>{{ $t("table.name") }}</th>
                <th>{{ $t("table.brand") }}</th>
                <th>{{ $t("table.sku") }}</th>
                <th>{{ $t("table.qty") }}</th>
                <th>{{ $t("table.price") }} ({{ $t("sar") }})</th>
                <th>{{ $t("table.created_at") }}</th>
              </tr>
            </thead>
            <tbody v-if="order_products.length > 0 && product_load">
              <tr v-for="(product, index) in order_products" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ product.order_id }}
                </td>
                <td>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        product.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="product image"
                      class="w-full h-full rounded-lg border object-cover border-gray-100"
                    />
                  </div>
                </td>
                <td>
                  <span
                    class="inline-block line-clamp-2 whitespace-normal max-w-[17rem]"
                  >
                    {{ product.name }}
                  </span>
                </td>
                <td>{{ (product.brand || {}).name || "-" }}</td>
                <td>{{ product.sku }}</td>
                <td>{{ product.qty }}</td>
                <td>{{ product.price }}</td>

                <td>
                  <div>
                    {{ product.created_at | moment("from") }}
                  </div>
                  <div>
                    <span dir="ltr" class="text-gray-600 text-xs font-medium">{{
                      product.created_at | moment(" h:mm a . MMMM Do YYYY")
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!product_load">
              <TableLoad :rows="9" v-for="i in 4" :key="i" />
            </tbody>
            <tbody v-if="product_load && order_products.length === 0">
              <tr>
                <td colspan="9" class="!text-center">
                  {{ $t("no_data.products") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="table mx-auto !py-6"
          v-if="product_load && product_totalPages > 1"
        >
          <paginate
            v-model="product_currentPage"
            :page-count="product_totalPages"
            :page-range="5"
            :margin-pages="2"
            :prev-text="$t('prev')"
            :next-text="$t('next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </main>
    <sweet-modal
      v-if="note_active"
      @close="note_active = $event"
      :title="$t('table.notes')"
    >
      <template>
        <div class="p-5 w-full">
          <p class="text-gray-600 font-medium" v-if="note_data.notes">
            {{ note_data.notes }}
          </p>
          <div class="text-gray-600 font-medium text-center" v-else>
            <div
              class="w-20 h-20 flex items-center justify-center text-3xl bg-gray-100 mx-auto rounded-full mb-3"
            >
              <i class="fa-solid fa-sticky-note"></i>
            </div>
            <p>
              {{ $t("no_data.notes") }}
            </p>
          </div>
        </div>
      </template>
    </sweet-modal>
    <SweetModal
      class="!m-0"
      v-if="email_active"
      @close="email_active = $event"
      :title="$t('drop.send')"
    >
      <template>
        <div class="p-5">
          <form class="w-full space-y-4">
            <input-tag
              id="keywords"
              v-model="email_data.emails"
              :placeholder="$t('add_new')"
              validate="email"
            ></input-tag>

            <button
              class="py-3 px-4 rounded-lg bg-blue-500 text-white font-medium rtl:font-semibold text-sm w-full max-w-[7rem]"
              :disabled="email_disabled"
              @click.prevent="sendViaEmails"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="email_disabled"></i>
              {{ $t("btn.submit") }}
            </button>
          </form>
        </div>
      </template>
    </SweetModal>
  </div>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "Orders",
  components: { TableLoad, SweetModal, ModelListSelect },
  data() {
    return {
      orders: [],
      load: false,
      order_modal: false,
      currentPage: 1,
      search_disabled: false,
      search_key: "",
      perPage: 0,
      rows: 0,
      totalPages: 0,
      note_active: false,
      note_data: {
        order_id: "",
        notes: null,
      },
      note_disabled: false,
      statuses: [
        "new",
        "preparing",
        "ready",
        "delivering",
        "completed",
        "cancelled",
      ],
      status_filter: "",
      status_filter_disabled: [],
      status: {
        delivering: "bg-yellow-500 text-white",
        completed: "bg-gray-600 text-white",
        new: "bg-green-500 text-white",
        preparing: "bg-orange-500 text-white",
        cancelled: "bg-red-500 text-white",
        ready: "bg-blue-600 text-white",
      },
      regions: [],
      cities: [],
      districts: [],
      product_filter: {
        shipping_region_id: "",
        shipping_city_id: "",
        shipping_district_id: "",
      },
      apply_disabled: false,
      clear_disabled: false,
      products: [],
      product_id: "",
      active_order: true,
      order_products: [],
      product_load: false,
      product_currentPage: 1,
      product_rows: 0,
      product_totalPages: 0,
      email_disabled: false,
      email_data: {
        emails: [],
      },
      email_active: false,
      export_disabled: false,
    };
  },
  created() {
    this.getOrders();
    this.getLocations();
    this.getProducts();
    this.email_data?.emails.push(this.$store.state.user?.email);
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
    product_currentPage() {
      this.getOrderProducts();
    },
  },
  methods: {
    getProducts() {
      return this.axios
        .get("products")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.products = data.result.products;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.products = {};
          }
        });
    },
    getLocations() {
      return this.axios
        .get("locations")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.regions = data.result.regions;
            this.cities = data.result.cities;
            this.districts = data.result.districts;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.regions = {};
          }
        });
    },
    getOrders() {
      return this.axios
        .get("orders", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.status_filter,
            ...this.product_filter,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.orders = data.result.orders.data;
            this.perPage = data.result.orders.pagination.per_page;
            this.rows = data.result.orders.pagination.total;
            this.totalPages = data.result.orders.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.orders = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    getOrderProducts() {
      return this.axios
        .get("orders/products", {
          params: {
            page: this.product_currentPage,
            search_key: this.search_key,
            product_id: this.product_id,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.order_products = data.result.ordered_products.data;
            this.product_perPage =
              data.result.ordered_products.pagination.per_page;
            this.product_rows = data.result.ordered_products.pagination.total;
            this.product_totalPages =
              data.result.ordered_products.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.order_products = [];
          }
        })
        .finally(() => {
          this.product_load = true;
        });
    },
    showModal(id) {
      this.order = { ...this.orders.find((order) => order.id === id) };
      this.order_status = {
        status: this.order?.status.toLowerCase(),
        id: this.order?.id,
      };
      this.order_modal = true;
    },

    searchAll() {
      this.search_disabled = true;
      if (this.active_order) {
        this.getOrders().then(() => {
          this.search_disabled = false;
        });
      } else {
        this.getOrderProducts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    statusFilter(status, i) {
      this.status_filter = status;
      this.$set(this.status_filter_disabled, i, true);
      this.getOrders().then(() => {
        this.status_filter_disabled.splice(i, 1);
      });
    },
    openNotes(id) {
      this.note_data.order_id = id;
      this.note_data.notes = this.orders.find(
        (order) => order.id === id
      )?.notes;
      this.note_active = true;
    },
    applyFilter(active) {
      if (active) {
        this.apply_disabled = true;
      } else {
        this.clear_disabled = true;
      }

      if (!active) {
        this.status_filter = "";
        this.product_id = "";
        Object.keys(this.product_filter).forEach((key) => {
          this.product_filter[key] = "";
        });
        this.search_key = "";
      }
      if (this.product_id) {
        this.active_order = false;
        this.getOrderProducts().then(() => {
          active
            ? (this.apply_disabled = false)
            : (this.clear_disabled = false);
        });
      } else {
        this.active_order = true;
        this.getOrders().then(() => {
          active
            ? (this.apply_disabled = false)
            : (this.clear_disabled = false);
        });
      }
    },
    exportAll() {
      this.export_disabled = true;
      this.$refs.moreBox.classList.remove("hidden");
      return this.axios
        .get("orders/export_excel", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.status_filter,
          },
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `orders-${new Date().getTime()}.xlsx`);
          link.click();
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.export_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    sendViaEmails() {
      this.email_disabled = true;
      let fd = new FormData();

      Object.keys(this.email_data).forEach((key) => {
        if (key === "emails") {
          this.email_data[key].forEach((email, index) => {
            fd.append(`${key}[${index}]`, email);
          });
        }
      });
      return this.axios
        .post("orders/send_excel", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.email_data.emails = [];
            this.email_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.email_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
