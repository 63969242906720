<template>
  <form class="w-full max-w-xl mx-auto space-y-6 mt-10">
    <header class="w-full max-w-lg table mx-auto relative !my-10">
      <span
        class="w-[calc(100%-7rem)] bg-gray-200 h-px absolute top-7 left-1/2 -translate-x-1/2 z-[-1]"
      ></span>
      <div class="flex items-start justify-between gap-4 relative">
        <div>
          <div
            class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
          >
            <i class="fa-solid fa-user"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-800 table mx-auto mt-2"
          >
            بيانات صاحب المتجر
          </span>
        </div>
        <div>
          <div
            class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
          >
            <i class="fa-solid fa-store"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
          >
            بيانات المتجر
          </span>
        </div>
        <div>
          <div
            class="w-14 h-14 rounded-full border border-gray-200 text-gray-400 flex items-center justify-center bg-white mx-auto"
          >
            <i class="fa-solid fa-file"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
          >
            المستندات
          </span>
        </div>
      </div>
    </header>
    <div class="w-full space-y-3">
      <label
        for="store_name"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        اسم المتجر
      </label>
      <input
        type="text"
        name="store_name"
        id="store_name"
        placeholder="example example"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        autocomplete="off"
        v-model="add_data.store_name"
      />
      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.store_name || []).join(" ") }}
      </span>
    </div>
    <div class="w-full space-y-3">
      <label
        for="text"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        اسم النطاق
      </label>
      <input
        type="text"
        name="text"
        id="text"
        placeholder="example"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-t-md"
        autocomplete="off"
        v-model="add_data.sub_domain"
      />
      <div
        class="w-full py-2 px-4 rounded-b-md bg-gray-200 text-gray-500 text-xs font-semibold !mt-0"
      >
        {{ checkSubDomain(add_data.sub_domain) }}.ia6g.com
      </div>

      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.sub_domain || []).join(" ") }}
      </span>
    </div>
    <div class="w-full space-y-3">
      <label
        for="category"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        نشاط المتجر
      </label>
      <select
        name="category"
        id="category"
        class="custom-select"
        v-model="add_data.category_id"
      >
        <option value="" disabled selected>-- select --</option>
        <option
          v-for="(category, idx) in categories"
          :key="idx"
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>
      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.category_id || []).join(" ") }}
      </span>
    </div>
    <div class="w-full space-y-3">
      <label
        for="full_location"
        class="font-medium rtl:font-semibold text-gray-600 table text-sm"
      >
        العنوان بالتفصيل
      </label>

      <gmap-autocomplete
        ref="Place"
        :value="add_data.address"
        :options="{
          componentRestrictions: { country: ['SA'] },
          fields: [],
          strictBounds: false,
          types: ['geocode'],
        }"
        class="w-full h-full relative py-3 px-4 rounded-lg bg-gray-100"
        @place_changed="setPlace"
        placeholder="..."
      />
      <span class="table font-medium rtl:font-semibold text-red-500 text-sm">
        {{ (add_errors.address || []).join(" ") }}
      </span>

      <div class="w-full">
        <GmapMap
          :center="{
            lat: Number(add_data.lat),
            lng: Number(add_data.lng),
          }"
          :cluster="{ options: { styles: map.clusterStyle } }"
          :zoom="8"
          map-type-id="terrain"
          :options="{
            fullscreenControl: false,
            styles: map.mapStyle,
            mapTypeControl: false,
          }"
          style="height: 400px"
          class="rounded-lg overflow-hidden"
        >
          <GmapMarker
            :position="{
              lat: Number(add_data.lat),
              lng: Number(add_data.lng),
            }"
            :draggable="true"
            @dragend="updatePosition"
          />
        </GmapMap>
      </div>
    </div>
    <div class="w-full space-y-4">
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500 text-gray-800 text-center font-medium rtl:font-semibold"
        :disabled="disabled"
        @click.prevent="AddNewVendor"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
        إنشاء حساب جديد
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-gray-300 text-gray-800 text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'register')"
        :class="{
          '!bg-red-500/20 text-red-500': (Object.keys(add_errors) || []).some(
            (e) => ['mobile', 'email', 'vendor_name', 'password'].includes(e)
          ),
        }"
      >
        السابق
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-transparent text-yellow-500 text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'login')"
      >
        لدي حساب بالفعل
      </button>
      <div
        v-if="
          (Object.keys(add_errors) || []).some((e) =>
            ['mobile', 'email', 'vendor_name', 'password'].includes(e)
          )
        "
      >
        <p
          class="table font-medium rtl:font-semibold text-red-500 text-sm"
          v-for="(item, index) in Object.keys(add_errors)"
          :key="index"
        >
          <span
            v-if="['mobile', 'email', 'vendor_name', 'password'].includes(item)"
          >
            {{ ((add_errors || {})[item] || []).join(" ") }}
          </span>
          <!-- {{ (add_errors.category_id || []).join(" ") }} -->
        </p>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "storeInfo",
  data() {
    return {
      disabled: false,
      add_data: {
        store_name: "",
        sub_domain: "",
        category_id: "",
        lat: 24.774265,
        lng: 46.738586,
        address: "",
      },
      map: {
        clusterStyle: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            width: 56,
            height: 56,
            textColor: "#fff",
          },
        ],
        mapStyle: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#3e606f",
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: "#313536",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#44a688",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#f5e4e4",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: "14",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#067372",
              },
              {
                lightness: "-20",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#357374",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#004757",
              },
            ],
          },
        ],
      },
      categories: [],
      add_errors: {},
      error: "",
    };
  },
  created() {
    Object.assign(this.add_data, this.$store.state.vendor_data);
    this.getCategories();
  },
  methods: {
    checkSubDomain(domain) {
      return domain.trim().split(" ").join("-");
    },
    getCategories() {
      return this.axios
        .get("categories")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.categories = data.result.categories;
          }
        })
        .catch(({ response }) => {
          if (response.data.error_flag === 1) {
            this.categories = [];
          }
        });
    },
    AddNewVendor() {
      this.disabled = true;
      this.error = "";
      this.add_errors = {};

      return this.axios
        .post("register", { ...this.add_data, form_step: 2 })
        .then(({ data }) => {
          if (data.error_flag == 0) {
            this.$store.commit("SET_REGISTER_DATA", this.add_data);
            this.$emit("action", "attachments");
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          this.$store.commit("SET_REGISTER_DATA", this.add_data);
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, (response) => {
        if (response.status === "OK") {
          const address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.Place.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(this.add_data, "address", this.$refs.Place.$refs.input.value);
      }
    },
  },
};
</script>
