<template>
  <div class="w-full space-y-6">
    <form class="w-full" ref="social_form">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            تغيير اللغات
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="w-full space-y-3">
            <label
              for="primary_lang"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              اللغة الأساسية
            </label>
            <select class="custom-select" v-model="add_data.primary_lang">
              <option value="" selected disabled>-- اختار --</option>
              <option
                v-for="(lang, idx) in Object.keys(langs)"
                :value="lang"
                :key="idx"
              >
                {{ langs[lang] }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.primary_lang || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="primary_lang"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              اللغة الأساسية
            </label>
            <MultiListSelect
              :list="arrLangs"
              :selectedItems="add_data.langs"
              v-model="add_data.langs"
              option-value="key"
              option-text="value"
              placeholder="..."
              @select="handleSearchSelect($event, 'add_data', 'langs')"
              class="custom-multi-select"
            >
            </MultiListSelect>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.langs || []).join(" ") }}
            </span>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="disabled"
        @click.prevent="updateLang"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
        حفظ التغييرات
      </button>
    </form>
  </div>
</template>
<script>
import { MultiListSelect } from "vue-search-select";

export default {
  name: "StoreLangs",
  components: { MultiListSelect },
  data() {
    return {
      disabled: false,
      add_data: {
        langs: [],
      },
      add_errors: {},
      langs: {},
      arrLangs: [],
    };
  },
  created() {
    this.getLangs();
  },
  methods: {
    getLangs() {
      return this.axios
        .get("settings/langs")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.langs = data.result.langs;
            let langArr = [];

            data.result.store_langs.forEach((lang) => {
              langArr.push({
                key: lang,
                value: data.result.langs[lang],
              });
            });

            this.add_data = {
              langs: langArr,
              primary_lang: data.result.primary_lang,
            };

            let arr = Object.keys(data.result.langs);

            arr.forEach((key) => {
              this.arrLangs.push({
                key,
                value: data.result.langs[key],
              });
            });
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.langs = {};
            this.add_data = {};
          }
        });
    },

    updateLang() {
      this.disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (key === "langs") {
          this.add_data[key].forEach((lang, index) => {
            fd.append(`langs[${index}]`, lang.key);
          });
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("settings/update_langs", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getLangs();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          this.createdAlert("error", message);
          if (error_flag === 1) {
            let { error_flag, message } = response.data;
            if (typeof message === "object") {
              this.add_errors = message;
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
