<template>
  <section class="w-full p-6">
    <div
      class="w-full xl:container mx-auto flex flex-col lg:flex-row items-start gap-6"
    >
      <main class="lg:flex-1 w-full bg-white rounded-xl">
        <header
          class="flex items-center flex-wrap gap-4 border-b border-gray-200 p-5"
        >
          <div class="flex-1">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              تحسينات محركات البحث
            </h1>
            <p class="text-sm mt-1 font-medium rtl:font-semibold text-gray-600">
              كتابة عنوان و وصف المتجر الخاص بك ليظهر بشكل جيد في محركات البحث
              مثل جوجل وغيره من المحركات الاخرى.
            </p>
          </div>
          <a
            href="#"
            class="w-10 h-10 rounded-full bg-green-600/10 flex items-center justify-center relative z-[1]"
            title="article url"
          >
            <i class="fa-solid fa-eye text-green-600 text-sm"></i>

            <span
              class="animate-ping z-[-1] absolute inline-flex h-full w-full rounded-full bg-gray-600 opacity-20"
            ></span>
          </a>
        </header>
        <div class="w-full p-6">
          <form class="w-full space-y-4" novalidate @submit.prevent>
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                صورة الصفحة
              </h1>
              <div v-if="add_data.seo_image" class="table">
                <div class="w-20 h-20 p-1 rounded-xl border-2 border-gray-200">
                  <img
                    :src="
                      typeof add_data.seo_image === 'object'
                        ? convertImage(add_data.seo_image)
                        : add_data.seo_image
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  v-if="typeof add_data.seo_image === 'object'"
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm z-[1] relative"
                  @click.prevent="add_data.seo_image = null"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
              <label
                for="id_uploader_image"
                class="bg-yellow-600/20 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                id="id_uploader_image"
                type="file"
                name="id_uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="uploadMainImage($event, 'seo_image')"
              />

              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.seo_image || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="title"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                عنوان الصفحة
              </label>
              <input
                type="text"
                name="title"
                id="title"
                v-model="add_data.seo_home_title"
                placeholder="title"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              />
              <span
                class="text-gray-500 text-xs font-medium rtl:font-semibold table text-right rtl:text-left"
              >
                عدد الكلمات
                {{
                  add_data.seo_home_title
                    ? add_data.seo_home_title.trim().split(" ").length || 0
                    : 0
                }}
              </span>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.seo_home_title || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="title"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                وصف الصفحة
              </label>
              <textarea
                name="desc"
                id="desc"
                v-model="add_data.seo_home_desc"
                class="resize-y w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500 min-h-[10rem]"
              ></textarea>
              <span
                class="text-gray-500 text-xs font-medium rtl:font-semibold table text-right rtl:text-left"
              >
                عدد الكلمات
                {{
                  add_data.seo_home_desc
                    ? add_data.seo_home_desc.trim().split(" ").length || 0
                    : 0
                }}
              </span>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.seo_home_desc || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="keywords"
                class="font-medium rtl:font-semibold text-gray-600 table text-base"
              >
                كلمات دلالية
              </label>
              <input-tag
                id="keywords"
                v-model="add_data.seo_tags"
                placeholder="اضف جديد"
              ></input-tag>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.seo_tags || []).join(" ") }}
              </span>
            </div>
            <div class="w-full">
              <button
                class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                @click.prevent="updateSeo"
                :disabled="add_disabled"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                حفظ التغييرات
              </button>
            </div>
          </form>
        </div>
      </main>
      <aside class="lg:flex-1 w-full lg:max-w-sm mx-auto">
        <div class="w-full bg-white rounded-xl">
          <header class="border-b border-gray-200 p-5">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              معاينة التغييرات
            </h1>
          </header>
          <div class="w-full p-6 space-y-4">
            <figure class="border border-gray-200 rounded-xl">
              <figcaption class="overflow-hidden w-full">
                <img
                  :src="
                    add_data.seo_image
                      ? typeof add_data.seo_image === 'object'
                        ? convertImage(add_data.seo_image)
                        : add_data.seo_image
                      : ''
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="seo image"
                  class="w-full h-40 object-cover rounded-t-xl"
                />
              </figcaption>
              <blockquote class="p-4 space-y-1">
                <h1 class="text-sm font-semibold rtl:font-bold text-blue-800">
                  {{ add_data.seo_home_title || "عنوان الصفحة" }}
                </h1>
                <a
                  :href="($store.state.store.store_info || {}).store_url"
                  class="text-xs font-medium text-green-500 truncate"
                >
                  {{ ($store.state.store.store_info || {}).store_url }}
                </a>
                <p
                  class="text-xs font-medium rtl:font-semibold text-gray-600 clamp-3"
                >
                  {{ add_data.seo_home_desc || "وصف الصفحة" }}
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
      </aside>
    </div>
  </section>
</template>
<script>
export default {
  name: "Seo",
  data() {
    return {
      // active: false,
      add_data: {},
      add_disabled: false,
      load: false,
      add_errors: {},
    };
  },
  created() {
    this.getSeo();
  },
  methods: {
    getSeo() {
      return this.axios
        .get("settings/seo")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = {
              ...data.result,
              seo_tags: data.result.seo_tags ? data.result.seo_tags : [],
            };
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateSeo() {
      this.add_disabled = true;
      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (["seo_image"].includes(key)) {
          if (typeof this.add_data[key] == "object") {
            fd.append(key, this.add_data[key], this.add_data[key].name);
          } else {
            fd.delete(key);
          }
        } else if (key === "seo_tags") {
          this.add_data[key].forEach((tag, idx) => {
            fd.append(`seo_tags[${idx}]`, tag);
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.add_errors = {};
      return this.axios
        .post("settings/update_seo", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getSeo();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    uploadMainImage(e, type) {
      e.preventDefault();
      this.add_data[type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
  },
};
</script>
