<template>
  <form class="w-full space-y-6">
    <figure class="w-full bg-white rounded-xl">
      <header class="w-full p-4 border-b border-gray-100">
        <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
          اعدادات الحساب
        </h1>
        <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
          بإمكانك تغيير معلومات حسابك [ الاسم، البريد الإلكتروني، الجوال ] عبر
          هذه الصفحة.
        </p>
      </header>
      <blockquote class="w-full p-6 space-y-6">
        <p
          class="text-center font-semibold"
          v-if="error || success"
          :class="error ? 'text-red-500' : 'text-green-600'"
        >
          {{ error || success }}
        </p>
        <div class="w-full space-y-3">
          <label
            for="name"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            الاسم بالكامل
          </label>
          <input
            type="text"
            name="name"
            id="name"
            class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            v-model="add_data.name"
          />
          <p class="text-red-500 font-medium rtl:font-semibold">
            {{ (add_errors || {}).name }}
          </p>
        </div>
        <div class="w-full space-y-3">
          <label
            for="email"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            البريد الالكتروني
          </label>
          <input
            type="text"
            name="email"
            id="email"
            class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            v-model="add_data.email"
          />
          <p class="text-red-500 font-medium rtl:font-semibold">
            {{ (add_errors || {}).email }}
          </p>
        </div>
        <div class="w-full space-y-3">
          <label
            for="tel"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            رقم الجوال
          </label>
          <vue-tel-input
            id="mobile"
            ref="registerMobile"
            v-model="add_data.mobile"
            @open="handleOpen('registerMobile')"
          ></vue-tel-input>
          <p class="text-red-500 font-medium rtl:font-semibold">
            {{ (add_errors || {}).mobile }}
          </p>
        </div>
        <div class="flex w-full flex-col sm:flex-row gap-4">
          <div class="w-full space-y-3">
            <label
              for="current_password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              كلمة المرور الحالية
            </label>
            <div class="relative">
              <input
                type="password"
                name="current_password"
                id="current_password"
                placeholder="••••••••"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.current_password"
                autocomplete="off"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors || {}).current_password }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="new_password"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              كلمة المرور الجديدة
            </label>
            <div class="relative">
              <input
                type="password"
                name="new_password"
                id="new_password"
                placeholder="••••••••"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.new_password"
                autocomplete="off"
              />
              <button
                class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
                type="button"
                @click.prevent="textPasswordType"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors || {}).new_password }}
            </p>
          </div>
        </div>
      </blockquote>
    </figure>
    <button
      class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
      :disabled="disabled"
      @click.prevent="updateAccount"
    >
      <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
      حفظ التغييرات
    </button>
  </form>
</template>
<script>
export default {
  name: "PersonalAccount",
  data() {
    return {
      add_data: {},
      load: false,
      add_errors: {},
      error: "",
      success: "",
      disabled: false,
    };
  },
  created() {
    this.getProfileData();
  },
  methods: {
    getProfileData() {
      return this.axios
        .get("settings/account")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = data.result.account;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.add_data = {};
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateAccount() {
      this.disabled = true;
      let data = {
        ...this.add_data,
        mobile: this.add_data.mobile.replace(/\s/g, "").replace("+", ""),
      };
      return this.axios
        .post("settings/update_account", data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getProfileData();
            this.success = data.message;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
