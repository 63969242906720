<template>
  <figure class="w-full mx-auto">
    <figcaption class="w-full relative bg-white">
      <img
        :src="product.image || require('@/assets/images/product.webp')"
        alt="product image"
        class="object-contain max-h-[17rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
    </figcaption>
    <blockquote>
      <div class="w-full relative pt-4 text-center space-y-2">
        <h1 class="font-medium rtl:font-semibold text-sm">
          {{ product.title }}
        </h1>
        <h5 class="text-color text-sm font-medium">
          {{ product.price.toFixed(2) }}
          <span class="line-through text-gray-500">{{
            product.price.toFixed(2)
          }}</span>
        </h5>
      </div>
    </blockquote>
  </figure>
</template>
<script>
export default {
  name: "advancedProduct",
  props: ["product"],
};
</script>
