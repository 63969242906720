<template>
  <section class="w-full p-6 space-y-7">
    <FileSystemHeader>
      <template #title>المخزون</template>
    </FileSystemHeader>

    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative xl:container"
    >
      <div class="w-full">
        <ModelListSelect
          :list="categories"
          :isDisabled="categories.length === 0"
          :selectedItems="product_filter.category_id"
          v-model="product_filter.category_id"
          option-value="id"
          option-text="name"
          placeholder="التصنيف"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>
      <div class="w-full">
        <ModelListSelect
          :list="brands"
          :isDisabled="brands.length === 0"
          :selectedItems="product_filter.brand_id"
          v-model="product_filter.brand_id"
          option-value="id"
          option-text="name"
          placeholder="العلامة التجارية"
          class="filter-item custom-multi-select h-full"
        >
        </ModelListSelect>
      </div>
      <div class="w-full flex items-stretch gap-2 flex-wrap">
        <div>
          <button
            class="py-2 px-4 text-sm rounded-lg font-medium rtl:font-semibold bg-blue-500 text-white"
            @click.prevent="applyFilter(true)"
            :disabled="apply_disabled"
          >
            <i class="fa-solid fa-spinner fa-spin" v-if="apply_disabled"></i>

            تطبيق
          </button>
        </div>
        <div>
          <button
            class="py-2 px-4 text-sm rounded-lg font-medium rtl:font-semibold bg-gray-200 text-gray-600"
            @click.prevent="applyFilter(false)"
            :disabled="clear_disabled"
          >
            <i class="fa-solid fa-spinner fa-spin" v-if="clear_disabled"></i>
            الغاء الكل
          </button>
        </div>
      </div>
    </div>

    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header class="p-5 border-b border-gray-200 space-y-5">
        <div
          class="w-full flex items-start sm:items-center flex-col sm:flex-row gap-4 relative"
        >
          <div class="flex-1 w-full">
            <div class="w-full relative sm:max-w-sm">
              <input
                type="text"
                name="search"
                id="search"
                :placeholder="$t('search')"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
                v-model="search_key"
                @keyup.enter.stop="searchAll"
              />
              <!-- @blur="handleSearch('close')" -->
              <span
                class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="search_disabled"
                ></i>
                <i v-else class="fa-solid fa-search"></i>
              </span>
            </div>
          </div>

          <div class="flex gap-2 flex-wrap">
            <div>
              <button
                class="py-2 px-4 rounded-md bg-gray-100 text-gray-600 text-center text-sm flex gap-3 items-center ml-auto rtl:mr-auto rtl:ml-0"
                @click="openCloseMoreBox"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
                <span class="inline sm:hidden"> عرض المزيد </span>
              </button>
            </div>
          </div>
          <div
            class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
            ref="moreBox"
          >
            <div>
              <router-link
                to="/products/create"
                class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm block w-full"
              >
                <i class="fa-solid fa-plus"></i>
                اضافة جديد
              </router-link>
            </div>

            <div>
              <button
                class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block w-full"
                @click.prevent="exportAll"
                :disabled="export_disabled"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="export_disabled"
                ></i>
                <i class="fa-solid fa-file-excel" v-else></i>
                تصدير الكل
              </button>
            </div>
            <div>
              <button
                class="py-2 px-6 rounded-lg bg-gray-300 text-gray-600 text-center text-sm block w-full"
                @click.prevent="email_active = true"
              >
                <i class="fa-solid fa-file-excel"></i>
                ارسال عبر البريد الالكتروني
              </button>
            </div>
          </div>
        </div>
        <div class="flex gap-2 whitespace-nowrap flex-wrap">
          <div>
            <button
              class="py-2 px-4 text-sm rounded-lg font-medium rtl:font-semibold"
              :class="{
                'bg-yellow-600 text-white': status_filter === '',
                'bg-gray-100 text-gray-600': status_filter !== '',
              }"
              @click.prevent="statusFilter('')"
            >
              الكل
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': status_filter !== '',
                  'bg-yellow-700': status_filter === '',
                }"
                >{{ products.length || 0 }}</span
              >
            </button>
          </div>
          <div v-for="(status, index) in statuses" :key="index">
            <button
              class="py-2 px-4 text-sm rounded-lg font-medium rtl:font-semibold"
              @click.prevent="statusFilter(status, index)"
              :disabled="status_filter_disabled[index]"
              :class="{
                'bg-yellow-600 text-white': status_filter === status,
                'bg-gray-100 text-gray-600': status_filter !== status,
              }"
            >
              <i
                class="fa-solid fa-spinner fa-spin"
                v-if="status_filter_disabled[index]"
              ></i>
              {{ $t(`status.${status}`) }}
              <span
                class="px-2 py-1 rounded-full inline-block text-xs"
                :class="{
                  'bg-gray-300': status_filter !== status,
                  'bg-yellow-700': status_filter === status,
                }"
                >{{
                  (products.filter((o) => o.status.includes(status)) || [])
                    .length || 0
                }}</span
              >
            </button>
          </div>
        </div>
      </header>
      <div class="w-full pb-5 overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>المنتج</th>
              <th>الاسم</th>
              <th>رمز المنتج</th>
              <th>الكمية</th>
              <th>السعر</th>
              <th>العرض في المتجر</th>
              <th>تاريخ الانشاء</th>
              <th>الاجراءات</th>
            </tr>
          </thead>
          <tbody v-if="products.length > 0 && load">
            <tr v-for="(product, index) in products" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                <div class="w-10 h-10">
                  <img
                    :src="product.image"
                    @error="$event.target.src = '/images/placeholder.png'"
                    alt="product image"
                    class="w-full h-full rounded-lg border object-cover border-gray-100"
                  />
                </div>
              </td>
              <td>
                <span
                  class="inline-block line-clamp-2 whitespace-normal max-w-[12rem]"
                >
                  {{ product.name }}
                </span>
              </td>
              <td>{{ product.sku }}</td>
              <td>{{ product.qty }}</td>
              <td>{{ product.price }}</td>
              <td>
                <span
                  class="inline-block py-1.5 px-3 rounded-full text-sm text-white"
                  :class="
                    product.status === 'on' ? 'bg-blue-500' : 'bg-red-500'
                  "
                >
                  {{ product.status === "on" ? "نعم" : "لا" }}
                </span>
              </td>

              <td>
                <div>
                  {{ product.created_at | moment("from") }}
                </div>
                <div>
                  <span dir="ltr" class="text-gray-600 text-xs font-medium">{{
                    product.created_at | moment(" h:mm a . MMMM Do YYYY")
                  }}</span>
                </div>
              </td>
              <td>
                <div class="actions-group">
                  <div>
                    <router-link
                      :to="`/products/${product.id}`"
                      class="py-2 px-3 rounded-lg bg-green-600 text-white text-center text-xs block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تعديل"
                    >
                      تعديل
                    </router-link>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-blue-500/20 text-blue-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تغيير الحالة"
                      @click.prevent="changeStatus(product.id, index)"
                      :disabled="status_disabled[index]"
                    >
                      <i
                        class="fa-solid fa-spinner fa-spin"
                        v-if="status_disabled[index]"
                      ></i>
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-red-500/20 text-red-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="حذف"
                      @click.prevent="showModal(product.id)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <TableLoad :rows="9" v-for="i in 4" :key="i" />
          </tbody>
          <tbody v-if="load && products.length === 0">
            <tr>
              <td colspan="9" class="!text-center">لا توجد بيانات</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
    <SweetModal
      @close="active = $event"
      class="!mt-0"
      v-if="active"
      :close="false"
    >
      <template>
        <div class="p-5">
          <h1 class="font-bold text-lg text-gray-800 text-center mb-2">
            هل انت متاكد؟
          </h1>
          <p
            class="font-medium rtl:font-semibold text-lg text-gray-600 text-center"
          >
            اذا كنت تريد حذف تلك المنتج هذا يعني ان لم يتم عرض اي محتوى لتلك
            المنتج في متجرك ولن يظهر لعملاء وبالتالي سيتم حذف الطلب من سلات
            التسوق للعملاء.
          </p>
          <p
            class="font-medium rtl:font-semibold text-sm text-red-500 text-center mt-4"
            v-if="error"
          >
            {{ error }}
          </p>

          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="delete_disabled"
                @click.prevent="deleteItem"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="delete_disabled"
                ></i>
                حذف
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="
                  active = false;
                  product_id = null;
                "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      </template>
    </SweetModal>

    <SweetModal
      class="!m-0"
      v-if="email_active"
      @close="email_active = $event"
      :title="'ارسال المخزون عبر البريد الالكتروني'"
    >
      <template>
        <div class="p-5">
          <form class="w-full space-y-4">
            <input-tag
              id="keywords"
              v-model="email_data.emails"
              placeholder="اضف جديد"
              validate="email"
            ></input-tag>

            <button
              class="py-3 px-4 rounded-lg bg-blue-500 text-white font-medium rtl:font-semibold text-sm w-full max-w-[7rem]"
              :disabled="email_disabled"
              @click.prevent="sendViaEmails"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="email_disabled"></i>
              ارسال
            </button>
          </form>
        </div>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "StockIndex",
  data() {
    return {
      load: false,
      products: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      export_disabled: false,
      export_data: {},
      export_active: false,
      email_disabled: false,
      email_data: {
        emails: [],
      },
      email_active: false,
      statuses: ["on", "off"],
      categories: [],
      brands: [],
      status_filter: "",
      product_filter: {
        brand_id: "",
        category_id: "",
      },
      status_filter_disabled: [],
      clear_disabled: false,
      apply_disabled: false,
    };
  },
  created() {
    this.getProducts();
    this.getCategories();
    this.getBrands();
    this.email_data?.emails.push(this.$store.state.user?.email);
  },
  watch: {
    currentPage() {
      this.getProducts();
    },
  },
  methods: {
    getCategories() {
      return this.axios.get("store_categories").then(({ data }) => {
        this.categories = data.result.store_categories;
      });
    },
    getBrands() {
      return this.axios.get("store_brands").then(({ data }) => {
        this.brands = data.result.store_brands;
      });
    },
    getProducts() {
      return this.axios
        .get("products", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.status_filter,
            ...this.product_filter,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.products = data.result.products.data;
            this.perPage = data.result.products.pagination.per_page;
            this.rows = data.result.products.pagination.total;
            this.totalPages = data.result.products.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.products = [];
          }
        })
        .finally(() => {
          this.load = true;
          this.search_disabled = false;
        });
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    showModal(id) {
      this.product_id = id;
      this.active = true;
    },
    changeStatus(id, i) {
      this.$set(this.status_disabled, i, true);
      return this.axios
        .post("products/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getProducts();
            this.products.find((product) => product.id === id).status =
              data.result.status;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.status_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    deleteItem() {
      this.delete_disabled = true;
      return this.axios
        .post("products/delete/" + this.product_id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getProducts();
            this.products.splice(
              this.products.findIndex(
                (product) => product.id === this.product_id
              ),
              1
            );
            this.active = false;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.delete_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },

    searchAll() {
      this.search_disabled = true;
      this.getProducts().then(() => {
        this.search_disabled = false;
      });
    },

    statusFilter(status, i) {
      this.status_filter = status;
      this.$set(this.status_filter_disabled, i, true);
      this.getProducts().then(() => {
        this.status_filter_disabled.splice(i, 1);
      });
    },
    applyFilter(active) {
      active ? (this.apply_disabled = true) : (this.clear_disabled = true);
      if (!active) {
        this.status_filter = "";
        Object.keys(this.product_filter).forEach((key) => {
          this.product_filter[key] = "";
        });
      }
      this.getProducts().then(() => {
        active ? (this.apply_disabled = false) : (this.clear_disabled = false);
      });
    },
    exportAll() {
      this.export_disabled = true;
      this.$refs.moreBox.classList.remove("hidden");
      return this.axios
        .get("products/export_excel", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.status_filter,
            ...this.product_filter,
          },
          responseType: "arraybuffer",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `products-${new Date().getTime()}.xlsx`
          );
          link.click();
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.export_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    sendViaEmails() {
      this.email_disabled = true;
      let fd = new FormData();

      Object.keys(this.email_data).forEach((key) => {
        if (key === "emails") {
          this.email_data[key].forEach((email, index) => {
            fd.append(`${key}[${index}]`, email);
          });
        }
      });
      return this.axios
        .post("products/send_excel", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.email_data.emails = [];
            this.email_active = false;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.email_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
  components: { TableLoad, SweetModal, ModelListSelect, FileSystemHeader },
};
</script>
