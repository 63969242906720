<template>
  <div class="sticky top-0 z-20">
    <!-- header -->
    <header
      class="w-full p-4 bg-white border-b border-gray-100"
      v-if="(store.store_info || {}).id || (store.user || {}).id"
    >
      <div
        class="relative flex items-center justify-between gap-3 xl:container sidebar-move"
      >
        <div>
          <div class="flex items-center gap-4">
            <div>
              <button
                @click="closeOpenSideBar"
                class="w-10 h-10 flex items-center justify-center text-yellow-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:block hidden" v-if="(store.store_info || {}).id">
              <p class="font-medium rtl:font-semibold text-gray-800">
                {{ $t(`pages.${$store.state.title}`) }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-1"></div>
        <div>
          <button
            class="flex items-center gap-2 text-gray-600 border border-gray-100 w-10 h-10 rounded-full justify-center relative"
            type="button"
            data-btn="header-user-drop-down"
            @click.prevent="openNotifications"
          >
            <i class="fa-solid fa-bell"></i>
            <span
              class="absolute -top-1 left-[calc(100%-35%)] bg-red-500 text-white text-[10px] font-semibold rounded-full p-0.5 px-1.5 border-2 border-white"
              v-if="notifications ? notifications.length !== 0 : false"
            >
              {{ notifications ? notifications.length : 0 }}
            </span>
          </button>
        </div>
        <div
          class="absolute top-[calc(100%+30px)] bg-white right-0 rtl:right-auto rtl:left-0 py-4 w-full max-w-md max-h-[calc(100vh-200px)] rounded-xl overflow-y-auto v-dropdown hidden border border-gray-100"
          ref="notificationsBox"
        >
          <div
            v-if="load && (notifications ? notifications.length !== 0 : false)"
          >
            <figure
              class="w-full p-5 border-b border-gray-100 flex items-start gap-4 last:border-0"
              v-for="(item, i) in notifications"
              :key="i"
            >
              <div>
                <span
                  class="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full"
                >
                  <i class="fa-solid fa-bookmark text-gray-500"></i>
                </span>
              </div>
              <div class="flex-1 space-y-2">
                <h1
                  class="text-base text-gray-800 font-medium rtl:font-semibold"
                >
                  {{ item.subject }}
                </h1>
                <p class="text-sm text-gray-600 font-medium rtl:font-semibold">
                  {{ item.body }}
                </p>
                <span class="font-medium text-xs text-gray-500" dir="ltr">
                  {{ item.created_at | moment(" h:mm a. MMMM Do YYYY") }}
                </span>
              </div>
            </figure>
          </div>
          <div
            v-if="load && (notifications ? notifications.length === 0 : true)"
            class="px-4 text-center"
          >
            {{ $t("no_data.data") }}
          </div>
          <div v-if="!load" class="p-4">
            <div class="w-full space-y-2">
              <div class="w-1/2 h-4 rounded-md bg-gray-200 animate-pulse"></div>
              <div class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
              <div class="w-2/5 h-3 rounded-md bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        </div>
        <div>
          <button
            class="flex items-center justify-start gap-3"
            type="button"
            @click.prevent="openMenu($event, 'open')"
            data-btn="header-user-drop-down"
          >
            <div class="flex-1 sm:grid hidden" style="direction: ltr">
              <h1
                class="text-sm truncate text-gray-800 font-medium rtl:font-semibold"
              >
                {{ user.name }}
              </h1>
              <a
                :href="`mailto:${user.email}`"
                class="text-sm truncate text-gray-600 font-medium rtl:font-semibold underline hover:no-underline max-w-[10rem]"
              >
                {{ user.email }}
              </a>
            </div>
            <div>
              <img
                :src="user.image"
                alt="user image"
                class="w-10 h-10 rounded-full object-cover"
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
                v-if="user.image"
              />
              <div
                class="w-10 h-10 rounded-full bg-gray-300 text-gray-500 text-sm flex items-center justify-center"
                v-else
              >
                <i class="fa-solid fa-user"></i>
              </div>
            </div>
            <span>
              <i class="fa-solid fa-angle-down text-sm text-gray-500"></i>
            </span>
          </button>
        </div>
        <!-- menu -->
        <div
          class="absolute top-[calc(100%+30px)] bg-white right-0 rtl:right-auto rtl:left-0 p-4 w-full max-w-sm max-h-[calc(100vh-100px)] rounded-xl hidden menu-box v-dropdown overflow-y-auto"
          data-btn="header-user-drop-down"
        >
          <header
            class="w-full flex pb-4 border-b border-gray-100 items-center gap-4"
          >
            <div>
              <img
                :src="user.image"
                alt="user image"
                class="w-12 h-12 rounded-full object-cover"
                v-if="user.image"
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
              />
              <div
                class="w-12 h-12 rounded-full bg-gray-300 text-gray-500 text-lg flex items-center justify-center"
                v-else
              >
                <i class="fa-solid fa-user"></i>
              </div>
            </div>
            <div class="flex-1 grid">
              <h1
                class="text-base truncate text-gray-800 font-medium rtl:font-semibold"
              >
                {{ user.name }}
              </h1>
              <a
                :href="`mailto:${user.email}`"
                class="text-sm truncate text-gray-600 font-medium rtl:font-semibold underline hover:no-underline"
              >
                {{ user.email }}
              </a>
            </div>
          </header>
          <nav class="w-full mt-4">
            <ul class="list-none space-y-2">
              <li>
                <router-link
                  to="/settings"
                  class="flex items-center gap-4 px-4 py-2 text-gray-600 font-medium rtl:font-semibold hover:text-yellow-500"
                >
                  <span>
                    <i class="fa-solid fa-cog"></i>
                  </span>
                  تعديل الحساب
                </router-link>
              </li>
              <li>
                <router-link
                  to="/settings/plans"
                  class="flex items-center gap-4 px-4 py-2 text-gray-600 font-medium rtl:font-semibold hover:text-yellow-500"
                >
                  <span> <i class="fa-solid fa-receipt"></i> </span>
                  تغيير الباقة
                </router-link>
              </li>

              <li>
                <a
                  href="javascript:void(0)"
                  @click.prevent="logUserOut"
                  class="flex items-center gap-4 px-4 py-2 text-red-500 font-medium rtl:font-semibold"
                >
                  <span>
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  تسجيل خروج
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- ./menu -->
      </div>
    </header>
    <div v-else>
      <header class="w-full border-b border-gray-100 px-6 bg-white">
        <div class="xl:container flex items-center justify-center gap-4">
          <div>
            <div class="w-20 h-6 rounded-lg bg-gray-200 animate-pulse"></div>
          </div>
          <div class="flex-1"></div>
          <div>
            <div class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"></div>
          </div>
          <div>
            <div class="w-20 h-full py-6 bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </header>
      <div class="table mx-auto mt-4 px-6">
        <div
          class="w-12 h-12 rounded-full flex items-center justify-center text-gray-600 bg-white shadow-2xl"
        >
          <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
    <!-- ./header -->
    <!-- sidebar -->
    <div
      class="fixed inset-0 z-20 bg-gray-800/20 min-h-screen hidden"
      ref="sidebar"
    >
      <aside
        class="table w-full max-w-xs mr-auto rtl:mr-0 rtl:ml-auto bg-gray-800 h-full"
      >
        <div
          class="overflow-y-auto h-full flex flex-col sidebar-box scroll-smooth pb-20"
        >
          <div>
            <header
              class="w-full flex p-4 border-b border-gray-700 items-center gap-4"
            >
              <div class="flex-1">
                <h1 class="text-sm text-gray-300 font-medium rtl:font-semibold">
                  {{ $t("menu") }}
                </h1>
              </div>
              <div>
                <button
                  class="w-8 h-8 flex items-center justify-center text-sm"
                  @click="closeOpenSideBar"
                >
                  <i class="fa-solid fa-times text-white"></i>
                </button>
              </div>
            </header>
          </div>
          <div>
            <header
              class="w-full flex p-4 border-b border-gray-700 items-start gap-4"
            >
              <div>
                <img
                  :src="user.image"
                  alt="user image"
                  class="w-12 h-12 rounded-full object-cover"
                  v-if="user.image"
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                />
                <div
                  class="w-12 h-12 rounded-full bg-gray-700 text-gray-400 text-lg flex items-center justify-center"
                  v-else
                >
                  <i class="fa-solid fa-user"></i>
                </div>
              </div>
              <div class="flex-1 grid">
                <h1
                  class="text-base truncate text-white font-medium rtl:font-semibold"
                >
                  {{ user.name }}
                </h1>
                <a
                  :href="`mailto:${user.email}`"
                  class="text-sm truncate text-gray-300 font-medium rtl:font-semibold underline hover:no-underline"
                >
                  {{ user.email }}
                </a>
                <div class="space-y-2 mt-4">
                  <a
                    :href="(store.store_info || {}).store_url || '#'"
                    class="flex items-center gap-3 py-2 px-4 text-white font-medium rtl:font-semibold hover:bg-yellow-500 bg-yellow-600 rounded-lg text-sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <i class="fa-solid fa-eye"></i>
                    </span>
                    {{ $t("pages.view_store") }}
                  </a>
                  <router-link
                    to="/settings"
                    class="flex items-center gap-3 py-2 px-4 text-white font-medium rtl:font-semibold hover:bg-gray-500 bg-gray-600 rounded-lg text-sm"
                  >
                    <span>
                      <i class="fa-solid fa-pen-nib"></i>
                    </span>

                    {{ $t("pages.design_options") }}
                  </router-link>
                </div>
              </div>
            </header>
          </div>
          <div class="flex-1 p-4 sidebar-container">
            <nav class="w-full h-auto">
              <ul class="list-none space-y-2">
                <li>
                  <router-link
                    to="/"
                    exact
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-chart-pie"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.home") }}</span>
                  </router-link>
                </li>
                <li v-if="false">
                  <router-link
                    to="/analytics"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-chart-column"></i>
                    </span>
                    التحليلات
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/orders"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-basket-shopping"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.orders") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/incomes"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-coins"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.incomes") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/customers"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-user-group"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.customers") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/branches"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-map-location-dot"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.branches") }}</span>
                    <span class="py-1 px-2 rounded-sm bg-gray-700 text-xs"
                      >10</span
                    >
                  </router-link>
                </li>
                <li class="drop-down">
                  <a
                    href="javascript:void(0)"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 justify-between"
                  >
                    <span>
                      <i class="fa-solid fa-shopping-cart"></i>
                    </span>

                    <span class="flex-1">{{ $t("pages.products") }}</span>
                    <span class="py-1 px-2 rounded-sm bg-gray-700 text-xs"
                      >10</span
                    >
                    <span>
                      <i class="fa-solid fa-angle-down icon"></i>
                    </span>
                  </a>
                  <ul
                    class="w-full list-none bg-gray-700 rounded-xl py-4 space-y-1 hidden"
                  >
                    <li>
                      <router-link
                        to="/products"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.products") }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/stock"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.stock") }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/products/categories"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.categories") }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/products/brands"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.brands") }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="drop-down">
                  <a
                    href="javascript:void(0)"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 justify-between"
                  >
                    <span>
                      <i class="fa-solid fa-award"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.shopping_list") }}</span>
                    <span>
                      <i class="fa-solid fa-angle-down icon"></i>
                    </span>
                  </a>
                  <ul
                    class="w-full list-none bg-gray-700 rounded-xl py-4 space-y-1 hidden"
                  >
                    <li>
                      <router-link
                        to="/abandoned-carts"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{
                          $t("pages.abandoned_carts")
                        }}</span>
                      </router-link>
                    </li>

                    <li>
                      <router-link
                        to="/coupons"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.coupons") }}</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/seo"
                        class="text-sm flex items-center gap-4 py-2 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500 pl-11 rtl:pl-0 rtl:pr-11"
                      >
                        <span class="flex-1">{{ $t("pages.seo") }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>

                <li>
                  <router-link
                    to="/settings"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-cog"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.settings") }}</span>
                  </router-link>
                </li>
                <li v-if="false">
                  <a
                    href="#"
                    class="flex items-center gap-4 py-3 px-4 text-white font-medium rtl:font-semibold hover:text-yellow-500"
                  >
                    <span>
                      <i class="fa-solid fa-shapes"></i>
                    </span>
                    <span class="flex-1">{{ $t("pages.academy") }}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </div>
    <!-- ./sidebar -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      search_disabled: false,
      load: false,
      notifications: [],
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (e.target === this.$refs.sidebar) {
        this.closeOpenSideBar();
      }
    });
    this.getNotifications();
  },
  watch: {
    "$route.name"() {
      this.$refs.sidebar.classList.add("hidden");
      // this.closeOpenSideBar();
      document.querySelector("html").classList.remove("overflow-hidden");
      // document.querySelectorAll(".sidebar-move").forEach((el) => {
      //   el.classList.remove("sidebar-moved");
      // });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUserInfo;
    },
    store() {
      return this.$store.state.store;
    },
  },
  mounted() {
    this.openDrop();
  },
  methods: {
    getNotifications() {
      return this.axios
        .get("notifications")
        .then(({ data }) => {
          this.notifications = data.result.notifications;
        })
        .finally(() => (this.load = true));
    },
    closeOpenSideBar() {
      this.$refs.sidebar.classList.toggle("hidden");
      document.querySelector("html").classList.toggle("overflow-hidden");
      // document.querySelectorAll(".sidebar-move").forEach((el) => {
      //   el.classList.toggle("sidebar-moved");
      // });
    },
    openDrop() {
      const dropDowns = document.querySelectorAll(".drop-down");
      dropDowns.forEach((drop) => {
        let currentParent = drop.querySelectorAll("a")[0],
          parentList = drop.querySelector("ul");

        currentParent.addEventListener("click", (e) => {
          // close another dropdowns
          dropDowns.forEach((drop) => {
            if (drop !== currentParent) {
              // console.log("is not the same", drop);
              drop.querySelector("ul").classList.add("hidden");
              drop
                .querySelector(".icon")
                .classList.replace(
                  drop.querySelector("ul").classList.contains("hidden")
                    ? "fa-angle-up"
                    : "fa-angle-down",
                  drop.querySelector("ul").classList.contains("hidden")
                    ? "fa-angle-down"
                    : "fa-angle-up"
                );
            }
          });
          // open and close current dropdown
          let icon = e.currentTarget.querySelector(".icon"),
            side = document.querySelector(".sidebar-box");
          parentList.classList.toggle("hidden");
          side.scrollTop = parentList.offsetTop;
          icon.classList.replace(
            parentList.classList.contains("hidden")
              ? "fa-angle-up"
              : "fa-angle-down",
            parentList.classList.contains("hidden")
              ? "fa-angle-down"
              : "fa-angle-up"
          );
        });
      });
    },
    handleSearch(type) {
      let searchBox = this.$refs.searchBox;
      switch (type) {
        case "open":
          if (searchBox.classList.contains("hidden")) {
            searchBox.classList.remove("hidden");
          }

          break;
        case "close":
          if (!searchBox.classList.contains("hidden")) {
            searchBox.classList.add("hidden");
          } else {
            return false;
          }

          break;

        default:
          break;
      }
    },
    openMenu(e, type) {
      let menu = document.querySelector(".menu-box");
      switch (type) {
        case "open":
          menu.classList.toggle("hidden");
          e.currentTarget
            .querySelector("i")
            .classList.replace(
              menu.classList.contains("hidden")
                ? "fa-angle-up"
                : "fa-angle-down",
              menu.classList.contains("hidden")
                ? "fa-angle-down"
                : "fa-angle-up"
            );
          break;
        case "close":
          menu.classList.add("hidden");
          break;

        default:
          break;
      }
    },
    openNotifications() {
      if (this.$refs.notificationsBox.classList.contains("hidden")) {
        this.$refs.notificationsBox.classList.remove("hidden");
      }
    },
  },
};
</script>
