<template>
  <section class="w-full p-6">
    <form
      class="w-full max-w-screen-lg mx-auto space-y-7"
      @submit.prevent
      novalidate
    >
      <FileSystemHeader>
        <template #default>
          <router-link to="/products">المنتجات</router-link> /
        </template>
        <template #title>اضافة منتج جديد</template>
      </FileSystemHeader>
      <figure class="w-full bg-white rounded-xl">
        <header class="flex items-center flex-wrap gap-4 py-3 px-4">
          <div class="flex-1">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              البيانات الاساسية
            </h1>
          </div>
          <button
            class="w-10 h-10 rounded-full flex items-center justify-center relative"
            title="article url"
          >
            <i class="fa-solid fa-angle-down text-gray-600 text-sm"></i>
          </button>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-6">
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1 w-full space-y-3">
                <label
                  for="name_ar"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  اسم المنتج | عربي
                </label>
                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.ar.name"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["ar.name"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex-1 w-full space-y-3">
                <label
                  for="name_en"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  اسم المنتج | انجليزي
                </label>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.en.name"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["en.name"] || []).join(" ") }}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1 w-full space-y-3">
                <label
                  for="prod_date"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  تاريخ الانتاج
                </label>
                <input
                  type="date"
                  name="prod_date"
                  id="prod_date"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.prod_date"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["prod_date"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex-1 w-full space-y-3">
                <label
                  for="exp_date"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  تاريخ انتهاء الصلاحية
                </label>
                <input
                  type="date"
                  name="exp_date"
                  id="exp_date"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.exp_date"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["exp_date"] || []).join(" ") }}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="category"
              class="font-medium rtl:font-semibold text-gray-600"
            >
              الماركة
            </label>
            <ModelListSelect
              :list="brands"
              :selectedItems="add_data.brand_id"
              v-model="add_data.brand_id"
              option-value="id"
              option-text="name"
              placeholder="..."
              class="custom-multi-select"
            >
            </ModelListSelect>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["brand_id"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="category"
              class="font-medium rtl:font-semibold text-gray-600"
            >
              التصنيف
            </label>

            <multiselect
              v-model="add_data.categories_ids"
              label="name"
              track-by="name"
              :options="categories"
              :option-height="104"
              :show-labels="false"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="true"
              :placeholder="$t('search')"
              :hideSelected="true"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                  <div
                    v-if="
                      props.option.children
                        ? props.option.children.length > 0
                        : false
                    "
                    class="mt-2"
                  >
                    <span
                      class="option__small p-1 inline-block"
                      v-for="(item, index) in props.option.children"
                      :key="index"
                    >
                      <span
                        class="inline-block bg-gray-600 text-sm p-1 px-2 rounded-md text-gray-300"
                        >{{ item.name }}</span
                      ></span
                    >
                  </div>
                </div>
              </template>
            </multiselect>
          </div>

          <button
            class="py-2 px-4 mt-4 rounded-full bg-yellow-600 text-sm font-medium rtl:font-semibold text-white"
            @click.prevent="active = true"
          >
            اضافة تصنيف جديد
          </button>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded-xl">
        <header class="flex items-center flex-wrap gap-4 py-3 px-4">
          <div class="flex-1">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              وصف المنتج
            </h1>
          </div>
          <button
            class="w-10 h-10 rounded-full flex items-center justify-center relative"
            title="article url"
          >
            <i class="fa-solid fa-angle-down text-gray-600 text-sm"></i>
          </button>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-6">
          <div class="w-full space-y-3">
            <label
              for="desc_ar"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              الوصف | عربي
            </label>
            <tinymce
              :other_options="options"
              id="desc_ar"
              style="height: auto !important"
              :plugins="plugins"
              :toolbar1="toolbar"
              v-model="add_data.ar.desc"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["ar.desc"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="desc_en"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              الوصف | انجليزي
            </label>
            <tinymce
              :other_options="options"
              id="desc_en"
              style="height: auto !important"
              :plugins="plugins"
              :toolbar1="toolbar"
              v-model="add_data.en.desc"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["en.desc"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="keywords"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              كلمات دلالية
            </label>
            <input-tag
              id="keywords"
              v-model="add_data.tags"
              placeholder="اضف جديد"
            ></input-tag>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded-xl">
        <header class="flex items-center flex-wrap gap-4 py-3 px-4">
          <div class="flex-1">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              السعر والكمية
            </h1>
          </div>
          <button
            class="w-10 h-10 rounded-full flex items-center justify-center relative"
            title="article url"
          >
            <i class="fa-solid fa-angle-down text-gray-600 text-sm"></i>
          </button>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-6">
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1 w-full space-y-3">
                <label
                  for="product_q"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  الكمية
                </label>
                <input
                  type="number"
                  name="product_q"
                  id="product_q"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.qty"
                  :disabled="is_unlimited === 1"
                />
                <div class="checkbox">
                  <div>
                    <input
                      type="checkbox"
                      id="unlimited"
                      v-model="is_unlimited"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                  <div>
                    <label for="unlimited"> كمية غير محدودة </label>
                  </div>
                </div>
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["qty"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex-1 w-full space-y-3">
                <label
                  for="price"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  السعر
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.price"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["price"] || []).join(" ") }}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="product_sku"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              رمز المنتج
            </label>
            <input
              type="text"
              name="product_sku"
              id="product_sku"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.sku"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["sku"] || []).join(" ") }}
            </span>
          </div>
          <p
            class="font-medium rtl:font-semibold text-gray-600 table text-base"
          >
            اضافة خصم للمنتج
          </p>
          <div
            class="w-full p-5 rounded-xl border-dashed border-2 border-gray-300"
          >
            <div class="w-full flex gap-4 flex-col md:flex-row">
              <div class="flex-1 w-full space-y-3">
                <label
                  for="product_price"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  تكلفة المنتج
                </label>
                <input
                  type="text"
                  name="product_price"
                  id="product_price"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.sale_price"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["sale_price"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex-1 w-full space-y-3">
                <label
                  for="sale_start"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  تاريخ البدء
                </label>
                <input
                  type="date"
                  id="sale_start"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.sale_start"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["sale_start"] || []).join(" ") }}
                </span>
              </div>
              <div class="flex-1 w-full space-y-3">
                <label
                  for="sale_end"
                  class="font-medium rtl:font-semibold text-gray-600 table text-base"
                >
                  تاريخ الانتهاء
                </label>
                <input
                  type="date"
                  id="sale_end"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.sale_end"
                />
                <span
                  class="table font-medium rtl:font-semibold text-red-500 text-sm"
                >
                  {{ (add_errors["sale_end"] || []).join(" ") }}
                </span>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded-xl">
        <header class="flex items-center flex-wrap gap-4 py-3 px-4">
          <div class="flex-1">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              الصور
            </h1>
          </div>
          <button
            class="w-10 h-10 rounded-full flex items-center justify-center relative"
            title="article url"
          >
            <i class="fa-solid fa-angle-down text-gray-600 text-sm"></i>
          </button>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-6">
          <div class="w-full space-y-3 px-5">
            <h1
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              صورة المنتج
            </h1>
            <label
              for="uploader_image"
              class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
            >
              تحميل ( .png , .jpg )
            </label>
            <input
              type="file"
              name="uploader_image"
              id="uploader_image"
              hidden
              accept="image/*"
              class="hidden"
              @change.prevent="uploadMainImage"
            />
            <div v-if="add_data.image" class="table">
              <div
                class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
              >
                <img
                  :src="convertImage(add_data.image)"
                  alt="upload"
                  class="w-full rounded-lg h-full object-cover"
                />
              </div>
              <button
                class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                @click.prevent="add_data.image = null"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="w-full space-y-3 px-5">
            <h1
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              صور اضافية
            </h1>
            <label
              for="images_uploader"
              class="w-full rounded-lg !py-12 px-8 bg-yellow-600/10 text-yellow-600 text-center border-dashed border-2 border-yellow-600/50 block cursor-pointer"
              @dragover="
                $event.target.classList.replace('border-dashed', 'border')
              "
              @dragleave="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @dragend="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @drop="dropControl($event)"
            >
              <span
                class="w-14 h-14 rounded-full flex items-center justify-center mx-auto bg-yellow-600/20 text-xl mb-4"
              >
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
              </span>
              <p class="text-sm font-medium rtl:font-semibold">
                سحب وافلات أو تصفح الصور الخاصة بك هنا
              </p>
            </label>
            <input
              type="file"
              name="images_uploader"
              id="images_uploader"
              hidden
              class="hidden"
              @change="attachmentsUploader"
              accept="image/*"
            />
            <div class="block mt-6 w-auto">
              <div class="flex items-start gap-4 flex-wrap">
                <div v-for="(img, imgIdx) in add_data.images" :key="imgIdx">
                  <div
                    class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                  >
                    <img
                      :src="convertFile(imgIdx, 'add_data', 'images')"
                      alt="upload"
                      class="w-full rounded-lg h-full object-cover"
                    />
                  </div>
                  <button
                    class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                    @click.prevent="add_data.images.splice(imgIdx, 1)"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full p-4">
        <button
          class="py-2 px-6 rounded-xl text-white font-medium rtl:font-semibold bg-green-600"
          :disabled="disabled"
          @click.prevent="addProduct"
        >
          <i
            class="fa-solid fa-spinner fa-spin mr-1 rtl:ml-1 rtl:mr-0"
            v-if="disabled"
          ></i>
          إضافة
        </button>
      </figure>
    </form>
    <SweetModal :close="true" v-if="active" @close="active = $event">
      <template>
        <form class="w-full space-y-4 p-5">
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_ar"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | عربي
            </label>
            <input
              type="text"
              name="category_name_ar"
              id="category_name_ar"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="category_data.ar.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors["ar.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_en"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | انجليزي
            </label>
            <input
              type="text"
              name="category_name_en"
              id="category_name_en"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="category_data.en.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors["en.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="modal_category"
              class="font-medium rtl:font-semibold text-gray-600"
            >
              التصنيف
            </label>
            <select
              name="modal_category"
              id="modal_category"
              class="custom-select"
              v-model="category_data.parent_id"
            >
              <option value="" selected>-- اختار --</option>
              <option
                v-for="(catItem, index) in categories"
                :key="index"
                :value="catItem.id"
              >
                {{ catItem.name }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors.parent_id || []).join(" ") }}
            </span>
          </div>
          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="category_disabled"
                @click.prevent="addNewCategory"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="category_disabled"
                ></i>
                اضافة
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import SweetModal from "@/components/modals/sweetModal.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
import { MultiListSelect, ModelListSelect } from "vue-search-select";

export default {
  name: "CreateProduct",
  data() {
    return {
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste imagetools wordcount",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      options: {
        height: 250,
        menubar: false,
        statusbar: false,
        directionality: "rtl",
        language: this.$i18n.locale,
        language_url: "/" + this.$i18n.locale + ".js",
        skin: "naked",
        icons: "small",
        toolbar_location: "bottom",
        automatic_uploads: true,
        images_reuse_filename: true,
        content_style:
          "body { font-family:Cairo,Quicksand,Helvetica,Arial,sans-serif; font-size:14px }",
      },
      active: false,
      categories: [],
      category_data: {
        ar: {},
        en: {},
        parent_id: "",
      },
      category_errors: {},
      category_disabled: false,
      add_data: {
        ar: {},
        en: {},
        categories_ids: [],
        images: [],
        tags: [],
        image: null,
        brand_id: "",
      },
      add_errors: {},
      error: "",
      success: "",
      disabled: false,
      brands: [],
      is_unlimited: 0,
    };
  },
  created() {
    this.getCategories();
    this.getBrands();
  },
  methods: {
    getCategories() {
      return this.axios
        .get("store_categories", {
          params: {
            status: "on",
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.categories = data.result.store_categories;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.categories = [];
          }
        });
    },
    getBrands() {
      return this.axios
        .get("store_brands", {
          params: {
            status: "on",
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.brands = data.result.store_brands;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.brands = [];
          }
        });
    },
    addNewCategory() {
      this.category_disabled = true;
      let fd = new FormData();

      Object.keys(this.category_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.category_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.category_data[key][trKey]);
          });
        } else {
          fd.append(key, this.category_data[key]);
        }
      });
      return this.axios
        .post("store_categories/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCategories();
            this.category_data = {
              ar: {},
              en: {},
              parent_id: "",
            };
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.category_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.category_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    addProduct() {
      this.disabled = true;
      this.success = "";
      this.error = "";
      this.add_errors = {};
      let fd = new FormData();
      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          this.add_data[key] &&
            Object.keys(this.add_data[key]).forEach((trKey) => {
              fd.append(`${key}[${trKey}]`, this.add_data[key][trKey]);
            });
        } else if (key === "images") {
          this.add_data[key] &&
            this.add_data[key].forEach((image, idx) => {
              fd.append(`images[${idx}]`, image, image.name);
            });
        } else if (key === "image") {
          this.add_data[key] &&
            fd.append(`image`, this.add_data[key], this.add_data[key].name);
        } else if (["categories_ids", "tags"].includes(key)) {
          this.add_data[key] &&
            this.add_data[key].forEach((item, idx) => {
              fd.append(
                `${key}[${idx}]`,
                typeof item === "object" ? item.id : item
              );
            });
        } else if (key === "qty") {
          if (this.is_unlimited === 1) {
            fd.delete(key);
          } else {
            fd.append(key, this.add_data[key]);
          }
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("products/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.success = data.message;
            this.add_data = {
              ar: {},
              en: {},
              categories_ids: [],
              images: [],
              tags: [],
            };
            this.$router.push("/products");
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    dropControl(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files) {
        files.forEach((file) => {
          this.add_data.images.push(file);
        });
        e.currentTarget.classList.replace("border", "border-dashed");
      }
    },
    attachmentsUploader(e) {
      e.preventDefault();
      e.target.files.forEach((file) => {
        this.add_data.images.push(file);
      });
    },
    uploadMainImage(e) {
      e.preventDefault();
      this.add_data.image = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
  },
  components: {
    SweetModal,
    MultiListSelect,
    ModelListSelect,
    FileSystemHeader,
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
