<template>
  <section
    class="w-full sm:py-16 pt-16 px-8 bg-gray-800/20 overflow-y-auto h-screen fixed inset-0 z-20 sm:block !m-0"
    ref="sweetAlert"
  >
    <transition name="move">
      <main
        class="w-full sm:max-w-xl bg-white block mx-auto rounded-xl relative transition-all"
      >
        <header
          class="p-4 border-b border-gray-100 flex items-center gap-2 w-full"
          v-if="close"
        >
          <div class="flex-1">
            <p class="text-yellow-600 font-semibold rtl:font-bold">
              {{ title }}
            </p>
          </div>
          <div>
            <button
              class="py-1 p-3 text-gray-600"
              @click.prevent="$emit('close', false)"
            >
              <i class="fa-solid fa-times"></i>
            </button>
          </div>
        </header>

        <main class="w-full h-auto">
          <slot name="default"></slot>
        </main>
      </main>
    </transition>
  </section>
</template>
<script>
export default {
  name: "SweetModal",
  props: {
    close: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (e.target === this.$refs.sweetAlert) {
        this.$emit("close", false);
      }
    });
  },
};
</script>
