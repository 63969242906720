<template>
  <transition name="fade">
    <div v-if="load">
      <div
        class="fixed inset-0 bg-gray-800/20 flex items-center justify-center z-20"
      >
        <div
          class="w-28 h-28 rounded-lg bg-white/60 p-4 flex items-center justify-center"
        >
          <svg
            width="47"
            height="66"
            viewBox="0 0 47 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="loadingIcon"
          >
            <path
              d="M43.7256 10.5161H45.2256V9.01615V3.98389V2.48389H43.7256C40.4597 2.48389 37.7368 2.67842 35.5879 3.08836C33.5487 3.43582 31.8956 3.7908 30.6543 4.15859L29.5804 4.47678V5.59679V30.5645C29.5804 34.4297 28.7106 36.9086 27.2977 38.3551L27.2854 38.3677L27.2733 38.3806C25.8645 39.8926 23.7918 40.7419 20.8224 40.7419C17.2955 40.7419 14.9187 39.805 13.402 38.1854C11.8948 36.573 11.032 34.1532 11.032 30.6936C11.032 28.3107 11.3952 25.8966 12.1301 23.4472L12.5675 21.989L11.1031 21.5732L5.87725 20.0893L4.52002 19.7039L4.05233 21.035C3.46816 22.6977 3.01803 24.3848 2.70267 26.0957C2.38545 27.7736 2.22559 29.4357 2.22559 31.0807C2.22559 33.8961 2.6565 36.436 3.55864 38.6699C4.44815 40.9153 5.70485 42.825 7.3427 44.3665L7.35059 44.3739L7.35859 44.3812C9.02933 45.9088 11.0009 47.0439 13.2512 47.794L13.26 47.7969C15.5378 48.5407 18.02 48.9032 20.6933 48.9032C23.1973 48.9032 25.5146 48.5399 27.6289 47.7909C29.8048 47.0784 31.6986 45.9548 33.2859 44.4156C34.8809 42.8689 36.0916 40.9552 36.9346 38.7092C37.7892 36.4757 38.1933 33.8778 38.1933 30.9516V11.0708C38.5843 10.9859 39.0407 10.9022 39.5666 10.8213L39.5943 10.817L39.6218 10.8117C40.6089 10.6219 41.9659 10.5161 43.7256 10.5161ZM19.7838 63.0943C20.8304 62.1131 21.2901 60.8042 21.2901 59.3548C21.2901 57.9054 20.8304 56.5966 19.7838 55.6154C18.803 54.6959 17.5916 54.2419 16.2417 54.2419C14.8918 54.2419 13.6804 54.6959 12.6997 55.6154C11.6531 56.5966 11.1933 57.9054 11.1933 59.3548C11.1933 60.8042 11.6531 62.1131 12.6997 63.0943C13.6804 64.0138 14.8918 64.4677 16.2417 64.4677C17.5916 64.4677 18.803 64.0138 19.7838 63.0943ZM34.6225 63.0943C35.6691 62.1131 36.1288 60.8042 36.1288 59.3548C36.1288 57.9054 35.6691 56.5966 34.6225 55.6154C33.6417 54.6959 32.4303 54.2419 31.0804 54.2419C29.7305 54.2419 28.5191 54.6959 27.5384 55.6154C26.4918 56.5966 26.032 57.9054 26.032 59.3548C26.032 60.8042 26.4918 62.1131 27.5384 63.0943C28.5191 64.0138 29.7305 64.4677 31.0804 64.4677C32.4303 64.4677 33.6417 64.0138 34.6225 63.0943Z"
              stroke="#FB923C"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "LoadingComponent",
  computed: {
    load() {
      if (!["Auth", "Error"].includes(this.$route.name)) {
        return this.$store.state.isLoading;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.loadingIcon {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: loading 2s linear infinite;
  -webkit-animation: loading 2s linear infinite;
}
@keyframes loading {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 400;
  }
}
@-webkit-keyframes loading {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 400;
  }
}
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */
</style>
