<template>
  <div>
    <div class="py-10 table mx-auto text-center" v-if="!load">
      <i class="fa-solid fa-spinner fa-spin text-3xl text-yellow-500"></i>
      <p class="text-gray-600 text-sm font-medium rtl:font-semibold mt-2">
        جاري تجهيز البيانات
      </p>
    </div>
    <section
      class="w-full py-10 px-6 space-y-6"
      v-if="(components ? components.length !== 0 : false) && load"
    >
      <main class="w-full container space-y-4" ref="product">
        <!-- small items -->
        <div class="w-full bg-white rounded-sm">
          <header class="w-full p-4 border-b border-gray-100">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              تسوق قوالب المنتجات
            </h1>
            <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
              تظهر هنا جميع عناصر المنتجات المتاحه بامكانك اختيار العناصر
              المناسب واعد تخصيص ال CSS المناسب له كما تريد
            </p>
          </header>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 p-4"
          >
            <figure
              class="w-full border border-gray-100 relative bg-white item-component"
              v-for="(comp, idx) in components.filter((e) =>
                /\\*product/gi.test(e)
              )"
              :key="idx"
              :data-index="idx"
              :data-name="comp"
            >
              <header class="w-full bg-gray-100 p-3">
                <div class="checkbox">
                  <div>
                    <input
                      type="radio"
                      name="productGroup"
                      :id="`product_${comp}`"
                      @change="
                        changeCurrentComponent(
                          $event,
                          idx,
                          comp,
                          'product',
                          'product'
                        )
                      "
                      :checked="comp === product.current_name"
                    />
                  </div>
                  <label
                    :for="`product_${comp}`"
                    class="flex-1 text-sm font-medium capitalize"
                    >{{ getName(comp) }}</label
                  >
                </div>
              </header>
              <blockquote class="p-4">
                <component
                  :is="importComponent(comp)"
                  :product="product.product"
                ></component>
              </blockquote>
            </figure>
          </div>
        </div>
        <div
          class="w-full flex flex-col lg:flex-row gap-4"
          v-if="product.current_name"
        >
          <div class="flex-1 w-full lg:max-w-xs">
            <figure class="w-full bg-white rounded-sm">
              <header class="w-full p-4 border-b border-gray-100">
                <h1
                  class="text-base font-semibold rtl:font-bold text-yellow-600"
                >
                  معاينة التغييرات
                </h1>
                <p
                  class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600"
                >
                  تظهر هنا معاينة القالب بعد التعديل
                </p>
              </header>
              <blockquote class="w-full p-6">
                <component :is="`style`" lang="scss">
                  {{ product.content }}
                </component>

                <component
                  :is="importComponent(product.current_name)"
                  :product="product.product"
                  class="product-preview"
                  :data-type="product.current_name"
                  v-if="product.current_name"
                ></component>
                <div class="w-full max-w-xs mx-auto space-y-4" v-else>
                  <header
                    class="w-full bg-gray-100 flex items-center p-4 gap-4"
                  >
                    <div>
                      <div
                        class="w-6 h-6 rounded-full bg-gray-300 animate-pulse"
                      ></div>
                    </div>
                    <div
                      class="w-3/5 h-3 rounded-sm bg-gray-300 animate-pulse"
                    ></div>
                  </header>
                </div>
              </blockquote>
            </figure>
          </div>

          <div class="flex-1">
            <figure class="w-full bg-white rounded-sm">
              <header class="w-full p-4 border-b border-gray-100">
                <div class="flex items-center gap-4">
                  <div class="flex-1">
                    <h1
                      class="text-base font-semibold rtl:font-bold text-yellow-600"
                    >
                      محرر الاكواد
                    </h1>
                    <div class="grid">
                      <p
                        class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600 truncate"
                      >
                        بامكانك تخصيص كود العنصر وتغيير شكله الى الشكل المناسب
                        لك ليتم عرضه في متجرك
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      class="relative w-8 h-8 rounded-full flex items-center justify-center text-blue-500 bg-blue-500/20 text-xs"
                      @click.prevent="openInfo"
                    >
                      <span
                        class="w-full h-full rounded-full bg-blue-500/50 absolute inset-0 animate-ping block"
                      ></span>
                      <i class="fa-solid fa-info"></i>
                    </button>
                  </div>
                </div>
              </header>
              <figcaption
                class="w-full h-0 max-h-screen opacity-0 overflow-hidden transition-all origin-center"
              >
                <div class="w-full bg-yellow-600/10 p-4">
                  <p
                    class="text-gray-500 text-left text-sm font-medium font-fire"
                    dir="ltr"
                    v-html="getComments(product.comments)"
                  ></p>
                </div>
              </figcaption>
              <blockquote class="w-full">
                <div
                  class="grid w-full my-4 px-4"
                  v-if="
                    product.tagNames ? product.tagNames.length !== 0 : false
                  "
                >
                  <div class="flex items-center gap-2 flex-wrap">
                    <div v-for="(item, index) in product.tagNames" :key="index">
                      <button
                        class="py-1.5 px-4 transition-all ease-in-out font-semibold text-xs flex items-center justify-center rounded-sm"
                        :data-type="item"
                        @click.prevent="
                          selectElement(item, 'product', 'product')
                        "
                        :class="{
                          'bg-yellow-600 text-white':
                            product.current_tag === item,
                          'bg-gray-300 text-gray-600':
                            product.current_tag !== item,
                        }"
                      >
                        {{ item }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="border-b border-t border-gray-200">
                  <codemirror
                    v-model="product.content"
                    ref="cssEditor"
                    :options="options"
                  />
                </div>
              </blockquote>
              <footer class="w-full p-4">
                <button
                  class="py-2 px-6 rounded-sm bg-blue-500 text-sm font-medium rtl:font-semibold text-white"
                  :disabled="header.add_disabled"
                  @click.prevent="updateTemplate('product')"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="header.add_disabled"
                  ></i>
                  حفظ التغييرات
                </button>
              </footer>
            </figure>
          </div>
        </div>
        <!-- ./small items -->
      </main>
      <main class="w-full container space-y-4" ref="header">
        <!-- small items -->
        <div class="w-full bg-white rounded-sm">
          <header class="w-full p-4 border-b border-gray-100">
            <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
              تسوق قوالب الـ header
            </h1>
            <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
              تظهر هنا جميع عناصر الـ header المتاحه بامكانك اختيار العناصر
              المناسب واعد تخصيص ال CSS المناسب له كما تريد
            </p>
          </header>
          <div class="space-y-4 p-4">
            <figure
              class="w-full border border-gray-100 relative bg-white item-component"
              v-for="(comp, idx) in components.filter((e) =>
                /\\*header/gi.test(e)
              )"
              :key="idx"
              :data-index="idx"
              :data-name="comp"
            >
              <header class="w-full bg-gray-100 p-3">
                <div class="checkbox">
                  <div>
                    <input
                      type="radio"
                      name="headerGroup"
                      :id="`header_${comp}`"
                      @change="
                        changeCurrentComponent(
                          $event,
                          idx,
                          comp,
                          'header',
                          'header'
                        )
                      "
                      :checked="comp === header.current_name"
                    />
                  </div>
                  <label
                    :for="`header_${comp}`"
                    class="flex-1 text-sm font-medium capitalize"
                    >{{ getName(comp) }}</label
                  >
                </div>
              </header>
              <blockquote>
                <component
                  :is="importComponent(comp)"
                  :product="header.product"
                ></component>
              </blockquote>
            </figure>
          </div>
        </div>
        <div class="w-full flex flex-col gap-4" v-if="header.current_name">
          <div class="flex-1 w-full">
            <figure class="w-full bg-white rounded-sm">
              <header class="w-full p-4 border-b border-gray-100">
                <h1
                  class="text-base font-semibold rtl:font-bold text-yellow-600"
                >
                  معاينة التغييرات
                </h1>
                <p
                  class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600"
                >
                  تظهر هنا معاينة القالب بعد التعديل
                </p>
              </header>
              <blockquote class="w-full">
                <component :is="`style`" lang="scss">
                  {{ header.content }}
                </component>

                <component
                  :is="importComponent(header.current_name)"
                  :product="header.product"
                  class="header-preview"
                  :data-type="header.current_name"
                  v-if="header.current_name"
                ></component>
                <div class="w-full max-w-xs mx-auto space-y-4 p-6" v-else>
                  <header
                    class="w-full bg-gray-100 flex items-center p-4 gap-4"
                  >
                    <div>
                      <div
                        class="w-6 h-6 rounded-full bg-gray-300 animate-pulse"
                      ></div>
                    </div>
                    <div
                      class="w-3/5 h-3 rounded-sm bg-gray-300 animate-pulse"
                    ></div>
                  </header>
                </div>
              </blockquote>
            </figure>
          </div>

          <div class="flex-1">
            <figure class="w-full bg-white rounded-sm">
              <header class="w-full p-4 border-b border-gray-100">
                <div class="flex items-center gap-4">
                  <div class="flex-1">
                    <h1
                      class="text-base font-semibold rtl:font-bold text-yellow-600"
                    >
                      محرر الاكواد
                    </h1>
                    <div class="grid">
                      <p
                        class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600 truncate"
                      >
                        بامكانك تخصيص كود العنصر وتغيير شكله الى الشكل المناسب
                        لك ليتم عرضه في متجرك
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      class="relative w-8 h-8 rounded-full flex items-center justify-center text-blue-500 bg-blue-500/20 text-xs"
                      @click.prevent="openInfo"
                    >
                      <span
                        class="w-full h-full rounded-full bg-blue-500/50 absolute inset-0 animate-ping block"
                      ></span>
                      <i class="fa-solid fa-info"></i>
                    </button>
                  </div>
                </div>
              </header>
              <figcaption
                class="w-full h-0 max-h-screen opacity-0 overflow-hidden transition-all origin-center"
              >
                <div class="w-full bg-yellow-600/10 p-4">
                  <p
                    class="text-gray-500 text-left text-sm font-medium font-fire"
                    dir="ltr"
                    v-html="getComments(header.comments)"
                  ></p>
                </div>
              </figcaption>
              <blockquote class="w-full">
                <div
                  class="grid w-full my-4 px-4"
                  v-if="header.tagNames ? header.tagNames.length !== 0 : false"
                >
                  <div class="flex items-center gap-2 flex-wrap">
                    <div v-for="(item, index) in header.tagNames" :key="index">
                      <button
                        class="py-1.5 px-4 transition-all ease-in-out font-semibold text-xs flex items-center justify-center rounded-sm"
                        :data-type="item"
                        @click.prevent="selectElement(item, 'header', 'header')"
                        :class="{
                          'bg-yellow-600 text-white':
                            header.current_tag === item,
                          'bg-gray-300 text-gray-600':
                            header.current_tag !== item,
                        }"
                      >
                        {{ item }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="border-b border-t border-gray-200">
                  <codemirror
                    v-model="header.content"
                    ref="cssEditor"
                    :options="options"
                  />
                </div>
              </blockquote>
              <footer class="w-full p-4">
                <button
                  class="py-2 px-6 rounded-sm bg-blue-500 text-sm font-medium rtl:font-semibold text-white"
                  :disabled="header.add_disabled"
                  @click.prevent="updateTemplate('header')"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="header.add_disabled"
                  ></i>
                  حفظ التغييرات
                </button>
              </footer>
            </figure>
          </div>
        </div>
        <!-- ./small items -->
      </main>
    </section>
  </div>
</template>
<script>
// import myComponents from "@/components/templates/components";
export default {
  name: "CustomizingPage",
  data() {
    return {
      components: [],
      load: false,
      options: {
        // theme: "material-palenight",
        // theme: "mdn-like",
        tabSize: 2,
        autofocus: false,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/css",
        matchBrackets: true,
        autoCloseBrackets: true,
        showHint: true,
      },
      product: {
        content: "",
        product: {
          image: null,
          price: 100,
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          title: "What is Lorem Ipsum?",
        },
        tagNames: [],
        current_tag: "",
        current_name: "",
        comments: [
          `/* \n * There is no need to click on the save shortcuts because there is an automatic save that works \n * You must have enough knowledge about CSS to start customizing items \n * learn more about css https://www.w3schools.com/css/ \n * Use !important before semicolon ; to run the code successfully \n * element { color: red !important; } \n * You must add .preview before any element to see results in selected item only\n * .preview element { color: red !important; } \n */`,
        ],
        add_disabled: false,
      },
      header: {
        content: "",
        product: {
          image: null,
          price: 100,
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          title: "What is Lorem Ipsum?",
        },
        tagNames: [],
        current_tag: "",
        current_name: "",
        comments: [
          `/* \n * There is no need to click on the save shortcuts because there is an automatic save that works \n * You must have enough knowledge about CSS to start customizing items \n * learn more about css https://www.w3schools.com/css/ \n * Use !important before semicolon ; to run the code successfully \n * element { color: red !important; } \n * You must add .preview before any element to see results in selected item only\n * .preview element { color: red !important; } \n */`,
        ],
        add_disabled: false,
      },
      customize_data: {},
    };
  },
  created() {
    try {
      const myComponents = require("@/components/templates/components").default;
      Object.keys(myComponents).reduce((obj, name) => {
        this.components.push(name);
      }, {});
    } catch (error) {
      this.$router.go(-1);
    }
    this.getCustomizeTemplate();
  },

  methods: {
    async getCustomizeTemplate() {
      await this.axios
        .get("settings/template_customize")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            document.documentElement.style.setProperty(
              "--main-color",
              `${this.convertHex(data.result.theme_bg_color || "#000000").join(
                " "
              )}`
            );
            document.documentElement.style.setProperty(
              "--content-color",
              `${this.convertHex(
                data.result.theme_font_color || "#ffffff"
              ).join(" ")}`
            );
            let customize_data = data.result.template_customize;
            if (customize_data) {
              this.customize_data = customize_data;
              Object.keys(customize_data).forEach((key) => {
                this[key].content = customize_data[key].css;
                this[key].current_name = customize_data[key].value;
                setTimeout(() => {
                  this.changeListStyle(customize_data[key].value, key);
                }, 100);
                this.getElements(key, key);
              });
            } else {
              this.customize_data = {
                header: {
                  value: "advancedHeader",
                  css: ".product-preview",
                },
                product: {
                  value: "basicProduct",
                  css: ".product-preview",
                },
              };
              Object.keys(this.customize_data).forEach((key) => {
                this[key].content = this.customize_data[key].css;
                this[key].current_name = this.customize_data[key].value;
                setTimeout(() => {
                  this.changeListStyle(this.customize_data[key].value, key);
                }, 100);
                this.getElements(key, key);
              });
            }
          }
        })
        .catch((response) => {
          console.log(response);
        })
        .finally(() => (this.load = true));
    },

    getElements(parentRef, objType) {
      let tags = [];
      this[objType].current_tag = null;
      setTimeout(() => {
        const previewChildren = this.$refs[parentRef].querySelectorAll(
          `.${objType}-preview[data-type="${this[objType].current_name}"] *`
        );
        previewChildren.forEach((child) => {
          tags.push(child.tagName);
        });
        this[objType].tagNames = [...new Set(tags)];
      }, 100);
    },
    selectElement(name, parentRef, objType) {
      this[objType].current_tag = name;
      const previewElement = this.$refs[parentRef].querySelectorAll(
        `.${objType}-preview[data-type="${this[objType].current_name}"] *`
      );
      // loop all items inside preview container tp check if highligh box existed to no
      previewElement.forEach((e) => {
        if (
          e.classList.contains("highlight-box") ||
          e.parentElement.classList.contains("highlight-box")
        ) {
          // remove all highlight box's inside all preview box items and remove relative class name of their parent element;
          e.remove();
          if (e.parentElement) {
            e.parentElement.classList.remove("relative");
          }
        }
      });
      const selectedItems = this.$refs[parentRef].querySelectorAll(
        `.${objType}-preview ${name}`
      );
      selectedItems.forEach((e) => {
        let styles = e.getBoundingClientRect();
        let parentStyles = this.$refs[parentRef]
          .querySelector(`.${objType}-preview`)
          .getBoundingClientRect();
        let computedStyles = window.getComputedStyle(e);
        let properties = {
          top: styles.top - parentStyles.top + "px",
          left: styles.left - parentStyles.left + "px",
          width: styles.width + "px",
          height: styles.height + "px",
        };
        if (["IMG", "INPUT"].includes(name)) {
          if (computedStyles.position !== "absolute") {
            e.parentElement.classList.add("relative");
          }
          let highlighter = document.createElement("DIV");
          highlighter.classList.add("highlight-box");
          highlighter.dataset.tag = name;
          e.parentElement.append(highlighter);
        } else {
          if (computedStyles.position !== "absolute") {
            e.classList.add("relative");
          }
          let highlighter = document.createElement("DIV");
          highlighter.classList.add("highlight-box");
          highlighter.dataset.tag = name;
          e.append(highlighter);
        }

        // check if current tag name of element existed to content in style element, if true replace it from content
        if (
          new RegExp(
            `.${objType}-preview ${name.toLowerCase()} {}\n\n`,
            "gi"
          ).test(this[objType].content)
        ) {
          this[objType].content.replace(
            new RegExp(
              `.${objType}-preview ${name.toLowerCase()} {}\n\n`,
              "gi"
            ),
            ""
          );
        } else {
          this[
            objType
          ].content += `.${objType}-preview ${name.toLowerCase()} {}\n\n`;
        }
      });
    },
    changeCurrentComponent(e, index, component, parentRef, objType) {
      if (e) {
        this[objType].current_name = component;
        this[objType].content = "";
        this.changeListStyle(component, parentRef);
        this.getElements(parentRef, objType);
      }
    },
    changeListStyle(component, parentRef) {
      const componentList =
        this.$refs[parentRef].querySelectorAll(".item-component");
      componentList.forEach((p) => {
        p.classList.replace("border-yellow-400", "border-gray-100");
        p.children[0].classList.replace("bg-yellow-400", "bg-gray-100");
      });
      const currentElementParent = this.$refs[parentRef].querySelector(
        `[data-name="${component}"]`
      );
      if (currentElementParent) {
        currentElementParent.classList.replace(
          "border-gray-100",
          "border-yellow-400"
        );
        currentElementParent.children[0].classList.replace(
          "bg-gray-100",
          "bg-yellow-400"
        );
      }
    },
    openInfo(e) {
      const parentElem =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement;
      const figInfo = parentElem.querySelector("figcaption");

      if (figInfo.classList.contains("opacity-0")) {
        figInfo.classList.remove(...["opacity-0", "h-0"]);
        figInfo.classList.add(...["h-auto"]);
      } else {
        figInfo.classList.add(...["opacity-0", "h-0"]);
        figInfo.classList.remove(...["h-auto"]);
      }
    },
    getName(name) {
      let final = name
        .replace(/(product|header|footer|banner)/gi, "")
        .replace(/[A-Z]/g, (e) => {
          return ` ${e.toLowerCase()}`;
        });
      return final;
    },
    convertHex(hex) {
      return hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));
    },
    importComponent(name) {
      if (name) {
        let component =
          require(`@/components/templates/components/${name}.vue`).default;
        return component;
      }
    },
    getComments(comments) {
      let regex = /(https?:\/\/[^\s]+)/g;

      return comments
        .join("")
        .replace(regex, function (url) {
          return (
            '<a class="text-yellow-500 italic" href="' +
            url +
            '">' +
            url +
            "</a>"
          );
        })
        .replace(/\n/g, "<br />");
    },
    // update button
    updateTemplate(type) {
      this[type].add_disabled = true;
      let content = JSON.parse(JSON.stringify(this[type].content));
      let convertContent = content.replace(/preview\s/g, "item ");
      this.axios
        .post("settings/update_template_customize", {
          template_customize: {
            ...this.customize_data,
            [type]: {
              value: this[type].current_name,
              css: convertContent,
            },
          },
        })
        .then(({ data }) => {
          // console.log(data);
          if (data.error_flag === 0) {
            this.getCustomizeTemplate();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          this[type].add_disabled = false;
        });
    },
  },
};
</script>

<style lang="scss">
.CodeMirror {
  font-family: "Fira Code", "Cairo", sans-serif !important;
  @apply text-sm;
  .CodeMirror-line {
    padding-inline: 5px !important;
  }
  .CodeMirror-hscrollbar,
  .CodeMirror-scrollbar-filler,
  .CodeMirror-vscrollbar {
    z-index: 1;
  }
}
.highlight-box {
  @apply w-full h-full rounded-sm border-2 border-dashed border-yellow-500 absolute inset-0 pointer-events-none;
  &::before {
    content: attr(data-tag);
    @apply absolute bottom-full left-0 rtl:right-0 rtl:left-auto bg-yellow-600 text-white font-medium py-1 px-2 rounded-t-sm text-xs whitespace-nowrap;
    animation: moveTop 0.2s linear;
    -webkit-animation: moveTop 0.2s linear;
    -moz-animation: moveTop 0.2s linear;
    -ms-animation: moveTop 0.2s linear;
    @keyframes moveTop {
      0% {
        @apply opacity-0 bottom-[calc(100%-10px)];
      }
      100% {
        @apply opacity-100;
      }
    }
  }
}
</style>
