<template>
  <figure class="w-full mx-auto outside-product-hover">
    <figcaption class="w-full relative">
      <img
        :src="product.image || require('@/assets/images/product.png')"
        alt="product image"
        class="object-contain max-h-[17rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
    </figcaption>
    <blockquote class="rounded-2xl pt-32 -mt-36 p-4 pb-8 transition-all">
      <div class="w-full relative pt-4 text-center space-y-2">
        <h1 class="font-medium rtl:font-semibold text-sm">
          {{ product.title }}
        </h1>
        <p
          class="font-medium rtl:font-semibold text-sm text-gray-500 line-clamp-2"
        >
          {{ product.content }}
        </p>
        <h5 class="text-color text-sm font-medium">
          {{ product.price.toFixed(2) }}
          <span class="line-through text-gray-500">{{
            product.price.toFixed(2)
          }}</span>
        </h5>
        <button
          class="py-2.5 px-6 rounded-lg text-center text-xs bg-color text-content font-medium mx-auto flex gap-2 items-center justify-center !mt-4"
        >
          <i class="fa-solid fa-basket-shopping"></i>
          <span>Add to cart</span>
        </button>
      </div>
    </blockquote>
  </figure>
</template>
<script>
export default {
  name: "outsideProduct",
  props: ["product"],
};
</script>
<style lang="scss">
.outside-product-hover {
  &:hover {
    blockquote {
      @apply bg-color;
    }
    h1,
    p,
    h5,
    h5 span {
      color: #ffffff !important;
    }
    button {
      @apply bg-white text-color;
    }
  }
}
</style>
