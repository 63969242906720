var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.dateActive)?_c('span',{staticClass:"date-line",attrs:{"data-title":_vm._f("moment")(_vm.message.created_at,'MMMM Do YYYY')}},[_c('span',{staticClass:"bg-white relative table mx-auto px-2 z-[1]"},[_vm._v(_vm._s(_vm._f("moment")(_vm.message.created_at,"MMMM Do YYYY")))])]):_vm._e(),_c('div',{staticClass:"table max-w-[80%] message-item",class:{
      'mr-auto rtl:mr-0 rtl:ml-auto': _vm.message.sender === 'store_admin',
      'ml-auto rtl:ml-0 rtl:mr-auto': _vm.message.sender === 'admin',
    },attrs:{"data-message":_vm.message.id}},[(!_vm.isSame && _vm.message.sender === 'admin')?_c('div',{staticClass:"flex items-center gap-2 mb-2",class:{
        'flex-row': _vm.message.sender === 'store_admin',
        'flex-row-reverse': _vm.message.sender === 'admin',
      }},[_c('div',[_c('div',{staticClass:"w-8 h-8"},[(_vm.message.sender === 'admin')?_c('img',{staticClass:"w-full h-full rounded-full object-cover border border-gray-100",attrs:{"src":_vm.message.sender === 'store_admin' ? _vm.avatar : _vm.admin_avatar}}):_vm._e()])]),(_vm.message.sender === 'admin')?_c('div',[_c('h1',{staticClass:"font-bold text-sm"},[_vm._v(" "+_vm._s(_vm.message.sender === "store_admin" ? _vm.message.store_admin.name : _vm.message.admin.name)+" ")])]):_vm._e()]):_vm._e(),_c('div',{class:{
        'mr-auto rtl:mr-0 rtl:ml-auto': _vm.message.sender === 'store_admin',
        'ml-auto rtl:ml-0 rtl:mr-auto pr-8 rtl:pr-0 rtl:pl-8':
          _vm.message.sender === 'admin',
      }},[_c('div',{staticClass:"table py-2 px-4 bg-gray-200 text-sm font-medium text-gray-600 rounded-md",class:{
          'bg-yellow-500 !text-white': _vm.message.sender === 'store_admin',
        },on:{"dblclick":function($event){$event.preventDefault();return _vm.openDetails(_vm.message.id)}}},[_vm._v(" "+_vm._s(_vm.message.body)+" ")]),_c('p',{staticClass:"text-xs text-gray-500 font-medium mt-1 px-2 hidden",class:{
          'text-left rtl:text-right': _vm.message.sender === 'store_admin',
          'text-right rtl:text-left': _vm.message.sender === 'admin',
        }},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.message.created_at,"hh:mma"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }