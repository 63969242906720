<template>
  <div class="w-full space-y-6">
    <form class="w-full" ref="social_form">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            نطاق مخصص
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="w-full space-y-3">
            <label
              for="custom_domain"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              اسم النطاق
            </label>
            <input
              type="url"
              name="custom_domain"
              id="custom_domain"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="custom_data.custom_domain"
              placeholder="https://example.com"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (custom_errors.custom_domain || []).join(" ") }}
            </span>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="custom_disabled"
        @click.prevent="updateCustomDomain"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="custom_disabled"></i>
        حفظ التغييرات
      </button>
    </form>
    <form class="w-full" ref="social_form">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            نطاق فرعي
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="w-full space-y-3">
            <label
              for="sub_domain"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              اسم النطاق
            </label>
            <input
              type="text"
              name="sub_domain"
              id="sub_domain"
              class="w-full py-3 px-4 rounded-t-lg bg-gray-100 placeholder:text-gray-500"
              v-model="sub_domain_data.sub_domain"
              placeholder="https://example.com"
            />
            <div
              class="w-full py-2 px-4 rounded-b-lg bg-gray-200 text-gray-500 text-xs font-semibold !mt-0"
            >
              {{ sub_domain_data.sub_domain }}.ia6g.com
            </div>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (sub_domain_errors.sub_domain || []).join(" ") }}
            </span>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="sub_domain_disabled"
        @click.prevent="updateSubDomain"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="sub_domain_disabled"></i>
        حفظ التغييرات
      </button>
    </form>
  </div>
</template>
<script>
export default {
  name: "Domains",
  data() {
    return {
      custom_disabled: false,
      custom_data: {},
      custom_errors: {},
      sub_domain_disabled: false,
      sub_domain_data: {},
      sub_domain_errors: {},
    };
  },
  created() {
    this.getDomains();
  },
  methods: {
    getDomains() {
      return this.axios
        .get("settings/domain")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.custom_data = { custom_domain: data.result.custom_domain };
            this.sub_domain_data = { sub_domain: data.result.sub_domain };
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.custom_data = {};
            this.sub_domain_data = {};
          }
        });
    },
    updateCustomDomain() {
      this.custom_disabled = true;
      this.custom_errors = {};
      return this.axios
        .post("settings/update_custom_domain", this.custom_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getDomains();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.custom_errors = message;
            } else {
              this.createdAlert("success", message);
            }
          }
        })
        .finally(() => {
          this.custom_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateSubDomain() {
      this.sub_domain_disabled = true;
      this.sub_domain_errors = {};
      return this.axios
        .post("settings/update_sub_domain", this.sub_domain_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getDomains();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.sub_domain_errors = message;
            } else {
              this.createdAlert("success", message);
            }
          }
        })
        .finally(() => {
          this.sub_domain_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
