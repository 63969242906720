<template>
  <form class="w-full max-w-xl mx-auto space-y-6 mt-10">
    <header class="w-full max-w-lg table mx-auto relative !my-10">
      <span
        class="w-[calc(100%-7rem)] bg-gray-200 h-px absolute top-7 left-1/2 -translate-x-1/2 z-[-1]"
      ></span>
      <div class="flex items-start justify-between gap-4 relative">
        <div>
          <div
            class="w-14 h-14 rounded-full flex items-center justify-center bg-yellow-600 text-white mx-auto"
          >
            <i class="fa-solid fa-user"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-800 table mx-auto mt-2"
          >
            بيانات صاحب المتجر
          </span>
        </div>
        <div>
          <div
            class="w-14 h-14 rounded-full border border-gray-200 text-gray-400 flex items-center justify-center bg-white mx-auto"
          >
            <i class="fa-solid fa-store"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
          >
            بيانات المتجر
          </span>
        </div>
        <div>
          <div
            class="w-14 h-14 rounded-full border border-gray-200 text-gray-400 flex items-center justify-center bg-white mx-auto"
          >
            <i class="fa-solid fa-file"></i>
          </div>
          <span
            class="text-center font-medium text-xs text-gray-600 table mx-auto mt-2"
          >
            المستندات
          </span>
        </div>
      </div>
    </header>
    <p class="text-red-500 font-medium rtl:font-semibold text-center">
      {{ error }}
    </p>
    <div class="w-full space-y-3">
      <label
        for="name"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        الاسم بالكامل
      </label>
      <input
        type="text"
        name="name"
        id="name"
        placeholder="example example"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        autocomplete="off"
        v-model="add_data.vendor_name"
      />
      <p class="text-red-500 font-medium rtl:font-semibold">
        {{ (add_errors.vendor_name || []).join(" ") }}
      </p>
    </div>
    <div class="w-full space-y-3">
      <label
        for="email"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        البريد الالكتروني
      </label>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="example@example.com"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        autocomplete="off"
        v-model="add_data.email"
      />
      <p class="text-red-500 font-medium rtl:font-semibold">
        {{ (add_errors.email || []).join(" ") }}
      </p>
    </div>
    <div class="w-full space-y-3">
      <label
        for="national_id"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        رقم الهوية
      </label>
      <input
        type="text"
        name="national_id"
        id="national_id"
        placeholder="xxxxxxxxxxxxxxx"
        class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded-md"
        autocomplete="off"
        v-model="add_data.national_id"
      />
      <p class="text-red-500 font-medium rtl:font-semibold">
        {{ (add_errors.national_id || []).join(" ") }}
      </p>
    </div>
    <div class="w-full space-y-3">
      <label
        for="mobile"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        رقم الجوال
      </label>
      <vue-tel-input
        id="mobile"
        ref="registerMobile"
        v-model="add_data.mobile"
        @open="handleOpen('registerMobile')"
      ></vue-tel-input>
      <p class="text-red-500 font-medium rtl:font-semibold">
        {{ (add_errors.mobile || []).join(" ") }}
      </p>
    </div>
    <div class="w-full space-y-3">
      <label
        for="password"
        class="font-medium rtl:font-semibold text-gray-600 text-sm"
      >
        كلمة المرور
      </label>
      <div class="relative">
        <input
          type="password"
          name="password"
          id="password"
          placeholder="••••••••"
          v-model="add_data.password"
          class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium pr-12 rtl:pr-4 rtl:pl-4 rounded-md"
          autocomplete="off"
        />
        <button
          class="w-12 h-full flex items-center justify-center text-gray-500 text-sm absolute top-0 right-0 rtl:left-0 rtl:right-auto"
          type="button"
          @click.prevent="textPasswordType"
        >
          <i class="fa-solid fa-eye"></i>
        </button>
      </div>
      <p class="text-red-500 font-medium rtl:font-semibold">
        {{ (add_errors.password || []).join(" ") }}
      </p>
    </div>

    <div class="w-full space-y-4">
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-yellow-500 text-gray-800 text-center font-medium rtl:font-semibold"
        @click.prevent="AddNewVendor"
        :disabled="add_disabled"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
        التالي
      </button>
      <button
        class="w-full h-auto py-3 px-4 rounded-md bg-transparent text-yellow-500 text-center font-medium rtl:font-semibold"
        @click.prevent="$emit('action', 'login')"
      >
        لدي حساب بالفعل
      </button>
    </div>
  </form>
</template>
<script>
export default {
  name: "register",
  data() {
    return {
      add_data: {
        vendor_name: "",
        email: "",
        mobile: "",
        password: "",
      },
      add_disabled: false,
      error: "",
      add_errors: {},
    };
  },
  // watch: {
  //   "add_data.email"() {
  //     if (this.validate(this.add_data.email)) {
  //       delete this.add_errors.email;
  //     } else {
  //       this.add_errors.email = "Email address doesn't match";
  //     }
  //   },
  // },
  created() {
    Object.assign(this.add_data, this.$store.state.vendor_data);
  },
  methods: {
    checkEnteredData() {
      let isNotEmpty = Object.keys(this.add_data).every((key) => {
        if (["vendor_name", "email", "mobile", "password"].includes(key)) {
          if (key === "email") {
            return this.validate(this.add_data[key]);
          } else {
            return this.add_data[key];
          }
        } else {
          return true;
        }
      });
      if (isNotEmpty) {
        this.$store.commit("SET_REGISTER_DATA", this.add_data);
        this.$emit("action", "store");
        this.error = "";
      } else {
        this.error =
          "Something went wrong please check all required fields and try again.";
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    validate(email) {
      return new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email);
    },
    AddNewVendor() {
      this.add_disabled = true;
      this.error = "";
      this.add_errors = {};
      let formData = {
        ...this.add_data,
        mobile: this.add_data.mobile.replace(/\s/g, "").replace("+", ""),
        form_step: 1,
      };
      return this.axios
        .post("register", formData)
        .then(({ data }) => {
          if (data.error_flag == 0) {
            this.$store.commit("SET_REGISTER_DATA", this.add_data);
            this.$emit("action", "store");
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          // this.$store.commit("SET_REGISTER_DATA", this.add_data);
        });
    },
  },
};
</script>
