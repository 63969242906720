<template>
  <section class="w-full p-6">
    <main class="xl:container mx-auto space-y-6">
      <div class="w-full bg-white rounded-xl">
        <header class="border-b border-gray-200 p-5">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            اختر باقة من الباقات الاتية التي تناسب احتياجك حتى تتمكن من إنشاء
            حملات الرسائل النصية
          </h1>
        </header>
        <div class="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <figure
            class="w-full py-10 px-6 rounded-xl border border-gray-100 text-center"
          >
            <div
              class="w-14 h-14 bg-yellow-600/10 text-yellow-600 flex items-center justify-center mx-auto rounded-full mb-4 text-lg"
            >
              <i class="fa-solid fa-comment"></i>
            </div>
            <h2 class="text-gray-600 text-base font-semibold rtl:font-bold">
              الرسائل النصية للحملات 1000
            </h2>
            <h1
              class="text-yellow-600 text-xl font-semibold rtl:font-bold my-3"
            >
              120 ريال
            </h1>
            <button
              class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block mx-auto"
            >
              اختار الباقة
            </button>
          </figure>
          <figure
            class="w-full py-10 px-6 rounded-xl border border-gray-100 text-center"
          >
            <div
              class="w-14 h-14 bg-yellow-600/10 text-yellow-600 flex items-center justify-center mx-auto rounded-full mb-4 text-lg"
            >
              <i class="fa-solid fa-comments"></i>
            </div>
            <h2 class="text-gray-600 text-base font-semibold rtl:font-bold">
              الرسائل النصية للحملات 5000
            </h2>
            <h1
              class="text-yellow-600 text-xl font-semibold rtl:font-bold my-3"
            >
              600 ريال
            </h1>
            <button
              class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block mx-auto"
            >
              اختار الباقة
            </button>
          </figure>
          <figure
            class="w-full py-10 px-6 rounded-xl border border-gray-100 text-center"
          >
            <div
              class="w-14 h-14 bg-yellow-600/10 text-yellow-600 flex items-center justify-center mx-auto rounded-full mb-4 text-lg"
            >
              <i class="fa-solid fa-comments-dollar"></i>
            </div>
            <h2 class="text-gray-600 text-base font-semibold rtl:font-bold">
              الرسائل النصية للحملات 10000
            </h2>
            <h1
              class="text-yellow-600 text-xl font-semibold rtl:font-bold my-3"
            >
              1200 ريال
            </h1>
            <button
              class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block mx-auto"
            >
              اختار الباقة
            </button>
          </figure>
        </div>
      </div>
      <div class="w-full bg-white rounded-xl">
        <header class="border-b border-gray-200 p-5">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            اسم المرسل
          </h1>
        </header>
        <div class="w-full p-5 space-y-4">
          <h1 class="text-base font-semibold rtl:font-bold text-gray-800">
            اسم المرسل الحالي
            <span class="text-yellow-600"> MeStore </span>
          </h1>
          <div class="space-y-1">
            <h2 class="text-base font-semibold rtl:font-bold text-gray-800">
              تخصيص اسم المرسل
            </h2>
            <p class="text-base font-medium rtl:font-semibold text-gray-600">
              يمكنك تخصيص اسم المرسل الذي يظهر لعملائك برسوم منفصلة عن رسوم شحن
              الرصيد
            </p>
          </div>
          <div class="!mt-8 flex space-y-4">
            <div
              class="w-auto py-3 px-4 rounded-xl bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold flex items-center gap-6 text-sm"
            >
              <i class="fa-solid fa-circle-exclamation"></i>
              لتخصيص اسم المرسل يشترط وجود سجل تجاري وختم باسم المؤسسة
            </div>
          </div>
          <div class="flex space-y-4">
            <div
              class="w-auto py-3 px-4 rounded-xl bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold flex items-center gap-6 text-sm"
            >
              <i class="fa-solid fa-circle-exclamation"></i>
              تفعيل الخدمة لايتم بعد الدفع مباشرة وإنما يستلزم تعبئة نموذج
              وموافقة فريق زد على التفعيل.
            </div>
          </div>
          <div class="w-full !mt-8">
            <ul class="list-none space-y-1">
              <li
                class="flex items-start justify-between gap-4 flex-wrap py-2 px-3"
              >
                <div class="flex-1">
                  <div class="checkbox">
                    <div>
                      <input type="radio" name="check_group" id="check_1" />
                    </div>
                    <label
                      for="check_1"
                      class="text-base font-medium rtl:font-semibold text-gray-600"
                    >
                      إسم مرسل للرسائل النصية - السعودية فقط
                    </label>
                  </div>
                </div>
                <div class="space-y-1">
                  <h1
                    class="text-sm font-semibold rtl:font-bold text-yellow-600"
                  >
                    230.00 SAR
                  </h1>
                  <h2 class="font-semibold rtl:font-bold text-gray-600 text-xs">
                    شامل الضريبة
                  </h2>
                </div>
              </li>
              <li
                class="flex items-start justify-between gap-4 flex-wrap py-2 px-3"
              >
                <div class="flex-1">
                  <div class="checkbox">
                    <div>
                      <input type="radio" name="check_group" id="check_2" />
                    </div>
                    <label
                      for="check_2"
                      class="text-base font-medium rtl:font-semibold text-gray-600"
                    >
                      إسم مرسل للرسائل النصية - دول الخليج بدون السعودية
                    </label>
                  </div>
                </div>
                <div class="space-y-1">
                  <h1
                    class="text-sm font-semibold rtl:font-bold text-yellow-600"
                  >
                    230.00 SAR
                  </h1>
                  <h2 class="font-semibold rtl:font-bold text-gray-600 text-xs">
                    شامل الضريبة
                  </h2>
                </div>
              </li>
              <li
                class="flex items-start justify-between gap-4 flex-wrap py-2 px-3"
              >
                <div class="flex-1">
                  <div class="checkbox">
                    <div>
                      <input type="radio" name="check_group" id="check_3" />
                    </div>
                    <label
                      for="check_3"
                      class="text-base font-medium rtl:font-semibold text-gray-600"
                    >
                      إسم مرسل للرسائل النصية - السعودية و دول الخليج
                    </label>
                  </div>
                </div>
                <div class="space-y-1">
                  <h1
                    class="text-sm font-semibold rtl:font-bold text-yellow-600"
                  >
                    230.00 SAR
                  </h1>
                  <h2 class="font-semibold rtl:font-bold text-gray-600 text-xs">
                    شامل الضريبة
                  </h2>
                </div>
              </li>
            </ul>
          </div>
          <button
            class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
          >
            اطلب الخدمة
          </button>
        </div>
      </div>
    </main>
  </section>
</template>
<script>
export default {
  name: "SmsPricing",
};
</script>
