import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  ar: {
    app_name: "متجري",
    pages: {
      home: "الرئيسية",
      auth: "التسجيل",
      orders: "الطلبات",
      products: "المنتجات",
      products_categories: "اقسام المنتجات",
      create_product: "انشاء منتج",
      update_product: "تحديث المنتج",
      customers: "العملاء",
      create_customer: "انشاء عميل",
      update_customer: "تحديث بيانات العميل",
      branches: "الفروع والمستودعات",
      create_branch: "انشاء فرع",
      update_branch: "تحديث الفرع",
      seo: "محركات البحث",
      settings: "الاعدادات",
      pages: "الصفحات الفرعية",
      create_page: "انشاء صفحة",
      update_page: "تحديث الصفحة",
      page_not_found: "لم يتم العثور على الصفحة الحالية",
      abandoned_carts: "السلات المتروكة",
      coupons: "الكوبونات",
      error: "خطأ ما حدث",
      incomes: "الفواتير",
      incomes_history: "سجل الدخل",
      brands: "العلامات التجارية",
      packages: "الباقات",
      email_templates: "اشعارات البريد الالكتروني",
      analytics: "التحليلات",
      stock: "المخزون",
      customizing: "تخصيص القالب",
      categories: "الاقسام",
      shopping_list: "قائمة التسوق",
      view_store: "عرض صفحة المتجر",
      design_options: "خيارات تصميم المتجر",
    },
    menu: "القائمة",
    banner: {
      title: "مرحبا",
      content:
        "هناك العديد من الطلبات في انتظارك ، اسرع في قبولها لزيادة المبيعات في متجرك.",
      morning: "صباح الخير",
      evening: "مساء الخير",
    },
    reports: {
      title: "التقارير",
      content: "مخلص لما حدث في بقالاتك",
    },
    table: {
      name: "الاسم",
      client: "العميل",
      product: "المنتج",
      products: "المنتجات",
      products_count: "عدد المنتجات",
      orders_count: "عدد الطلبات",
      store: "المتجر",
      price: "السعر",
      latest_update: "اخر تحديث",
      created_at: "تاريخ الانشاء",
      status: "الحالة",
      category: "التصنيف",
      sku: "SKU",
      qty: "الكمية",
      actions: "الاجراءات",
      total: "الاجمالي",
      subtotal: "المجوع الفرعي",
      vat: "الضريبة",
      delivery_fees: "رسوم التوصيل ",
      day: "اليوم",
      sales_count: "عدد المبيعات",
      client_info: "بيانات العميل",
      payment_opt_meth: "طرق الدفع والسداد",
      supplier: "المورد",
      total_qty: "اجمالي الكمية",
      order_no: "رقم الطلب",
      notes: "الملاحظات",
      address: "العنوان",
      brand: "العلامة التجارية",
      payment_details: "تفاصيل الدفع",
      discount: "الخصم",
    },

    btn: {
      edit: "تعديل",
      delete: "حذف",
      change_status: "تغيير الحالة",
      checkout: "اتمام الشراء",
      pay: "دفع",
      submit: "ارسال",
      save: "حفظ",
      save_changes: "حفظ التغييرات ",
      add: "اضافة",
      cancel: "الغاء",
      update: "تحديث",
      select_all: "تحديد الكل ",
      view: "عرض",
    },
    status: {
      on: "نشط",
      off: "غير نشط",
      delivering: "جاري التوصيل",
      completed: "مكتمل",
      new: "جديد",
      preparing: "جاري التجهيز",
      cancelled: "ملغي",
      ready: "جاهز",
      success: "مفعل",
      pending: "قيد المراجعة",
      rejected: "مرفوض",
    },
    add_btn: {
      new_baqala: "اضافة بقالة جديدة",
      new_product: "اضافة منتج جديد",
      new_cashier: "اضافة صراف جديد",
      to_cart: "اضف الي السلة",
      add_cart: "اضافة منتج الى السلة",
    },
    no_data: {
      products: "لا توجد منتجات.",
      orders: "لا توجد طلبات.",
      sales: "لا توجد مبيعات.",
      cashier: "لا يوجد صرافين.",
      suppliers: "لا يوجد موردين.",
      cart: "قم بمتابعة الشراء واضف منتجات الى السلة",
      cart_title: "السلة فارغة",
      data: "لا توجد بيانات",
      notes: "لا توجد ملاحظات وردت من العميل.",
    },
    form: {
      name: "الاسم",
      logo: "الشعار",
      upload: "رفع (.png,.jpg,.jpeg)",
      baqala: "البقالة",
      product: "المنتج",
      sale_price: "سعر البيع",
      price: "السعر",
      qty: "الكمية",
      sku: "SKU",
      photo: "صورة شخصية",
      email: "البريد الالكتروني",
      mobile: "رقم الجوال",
      password: "كلمة المرور",
      current_password: "كلمة المرور الحالية ",
      confirmation_password: "تاكيد كلمة المرور ",
    },
    delete: {
      title: "هل انت متاكد؟",
      content:
        "أنت على وشك حذف هذا العنصر ، وسيتم حذف هذا العنصر من القائمة. إذا كنت متأكدًا ، فانقر فوق زر الحذف أدناه",
    },
    invoice: "الفاتورة",
    client_notes: "ملاحظات العميل",
    shipping_way: "تفاصيل الشحن و الدفع",
    supplier_info: "بيانات المورد",
    payment_option: "خيارت الدفع",
    order_history: "سجل الطلبات",
    order_details: "بيانات الطلب",
    recent_orders: "احدث مبيعات اليوم",
    wanted_products: "المنتجات الاكثر طلبا",
    search: "بحث..",
    new_order: "طلب جديد",
    view_more: "عرض المزيد ",
    view_products: "عرض المنتجات",
    edit_profile: "تعديل الملف الشخصي",
    logout: "تسجيل خروج",
    status_title: "الحالة",
    next: "التالية",
    prev: "السابقة",
    personal_data_content:
      "يمكنك تغيير معلومات حسابك [الاسم ، البريد الإلكتروني ، الجوال] على هذه الصفحة.",
    sar: "ريال",
    week_days: {
      mon: "الاثنين",
      tue: "الثلاثاء",
      wed: "الاربعاء",
      thu: "الخميس",
      fri: "الجمعة",
      sat: "السبت",
      sun: "الاحد",
    },
    templates: {
      food: "قالب مطاعم",
      classic: "قالب افتراضي",
    },
    drop: {
      export: "تصدير الكل",
      import: "استيراد",
      send: "ارسال عبر البريد الالكتروني",
    },
    all: "الكل",
    monthly: "شهري",
    yearly: "سنوي",
  },
  en: {
    app_name: "Matgary",
    pages: {
      home: "Home",
      auth: "Auth",
      orders: "Orders",
      products: "Products",
      products_categories: "Products categories",
      create_product: "Create product",
      update_product: "Update product",
      customers: "Customers",
      create_customer: "Create customer",
      update_customer: "Update customer",
      branches: "Branches and Warehoueses",
      create_branch: "Create branch",
      update_branch: "Update branch",
      seo: "Seo",
      settings: "Settings",
      pages: "Pages",
      create_page: "Create page",
      update_page: "Update page",
      page_not_found: "Page not found",
      error: "Error",
      abandoned_carts: "ABandoned carts",
      coupons: "Coupons",
      incomes: "Invoices",
      incomes_history: "Incomes history",
      brands: "Brands",
      packages: "Packages",
      email_templates: "Email templates",
      analytics: "Analytics",
      stock: "Stock",
      customizing: "Customizing template",
      categories: "Categories",
      shopping_list: "Shopping list",
      view_store: "View store page",
      design_options: "Store design options",
    },
    menu: "Menu",
    banner: {
      title: "Welcome",
      content:
        "There are many orders waiting for you, hurry up to accept them to increase sales in your store.",
      morning: "Good morning",
      evening: "Good evening",
    },
    reports: {
      title: "Reports",
      content: "Summary of what happened in your baqala's",
    },
    table: {
      name: "Name",
      client: "Client",
      product: "Product",
      products: "Products",
      products_count: "Products count",
      orders_count: "Orders count",
      store: "Store",
      price: "Price",
      cashier: "Cashier",
      latest_update: "Latest update",
      created_at: "Created at",
      status: "Status",
      category: "Category",
      sku: "SKU",
      qty: "Quantity",
      actions: "Actions",
      total: "Total",
      subtotal: "Subtotal",
      vat: "VAT",
      delivery_fees: "Delivery fees",
      day: "Day",
      sales_count: "Sales count",
      client_info: "Client info",
      payment_opt_meth: "Payment method",
      total_qty: "Total quantitiy",
      order_no: "Order NO.",
      notes: "Notes",
      address: "Address",
      brand: "Brand",
      payment_details: "Payment details",
      discount: "Discount",
    },

    btn: {
      edit: "Edit",
      delete: "Delete",
      change_status: "Change status",
      checkout: "Checkout",
      pay: "Pay",
      submit: "Submit",
      save: "Save",
      save_changes: "Save changes",
      add: "Add",
      cancel: "Cancel",
      update: "Update",
      select_all: "Select all",
      view: "View",
    },
    status: {
      on: "On",
      off: "Off",
      delivering: "Delivering",
      completed: "Completed",
      new: "New",
      preparing: "Preparing",
      cancelled: "Cancelled",
      ready: "Ready",
      success: "Success",
      pending: "Pending",
      rejected: "Rejected",
    },
    add_btn: {
      new_baqala: "Add new Baqala",
      new_product: "Add new product",
      new_cashier: "Add new cashier",
      to_cart: "Add to cart",
      add_cart: "Add product to cart",
    },
    no_data: {
      products: "There are no products.",
      orders: "There are no orders.",
      sales: "There are no sales.",
      cashier: "There are no cashier.",
      suppliers: "There are no suppliers.",
      cart: "Continue shopping to add products to your cart.",
      cart_title: "Cart is empty",
      data: "There is no data",
      notes: "There are no notes received from client.",
    },
    form: {
      name: "Name",
      logo: "Logo",
      upload: "Upload (.png,.jpg,.jpeg)",
      baqala: "Baqala",
      product: "Product",
      sale_price: "Sale price",
      price: "Price",
      qty: "Quantity",
      sku: "SKU",
      photo: "Personal photo",
      email: "E-mail address",
      mobile: "Mobile number",
      password: "Password",
      current_password: "Current password",
      confirmation_password: "Confirmation password",
    },
    delete: {
      title: "Are you sure?",
      content:
        "You are about to delete that item, that item will be deleted from the list. If you are sure, click the delete button below",
    },
    invoice: "Invoice",
    client_notes: "Client notes",
    shipping_way: "Shipping and payment method",
    supplier_info: "Supplier info",
    payment_option: "Payment option",
    order_history: "Order history",
    order_details: "Order details",
    recent_orders: "Today's latest sales",
    wanted_products: "Most Wanted Products",
    search: "Search..",
    new_order: "New order",
    view_more: "View more",
    view_products: "View products",
    edit_profile: "Edit profile",
    logout: "Logout",
    status_title: "Status",
    next: "Next",
    prev: "Previous",
    personal_data_content:
      "You can change your account information [name, email, mobile] on this page.",
    sar: "SAR",
    week_days: {
      mon: "Mon",
      tue: "Tue",
      wed: "Wed",
      thu: "Thu",
      fri: "Fri",
      sat: "Sat",
      sun: "Sun",
    },

    templates: {
      food: "Food template",
      classic: "Default template",
    },
    drop: {
      export: "Export all",
      import: "Import",
      send: "Send via E-mail",
    },
    all: "All",
    add_new: "Add new",
    monthly: "Monthly",
    yearly: "Yearly",
  },
};

const i18n = new VueI18n({
  locale: "ar",
  fallbackLocale: "ar",
  messages,
});

export default i18n;
