<template>
  <div>
    <section class="py-10 px-6 space-y-6">
      <FileSystemHeader>
        <template #title>الفواتير</template>
      </FileSystemHeader>
      <header
        class="p-5 flex sm:items-center gap-2 flex-wrap w-full relative flex-col sm:flex-row xl:container rounded-xl bg-white"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              :placeholder="$t('search')"
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
      </header>
      <main class="w-full xl:container rounded-xl overflow-x-auto bg-white">
        <table class="styled-table">
          <thead>
            <tr>
              <th colspan="2" class="bg-gray-200">
                <h1 class="text-gray-800 font-bold text-xl">
                  {{ $t("pages.incomes_history") }}
                </h1>
              </th>
              <th class="space-y-2 bg-gray-200">
                <p class="text-gray-500 font-medium text-sm">
                  {{ $t("table.total") }}
                  <span class="text-sx">({{ $t("sar") }})</span>
                </p>
                <h1 class="text-gray-800 font-bold text-xl">
                  {{ total }}
                </h1>
              </th>
            </tr>
          </thead>
          <tbody v-if="load && history.length !== 0">
            <tr v-for="(item, index) in history" :key="index" class="bg-white">
              <td class="space-y-2">
                <p class="text-gray-500 font-medium text-sm">
                  {{ item.day }}
                </p>
                <h1 class="text-gray-800 font-bold">
                  {{ item.date }}
                </h1>
              </td>
              <td class="space-y-2">
                <p class="text-gray-500 font-medium text-sm">
                  {{ $t("table.orders_count") }}
                  <router-link
                    :to="`/incomes/${item.date}/orders`"
                    class="text-blue-600 text-sm"
                  >
                    {{ $t("view_more") }}
                  </router-link>
                </p>
                <h1 class="text-gray-800 font-bold text-xl">
                  {{ item.orders_count }}
                </h1>
              </td>

              <td class="space-y-2">
                <p class="text-gray-500 font-medium text-sm">
                  {{ $t("table.total") }}
                  <span class="text-sx">({{ $t("sar") }})</span>
                </p>
                <h1 class="text-gray-800 font-bold text-xl">
                  {{ item.total }}
                </h1>
              </td>
            </tr>
          </tbody>
          <tbody v-if="load && history.length === 0">
            <tr>
              <td class="space-y-2" colspan="3">
                <p class="text-gray-500 font-medium text-sm text-center p-6">
                  {{ $t("no_data.data") }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <tr v-for="tr in 4" :key="tr">
              <td class="space-y-2" v-for="i in 3" :key="i">
                <div
                  class="w-1/2 h-3 rounded-md bg-gray-100 animate-pulse"
                ></div>
                <div
                  class="w-full h-3 rounded-md bg-gray-100 animate-pulse"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="$t('prev')"
          :next-text="$t('next')"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>
<script>
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  components: { FileSystemHeader },
  name: "IncomesIndex",
  data() {
    return {
      search_key: "",
      search_disabled: false,
      currentPage: 1,
      totalPages: 1,
      active: false,
      history: [],
      load: false,
      total: 0,
    };
  },
  created() {
    this.getHistory();
  },
  watch: {
    currentPage() {
      this.getHistory();
    },
  },
  methods: {
    getHistory() {
      return this.axios
        .get("incomes", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.history = data.result.daily_incomes.data;
            this.totalPages = data.result.daily_incomes.pagination.total_pages;
            this.total = data.result.total;
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {},
  },
};
</script>
