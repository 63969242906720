export default {
  basicProduct: "@/components/basics/templates/components/basicProduct.vue",
  foodProduct: "@/components/basics/templates/components/foodProduct.vue",
  proProduct: "@/components/basics/templates/components/proProduct.vue",
  advancedProduct:
    "@/components/basics/templates/components/advancedProduct.vue",
  hoveredProduct: "@/components/basics/templates/components/hoveredProduct.vue",
  transparentProduct:
    "@/components/basics/templates/components/transparentProduct.vue",
  outsideProduct: "@/components/basics/templates/components/outsideProduct.vue",
  advancedHeader: "@/components/basics/templates/components/advancedHeader.vue",
  skewHeader: "@/components/basics/templates/components/skewHeader.vue",
  searchDownHeader:
    "@/components/basics/templates/components/searchDownHeader.vue",
  withLocationHeader:
    "@/components/basics/templates/components/withLocationHeader.vue",
};
