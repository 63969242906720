<template>
  <div>
    <header class="w-full sticky top-0 px-6">
      <div
        class="w-full xl:container border-b border-gray-100 flex items-center justify-between gap-3 py-4"
      >
        <div>
          <div class="w-full">
            <img
              src="@/assets/images/logo-ipsum.png"
              alt="store logo"
              class="max-w-[10rem] max-h-9 object-contain align-middle"
            />
          </div>
        </div>
        <nav class="hidden lg:block flex-1">
          <ul class="list-none flex items-center justify-center gap-6 mx-auto">
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
              >
                <span>Home</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="#"
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
              >
                <span>Products</span>
              </router-link>
            </li>
            <li>
              <button
                class="relative text-gray-600 font-medium rtl:font-semibold flex items-center gap-2 text-sm"
                active-class="text-color"
                @click="openCategoriesList"
              >
                <span>Categories</span>
                <i class="fa-solid fa-angle-down"></i>
              </button>
            </li>
          </ul>
        </nav>

        <div class="block lg:hidden">
          <button
            class="w-10 h-10 flex items-center justify-center rounded-md text-gray-600 font-medium rtl:font-semibold text-sm text-center"
          >
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
        <div class="hidden lg:block">
          <button
            class="w-10 h-10 rounded-full text-gray-600 bg-gray-100 text-sm"
          >
            <i class="fa-regular fa-user"></i>
          </button>
        </div>
        <div class="hidden lg:block">
          <button class="w-10 h-10 rounded-full text-gray-600 text-sm relative">
            <span
              class="px-1.5 absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 bg-color rounded-full text-[10px] text-content"
              >+99</span
            >
            <i class="fa-solid fa-shopping-cart"></i>
          </button>
        </div>
      </div>
      <div class="w-full xl:container border-b border-gray-100 py-4">
        <div class="md:max-w-screen-md mx-auto">
          <div
            class="w-full relative bg-gray-100 rounded-full border border-gray-200"
          >
            <input
              type="text"
              name="search"
              id="search"
              class="w-full py-3 px-4 pr-12 rtl:pl-12 rtl:pr-6 bg-transparent font-medium text-sm"
              placeholder="Search in products.."
            />
            <div
              class="absolute flex items-center justify-center w-12 h-full right-0 rtl:right-auto rtl:left-0 top-0 text-sm text-gray-500"
            >
              <i class="fa-solid fa-search"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full xl:container overflow-hidden opacity-0 pointer-events-none invisible h-0 max-h-screen hidden lg:block"
        ref="category"
      >
        <nav class="w-full">
          <ul class="list-none w-full inline-flex flex-wrap gap-4">
            <li class="p-3 flex items-center gap-3" v-for="i in 20" :key="i">
              <div class="relative text-gray-600">
                <span
                  class="absolute bottom-0 -left-2 rtl:left-auto rtl:-right-2 w-6 h-6 rounded-full bg-gray-200"
                ></span>
                <span class="z-[1] inline-block relative"
                  >{{ i > 9 ? i : "0" + i }}.</span
                >
              </div>
              <div class="flex-1">
                <router-link
                  to="#"
                  class="text-gray-600 font-medium rtl:font-semibold text-sm"
                >
                  Category item name
                </router-link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "searchDownHeader",
  methods: {
    openCategoriesList() {
      let list = this.$refs.category;
      if (list.classList.contains("h-0")) {
        list.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.add(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      } else {
        list.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "h-0"]
        );
        list.classList.remove(
          ...["opacity-100", "pointer-events-auto", "visible", "py-6"]
        );
      }
    },
  },
};
</script>
<style lang="scss"></style>
