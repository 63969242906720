<template>
  <div class="w-full py-10 px-6 space-y-7">
    <FileSystemHeader>
      <template #default>
        <router-link to="/incomes">الدخل والفواتير</router-link> /
      </template>
      <template #title>الطلبات</template>
    </FileSystemHeader>
    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header
        class="p-5 border-b border-gray-200 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              :placeholder="$t('search')"
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
      </header>
      <div class="w-full pb-5 overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>رقم الطلب</th>
              <th>المنتجات</th>
              <th>العنوان</th>
              <th>السعر</th>
              <th>الحالة</th>
              <th>اخر تحديث</th>
              <th>الاجراءات</th>
            </tr>
          </thead>
          <tbody v-if="orders.length > 0 && load">
            <tr v-for="(order, index) in orders" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ order.order_no }}
              </td>
              <td>
                {{ order.products.length }}
              </td>
              <td>
                {{ order.shipping_address }}
              </td>
              <td>
                {{ Number(order.sub_total).toFixed(2) }}
              </td>
              <td>
                <span class="inline-block py-1 5 px-3 rounded-lg bg-color">
                  {{ order.status }}
                </span>
              </td>

              <td>
                <div>
                  {{ order.updated_at | moment("from") }}
                </div>
                <div>
                  <span dir="ltr" class="text-gray-600 text-xs font-medium">{{
                    order.updated_at | moment(" h:mm a . MMMM Do YYYY")
                  }}</span>
                </div>
              </td>

              <td>
                <div class="actions-group">
                  <div>
                    <router-link
                      :to="`/orders/${order.id}`"
                      class="py-1.5 px-3 rounded-lg bg-green-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="عرض"
                    >
                      <i class="fa-solid fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <TableLoad v-for="i in 4" :key="i" :rows="8" />
          </tbody>
          <tbody v-if="load && orders.length === 0">
            <tr>
              <td colspan="9" class="!text-center">لا توجد بيانات</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="$t('prev')"
          :next-text="$t('next')"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
    <!-- modal -->
    <transition name="move">
      <section
        v-if="order_modal"
        class="w-full pt-24 p-6 bg-white fixed z-10 min-h-screen inset-0 overflow-y-auto"
      >
        <button
          class="absolute top-7 left-1/2 -translate-x-1/2 w-10 h-10 rounded-full border border-gray-200"
          @click="order_modal = !order_modal"
        >
          <i class="fa-solid fa-times"></i>
        </button>
        <div
          class="xl:container mx-auto w-full flex lg:flex-row flex-col flex-wrap gap-6"
        >
          <div class="flex-1 border border-gray-200 rounded-xl w-full">
            <header
              class="w-full p-5 flex items-center flex-wrap border-b border-gray-200"
            >
              <div>
                <p
                  class="text-yellow-600 text-sm font-medium rtl:font-semibold"
                >
                  رقم الطلب
                  <span class="text-gray-600">{{ order.order_no }}</span>
                </p>
              </div>
              <div class="flex-1 text-right rtl:text-left">
                <p class="text-gray-600 text-sm font-medium rtl:font-semibold">
                  {{ order.updated_at | moment("from") }}
                </p>
              </div>
            </header>
            <div class="w-full p-5">
              <h1
                class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
              >
                المنتجات <span>({{ order.products.length }})</span>
              </h1>
              <div
                class="w-full border border-gray-200 rounded-lg overflow-x-auto overflow-y-hidden mb-8"
              >
                <table class="styled-table">
                  <thead>
                    <tr>
                      <th>المنتج</th>
                      <th>رمز المنتج</th>
                      <th>الكمية</th>
                      <th>السعر</th>
                      <th>الاجمالي</th>
                    </tr>
                  </thead>
                  <tbody v-if="order.products.length > 0">
                    <tr v-for="(product, idx) in order.products" :key="idx">
                      <td>
                        <div class="flex items-center gap-4">
                          <div>
                            <div class="w-10 h-10">
                              <img
                                :src="product.image"
                                alt="product image"
                                class="w-full h-full rounded-full object-cover border border-gray-200 bg-gray-200"
                              />
                            </div>
                          </div>
                          <div class="flex-1">
                            <span
                              class="text-sm font-medium rtl:font-semibold max-w-[10rem] block truncate"
                            >
                              {{ product.name }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{{ product.sku }}</td>
                      <td>{{ product.qty }}</td>
                      <td>{{ product.price }}</td>
                      <td>
                        {{
                          Number(
                            Number(product.qty) * Number(product.price)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="!text-center">لا توجد منتجات</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h1
                class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
              >
                الفاتورة
              </h1>
              <div class="flex gap-4 flex-col md:flex-row mb-8">
                <div class="flex-1">
                  <div
                    class="w-full overflow-y-hidden overflow-x-auto border border-gray-200 rounded-xl"
                  >
                    <table class="styled-table">
                      <tbody>
                        <tr>
                          <td>قيمة المنتجات</td>
                          <td>{{ order.sub_total }}</td>
                        </tr>
                        <tr>
                          <td>التوصيل</td>
                          <td>{{ order.delivery_fees }}</td>
                        </tr>
                        <tr>
                          <td>المجموع الكلي</td>
                          <td>{{ order.total }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex-1 w-full md:max-w-sm">
                  <h1
                    class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
                  >
                    ملاحظات العميل حول الطلب
                  </h1>
                  <div class="w-full bg-yellow-600/10 p-5 rounded-lg mb-6">
                    <p
                      class="text-sm font-medium rtl:font-semibold text-gray-800"
                    >
                      {{ order.notes || "-" }}
                    </p>
                  </div>
                  <h1
                    v-if="false"
                    class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
                  >
                    تفاصيل الشحن و الدفع
                  </h1>
                  <table v-if="false" class="styled-table">
                    <tbody>
                      <tr>
                        <td>طريقة التوصيل</td>
                        <td>مندوب</td>
                      </tr>
                      <tr>
                        <td>طريقة الدفع</td>
                        <td>دفع عند الاستلام</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h1
                class="font-semibold rtl:font-bold text-yellow-600 mb-4 text-sm"
              >
                سجل تحديثات الطلب
              </h1>
              <div
                class="w-full border border-gray-200 rounded-lg overflow-x-auto overflow-y-hidden"
              >
                <table class="styled-table">
                  <thead>
                    <tr>
                      <th>حالة الطلب</th>
                      <th>التحديث</th>
                      <th>طريقة الدفع او السداد</th>
                      <th>خيار الدفع</th>
                      <th>الضريبة</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{{ order.status || "-" }}</th>
                      <th>{{ order.updated_at | moment("from") }}</th>
                      <th>{{ order.payment_method || "-" }}</th>
                      <th>{{ order.payment_option || "-" }}</th>
                      <th>{{ order.vat || "-" }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="flex-1 border border-gray-100 rounded-xl"></div> -->
          <div class="flex-1 w-full lg:max-w-md space-y-6">
            <div>
              <div class="w-full border border-gray-200 rounded-xl">
                <header class="w-full p-5 border-b border-gray-200">
                  <p
                    class="text-sm font-semibold rtl:font-bold text-yellow-600"
                  >
                    بيانات العميل
                  </p>
                </header>
                <div class="w-full p-5">
                  <ul class="list-none space-y-4">
                    <li>
                      <a
                        href="#"
                        class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                      >
                        <span>
                          <i class="fa-regular fa-user text-gray-600"></i>
                        </span>
                        <span class="flex-1"> {{ order.customer_name }} </span>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="`tel:${order.customer_mobile}`"
                        class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                      >
                        <span>
                          <i class="fa-solid fa-phone text-gray-600"></i>
                        </span>
                        <span class="flex-1">
                          {{ order.customer_mobile }}
                        </span>
                        <span>
                          <i
                            class="fa-solid fa-circle-check text-green-600"
                          ></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="`mailto:${order.customer_email}`"
                        class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                      >
                        <span> <i class="fa-solid fa-envelope"></i> </span>
                        <span class="flex-1"> {{ order.customer_email }} </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="flex items-center gap-4 text-sm font-medium rtl:font-semibold text-gray-800 px-2 py-1"
                      >
                        <span>
                          <i class="fa-solid fa-map-marker-alt"></i>
                        </span>
                        <span class="flex-1">
                          {{ order.shipping_address }}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </transition>
    <!-- ./modal -->
  </div>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "OrdersIncomes",
  components: { TableLoad, FileSystemHeader },
  data() {
    return {
      orders: [],
      load: false,
      order_modal: false,
      order: {},
      currentPage: 1,
      search_disabled: false,
      search_key: "",
      perPage: 0,
      rows: 0,
      totalPages: 0,
      order_disabled: false,
      order_status: {
        status: "",
        id: null,
      },
    };
  },
  created() {
    this.getOrders();
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
  methods: {
    getOrders() {
      return this.axios
        .get("orders", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            from: this.$route.params.date,
            to: this.$route.params.date,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.orders = data.result.orders.data;
            this.perPage = data.result.orders.pagination.per_page;
            this.rows = data.result.orders.pagination.total;
            this.totalPages = data.result.orders.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          const { error_flag } = response.data;
          if (error_flag === 1) {
            this.orders = [];
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    showModal(id) {
      this.order = { ...this.orders.find((order) => order.id === id) };
      this.order_status = {
        status: this.order.status,
        id: this.order.id,
      };
      this.order_modal = true;
    },
    updateOrderStatus() {
      this.order_disabled = true;
      return this.axios
        .post("orders/update_status", this.order_status)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getOrders();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.order_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getOrders().then(() => {
        this.search_disabled = false;
      });
    },
  },
};
</script>
