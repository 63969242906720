<template>
  <button
    class="w-full p-4 rounded-lg border border-gray-100 flex gap-4 transition-all relative items-center"
    :class="{
      'bg-yellow-600/10 border-yellow-600/30 hover:bg-yellow-600/20':
        isComponent,
    }"
    @click.prevent="changeComponents"
  >
    <div>
      <div
        class="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center text-gray-600"
        :class="{
          'bg-yellow-600/25 !text-yellow-600': isComponent,
        }"
      >
        <i :class="icon"></i>
      </div>
    </div>
    <div class="flex-1 space-y-1 rtl:text-right text-left">
      <h1 class="text-sm font-semibold rtl:font-bold text-yellow-600">
        {{ title }}
      </h1>
      <div class="grid">
        <p class="text-xs font-medium rtl:font-semibold text-gray-600 truncate">
          {{ content }}
        </p>
      </div>
    </div>
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    icon: {
      type: String,
    },
    component: {
      type: String,
    },
    isComponent: {
      type: Boolean,
    },
  },
  methods: {
    changeComponents() {
      this.$emit("change", this.component);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
