<template>
  <section class="w-full p-6 space-y-6">
    <FileSystemHeader>
      <template #default>
        <router-link to="/products">المنتجات</router-link> /
      </template>
      <template #title>التصنيفات</template>
    </FileSystemHeader>
    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header
        class="p-5 border-b border-gray-200 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="بحث في التصنيفات.."
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
        <div class="flex gap-2 flex-wrap">
          <div>
            <button
              class="py-2 px-4 rounded-lg bg-yellow-600/10 text-yellow-600 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
              @click="openCloseMoreBox"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
          </div>
        </div>
        <div
          class="absolute top-full right-0 rtl:right-auto rtl:left-0 w-full max-w-xs bg-white p-4 rounded-xl shadow-2xl z-[1] space-y-2 hidden v-dropdown"
          ref="moreBox"
        >
          <button
            @click.prevent="new_active = true"
            class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm block w-full"
          >
            <i class="fa-solid fa-plus"></i>
            اضافة تصنيف جديد
          </button>
        </div>
      </header>
      <div class="w-full pb-5 overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>الصورة</th>
              <th>الاسم</th>
              <th>تاريخ الانشاء</th>
              <th>الحالة</th>
              <th>الاجراءات</th>
            </tr>
          </thead>
          <tbody v-if="categories.length > 0 && load">
            <tr v-for="(category, index) in categories" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                <div class="w-10 h-10">
                  <img
                    :src="category.image"
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-lg border object-cover border-gray-100"
                  />
                </div>
              </td>
              <td>{{ category.name }}</td>
              <td>
                <span dir="ltr" class="text-left text-gray-600 font-medium">
                  {{ category.created_at | moment("h:mm a . MMMM Do YYYY") }}
                </span>
              </td>
              <td>
                <span
                  class="inline-block py-1.5 px-3 rounded-full text-sm text-white"
                  :class="
                    category.status === 'on' ? 'bg-blue-500' : 'bg-red-500'
                  "
                >
                  {{ category.status }}
                </span>
              </td>
              <td>
                <div class="actions-group">
                  <div>
                    <button
                      @click.prevent="updateData(category.id)"
                      class="py-2 px-3 rounded-lg bg-green-600 text-white text-center text-xs block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تعديل"
                    >
                      تعديل
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-blue-500/20 text-blue-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="تغيير الحالة"
                      @click.prevent="changeStatus(category.id, index)"
                      :disabled="status_disabled[index]"
                    >
                      <i
                        class="fa-solid fa-spinner fa-spin"
                        v-if="status_disabled[index]"
                      ></i>
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div>
                    <button
                      class="py-1.5 px-3 rounded-lg bg-red-500/20 text-red-500 text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                      data-title="حذف"
                      @click.prevent="showModal(category.id)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!load">
            <TableLoad :rows="6" v-for="i in 4" :key="i" />
          </tbody>
          <tbody v-if="load && categories.length === 0">
            <tr>
              <td colspan="6" class="!text-center">لا توجد بيانات</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && currentPage > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="'السابقة'"
          :next-text="'التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
    <SweetModal
      :close="false"
      @close="active = $event"
      class="!mt-0"
      v-if="active"
    >
      <template>
        <div class="p-6">
          <h1 class="font-bold text-lg text-gray-800 text-center mb-2">
            هل انت متاكد؟
          </h1>
          <p
            class="font-medium rtl:font-semibold text-base text-gray-600 text-center"
          >
            اذا كنت تريد حذف تلك المنتج هذا يعني ان لم يتم عرض اي محتوى لتلك
            المنتج في متجرك ولن يظهر لعملاء وبالتالي سيتم حذف الطلب من سلات
            التسوق للعملاء.
          </p>
          <p
            class="font-medium rtl:font-semibold text-sm text-red-500 text-center mt-4"
            v-if="error"
          >
            {{ error }}
          </p>

          <div class="mt-6 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="delete_disabled"
                @click.prevent="deleteItem"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="delete_disabled"
                ></i>
                حذف
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="
                  active = false;
                  category_id = null;
                "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      </template>
    </SweetModal>
    <SweetModal
      :close="true"
      v-if="edit_active"
      @close="edit_active = $event"
      class="!mt-0"
    >
      <template>
        <form class="w-full space-y-4 p-6">
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_ar"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | عربي
            </label>
            <input
              type="text"
              name="category_name_ar"
              id="category_name_ar"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="category_data.ar.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors["ar.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_en"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | انجليزي
            </label>
            <input
              type="text"
              name="category_name_en"
              id="category_name_en"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="category_data.en.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors["en.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="modal_category"
              class="font-medium rtl:font-semibold text-gray-600"
            >
              التصنيف
            </label>
            <select
              name="modal_category"
              id="modal_category"
              class="custom-select"
              v-model="category_data.parent_id"
            >
              <option value="" selected>-- اختار --</option>
              <option
                v-for="(catItem, index) in categories"
                :key="index"
                :value="catItem.id"
              >
                {{ catItem.name }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (category_errors.parent_id || []).join(" ") }}
            </span>
          </div>
          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="category_disabled"
                @click.prevent="updateCategory"
              >
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="category_disabled"
                ></i>
                حفظ
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="edit_active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
    <SweetModal
      :close="true"
      v-if="new_active"
      @close="new_active = $event"
      class="!mt-0"
    >
      <template>
        <form class="w-full space-y-4 p-6">
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_ar"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | عربي
            </label>
            <input
              type="text"
              name="category_name_ar"
              id="category_name_ar"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.ar.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["ar.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="flex-1 w-full space-y-3">
            <label
              for="category_name_en"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              اسم التصنيف | انجليزي
            </label>
            <input
              type="text"
              name="category_name_en"
              id="category_name_en"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.en.name"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["en.name"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="modal_category"
              class="font-medium rtl:font-semibold text-gray-600"
            >
              التصنيف
            </label>
            <select
              name="modal_category"
              id="modal_category"
              class="custom-select"
              v-model="add_data.parent_id"
            >
              <option value="" selected>-- اختار --</option>
              <option
                v-for="(catItem, index) in categories"
                :key="index"
                :value="catItem.id"
              >
                {{ catItem.name }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.parent_id || []).join(" ") }}
            </span>
          </div>
          <div class="mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="add_disabled"
                @click.prevent="addNewCategory"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                اضافة
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                @click.prevent="new_active = false"
              >
                الغاء
              </button>
            </div>
          </div>
        </form>
      </template>
    </SweetModal>
  </section>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "Categories",
  data() {
    return {
      load: false,
      categories: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      new_active: false,
      edit_active: false,
      category_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      add_data: {
        ar: {},
        en: {},
        parent_id: "",
      },
      add_errors: {},
      add_disabled: false,
      category_data: {
        ar: {},
        en: {},
        parent_id: "",
      },
      category_errors: {},
      category_disabled: false,
    };
  },
  created() {
    this.getCategories();
  },
  watch: {
    currentPage() {
      this.getCategories();
    },
  },
  methods: {
    getCategories() {
      return this.axios
        .get("store_categories", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.categories = data.result.store_categories.data;
            this.perPage = data.result.store_categories.pagination.per_page;
            this.rows = data.result.store_categories.pagination.total;
            this.totalPages =
              data.result.store_categories.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.categories = [];
          }
        })
        .finally(() => {
          this.load = true;
          this.search_disabled = false;
        });
    },
    openCloseMoreBox() {
      this.$refs.moreBox.classList.toggle("hidden");
    },
    showModal(id) {
      this.category_id = id;
      this.active = true;
    },
    changeStatus(id, i) {
      this.$set(this.status_disabled, i, true);
      return this.axios
        .post("store_categories/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCategories();
            this.categories.find((category) => category.id === id).status =
              data.result.status;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.status_disabled.splice(i, 1);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    deleteItem() {
      this.delete_disabled = true;
      return this.axios
        .post("store_categories/delete/" + this.category_id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCategories();
            this.categories.splice(
              this.categories.findIndex(
                (category) => category.id === this.category_id
              ),
              1
            );
            this.active = false;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.error = message;
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.delete_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },

    searchAll() {
      this.search_disabled = true;
      this.getCategories().then(() => {
        this.search_disabled = false;
      });
    },
    addNewCategory() {
      this.add_disabled = true;
      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.add_data[key][trKey]);
          });
        } else {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("store_categories/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCategories();
            this.new_active = false;
            this.add_data = {
              ar: {},
              en: {},
              parent_id: "",
            };
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateCategory() {
      this.category_disabled = true;
      let fd = new FormData();

      Object.keys(this.category_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.category_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.category_data[key][trKey]);
          });
        } else if (key === "image") {
          fd.delete("image");
        } else {
          fd.append(key, this.category_data[key]);
        }
      });

      return this.axios
        .post("store_categories/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getCategories();
            this.edit_active = false;
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.category_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.category_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateData(id) {
      this.category_data = {
        ...this.categories.find((category) => category.id === id),
      };
      this.edit_active = true;
    },
  },
  components: { TableLoad, SweetModal, FileSystemHeader },
};
</script>
