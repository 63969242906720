<template>
  <div class="w-full space-y-6">
    <form class="w-full space-y-6">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            الصور المتحركة
          </h1>
          <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
            التحكم في الصور المتحركة في صفحة المتجر الرئيسية
          </p>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="w-full space-y-3">
            <h1
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              ارفاق صورة
            </h1>
            <div v-if="slide_data.image" class="table">
              <div class="w-20 h-20 p-1 rounded-xl border-2 border-gray-200">
                <img
                  :src="convertImage(slide_data.image)"
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="upload"
                  class="w-full rounded-lg h-full object-cover"
                />
              </div>
              <button
                class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm z-[1] relative"
                @click.prevent="slide_data.image = null"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <label
              for="slide_image"
              class="bg-yellow-600/20 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
            >
              تحميل ( .png , .jpg )
            </label>
            <input
              id="slide_image"
              type="file"
              name="slide_image"
              hidden
              accept="image/*"
              class="hidden"
              @change.prevent="uploadMainImage($event, 'slide_data', 'image')"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (slide_errors.image || []).join(" ") }}
            </span>

            <div class="w-full" v-if="slide_load && slides.length !== 0">
              <div class="space-y-3">
                <figure
                  class="w-full p-4 flex gap-4 items-end border-b border-gray-100 last:border-0"
                  v-for="(slide, index) in slides"
                  :key="index"
                >
                  <div>
                    <div
                      class="w-20 h-20 p-1 rounded-xl border-2 border-gray-200"
                    >
                      <img
                        :src="slide.image"
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="upload"
                        class="w-full rounded-lg h-full object-cover"
                      />
                    </div>
                  </div>
                  <div class="flex-1 space-y-3">
                    <p
                      class="text-xs font-medium rtl:font-semibold text-gray-600"
                    >
                      {{ slide.updated_at | moment("from") }}
                      <span class="table text-gray-400 mt-1" dir="ltr">
                        {{ slide.updated_at | moment("h:mma . MMMM Do YYYY") }}
                      </span>
                    </p>
                    <div class="flex flex-wrap gap-3">
                      <div>
                        <button
                          class="py-2 px-4 rounded-md bg-green-500 text-white text-sm table"
                          @click.prevent="openUpdate(slide.id)"
                        >
                          تعديل
                        </button>
                      </div>
                      <div>
                        <button
                          class="py-2 px-4 rounded-md bg-transparent text-red-500 text-sm table"
                          data-title="حذف"
                          :disabled="delete_disabled[index]"
                          @click.prevent="deleteItem(slide.id, index)"
                        >
                          <i
                            class="fa-solid fa-spinner fa-spin"
                            v-if="delete_disabled[index]"
                          ></i>

                          <i class="fa-solid fa-trash" v-else></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="self-start">
                    <div
                      class="flex flex-col items-center justify-center gap-3"
                    >
                      <input
                        v-model="slide.status"
                        type="checkbox"
                        class="toggle-input"
                        :true-value="'on'"
                        :false-value="'off'"
                        @change="changeStatus(slide.id)"
                      />
                    </div>
                  </div>
                </figure>
              </div>
            </div>
            <div class="w-full space-y-4" v-if="!slide_load">
              <div class="space-y-3 px-4 py-3" v-for="l in 3" :key="l">
                <div
                  class="w-2/5 h-4 rounded-md bg-gray-200 animate-pulse"
                ></div>
                <div
                  class="w-4/5 h-3 rounded-md bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="slide_disabled"
        @click.prevent="addSlide"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="slide_disabled"></i>
        حفظ التغييرات
      </button>
    </form>
    <form class="w-full space-y-6" ref="theme">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            قوالب المتجر
          </h1>
          <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
            يمكن اختيار القالب المناسب لمتجرك من هنا او التواصل معنا اذا كنت
            تريد معرفة اذا هناك المزيد من القوالب قادمة ام لا.
          </p>
        </header>
        <blockquote
          class="w-full p-6 flex items-start flex-wrap gap-4"
          v-if="template_load && templates"
        >
          <div v-for="(template, idx) in templates" :key="idx">
            <div class="min-w-[12rem] max-w-[12rem]">
              <div
                class="w-full h-56 rounded-xl border-2 border-yellow-500 overflow-hidden cursor-pointer relative p-2"
              >
                <img
                  :src="template.src"
                  alt="template preview"
                  class="absolute top-0 right-0 left-0 w-full object-contain transition-all ease-in-out duration-[1.5s]"
                  @mouseover="scrollWhenHover"
                  @mouseleave="scrollWhenHover"
                />
                <span
                  v-if="template.label !== 'use'"
                  class="flag-ad"
                  :class="{
                    'flag-in-use': template.type === template_name,
                    'flag-new': ['new'].includes(template.label),
                    'flag-soon': ['soon'].includes(template.label),
                    'flag-end': ['end'].includes(template.label),
                  }"
                  :data-flag-title="
                    template.type === template_name ? 'use' : template.label
                  "
                ></span>
              </div>

              <div class="mt-2 p-2 space-y-3 text-center">
                <h1 class="text-sm font-semibold rtl:font-bold text-yellow-600">
                  {{ $t(`templates.${template.type}`) }}
                </h1>
                <button
                  class="px-4 py-2 mt-1 font-medium rtl:font-semibold text-xs text-gray-600 rounded-full border border-gray-200"
                  v-if="template.type !== template_name"
                  @click.prevent="chooseCurrentTemplate(template.type, idx)"
                  :disabled="template_disabled[idx]"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="template_disabled[idx]"
                  ></i>
                  تعيين كقالب افتراضي
                </button>
                <router-link
                  :to="`/settings/${template.type}/customization`"
                  class="px-4 py-2 mt-1 font-medium rtl:font-semibold text-xs text-yellow-500 rounded-full bg-yellow-500/10 table mx-auto"
                  v-if="template.type === template_name"
                >
                  { CSS }
                </router-link>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
    </form>
    <form class="w-full space-y-6" ref="theme">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            خيارات تصمم المتجر
          </h1>
          <p class="mt-1 font-medium rtl:font-semibold text-sm text-gray-600">
            التحكم في الوان المتجر
          </p>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="w-full space-y-3">
            <label
              for="bgColor"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              لون الخلفيات
            </label>
            <div class="w-full flex items-stretch gap-2">
              <div class="flex-1">
                <input
                  type="text"
                  name="bgColor"
                  id="bgColor"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.theme_bg_color"
                  placeholder="#rrggbb"
                />
              </div>
              <div>
                <div class="w-10 h-full">
                  <input
                    type="color"
                    class="w-full h-full rounded-lg bg-gray-100 placeholder:text-gray-500 p-1"
                    v-model="add_data.theme_bg_color"
                  />
                </div>
              </div>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.theme_bg_color || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="textColor"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              لون الخطوط داخل اللون الاساسي
            </label>
            <div class="w-full flex items-stretch gap-2">
              <div class="flex-1">
                <input
                  type="text"
                  name="textColor"
                  id="textColor"
                  class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                  v-model="add_data.theme_font_color"
                  placeholder="#rrggbb"
                />
              </div>
              <div>
                <div class="w-10 h-full">
                  <input
                    type="color"
                    class="w-full h-full rounded-lg bg-gray-100 placeholder:text-gray-500 p-1"
                    v-model="add_data.theme_font_color"
                  />
                </div>
              </div>
            </div>
            <p class="text-red-500 font-medium rtl:font-semibold">
              {{ (add_errors.theme_font_color || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="add_disabled"
        @click.prevent="updateTheme"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
        حفظ التغييرات
      </button>
    </form>

    <SweetModal
      :close="false"
      v-if="active"
      @close="active = $event"
      class="!mt-0"
    >
      <template #default>
        <div class="w-full space-y-4">
          <img
            :src="
              typeof update_data.image === 'object'
                ? convertImage(update_data.image)
                : update_data.image
            "
            @error="
              $event.target.src = require('@/assets/images/placeholder.png')
            "
            alt="preview image"
            class="w-full h-full max-h-60 rounded-xl object-contain"
          />
          <div class="table mx-auto">
            <label
              for="update_image"
              class="bg-yellow-600/20 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
            >
              تحميل ( .png , .jpg )
            </label>
            <input
              id="update_image"
              type="file"
              name="update_image"
              hidden
              accept="image/*"
              class="hidden"
              @change.prevent="uploadMainImage($event, 'update_data', 'image')"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (update_errors.image || []).join(" ") }}
            </span>
          </div>
          <div class="!mt-8 flex items-center justify-center flex-wrap gap-2">
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-yellow-600 text-white font-medium rtl:font-semibold text-sm"
                :disabled="
                  update_disabled || typeof update_data.image !== 'object'
                "
                @click.prevent="updateCurrentSlide"
              >
                <!-- @click.prevent="updateHyperConfig" -->
                <i
                  class="fa-solid fa-spinner fa-spin"
                  v-if="update_disabled"
                ></i>
                حفظ التغييرات
              </button>
            </div>
            <div>
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm"
                type="reset"
                @click.prevent="closePopup"
              >
                <!-- @click.prevent="closeHyper" -->
                الغاء
              </button>
            </div>
          </div>
        </div>
      </template>
    </SweetModal>
  </div>
</template>
<script>
import SweetModal from "../modals/sweetModal.vue";
import path from "path";
import fs from "fs";
export default {
  name: "customizingStore",
  data() {
    return {
      add_errors: {},
      add_data: {
        theme_bg_color: "#000000",
        theme_font_color: "#ffffff",
      },
      slide_data: {
        image: null,
      },
      slides: [],
      slide_errors: {},
      slide_disabled: false,
      slide_error: "",
      add_disabled: false,
      slide_load: false,
      delete_disabled: [],
      update_data: {},
      update_errors: {},
      update_disabled: false,
      active: false,
      templates: [],
      templates_set: [],
      template_load: false,
      template_name: "",
      template_disabled: [],
    };
  },
  created() {
    this.getSlides();
    this.getThemes();
    this.getTemplates();
  },

  methods: {
    async readFiles(files) {
      this.templates = [];
      await files.keys().forEach((key) => {
        let templateType = key
          .split("./")
          .join("")
          .split(/.template.*/)
          .join("");

        let obj = {
          src: files(key),
          origin: key,
          type: templateType.replace(/.use|.new|.end|.soon/gi, ""),
          label: templateType.split(".")[templateType.split(".").length - 1],
        };

        this.templates.push(obj);
      });
    },
    getThemes() {
      return this.axios.get("settings/theme").then(({ data }) => {
        if (data.error_flag === 0) {
          this.add_data = data.result;
        }
      });
    },
    getTemplates() {
      return this.axios
        .get("settings/template")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.template_name = data.result.template_name;
            this.readFiles(
              require.context(
                "@/assets/images/templates/",
                true,
                /[\.png\.jpg\.jpeg\.webp]$/
              )
            );
          }
        })
        .finally(() => (this.template_load = true));
    },
    getSlides() {
      return this.axios
        .get("store_slides")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.slides = data.result.store_slides;
          }
        })
        .finally(() => {
          this.slide_load = true;
        });
    },
    addSlide() {
      this.slide_disabled = true;
      let fd = new FormData();
      this.slide_errors = {};
      this.slide_error = "";
      Object.keys(this.slide_data).forEach((key) => {
        if (key === "image") {
          if (this.slide_data[key]) {
            fd.append(key, this.slide_data[key], this.slide_data[key].name);
          }
        }
      });
      return this.axios
        .post("store_slides/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.getSlides();
            this.slide_data.image = null;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.slide_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.slide_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updateCurrentSlide() {
      this.update_disabled = true;
      let fd = new FormData();
      this.update_errors = {};
      Object.keys(this.update_data).forEach((key) => {
        if (key === "image") {
          if (this.update_data[key]) {
            fd.append(key, this.update_data[key], this.update_data[key].name);
          }
        } else {
          fd.append(key, this.update_data[key]);
        }
      });
      return this.axios
        .post("store_slides/update", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.getSlides();
            this.active = false;
            this.update_data = {};
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.update_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.update_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    uploadMainImage(e, par, type) {
      e.preventDefault();
      this[par][type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    changeStatus(id) {
      return this.axios
        .post("store_slides/update_status/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getSlides();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        });
    },
    deleteItem(id, i) {
      this.$set(this.delete_disabled, i, true);
      return this.axios
        .post("store_slides/delete/" + id)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getSlides();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.delete_disabled.splice(i, 1);
        });
    },
    openUpdate(id) {
      this.update_data = JSON.parse(
        JSON.stringify(this.slides.find((slide) => slide.id === id))
      );
      this.active = true;
    },
    closePopup() {
      this.update_data = {};
      this.active = false;
    },
    updateTheme() {
      this.add_disabled = true;
      this.add_errors = {};
      return this.axios
        .post("settings/update_theme", this.add_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getThemes();
            this.createdAlert("success", data.message);
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.update_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: this.$refs.theme.offsetTop,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    scrollWhenHover(e) {
      let type = e.type;
      let elementProperty = {
        el: e.target,
        height: e.target.offsetHeight,
      };
      let parentProperty = {
        el: e.target.parentElement,
        height: e.target.parentElement.offsetHeight,
      };
      let start = 0;
      if (elementProperty.height > parentProperty.height) {
        // elementProperty.
        let finalHeight = elementProperty.height - parentProperty.height;

        if (start < finalHeight) {
          start = finalHeight;
        }

        if (type === "mouseleave") {
          start = 0;
        }

        elementProperty.el.style.top = `-${start}px`;
      }
    },
    chooseCurrentTemplate(type, i) {
      this.$set(this.template_disabled, i, true);

      return this.axios
        .post("settings/update_template", {
          template_name: type,
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.getTemplates();
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          this.createdAlert("error", message);
        })
        .finally(() => {
          this.template_disabled.splice(i, 1);
        });
    },
  },
  components: { SweetModal },
};
</script>
<style lang="scss">
.flag-ad {
  &:not([data-flag-title=""]) {
    &::after {
      content: attr(data-flag-title);
      @apply absolute px-12 py-2 text-sm -rotate-45 top-5 -left-12 text-center w-[180px] font-medium rtl:font-semibold;
    }
  }
}
.flag-soon {
  &::after {
    @apply bg-yellow-300 text-gray-800;
  }
}
.flag-end {
  &::after {
    @apply bg-red-500 text-white;
  }
}
.flag-new {
  &::after {
    @apply bg-green-500 text-white;
  }
}
.flag-in-use {
  &::after {
    @apply bg-gray-600 text-white;
  }
}
</style>
