<template>
  <section class="w-full p-6 space-y-6">
    <main class="w-full xl:container mx-auto bg-white rounded-xl">
      <header
        class="p-5 border-b border-gray-200 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
      >
        <div class="sm:flex-1 w-full">
          <div class="w-full sm:max-w-sm relative">
            <input
              type="text"
              name="search"
              id="search"
              :placeholder="$t('search')"
              class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
              v-model="search_key"
              @keyup.enter.stop="searchAll"
            />
            <!-- @blur="handleSearch('close')" -->
            <span
              class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i>
              <i v-else class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
      </header>
      <div class="w-full overflow-x-auto">
        <table class="styled-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("table.product") }}</th>
              <th>{{ $t("table.brand") }}</th>
              <th>{{ $t("table.total") }}</th>
              <th>{{ $t("table.sku") }}</th>
              <th>{{ $t("table.total_qty") }}</th>
              <th>{{ $t("table.orders_count") }}</th>
            </tr>
          </thead>
          <tbody v-if="products.length > 0 && load">
            <tr v-for="(product, index) in products" :key="index">
              <td>
                {{ index + 1 }}
              </td>

              <td>
                <div class="flex items-center gap-3">
                  <div>
                    <div class="w-10 h-10">
                      <img
                        :src="
                          product.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="product image"
                        class="w-full h-full rounded-lg border object-cover border-gray-100"
                      />
                    </div>
                  </div>
                  <span
                    class="inline-block !line-clamp-2 !whitespace-normal max-w-sm"
                  >
                    {{ product.name }}
                  </span>
                </div>
              </td>
              <td>
                <p>
                  {{ (product.brand || {}).name || "-" }}
                </p>
              </td>

              <td>{{ product.price || 0 }}</td>
              <td>{{ product.sku || "-" }}</td>
              <td>{{ product.total_qty }}</td>
              <td>{{ product.orders_count }}</td>
            </tr>
          </tbody>

          <tbody v-if="!load">
            <TableLoad :rows="7" v-for="i in 4" :key="i" />
          </tbody>
          <tbody v-if="load && products.length === 0">
            <tr>
              <td colspan="7" class="!text-center">
                {{ $t("no_data.products") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
        <paginate
          v-model="currentPage"
          :page-count="totalPages"
          :page-range="5"
          :margin-pages="2"
          :prev-text="$t('prev')"
          :next-text="$t('next')"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </main>
  </section>
</template>
<script>
import TableLoad from "@/components/loading/TableLoad.vue";
export default {
  name: "Products",
  data() {
    return {
      load: false,
      products: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
    };
  },
  created() {
    this.getProducts();
  },
  watch: {
    currentPage() {
      this.getProducts();
    },
  },
  methods: {
    getProducts() {
      return this.axios
        .get("products/best_selling", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            from: this.$route.params.date,
            to: this.$route.params.date,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.products = data.result.best_selling_products.data;
            this.perPage =
              data.result.best_selling_products.pagination.per_page;
            this.rows = data.result.best_selling_products.pagination.total;
            this.totalPages =
              data.result.best_selling_products.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 0) {
            this.products = [];
          }
        })
        .finally(() => {
          this.load = true;
          this.search_disabled = false;
        });
    },

    searchAll() {
      this.search_disabled = true;
      this.getProducts().then(() => {
        this.search_disabled = false;
      });
    },
  },
  components: { TableLoad },
};
</script>
