<template>
  <div id="app" class="min-h-screen flex flex-col justify-between">
    <Header v-if="$store.state.token && $route.name !== 'Auth'" />
    <router-view class="flex-1 sidebar-move" />
    <Footer class="sidebar-move" />
    <button
      class="w-10 h-10 rounded-full flex items-center justify-center bg-yellow-600 text-white fixed bottom-20 right-5 z-[5] animate-bounce"
      @click.prevent="backTop"
      v-if="$store.state.token && $route.name !== 'Auth'"
    >
      <i class="fa-solid fa-arrow-up"></i>
    </button>

    <div
      class="fixed bottom-5 left-5 z-[5] flex flex-col-reverse sm:flex-row-reverse items-end gap-4"
      v-if="$route.name !== 'Auth'"
    >
      <button
        class="w-12 h-12 rounded-full bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white relative"
        @click.prevent="openWaPopup"
      >
        <i class="fa-brands fa-whatsapp text-xl"></i>
        <span
          class="w-full h-full bg-[#075E5420] rounded-full animate-ping absolute top-0 right-0 z-[-1]"
        ></span>
      </button>
      <div
        class="w-full max-w-sm py-6 px-7 rounded-xl bg-white border border-gray-100 space-y-3 text-center v-dropdown hidden wa-box"
      >
        <h1 class="text-lg font-semibold rtl:font-bold text-yellow-600">
          الدعم الفني
        </h1>
        <p class="text-base font-medium rtl:font-semibold text-gray-600">
          نحن دائما معك, اذا كنت تواجه شئ ما اثناء تجولك My Store قم بالتواصل
          معنا لنلبي طلبك .
        </p>
        <a
          href="#"
          class="block py-3 px-4 rounded-full bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white"
        >
          <i class="fa-brands fa-whatsapp"></i>
          واتساب
        </a>
      </div>
    </div>
    <SupportChat v-if="$store.state.token && $route.name !== 'Auth'" />
    <loadingComponent />
    <SweetModal
      :close="false"
      v-if="
        $store.state.should_upgrade.response || $store.state.should_upgrade.page
      "
      class="backdrop-blur-md filter"
    >
      <template>
        <div class="p-6 py-10 text-center space-y-6">
          <img
            src="@/assets/images/upgrade.webp"
            alt="upgrade image"
            class="table mx-auto w-full max-w-[14rem]"
          />
          <div class="w-full space-y-2">
            <h1 class="text-2xl font-semibold rtl:font-bold text-yellow-600">
              قم بترقية الحساب الان
            </h1>
            <p class="font-medium rtl:font-semibold text-gray-600">
              يرجى ترقية الحساب باحدى الباقات المميزة لدينا للاستمتاع بالمميزات
              الخاصة بتلك الباقة وايضا استخدام الميزة الحالية, للترقية قم
              بالتوجهة الى صفحة الباقات واختر الباقة المناسبة لنشاطك.
            </p>
            <router-link
              to="/settings/plans"
              class="py-3 px-6 rounded-full bg-green-600 text-white text-sm font-medium rtl:font-semibold block w-full max-w-[13rem] mx-auto !mt-6"
            >
              تحديث الان
            </router-link>
            <button
              class="py-3 px-6 rounded-full bg-gray-50 text-gray-600 text-sm font-medium rtl:font-semibold block w-full max-w-[13rem] mx-auto"
              @click.prevent="() => $router.go(-1)"
              v-if="$store.state.should_upgrade.page"
            >
              الرجوع للسابقة
            </button>
            <button
              class="py-3 px-6 rounded-full bg-gray-50 text-gray-600 text-sm font-medium rtl:font-semibold block w-full max-w-[13rem] mx-auto"
              @click.prevent="() => $store.commit('SET_SHOULD', {})"
              v-if="$store.state.should_upgrade.response"
            >
              تخطي
            </button>
          </div>
        </div>
      </template>
    </SweetModal>
  </div>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import SupportChat from "@/components/basics/SupportChat.vue";
import loadingComponent from "@/components/loadingComponent.vue";
import SweetModal from "./components/modals/sweetModal.vue";
export default {
  components: {
    Header,
    Footer,
    SupportChat,
    loadingComponent,
    SweetModal,
  },
  watch: {
    "$route.name"() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.$on("offline", () => {
      alert("You are offline! The website will not work");
    });
  },

  methods: {
    openWaPopup() {
      document.querySelector(".wa-box").classList.toggle("hidden");
    },
  },
};
</script>
