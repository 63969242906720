<template>
  <div class="w-full">
    <h1 class="text-sm font-semibold rtl:font-bold text-yellow-600 mb-6 hidden">
      تصنيف الطلبات
    </h1>
    <div class="w-full p-5 rounded-xl bg-white">
      <form class="w-full space-y-4">
        <div class="flex items-center flex-wrap gap-4">
          <div class="flex-1 space-y-2">
            <label
              for="products_count"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              عدد المنتجات
            </label>
            <input
              type="text"
              name="products_count"
              id="products_count"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            />
          </div>
          <div class="flex-1 space-y-2">
            <label
              for="cart_total"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              قيمة السلة
            </label>
            <input
              type="text"
              name="cart_total"
              id="cart_total"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            />
          </div>
        </div>
        <div class="w-full space-y-2">
          <label
            for="country"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            الدولة
          </label>
          <select name="status" id="country" class="custom-select">
            <option value="" selected disabled>-- اختار --</option>
          </select>
        </div>
        <div class="w-full space-y-2">
          <label
            for="city"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            المدينة
          </label>
          <select name="status" id="city" class="custom-select">
            <option value="" selected disabled>-- اختار --</option>
          </select>
        </div>
        <div class="w-full space-y-2">
          <label
            for="cart_status"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            حالة ترك السلة
          </label>
          <select name="status" id="cart_status" class="custom-select">
            <option value="" selected disabled>-- اختار --</option>
          </select>
        </div>
        <div class="w-full space-y-2">
          <h1
            for="cart_status"
            class="font-medium rtl:font-semibold text-gray-600 table text-sm"
          >
            حالة الشراء
          </h1>
          <div class="space-y-4 !mt-4 w-full">
            <div>
              <div class="checkbox">
                <div>
                  <input type="radio" name="check_group" :id="`${name}_1`" />
                </div>
                <label
                  :for="`${name}_1`"
                  class="text-base font-medium rtl:font-semibold text-gray-600"
                >
                  الكل
                </label>
              </div>
            </div>

            <div>
              <div class="checkbox">
                <div>
                  <input type="radio" name="check_group" :id="`${name}_2`" />
                </div>
                <label
                  :for="`${name}_2`"
                  class="text-base font-medium rtl:font-semibold text-gray-600"
                >
                  تم الشراء
                </label>
              </div>
            </div>

            <div>
              <div class="checkbox">
                <div>
                  <input type="radio" name="check_group" :id="`${name}_3`" />
                </div>
                <label
                  :for="`${name}_3`"
                  class="text-base font-medium rtl:font-semibold text-gray-600"
                >
                  لم يتم الشراء
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full space-y-3">
          <button
            class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm w-full"
          >
            تطبيق التصنيقات
          </button>
          <button
            class="py-2 px-6 rounded-lg bg-gray-100 text-gray-600 text-center text-sm w-full"
          >
            إعادة التعيين
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableFilter",
  props: {
    name: {
      type: String,
    },
  },
};
</script>
