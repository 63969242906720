<template>
  <header class="w-full xl:container">
    <div class="flex items-center justify-between">
      <div class="flex-1 space-y-2">
        <div class="grid">
          <h1 class="text-xl rtl:font-semibold ltr:font-medium">
            <slot name="title"></slot>
          </h1>
        </div>
        <div class="w-full text-gray-500">
          <router-link
            to="/"
            class="text-yellow-600 rtl:font-semibold ltr:font-medium text-sm"
            >الرئيسية</router-link
          >
          /

          <span
            class="text-sm text-gray-600 rtl:font-semibold ltr:font-medium"
            v-if="$slots.default"
          >
            <slot name="default"></slot>
          </span>
          <span
            class="text-sm text-gray-500 rtl:font-semibold ltr:font-medium"
            v-if="$slots.title"
          >
            <slot name="title"></slot>
          </span>
        </div>
      </div>
      <button
        class="flex items-center justify-center gap-2 rtl:font-semibold ltr:font-medium"
        @click="$router.back()"
      >
        العودة
        <i class="fas fa-angle-left"></i>
        <!-- <i v-else class="fas fa-angle-right"></i> -->
      </button>
    </div>
  </header>
</template>
<script>
export default {
  name: "FileSystemHeader",
};
</script>
