<template>
  <tr class="w-full">
    <td v-for="row in rows" :key="row">
      <div class="h-4 rounded-md bg-gray-200 w-24 animate-pulse"></div>
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 1,
    },
  },
};
</script>
