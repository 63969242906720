import Vue from "vue";
export default async function auth({ to, next, store }) {
  // console.log(store);
  const { template } = to.params;
  const { data } = await Vue.axios.get("settings/template");
  if (data.error_flag === 0) {
    if (template !== data.result.template_name) {
      return next({
        name: "PageNotFound",
      });
    }
  }

  return next();
}
