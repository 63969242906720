<template>
  <div class="w-full space-y-6">
    <form class="w-full space-y-6">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            بيانات المتجر
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                ايقونة المتجر
              </h1>

              <label
                for="favicon_image"
                class="bg-yellow-600/20 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                id="favicon_image"
                type="file"
                name="favicon_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="uploadMainImage($event, 'favicon')"
              />
              <div class="block max-w-sm">
                <figure
                  class="w-full border border-gray-100 rounded-lg flex items-center justify-center gap-3 p-3"
                >
                  <figcaption>
                    <div class="w-6 h-6">
                      <img
                        :src="
                          add_data.favicon
                            ? typeof add_data.favicon === 'object'
                              ? convertImage(add_data.favicon)
                              : add_data.favicon
                            : ''
                        "
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="fav icon"
                        class="w-full h-full object-cover rounded-full border-gray-100"
                      />
                    </div>
                  </figcaption>
                  <blockquote class="flex-1 grid">
                    <p
                      class="text-sm font-medium rtl:font-semibold text-gray-600 truncate"
                    >
                      {{ add_data.name || "-" }}
                    </p>
                  </blockquote>
                </figure>
              </div>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.favicon || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                شعار المتجر
              </h1>
              <label
                for="id_uploader_image"
                class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                type="file"
                name="id_uploader_image"
                id="id_uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="uploadMainImage($event, 'logo')"
              />
              <div v-if="add_data.logo" class="table">
                <div
                  class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                >
                  <img
                    :src="
                      typeof add_data.logo === 'object'
                        ? convertImage(add_data.logo)
                        : add_data.logo
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                  v-if="typeof add_data.logo === 'object'"
                  @click.prevent="add_data.logo = null"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.logo || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="name_ar"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم المتجر | عربي
              </label>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.ar.name"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["ar.name"] || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="name_en"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                اسم المتجر | انجليزي
              </label>
              <input
                type="text"
                name="name_en"
                id="name_en"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.en.name"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["en.name"] || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="email"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              البريد الالكتروني
            </label>
            <input
              type="text"
              name="email"
              id="email"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              v-model="add_data.email"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.email || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="tel"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              رقم الجوال
            </label>
            <vue-tel-input
              id="mobile"
              ref="registerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('registerMobile')"
            ></vue-tel-input>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="desc_ar"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                نبذة عن المتجر | عربي
              </label>
              <textarea
                name="desc_ar"
                id="desc_ar"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500 resize-y min-h-[7rem]"
                v-model="add_data.ar.desc"
              ></textarea>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["ar.desc"] || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="desc_en"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                نبذة عن المتجر | انجليزي
              </label>
              <textarea
                name="desc_en"
                id="desc_en"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500 resize-y min-h-[7rem]"
                v-model="add_data.en.desc"
              ></textarea>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["en.desc"] || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="w-full space-y-2">
            <label
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              مجال النشاط التجاري
            </label>
            <select class="custom-select" v-model="add_data.category_id">
              <option value="" selected disabled>-- اختار --</option>
              <option
                v-for="category in categories"
                :value="category.id"
                :key="category.id"
              >
                {{ category.name }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.category_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-2">
            <label
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              التصنيف
            </label>
            <MultiListSelect
              :list="sub_categories"
              :selectedItems="add_data.sub_categories_ids"
              v-model="add_data.sub_categories_ids"
              option-value="id"
              option-text="name"
              placeholder="..."
              @select="
                handleSearchSelect($event, 'add_data', 'sub_categories_ids')
              "
              class="custom-multi-select"
            >
            </MultiListSelect>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.sub_categories_ids || []).join(" ") }}
            </span>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            النشاط التجاري والهوية
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="rc_number"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم السجل التجاري للمتجر
              </label>
              <input
                type="text"
                name="rc_number"
                id="rc_number"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.commercial_register"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.commercial_register || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                صورة من السجل التجاري
              </h1>
              <label
                for="uploader_image"
                class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                type="file"
                name="uploader_image"
                id="uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="
                  uploadMainImage($event, 'commercial_register_image')
                "
              />

              <div v-if="add_data.commercial_register_image" class="table">
                <div
                  class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                >
                  <img
                    :src="convertImage(add_data.commercial_register_image)"
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                  @click.prevent="add_data.commercial_register_image = null"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.commercial_register_image || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="id_number"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                رقم هوية مسؤول المتجر
              </label>
              <input
                type="text"
                name="id_number"
                id="id_number"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.national_id"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.national_id || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <h1
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                صورة من الهوية
              </h1>
              <label
                for="id_uploader_image"
                class="bg-yellow-600/10 text-yellow-600 font-medium rtl:font-semibold text-center py-2 px-4 text-sm cursor-pointer rounded-xl inline-block"
              >
                تحميل ( .png , .jpg )
              </label>
              <input
                type="file"
                name="id_uploader_image"
                id="id_uploader_image"
                hidden
                accept="image/*"
                class="hidden"
                @change.prevent="uploadMainImage($event, 'national_id_image')"
              />
              <div v-if="add_data.national_id_image" class="table">
                <div
                  class="w-20 h-20 p-1 rounded-xl border-2 border-yellow-600/50"
                >
                  <img
                    :src="convertImage(add_data.national_id_image)"
                    alt="upload"
                    class="w-full rounded-lg h-full object-cover"
                  />
                </div>
                <button
                  class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm"
                  @click.prevent="add_data.national_id_image = null"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.national_id_image || []).join(" ") }}
              </span>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            عنوان المتجر
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-2">
              <label
                for="region"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المنطقة
              </label>
              <select
                name="region"
                id="region"
                class="custom-select"
                v-model="add_data.region_id"
                :disabled="
                  locations.regions ? locations.regions.length === 0 : false
                "
              >
                <option value="" selected disabled>-- اختار --</option>
                <option
                  v-for="region in locations.regions"
                  :value="region.id"
                  :key="region.id"
                >
                  {{ region["name"] }}
                </option>
              </select>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.region_id || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-2">
              <label
                for="city"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المدينة
              </label>
              <select
                name="city"
                id="city"
                class="custom-select"
                v-model="add_data.city_id"
                :disabled="cities.length === 0"
              >
                <option value="" selected disabled>-- اختار --</option>
                <option v-for="city in cities" :value="city.id" :key="city.id">
                  {{ city["name"] }}
                </option>
              </select>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.city_id || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="district"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              الحي
            </label>
            <select
              name="district"
              id="district"
              class="custom-select"
              v-model="add_data.district_id"
              :disabled="districts.length === 0"
            >
              <option value="" selected disabled>-- اختار --</option>
              <option
                v-for="district in districts"
                :value="district.id"
                :key="district.id"
              >
                {{ district["name"] }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.district_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="full_location"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              العنوان بالتفصيل
            </label>

            <gmap-autocomplete
              ref="Place"
              :value="add_data.address"
              :options="{
                componentRestrictions: { country: ['SA'] },
                fields: [],
                strictBounds: false,
                types: ['geocode'],
              }"
              class="w-full h-full relative py-3 px-4 rounded-lg bg-gray-100"
              @place_changed="setPlace"
              placeholder="..."
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.address || []).join(" ") }}
            </span>

            <div class="w-full">
              <GmapMap
                :center="{
                  lat: Number(add_data.lat),
                  lng: Number(add_data.lng),
                }"
                :cluster="{ options: { styles: map.clusterStyle } }"
                :zoom="8"
                map-type-id="terrain"
                :options="{
                  fullscreenControl: false,
                  styles: map.mapStyle,
                  mapTypeControl: false,
                }"
                style="height: 400px"
                class="rounded-lg overflow-hidden"
              >
                <GmapMarker
                  :position="{
                    lat: Number(add_data.lat),
                    lng: Number(add_data.lng),
                  }"
                  :draggable="true"
                  @dragend="updatePosition"
                />
              </GmapMap>
            </div>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        @click.prevent="updateStore"
        :disabled="disabled"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="disabled"></i>
        حفظ التغييرات
      </button>
    </form>
    <form class="w-full" ref="social_form">
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            بيانات التواصل
          </h1>
        </header>
        <blockquote class="w-full py-10 px-6 space-y-6">
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="facebook"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                فيسبوك
              </label>
              <input
                type="url"
                name="facebook"
                id="facebook"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="social_data.facebook"
                placeholder="https://facebook.com"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (social_errors.facebook || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="twitter"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                تويتر
              </label>
              <input
                type="url"
                name="twitter"
                id="twitter"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="social_data.twitter"
                placeholder="https://twitter.com"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (social_errors.twitter || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="instagram"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                انستجرام
              </label>
              <input
                type="url"
                name="instagram"
                id="instagram"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="social_data.instagram"
                placeholder="https://instagram.com"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (social_errors.instagram || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="snapchat"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                سناب شات
              </label>
              <input
                type="url"
                name="snapchat"
                id="snapchat"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="social_data.snapchat"
                placeholder="https://snapchat.com"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (social_errors.snapchat || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="whatsapp"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الواتساب
              </label>
              <input
                type="url"
                name="whatsapp"
                id="whatsapp"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="social_data.whatsapp"
                placeholder="https://whatsapp.com"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (social_errors.whatsapp || []).join(" ") }}
              </span>
            </div>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        :disabled="social_disabled"
        @click.prevent="updateStoreSocial"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="social_disabled"></i>
        حفظ التغييرات
      </button>
    </form>
  </div>
</template>
<script>
import { MultiListSelect } from "vue-search-select";

export default {
  name: "StoreInfo",
  data() {
    return {
      add_data: {
        lat: 24.774265,
        lng: 46.738586,
        address: "",
        commercial_register_image: null,
        national_id_image: null,
        ar: {},
        en: {},
        region_id: "",
        city_id: "",
        district_id: "",
        category_id: "",
        sub_categories_ids: [],
      },
      add_errors: {},
      error: "",
      success: "",
      disabled: false,
      social_disabled: false,
      map: {
        clusterStyle: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            width: 56,
            height: 56,
            textColor: "#fff",
          },
        ],
        mapStyle: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#3e606f",
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: "#313536",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#44a688",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#f5e4e4",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: "14",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#067372",
              },
              {
                lightness: "-20",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#357374",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#004757",
              },
            ],
          },
        ],
      },
      locations: {},
      cities: [],
      districts: [],
      sub_categories: [],
      categories: [],
      social_data: {},
      social_errors: {},
    };
  },
  created() {
    this.getLocations();
    this.getCategories();
    this.getStoreInfo();
  },
  watch: {
    "add_data.category_id"() {
      if (this.add_data.category_id) {
        this.sub_categories =
          (
            this.categories.filter(
              (category) => category.id === this.add_data.category_id
            ) || {}
          ).sub_categories || [];
      } else {
        this.sub_categories = [];
      }
    },
    "add_data.region_id"() {
      this.cities = [];
      if (this.add_data.region_id) {
        this.cities = this.locations.cities
          ? this.locations.cities.filter(
              (city) =>
                Number(city.region_id) === Number(this.add_data.region_id)
            )
          : [];
      } else {
        this.cities = [];
      }
    },
    "add_data.city_id"() {
      this.districts = [];
      if (this.add_data.city_id) {
        this.districts = this.locations.districts
          ? this.locations.districts.filter(
              (district) =>
                Number(district.city_id) === Number(this.add_data.city_id)
            )
          : [];
      } else {
        this.districts = [];
      }
    },
  },
  methods: {
    getStoreInfo() {
      return this.axios.get("settings/store").then(({ data }) => {
        if (data.error_flag === 0) {
          if (data.result.store_info["en"]) {
            this.add_data = data.result.store_info;
          } else {
            this.add_data = Object.assign(data.result.store_info, { en: {} });
          }
          this.social_data = data.result.store_social;
          this.getLocations().then(() => {
            this.cities =
              this.locations.cities.filter(
                (city) =>
                  Number(city.region_id) ===
                  Number(data.result.store_info.region_id)
              ) || [];
            this.districts =
              this.locations.districts.filter(
                (district) =>
                  Number(district.city_id) ===
                  Number(data.result.store_info.city_id)
              ) || [];
          });
        }
      });
    },
    updateStore() {
      this.disabled = true;
      this.add_errors = {};
      this.error = "";
      this.success = "";

      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (key === "sub_categories") {
          this.add_data[key].forEach((category, cidx) => {
            fd.append(`sub_categories[${cidx}]`, category.id);
          });
        } else if (
          [
            "commercial_register_image",
            "national_id_image",
            "logo",
            "favicon",
          ].includes(key)
        ) {
          if (this.add_data[key] && typeof this.add_data[key] === "object") {
            fd.append(key, this.add_data[key], this.add_data[key].name);
          } else {
            fd.delete(key);
          }
        } else if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.add_data[key][trKey]);
          });
        } else if (["categories_ids"].includes(key)) {
          this.add_data[key].forEach((trKey, index) => {
            fd.append(`${key}[${index}]`, trKey.id);
          });
        } else if (key === "mobile") {
          fd.append(
            key,
            this.add_data[key].replace(/\s/g, "").replace("+", "")
          );
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("settings/update_store_info", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getStoreInfo();
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.error = message;
            }
          }
        })
        .finally(() => {
          this.disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    getCategories() {
      return this.axios
        .get("categories", {
          params: {
            status: "on",
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.categories = data.result.categories;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.categories = [];
          }
        });
    },
    updateStoreSocial() {
      this.social_disabled = true;
      return this.axios
        .post("settings/update_store_social", this.social_data)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.getStoreInfo();
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.social_errors = message;
            }
          }
        })
        .finally(() => {
          this.social_disabled = false;
          window.scrollTo({
            top: this.$refs.social_form.offsetTop,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    getLocations() {
      return this.axios
        .get("locations")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.locations = data.result;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.locations = {};
          }
        });
    },
    uploadMainImage(e, type) {
      e.preventDefault();
      this.add_data[type] = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, (response) => {
        if (response.status === "OK") {
          const address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.Place.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(this.add_data, "address", this.$refs.Place.$refs.input.value);
      }
    },
    handleDropContent(e) {
      e.preventDefault();
      let currentElement = e.currentTarget,
        icon = currentElement.querySelector("i"),
        blockquote =
          currentElement.parentElement.parentElement.parentElement.querySelector(
            "blockquote"
          ),
        header = currentElement.parentElement.parentElement;
      blockquote.classList.toggle("hidden");
      icon.classList.replace(
        blockquote.classList.contains("hidden")
          ? "fa-angle-up"
          : "fa-angle-down",
        blockquote.classList.contains("hidden")
          ? "fa-angle-down"
          : "fa-angle-up"
      );
      header.classList.replace(
        blockquote.classList.contains("hidden") ? "border-b" : "border-b-0",
        blockquote.classList.contains("hidden") ? "border-b-0" : "border-b"
      );
    },
  },
  components: { MultiListSelect },
};
</script>
