import Vue from "vue";
export default function auth({ to, from, next, store, $cookies }) {
  const token = store.state.token || $cookies.get("token");
  document.querySelector("body").classList.add("bg-gray-100");
  store.commit("SET_LOADING", true);
  if (token) {
    Vue.axios
      .get("settings/account")
      .then(({ data }) => {
        if (data.error_flag === 0) {
          store.commit("SET_USER_DATA", {
            user: data.result.account,
            token: token,
            image_url: null,
          });

          // get plan
          Vue.axios.get("settings/plan").then(({ data }) => {
            if (data.error_flag === 0) {
              const features = data.result.features;
              const item = features.find((e) =>
                new RegExp(to.meta.can, "g").test(e.code)
              );
              store.commit("SET_PLAN", data.result);
              if (item) {
                if (!item.can) {
                  return store.commit("SET_SHOULD", {
                    page: true,
                  });
                }
              }
              store.commit("SET_SHOULD", {});
            }
          });
          store.dispatch("getStoreInfo").then(() => {
            next();
          });
        }
      })
      .catch(({ response }) => {
        if (response.data.error_flag === 1) {
          store.commit("SET_USER_DATA", {
            user: {},
            token: null,
            image_url: null,
          });
          $cookies.remove("token");
          return next({
            path: "/auth",
            query: { redirect: to.fullPath },
          });
        }
      })
      .finally(() => {
        store.commit("SET_LOADING", false);
      });
  } else {
    if (["Auth"].includes(to.name)) {
      document.querySelector("body").classList.remove("bg-gray-100");
      store.commit("SET_LOADING", false);
    }
    return next({
      path: "/auth",
      query: { redirect: to.fullPath },
    });
  }
}
