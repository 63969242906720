<template>
  <div class="py-10 px-6 space-y-6">
    <FileSystemHeader>
      <template #title> انشاء صفحة تعريفية </template>
    </FileSystemHeader>
    <form class="w-full xl:container space-y-6">
      <div class="w-full rounded-xl bg-white">
        <header class="w-full p-5 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            انشاء صفحة تعريفية جديدة
          </h1>
          <p class="text-sm mt-1 font-medium rtl:font-semibold text-gray-600">
            بامكانك ادخال عنوان ومحتوى الصفحة التعريفية المراد اضافتها من هنا..
          </p>
        </header>
        <div class="p-6 space-y-4">
          <div class="flex items-start gap-4 flex-col md:flex-row">
            <div class="flex-1 w-full space-y-3">
              <label
                for="title_ar"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                عنوان الصفحة | عربي
              </label>
              <input
                type="text"
                name="title_ar"
                id="title_ar"
                v-model="add_data.ar.title"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              />

              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["ar.title"] || []).join(" ") }}
              </span>
            </div>
            <div class="flex-1 w-full space-y-3">
              <label
                for="title_en"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                عنوان الصفحة | انجليزي
              </label>
              <input
                type="text"
                name="title_en"
                id="title_en"
                v-model="add_data.en.title"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
              />

              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["en.title"] || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="flex-1 w-full space-y-3">
            <label
              for="page_link"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              رابط الصفحة
            </label>
            <input
              type="text"
              name="page_link"
              id="page_link"
              v-model="add_data.slug"
              class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
            />

            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["slug"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="content_ar"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              الوصف | عربي
            </label>
            <tinymce
              :other_options="options"
              id="content_ar"
              style="height: auto !important"
              :plugins="plugins"
              :toolbar1="toolbar"
              v-model="add_data.ar.content"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["ar.content"] || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="content_en"
              class="font-medium rtl:font-semibold text-gray-600 table text-base"
            >
              الوصف | انجليزي
            </label>
            <tinymce
              :other_options="options"
              id="content_en"
              style="height: auto !important"
              :plugins="plugins"
              :toolbar1="toolbar"
              v-model="add_data.en.content"
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["en.content"] || []).join(" ") }}
            </span>
          </div>
        </div>
      </div>
      <button
        class="py-2 px-6 rounded-xl text-white font-medium rtl:font-semibold bg-green-600"
        :disabled="add_disabled"
        @click.prevent="addPage"
      >
        <i
          class="fa-solid fa-spinner fa-spin mr-1 rtl:ml-1 rtl:mr-0"
          v-if="add_disabled"
        ></i>
        إضافة
      </button>
    </form>
  </div>
</template>
<script>
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  name: "createPage",
  components: { FileSystemHeader },

  data() {
    return {
      add_disabled: false,
      add_data: {
        ar: {},
        en: {},
      },

      add_errors: {},
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste imagetools wordcount",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      options: {
        height: 250,
        menubar: false,
        statusbar: false,
        directionality: "rtl",
        language: this.$i18n.locale,
        language_url: "/" + this.$i18n.locale + ".js",
        skin: "naked",
        icons: "small",
        toolbar_location: "bottom",
        automatic_uploads: true,
        images_reuse_filename: true,
        content_style:
          "body { font-family:Cairo,Quicksand,Helvetica,Arial,sans-serif; font-size:14px }",
      },
    };
  },
  methods: {
    addPage() {
      this.add_disabled = true;
      let fd = new FormData();
      this.add_errors = {};
      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((transitionKey) => {
            fd.append(
              `${key}[${transitionKey}]`,
              this.add_data[key][transitionKey]
            );
          });
        }
      });
      return this.axios
        .post("store_pages/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.$router.push("/pages");
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
  },
};
</script>
