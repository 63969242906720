<template>
  <section class="w-full p-6 relative">
    <div class="w-full xl:container">
      <main class="w-full">
        <div class="space-y-4">
          <header class="w-full flex items-center flex-col sm:flex-row gap-4">
            <figure
              class="sm:flex-1 w-full bg-white p-5 rounded-xl flex items-center gap-4"
            >
              <figcaption>
                <div
                  class="w-14 h-14 rounded-xl bg-red-500/10 flex items-center justify-center text-red-500 text-lg"
                >
                  <i class="fa-solid fa-cart-arrow-down"></i>
                </div>
              </figcaption>
              <blockquote class="flex-1 space-y-1">
                <h1
                  class="font-semibold rtl:font-bold text-gray-600 table text-sm"
                >
                  إجمالي عدد السلات المتروكة
                </h1>
                <h2 class="font-bold text-red-500 table text-xl">
                  {{ orders.length || 0 }}
                </h2>
              </blockquote>
            </figure>
            <figure
              class="sm:flex-1 w-full bg-white p-5 rounded-xl flex items-center gap-4"
            >
              <figcaption>
                <div
                  class="w-14 h-14 rounded-xl bg-green-600/10 flex items-center justify-center text-green-600 text-lg"
                >
                  <i class="fa-solid fa-cart-flatbed"></i>
                </div>
              </figcaption>
              <blockquote class="flex-1 space-y-1">
                <h1
                  class="font-semibold rtl:font-bold text-gray-600 table text-sm"
                >
                  إجمالي عدد السلات المتروكة التي تحولت الى طلبات
                </h1>
                <h2 class="font-bold text-green-600 table text-xl">0</h2>
              </blockquote>
            </figure>
          </header>
          <div class="w-full rounded-xl bg-white">
            <header
              class="p-5 border-b border-gray-200 flex items-start gap-2 flex-wrap w-full relative flex-col sm:flex-row"
            >
              <div class="sm:flex-1 w-full">
                <div class="w-full sm:max-w-sm relative">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="بحث في الطلبات.."
                    class="w-full py-3 px-4 rounded-xl bg-gray-100 placeholder:text-gray-400 font-medium pl-12 rtl:pl-4 rtl:pr-12 text-sm"
                    v-model="cart_filter.search_key"
                    @keyup.enter.stop="searchAll"
                  />
                  <!-- @blur="handleSearch('close')" -->
                  <span
                    class="absolute top-0 rtl:left-auto rtl:right-0 w-12 h-full left-0 flex items-center justify-center text-gray-500"
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="search_disabled"
                    ></i>
                    <i v-else class="fa-solid fa-search"></i>
                  </span>
                </div>
              </div>
              <div v-if="false">
                <button
                  class="py-2 px-6 rounded-lg bg-blue-500 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                  @click="active = true"
                >
                  <i class="fa-solid fa-arrow-up-wide-short"></i> تصنيف الطلبات
                </button>
              </div>
              <div v-if="false">
                <button
                  class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm block ml-auto rtl:mr-auto rtl:ml-0"
                >
                  <i class="fa-solid fa-file-excel"></i>
                  تصدير البيانات
                </button>
              </div>
            </header>
            <div class="w-full overflow-x-auto pb-5">
              <table class="styled-table">
                <thead>
                  <tr>
                    <th>العميل</th>
                    <th>تاريخ الانشاء</th>
                    <th>اخر تحديث</th>
                    <th>عدد المنتجات</th>
                    <th>قيمة السلة</th>
                    <th>اخر تحديث للسلة</th>
                    <th>الحالة</th>
                  </tr>
                </thead>
                <tbody v-if="orders.length > 0 && load">
                  <tr v-for="(order, index) in orders" :key="index">
                    <td>
                      <div class="flex items-center gap-3">
                        <div>
                          <div class="w-10 h-10">
                            <img
                              :src="order.photo"
                              @error="
                                $event.target.src = require('@/assets/images/placeholder.png')
                              "
                              class="w-full h-full object-cover rounded-full border border-gray-100"
                              alt="customer photo"
                            />
                          </div>
                        </div>
                        <div class="space-y-1">
                          <p>{{ order.name }}</p>
                          <a
                            :href="`tel:${order.mobile}`"
                            target="_blank"
                            class="text-gray-600"
                            rel="noopener noreferrer"
                            >{{ order.mobile }}</a
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ order.created_at | moment("from") }}
                      </div>
                      <div>
                        <span
                          dir="ltr"
                          class="text-gray-600 text-xs font-medium"
                          >{{
                            order.created_at | moment(" h:mm a . MMMM Do YYYY")
                          }}</span
                        >
                      </div>
                    </td>
                    <td>
                      {{ order.updated_at | moment("from") }}
                    </td>
                    <td>
                      {{ (order.cart || {}).products_count }}
                    </td>
                    <td>
                      {{ (order.cart || {}).sub_total }}
                    </td>

                    <td>
                      <div>
                        {{
                          (order.cart || {}).last_updated_at | moment("from")
                        }}
                      </div>
                      <div>
                        <span
                          dir="ltr"
                          class="text-gray-600 text-xs font-medium"
                          >{{
                            (order.cart || {}).last_updated_at
                              | moment(" h:mm a . MMMM Do YYYY")
                          }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <span class="inline-block py-1 5 px-3 rounded-lg">
                        {{ order.status }}
                      </span>
                    </td>

                    <!-- <td>{{ order.updated_at | moment("from") }}</td> -->
                  </tr>
                </tbody>
                <tbody v-if="!load">
                  <TableLoad v-for="i in 4" :key="i" :rows="7" />
                </tbody>
                <tbody v-if="load && orders.length === 0">
                  <tr>
                    <td colspan="9" class="!text-center">لا توجد بيانات</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table mx-auto !py-6" v-if="load && totalPages > 1">
              <paginate
                v-model="currentPage"
                :page-count="totalPages"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'السابقة'"
                :next-text="'التالية'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </main>
      <!-- <aside class="w-full max-w-sm xl:flex-1 hidden xxl:block mx-auto">
        <TableFilter name="main_filter" />
      </aside> -->
    </div>
    <transition name="move">
      <SweetModal v-if="active" @close="active = $event">
        <template #default>
          <div class="w-full">
            <h1
              class="text-sm font-semibold rtl:font-bold text-yellow-600 mb-6 hidden"
            >
              تصنيف الطلبات
            </h1>
            <div class="w-full p-5 rounded-xl bg-white">
              <form class="w-full space-y-4">
                <div class="flex items-center flex-col sm:flex-row gap-4">
                  <div class="flex-1 space-y-2 w-full">
                    <label
                      for="products_count"
                      class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                    >
                      عدد المنتجات
                    </label>
                    <input
                      type="text"
                      name="products_count"
                      id="products_count"
                      class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                      v-model="cart_filter.products_count"
                    />
                  </div>
                  <div class="flex-1 space-y-2 w-full">
                    <label
                      for="cart_total"
                      class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                    >
                      قيمة السلة
                    </label>
                    <input
                      type="text"
                      name="cart_total"
                      id="cart_total"
                      class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                      v-model="cart_filter.sub_total"
                    />
                  </div>
                </div>
                <p
                  class="font-medium rtl:font-semibold text-gray-600 table text-sm"
                >
                  تاريخ الاضافة
                </p>
                <div class="flex items-center flex-col sm:flex-row gap-4">
                  <div class="flex-1 space-y-2 w-full">
                    <input
                      type="date"
                      name="cart_date_from"
                      id="cart_date_from"
                      class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                      v-model="cart_filter.start_at"
                    />
                  </div>
                  <div class="flex-1 space-y-2 w-full">
                    <input
                      type="date"
                      name="cart_date_to"
                      id="cart_date_to"
                      class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                      v-model="cart_filter.end_at"
                    />
                  </div>
                </div>

                <div class="w-full space-y-3">
                  <button
                    class="py-2 px-6 rounded-lg bg-yellow-600 text-white text-center text-sm w-full"
                    :disabled="filter_disabled"
                    @click.prevent="applyFilters"
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="filter_disabled"
                    ></i>
                    تطبيق التصنيقات
                  </button>
                  <button
                    class="py-2 px-6 rounded-lg bg-gray-100 text-gray-600 text-center text-sm w-full"
                    :disabled="cancel_disabled"
                    @click.prevent="cancelFilters"
                  >
                    <i
                      class="fa-solid fa-spinner fa-spin"
                      v-if="cancel_disabled"
                    ></i>
                    إعادة التعيين
                  </button>
                </div>
              </form>
            </div>
          </div>
        </template>
      </SweetModal>
    </transition>
  </section>
</template>
<script>
import TableFilter from "@/components/TableFilter.vue";
import TableLoad from "@/components/loading/TableLoad.vue";
import SweetModal from "@/components/modals/sweetModal.vue";
export default {
  name: "AbandonedCarts",
  components: { TableFilter, TableLoad, SweetModal },
  data() {
    return {
      load: false,
      orders: [],
      search_key: "",
      cart_filter: {
        search_key: "",
      },
      currentPage: 1,
      perPage: 0,
      rows: 0,
      totalPages: 0,
      search_disabled: false,
      filter_disabled: false,
      cancel_disabled: false,
      active: false,
    };
  },
  created() {
    this.getAbandonedCarts();
  },
  watch: {
    currentPage() {
      this.getAbandonedCarts();
    },
  },
  methods: {
    getAbandonedCarts() {
      return this.axios
        .get("abandoned_carts", {
          params: {
            page: this.currentPage,
            ...this.cart_filter,
          },
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.orders = data.result.abandoned_carts.data;
            this.perPage = data.result.abandoned_carts.pagination.per_page;
            this.rows = data.result.abandoned_carts.pagination.total;
            this.totalPages =
              data.result.abandoned_carts.pagination.total_pages;
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            this.createdAlert("error", message);
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    openCloseFilter(type) {
      switch (type) {
        case "open":
          this.$refs.filterBox.classList.toggle("hidden");
          break;

        default:
          break;
      }
    },
    searchAll() {
      this.search_disabled = true;
      this.getAbandonedCarts().then(() => {
        this.search_disabled = false;
      });
    },
    applyFilters() {
      this.filter_disabled = true;
      this.getAbandonedCarts().then(() => {
        this.filter_disabled = false;
        this.active = false;
      });
    },
    cancelFilters() {
      this.cancel_disabled = true;
      this.getAbandonedCarts().then(() => {
        this.cancel_disabled = false;
        this.active = false;
      });
    },
  },
};
</script>
