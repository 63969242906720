<template>
  <section class="w-full p-6">
    <form
      class="w-full max-w-screen-lg mx-auto space-y-7"
      @submit.prevent
      novalidate
      v-if="load"
    >
      <FileSystemHeader>
        <template>
          <router-link to="/branches"> الفروع والمستودعات </router-link> /
        </template>
        <template #title>اضافة فرع جديد</template>
      </FileSystemHeader>
      <figure class="w-full bg-white rounded-xl">
        <header class="w-full p-4 border-b border-gray-100">
          <h1 class="text-base font-semibold rtl:font-bold text-yellow-600">
            بيانات الفرع
          </h1>
        </header>
        <blockquote class="w-full p-6 space-y-6">
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-3">
              <label
                for="name_ar"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الاسم | عربي
              </label>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.ar.name"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["ar.name"] || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="name_en"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                الاسم | انجليزي
              </label>
              <input
                type="text"
                name="name_en"
                id="name_en"
                class="w-full py-3 px-4 rounded-lg bg-gray-100 placeholder:text-gray-500"
                v-model="add_data.en.name"
              />
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors["en.name"] || []).join(" ") }}
              </span>
            </div>
          </div>

          <div class="w-full space-y-3">
            <label
              for="tel"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              رقم الجوال
            </label>
            <vue-tel-input
              id="mobile"
              ref="registerMobile"
              v-model="add_data.mobile"
              @open="handleOpen('registerMobile')"
            ></vue-tel-input>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.mobile || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <div class="checkbox">
              <div>
                <input
                  type="checkbox"
                  name="stock"
                  id="stock"
                  :true-value="1"
                  :false-value="0"
                  v-model="add_data.is_warehouse"
                />
              </div>
              <label for="stock"> مستودع </label>
            </div>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors["is_warehouse"] || []).join(" ") }}
            </span>
          </div>
          <div class="flex w-full flex-col sm:flex-row gap-4">
            <div class="w-full space-y-2">
              <label
                for="region"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المنطقة
              </label>
              <select
                name="region"
                id="region"
                class="custom-select"
                v-model="add_data.region_id"
                :disabled="
                  locations.regions ? locations.regions.length === 0 : false
                "
              >
                <option value="" selected disabled>-- اختار --</option>
                <option
                  v-for="region in locations.regions"
                  :value="region.id"
                  :key="region.id"
                >
                  {{ region["name"] }}
                </option>
              </select>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.region_id || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-2">
              <label
                for="city"
                class="font-medium rtl:font-semibold text-gray-600 table text-sm"
              >
                المدينة
              </label>
              <select
                name="city"
                id="city"
                class="custom-select"
                v-model="add_data.city_id"
                :disabled="
                  locations.cities.filter(
                    (city) => city.region_id == add_data.region_id
                  ).length === 0
                "
              >
                <option value="" selected disabled>-- اختار --</option>
                <option
                  v-for="city in locations.cities.filter(
                    (city) => city.region_id == add_data.region_id
                  )"
                  :value="city.id"
                  :key="city.id"
                >
                  {{ city["name"] }}
                </option>
              </select>
              <span
                class="table font-medium rtl:font-semibold text-red-500 text-sm"
              >
                {{ (add_errors.city_id || []).join(" ") }}
              </span>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="district"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              الحي
            </label>
            <select
              name="district"
              id="district"
              class="custom-select"
              v-model="add_data.district_id"
              :disabled="
                locations.districts.filter(
                  (district) => district.city_id == add_data.city_id
                ).length === 0
              "
            >
              <option value="" selected disabled>-- اختار --</option>
              <option
                v-for="district in locations.districts.filter(
                  (district) => district.city_id == add_data.city_id
                )"
                :value="district.id"
                :key="district.id"
              >
                {{ district["name"] }}
              </option>
            </select>
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.district_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full space-y-3">
            <label
              for="full_location"
              class="font-medium rtl:font-semibold text-gray-600 table text-sm"
            >
              العنوان بالتفصيل
            </label>

            <gmap-autocomplete
              ref="Place"
              :value="add_data.address"
              :options="{
                componentRestrictions: { country: ['SA'] },
                fields: [],
                strictBounds: false,
                types: ['geocode'],
              }"
              class="w-full h-full relative py-3 px-4 rounded-lg bg-gray-100"
              @place_changed="setPlace"
              placeholder="..."
            />
            <span
              class="table font-medium rtl:font-semibold text-red-500 text-sm"
            >
              {{ (add_errors.address || []).join(" ") }}
            </span>

            <div class="w-full">
              <GmapMap
                :center="{
                  lat: Number(add_data.lat),
                  lng: Number(add_data.lng),
                }"
                :cluster="{ options: { styles: map.clusterStyle } }"
                :zoom="8"
                map-type-id="terrain"
                :options="{
                  fullscreenControl: false,
                  styles: map.mapStyle,
                  mapTypeControl: false,
                }"
                style="height: 400px"
                class="rounded-lg overflow-hidden"
              >
                <GmapMarker
                  :position="{
                    lat: Number(add_data.lat),
                    lng: Number(add_data.lng),
                  }"
                  :draggable="true"
                  @dragend="updatePosition"
                />
              </GmapMap>
            </div>
          </div>
        </blockquote>
      </figure>
      <button
        class="py-2 px-6 rounded-lg text-sm bg-blue-500 text-white font-medium rtl:font-semibold mt-6 ml-auto rtl:ml-0 rtl:mr-auto table"
        @click.prevent="addBranch"
        :disabled="add_disabled"
      >
        <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
        اضافة
      </button>
    </form>
    <div class="py-10 table mx-auto text-center" v-if="!load">
      <i class="fa-solid fa-spinner fa-spin text-3xl text-yellow-500"></i>
      <p class="text-gray-600 text-sm font-medium rtl:font-semibold mt-2">
        جاري تجهيز البيانات
      </p>
    </div>
  </section>
</template>
<script>
import FileSystemHeader from "@/components/FileSystemHeader.vue";
export default {
  components: { FileSystemHeader },
  name: "CreateBranch",
  data() {
    return {
      map: {
        clusterStyle: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            width: 56,
            height: 56,
            textColor: "#fff",
          },
        ],
        mapStyle: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#3e606f",
              },
              {
                weight: 2,
              },
              {
                gamma: 0.84,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                weight: 0.6,
              },
              {
                color: "#313536",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#44a688",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#f5e4e4",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: "14",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#13876c",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#067372",
              },
              {
                lightness: "-20",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#357374",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#004757",
              },
            ],
          },
        ],
      },
      locations: {},
      add_data: {
        ar: {},
        en: {},
        city_id: "",
        region_id: "",
        district_id: "",
        lat: 24.774265,
        lng: 46.738586,
        is_stock: 0,
      },
      add_errors: {},
      add_disabled: false,
      load: false,
    };
  },
  created() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      return this.axios
        .get("locations")
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.locations = data.result;
          }
        })
        .catch(({ response }) => {
          let { error_flag } = response.data;
          if (error_flag === 1) {
            this.locations = {};
          }
        })
        .finally(() => (this.load = true));
    },
    addBranch() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (["ar", "en"].includes(key)) {
          Object.keys(this.add_data[key]).forEach((trKey) => {
            fd.append(`${key}[${trKey}]`, this.add_data[key][trKey]);
          });
        } else if (key === "mobile") {
          fd.append(
            key,
            this.add_data[key].replace(/\s/g, "").replace("+", "")
          );
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      return this.axios
        .post("branches/add", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.createdAlert("success", data.message);
            this.$router.push("/branches");
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createdAlert("error", message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, (response) => {
        if (response.status === "OK") {
          const address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.Place.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(this.add_data, "address", this.$refs.Place.$refs.input.value);
      }
    },
  },
};
</script>
