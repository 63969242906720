<template>
  <figure class="w-full mx-auto">
    <figcaption class="w-full relative bg-white">
      <img
        :src="product.image || require('@/assets/images/product.png')"
        alt="product image"
        class="object-contain max-h-[17rem] max-w-full mx-auto align-middle"
        @error="$event.target.src = require('@/assets/images/placeholder.png')"
      />
      <button
        class="w-9 h-9 rounded-full bg-white shadow-lg flex items-center justify-center absolute top-2 left-2 rtl:left-auto rtl:right-2 text-color text-sm"
      >
        <i class="fa-solid fa-plus align-middle"></i>
      </button>
    </figcaption>
    <blockquote>
      <div class="w-full relative pt-4">
        <h1 class="font-medium rtl:font-semibold text-sm">
          {{ product.title }}
        </h1>
        <p
          class="text-sm font-medium rtl:font-semibold text-gray-500 line-clamp-2 mt-1 mb-4"
        >
          {{ product.content }}
        </p>
        <h5 class="text-color text-sm font-medium">
          {{ product.price.toFixed(2) }}
          <span class="line-through text-gray-500">{{
            product.price.toFixed(2)
          }}</span>
        </h5>
        <div
          class="table mt-3 py-1 px-4 rounded-sm bg-color text-content text-xs"
        >
          Flag
        </div>
      </div>
    </blockquote>
  </figure>
</template>
<script>
export default {
  name: "proProduct",
  props: ["product"],
};
</script>
